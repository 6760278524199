h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a{
    word-break: break-word;
}



.wb-container {
    max-width: 1320px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    @media (min-width:1920px) {
        max-width: 1520px;
    }

    @media (max-width: 1440px) {
        max-width: 1200px;
    }

    svg {
        width: auto;
        margin-right: 0;
    }
}

.banner-image {
    &.full-width {
        .wb-container {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }
    }

    &.rounded {
        .fit-image {
            border-radius: 15px;
            overflow: hidden;
        }
    }
}

// input\
.wb-custom-input-col-2 {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    @media (max-width:767px) {
        flex-direction: column;

    }

    .wb-custom-input {
        flex: 1 1;
        width: 50%;
        padding: 0 15px;

        @media (max-width:767px) {
            width: 100%;

        }
    }
}

// custon grid
.wb-cus-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    justify-content: center;

    .wb-cus-col-3 {
        width: calc(100% / 3);
    }
}

.password-access-layout-wrapper {
    display: flex;
    align-items: center;
    padding: 15px !important;
    justify-content: center;
    min-height: 100vh;
}

.wb-template-error-message {
    color: red;
    font-size: 14px;
    text-align: center;

    @media (min-width: 1600px) {
        font-size: 16px;
    }
}

.gif-image {
    max-width: 100%;
    margin-bottom: 25px;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain !important;
    }
}

.quil-text-block {
    .text-center{
        text-align: center !important;
    }
    p {
        display: block;
        text-align: left !important;

    @media (max-width: 767px) {
        text-align: center !important;
    }
    }

    .ql-align-right {
        text-align: right !important;
    }

    .ql-align-center {
        text-align: center !important;
    }

    .ql-align-justify {
        text-align: justify !important;
    }
}

.person-view-card-item {
    margin-bottom: 20px;
}




.masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 100%;
    margin-left: -8px;
    margin-right: -8px;

    @media (max-width:767px) {
        margin: 0 -6px;
    }
}

.masonry-grid-item {
    padding: 0 8px;
    box-sizing: border-box;

    @media (max-width:767px) {
        padding: 0 3px;
    }

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    .fit-image {
        margin-bottom: 16px;

        @media (max-width:767px) {
            margin-bottom: 6px;
        }
    }
}
.wb-password-access-error {
    background: #FAF8F5;
    border-radius: 10px;
    .wb-template-error-message {
        margin-top: 5px;
        padding: 6px 20px;
        text-align: left;
    }
}
@import "../font";
@import "rose-bud";
@import "aqua-island";
@import "toledo";
@import "bleached-cedar";
@import "fountain-blue";
@import "kalyani";
@import "saira";
@import "arya";
@import "esha";
@import "brownrust";
@import "blue-lagoon";
@import "cerise";
@import "sandhya";
@import "jiya";
