@mixin fonts {
    --wb-primaryFont: "roadworthy", serif;
    --wb-secondaryFont: "Helixa";
    --wb-belleniaFont: "Bellenia Love";
}

.cerise-theme1 {
    --white: #fff;
    --color1: #43493F; //primary color
    --color2: #E04A92; //secondary color
    --color3: #868686; //sub content color
    --color4: #F6DEF2; //section rangoli color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #D13A83; // faq rangoli color
    --color8: #E50303; //heart icon color

    --wb-title-bar-text-color: var(--color2);
    --wb-title-bar-divider-color: var(--color2);
    --wb-common-divider-color: var(--color2);
    --wb-titlebar-rangoli-color: var(--color4);
    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color2);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-banner-view-button-bg: var(--color5);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-banner-view-button-hover-bg: var(--color2);
    --wb-banner-common-divider-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-text-color: var(--color3);
    --wb-countdown-bg: var(--color2);
    --wb-countdown-text-color: var(--white);
    --wb-countdown-divider-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color2);
    --wb-accordion-icon-color: var(--white);
    --wb-accordion-rangoli-icon-color: var(--color7);
    --wb-accordion-divider-color: var(--white);
    --wb-travel-title-color: var(--color1);
    --wb-heart-icon-color: var(--color8);
    --wb-rangoli-color: var(--color4);
    --wb-faq-rangoli-color: var(--color7);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color6);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-color: var(--white);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-how-we-met-rangoli-icon-color: var(--color7);
    --wb-how-we-met-rangoli-top-icon-color: var(--color10);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color8);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color6);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-person-card-title-color: var(--color4);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-travel-title-color: var(--color1);
    --wb-travel-bg-icon-color: var(--color2);
    --wb-travel-rangoli-icon-color: var(--color8);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color11);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color6);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-registry-rangoli-icon-color: var(--color8);
    --wb-footer-text-color: var(--color2);
    --wb-full-carousel-bg-icon-color: var(--color2);
    --wb-website-event-card-bg: var(--color2);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color7);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color2);


}

.cerise {
    @include fonts;
    background: var(--white);

    // button
    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 16px;
        font-weight: 500;
        padding: 12px 20px 12px 20px;
        text-transform: uppercase;
        border-radius: 0;

        &:hover {
            background-color: var(--wb-view-button-hover-bg);
            transition: background-color 0.3s ease;
        }

        @media (max-width: 1366px) {
            padding: 10px 25px;
            font-size: 14px;
        }
    }

    // divider
    .cerise-divider {
        display: flex;
        align-items: center;

        svg {
            width: 100%;
            margin: 0 auto;

            @media (max-width: 767px) {
                width: 150px;
            }

            path {
                fill: var(--wb-common-divider-color);
            }
        }
    }

    // rangoli section icon
    .cerise-rangoli-bg-icon {
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
            margin: 0 auto;

            path {
                fill: var(--wb-titlebar-rangoli-color);
            }
        }
    }

    //
    .cerise-rangoli-flower-icon {
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
            margin: 0 auto;

            path {
                fill: var(--wb-titlebar-rangoli-color);
            }
        }

    }

    // title bar

    .wb-cerise-title-bar-section {
        padding: 0 0 30px;
        width: 100%;
        margin: 0 auto;

        .wb-title-bar {
            h2 {
                color: var(--wb-title-bar-text-color);
                font-size: 60px;
                text-align: center;
                font-weight: 400;
                line-height: 68.36px;
                font-family: var(--wb-primaryFont);
                display: flex;
                align-items: baseline;
                justify-content: center;
                gap: 10px;

                @media (max-width: 767px) {
                    font-size: 40px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 23.2px;

                    @media (max-width: 767px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    // section head(common head)

    .indo-western-common-head {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;


        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            text-align: center;
            line-height: 58px;

            @media (max-width: 1600px) {
                font-size: 45px;
            }

            @media (max-width: 1440px) {
                font-size: 40px;
            }

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 1024px) {
                font-size: 32px;
            }

            @media (max-width: 992px) {
                font-size: 30px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-belleniaFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 40.14px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 30px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 35px;
            }
        }
    }

    // bride groom name
    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 10px 0 50px;
        margin: 0;

        @media (max-width: 1366px) {
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding: 25px 0;
        }

        @media (max-width: 580px) {
            padding: 20px 0;
        }

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            font-family: var(--wb-primaryFont);
            color: var(--wb-bridegroom-text-color);
            font-size: 80px;
            font-weight: 400;
            line-height: 92px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1600px) {
                font-size: 65px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                font-size: 60px;
            }

            @media (max-width: 1280px) {
                font-size: 55px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
                gap: 18px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 24px;
                flex-direction: column;
                gap: 3px;
            }



            span {
                text-align: center;
                text-align: left;
            }

            span:nth-child(1) {
                text-align: right;

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

            span:nth-child(2) {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 1024px) {
                    font-size: 20px;
                }

                @media (max-width: 1024px) {
                    font-size: 15px;
                }
            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 19px;
            margin-top: 10px;
            align-items: center;

            @media (max-width: 576px) {
                gap: 6px;
            }

            span {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 27.72px;
                color: var(--wb-banner-date-text-color);

                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 20.72px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: normal;
                }

                &:nth-child(1) {
                    flex: 1;
                    text-align: right;
                }

                &:nth-child(3) {
                    flex: 1;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 2px;
                    height: 18px;
                    background: var(--white);

                    @media (max-width: 767px) {
                        display: none;

                    }
                }
            }
        }
    }

    // slider banner

    .indo-western-slider-banner-wrp {
        position: relative;
        z-index: 9;

        .indo-western-banner-slider-image {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                top: 0;
                opacity: 0.3;
                left: 0px;
                right: 0;
                margin: 0;

            }

            img {
                object-fit: cover;
                height: 100vh !important;
            }
        }

        .indo-western-banner-slider-content {
            position: absolute;
            bottom: 10%;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            width: 100%;

            @media (max-width: 767px) {
                top: 20%;
            }

            .cerise-divider {
                svg {

                    path {
                        fill: var(--wb-banner-common-divider-color);
                    }
                }
            }

            h4 {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 20px;
                font-weight: 400;
                color: var(--wb-banner-text-color);
                margin-bottom: 30px;

                @media (max-width: 767px) {
                    font-size: 15px;
                }

                @media (max-width: 567px) {
                    font-size: 12px;
                }
            }

            .wb-view-btn {
                color: var(--wb-banner-button-text-color);
                background: var(--wb-banner-view-button-bg);
                border: 1px solid var(--wb-banner-button-border);
                margin-top: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                letter-spacing: 3px;

                @media (max-width: 767px) {
                    padding: 10px 25px;
                    font-size: 14px;
                    line-height: normal;
                }

                &:hover {
                    background-color: var(--wb-banner-view-button-hover-bg);
                    transition: background-color 0.3s ease;
                }
            }
        }
    }

    // how we met
    .wb-cerise-how-we-met-section {
        position: relative;

        .wb-cerise-how-we-met-left-icon {
            position: absolute;
            left: 0;
            top: 0;
            width: 400px;
            transform: translate(-180px, -57px);

            .cerise-rangoli-flower-icon {
                svg {
                    transform: scaleX(-1);
                    width: 600px;
                    overflow: hidden;

                    path {
                        fill: var(--wb-rangoli-color);
                    }
                }
            }
        }

        .wb-cerise-how-we-met-bottom-icon {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 300px;
            transform: translate(108px, -82px);

            .cerise-rangoli-flower-icon {
                svg {
                    width: 300px;

                    path {
                        fill: var(--wb-rangoli-color);
                    }
                }
            }
        }

        .wb-cerise-how-we-met-wrp {
            padding: 150px 0;

            @media (max-width:1600px) {
                padding: 120px 0;

            }

            @media (max-width:1366px) {
                padding: 80px 0;

            }

            @media (max-width:1199px) {
                padding: 50px 0;

            }

            @media (max-width:767px) {
                padding: 30px 0;

            }

            .wb-container {
                .wb-cus-row {
                    gap: 50px;
                    flex-wrap: nowrap;
                    align-items: center;

                    @media (max-width:767px) {
                        flex-wrap: wrap;
                        flex-direction: column;
                        gap: 20px;

                    }

                    .wb-col-3 {
                        padding: 0 15px;
                        flex: 0 0 auto;
                        width: calc(100% / 3);

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }

                    img {
                        width: 453px !important;
                        height: 562px !important;
                        max-width: 100% !important;
                        max-height: 100% !important;
                        object-fit: cover;

                        @media (max-width: 767px) {
                            width: 100% !important;
                            height: auto;
                            max-height: 300px !important;

                        }
                    }

                    p {
                        font-family: var(--wb-secondaryFont);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        color: var(--wb-sub-content-color);
                        margin: 40px 0;
                        text-align: center;

                        @media (max-width: 767px) {
                            font-size: 16px;
                            margin: 20px 0;
                        }
                    }

                }
            }
        }
    }

    // venue location
    .wb-cerise-venue-location-wrp {
        position: relative;
        background: var(--wb-venue-bg);
        overflow: hidden;

        .wb-container {
            padding: 150px 15px;

            @media (max-width: 1366px) {
                padding: 120px 15px;
            }

            @media (max-width: 992px) {
                padding: 90px 15px;
            }

            @media (max-width: 767px) {
                padding: 60px 15px;
            }

            .wb-cus-row {
                align-items: center;
                gap: 40px;
                flex-wrap: nowrap;

                @media (max-width:767px) {
                    flex-direction: column;
                    row-gap: 30px;
                }

                .wb-cus-col-3 {
                    padding: 0 15px;

                    @media (max-width:767px) {
                        width: 100%;

                    }

                }

                .col-order-1 {
                    @media (max-width:767px) {
                        order: 1;
                    }

                }

                .venue-left-image {
                    .fit-image {
                        width: 543px;

                        img {
                            width: 100% !important;
                            height: 632px !important;
                            max-width: 100% !important;
                            max-height: 100% !important;
                            object-fit: cover;
                        }
                    }
                }

                .venue-right-image {
                    position: relative;
                    margin-left: -9rem;

                    img {
                        width: 556px !important;
                        height: 518px !important;
                        max-width: 100% !important;
                        max-height: 100% !important;
                        object-fit: cover;
                    }

                }

                .wb-cerise-venue-info {
                    z-index: 9;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;


                    .common-head-divider {
                        svg {
                            path {
                                fill: var(--wb-venue-common-head-divider-color);
                            }
                        }
                    }

                    .wb-view-btn {
                        font-weight: 400;
                        font-size: 14px;
                        margin-top: 60px;

                        @media (max-width: 1600px) {
                            margin-top: 50px;
                        }

                        @media (max-width: 1366px) {
                            margin-top: 40px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 30px;
                        }
                    }

                    p {
                        color: var(--wb-venue-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        text-align: center;
                        margin-top: 50px;

                        @media (max-width: 1600px) {
                            font-size: 20px;
                            line-height: 41px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 18px;
                            line-height: 39px;
                            margin-top: 40px;
                        }

                        @media (max-width: 1199px) {
                            font-size: 16px;
                            line-height: 37px;
                            margin-top: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 35px;
                            text-align: center;
                            margin-top: 20px;
                        }
                    }

                    .venue-card-divider {
                        margin-top: 60px;

                        @media (max-width: 1600px) {
                            margin-top: 50px;
                        }

                        @media (max-width: 1366px) {
                            margin-top: 40px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 30px;
                        }

                        svg {
                            path {
                                fill: var(--wb-venue-common-head-divider-color);
                            }
                        }
                    }
                }
            }

            .cerise-venue-divider {
                margin-top: 60px;
            }
        }
    }

    // countdown
    .indo-western-count-down-wrp {
        padding: 50px 0;
        background: var(--wb-countdown-bg);

        @media (max-width: 1024px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-cus-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;

            .wb-divider {
                display: none;
            }

            .indo-western-count-down-content {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 576px) {
                    width: calc(100% / 2);
                }

                h6 {
                    color: var(--wb-countdown-text-color);
                    font-family: var(--wb-belleniaFont);
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 53.52px;
                    text-transform: capitalize;

                    @media (max-width: 1366px) {
                        font-size: 30px;
                        line-height: normal;
                    }

                    @media (max-width: 767px) {
                        font-size: 25px;
                    }
                }

                h4 {
                    color: var(--wb-countdown-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 58px;

                    @media (max-width: 1366px) {
                        font-size: 45px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 40px;
                    }

                    @media (max-width: 992px) {
                        font-size: 35px;
                    }
                }

                .countdown-divider {
                    svg {
                        width: 70%;

                        path {
                            fill: var(--wb-countdown-divider-color);
                        }
                    }
                }
            }
        }
    }

    // faq

    .wb-cerise-q-and-a-section {
        position: relative;
        z-index: 9;
        background: var(--wb-accordion-bg);

        .wb-cerise-q-and-a-top-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 350px;
            transform: translateY(-15%);

            .cerise-rangoli-flower-icon {
                overflow: hidden;

                svg {

                    width: 600px;
                    overflow: hidden;

                    path {
                        fill: var(--wb-faq-rangoli-color);
                    }
                }
            }
        }

        .wb-cerise-q-and-a-bottom-icon {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(-227px, 55px);

            .cerise-rangoli-flower-icon {
                svg {
                    transform: rotate(145deg);
                    width: 440px;
                    overflow: hidden;

                    path {
                        fill: var(--wb-faq-rangoli-color);
                    }
                }
            }
        }

        .wb-expandable-faq-wrp {
            padding: 150px 0;
            position: relative;

            .indo-western-common-head {

                h3 {
                    color: var(--wb-accordion-title-color);

                }

                h6 {
                    color: var(--wb-accordion-title-color);

                }
            }

            .cerise-divider {
                svg {

                    path {
                        fill: var(--wb-accordion-divider-color);
                    }
                }
            }

            .wb-accordion-wrp {
                padding: 50px 0;
                padding-bottom: 0;

                @media (max-width: 1366px) {
                    padding: 40px 0;
                }

                @media (max-width: 1199px) {
                    padding: 30px 0;
                }

                @media (max-width: 992px) {
                    padding: 25px 30px;
                }

                @media (max-width: 767px) {
                    padding: 20px 0px;
                }

                .wb-accordion {
                    margin-bottom: 0;
                    padding: 50px;
                    border-bottom: 1px solid var(--wb-accordion-border-color);
                    border-left: 1px solid var(--wb-accordion-border-color);
                    border-right: 1px solid var(--wb-accordion-border-color);

                    @media (max-width: 1366px) {
                        padding: 30px;

                    }


                    @media (max-width: 992px) {
                        padding: 20px;

                    }


                    &:nth-child(1) {
                        border-top: 1px solid var(--wb-accordion-border-color);
                    }

                    .wb-accordion-header {
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h3 {
                            font-size: 30px;
                            color: var(--wb-accordion-title-color);
                            font-weight: 400;
                            line-height: 34px;
                            margin: 30px 0;
                            font-family: var(--wb-primaryFont);

                            @media (max-width: 1600px) {
                                font-size: 28px;
                                margin: 28px 0;
                            }

                            @media (max-width: 1440px) {
                                font-size: 26px;
                                margin: 26px 0;
                            }

                            @media (max-width: 1366px) {
                                font-size: 24px;
                                margin: 24px 0;
                            }

                            @media (max-width: 1199px) {
                                font-size: 22px;
                                margin: 22px 0;
                            }

                            @media (max-width: 992px) {
                                font-size: 20px;
                                margin: 20px 0;
                            }

                            @media (max-width: 767px) {
                                font-size: 18px;
                                margin: 18px 0;
                            }
                        }

                        .wb-accordion-icon {
                            transition: transform 0.3s ease-in-out;

                            &.open {
                                transform: rotateX(180deg);
                            }

                            svg {
                                width: auto;
                                margin-right: 0;

                                @media (max-width: 767px) {
                                    width: 30px;
                                }

                                @media (max-width: 420px) {
                                    width: 25px;
                                }

                                path {
                                    fill: var(--wb-accordion-icon-color);
                                }
                            }
                        }
                    }

                    .wb-accordion-content {
                        max-height: 0;
                        overflow: hidden;

                        p {
                            color: var(--wb-accordion-text-color);
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 38px;
                            font-family: var(--wb-secondaryFont);

                            @media (max-width: 1600px) {
                                font-size: 17px;
                                line-height: 36px;
                            }

                            @media (max-width: 1440px) {
                                font-size: 16px;
                                line-height: 34px;
                            }

                            @media (max-width: 1366px) {
                                font-size: 15px;
                                line-height: 32px;
                            }

                            @media (max-width: 1199px) {
                                line-height: 30px;
                            }

                            @media (max-width: 992px) {
                                line-height: 28px;
                            }

                            @media (max-width: 767px) {
                                font-size: 14px;
                                line-height: 26px;
                            }
                        }

                        &.open {
                            max-height: 100%;
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
    }

    // things to do
    .wb-cerise-things-to-do-section {
        padding-bottom: 150px;

        @media (max-width: 1600px) {
            padding-bottom: 120px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 100px;
        }

        @media (max-width: 992px) {
            padding-bottom: 80px;
        }

        @media (max-width: 767px) {
            padding-bottom: 50px;
        }

        .common-head-divider {
            margin-bottom: 100px;

            @media (max-width: 768px) {
                margin-bottom: 30px;
            }

        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1024px) {
                gap: 30px 0;
            }

            .wb-activity-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100%;
                width: calc(100% / 3);
                padding: 0 15px;

                @media (max-width:767px) {
                    width: 100%;

                }

                .wb-activity-image {
                    width: 100%;
                }

                img {
                    width: 100%;
                    max-width: 100% !important;
                    height: 430px !important;
                    object-fit: cover;

                    @media (max-width: 1600px) {
                        height: 380px !important;
                    }

                    @media (max-width: 1366px) {
                        height: 300px !important;
                    }

                    @media (max-width: 767px) {
                        height: 250px !important;
                    }
                }

                h4 {
                    color: var(--wb-things-to-do-card-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 34.65px;
                    padding-bottom: 10px;
                    margin-top: 50px;

                    @media (max-width: 1366px) {
                        margin-top: 30px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 20px;
                        line-height: 25.65px;
                    }

                    @media (max-width: 1366px) {
                        margin-top: 20px;
                    }

                }

                h6 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    padding-bottom: 20px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 35px;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 28px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 28px;
                        padding-bottom: 10px;
                    }
                }

                h5 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 1024px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }

                p {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    padding-bottom: 20px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }

                .wb-view-btn {
                    margin-top: 20px;
                    background: var(--Wb-things-to-do-button-bg);
                    color: var(--wb-things-to-do-button-text-color);
                    font-size: 14px;
                    font-weight: 400;

                    &:hover {
                        background: var(--Wb-things-to-do-button-hover-bg);
                        color: var(--wb-things-to-do-button-hover-text-color);
                    }
                }
            }
        }
    }

    // footer
    .wb-cerise-footer-couple-name {
        position: relative;
        padding: 40px 0;

        @media (max-width:767px) {
            padding: 30px 0;

        }

        .wb-container {
            padding: 0;
        }

        .wb-cerise-footer-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 23px;

            .footer-left-divider,
            .footer-right-divider {
                svg {
                    path {
                        fill: var(--wb-footer-divider-color);
                    }

                    @media (max-width:767px) {
                        width: 50px;
                    }
                }
            }

            .footer-right-divider {
                transform: rotate(180deg);

            }
        }

        .wb-footer-couple-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h5 {
                font-size: 60px;
                font-weight: 400;
                line-height: 69.23px;
                color: var(--wb-footer-text-color);
                font-family: var(--wb-primaryFont);
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: baseline;
                gap: 10px;
                text-transform: lowercase;


                @media (max-width: 1440px) {
                    font-size: 45px;
                    line-height: 51.69px;
                }

                @media (max-width: 1199px) {
                    font-size: 40px;
                    line-height: 46.15px;
                }

                @media (max-width: 767px) {
                    font-size: 30px;
                    line-height: 34.61px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    line-height: 68.12px;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }

            h6 {
                display: none;
            }

            .wb-divider {
                display: none;
            }

        }


    }

    // rsvp
    .jiya-rsvp-form {
        position: relative;

        .jiya-rsvp-form-bg {
            img {
                max-width: 100% !important;
                max-height: 580px !important;
                width: 100% !important;
                height: auto !important;
                object-fit: cover;

                @media (max-width:767px) {
                    height: auto !important;
                    max-height: 100% !important;

                }

            }
        }

        .jiya-rsvp-form-wrp {
            position: relative;
            z-index: 9;

            .jiya-rsvp-form-title {
                text-align: center;
                flex-direction: column;
                display: flex;
                margin-bottom: 20px;

                h3 {
                    color: var(--wb-rsvp-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    text-align: center;

                    @media (max-width: 1440px) {
                        font-size: 40px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 35px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 32px;
                    }

                    @media (max-width: 992px) {
                        font-size: 30px;
                    }

                    @media (max-width: 420px) {
                        font-size: 28px;
                    }
                }

                h6 {
                    color: var(--wb-rsvp-title-color);
                    font-family: var(--wb-belleniaFont);
                    font-size: 18px;
                    text-transform: capitalize;
                    font-weight: 400;
                    line-height: 35px;
                    text-align: center;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }
            }

            .jiya-rsvp {
                background: var(--wb-rsvp-bg);
                padding: 60px;
                max-width: 650px;
                margin: 0 auto;
                margin-top: -17rem;


                @media (max-width: 1440px) {
                    padding: 45px;
                    max-width: 550px;
                    margin-top: -14rem;
                }

                @media (max-width: 992px) {
                    padding: 35px;
                    max-width: 450px;
                    margin-top: -9.5rem;
                }

                @media (max-width: 767px) {
                    padding: 30px;
                    max-width: 350px;
                    margin-top: -4.5rem;
                }

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    margin-top: 50px;

                    @media (max-width:767px) {
                        gap: 15px;
                        margin-top: 20px;
                    }
                }

                .wb-cus-input {
                    background-color: transparent;
                    border: 1px solid #e7e7e7;
                    border-radius: 0px;
                    padding: 10px 16px;
                    margin-bottom: 10px;
                    color: var(--wb-rsvp-text-color);
                    font-weight: 400;
                    font-size: 14px;
                    width: 80%;
                    height: 40px;
                    outline: none;
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    &::placeholder {
                        color: var(--wb-input-placeholder-color) !important;
                        font-family: var(--wb-secondaryFont);
                        line-height: 15.12px;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }

                .wb-view-btn {
                    width: fit-content;
                    text-transform: uppercase;
                    background-color: var(--wb-rsvp-btn-bg);
                    color: var(--wb-rsvp-btn-text-color);
                    font-family: var(--wb-secondaryFont);
                    margin: 30px 0;

                    &:hover {
                        background-color: var(--wb-rsvp-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-rsvp-btn-hover-text-color);
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                        margin: 20px 0;
                    }

                    @media (max-width: 560px) {
                        padding: 10px;
                        margin: 15px 0;
                    }
                }
            }
        }
    }


    // registry
    .wb-cerise-registry-section {
        position: relative;

        .wb-cerise-registry-rangoli-top {
            position: absolute;
            top: 0;
            left: -145px;
            width: 400px;

            @media (max-width:1440px) {
                left: -78px;
                width: 200px;
            }

            @media (max-width:1024px) {
                left: -56px;
                width: 180px;
            }

            @media (max-width:767px) {
                left: -79px;
                width: 150px;
            }

            @media (max-width:567px) {
                left: -36px;
                width: 80px;
            }

            svg {
                width: 360px;
                height: 100%;
                transform: rotate(90deg);

                @media (max-width:1440px) {
                    width: 200px;
                }

                @media (max-width:1024px) {
                    width: 160px;
                }

                @media (max-width:767px) {
                    width: 150px;
                }

                @media (max-width:567px) {
                    width: 90px;
                }

                path {
                    fill: var(--wb-registry-rangoli-icon-color);
                }
            }
        }

        .wb-cerise-registry-rangoli-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 220px;
            transform: translateY(34%);
            overflow: hidden;

            @media (max-width:1440px) {
                width: 160px;
                transform: translateY(35%);
            }

            @media (max-width:1024px) {
                width: 120px;
                transform: translateY(35%);
            }

            @media (max-width:767px) {
                width: 95px;
            }

            @media (max-width:567px) {
                width: 65px;
            }

            svg {
                width: 360px;
                height: 100%;
                transform: rotate(-91deg);

                @media (max-width:1440px) {
                    width: 250px;
                }

                @media (max-width:1024px) {
                    width: 190px;
                }

                @media (max-width:767px) {
                    width: 150px;
                }

                @media (max-width:567px) {
                    width: 90px;
                }

                path {
                    fill: var(--wb-registry-rangoli-icon-color);
                }
            }
        }

        .jiya-common-head-wrp {
            padding-top: 100px;

            @media (max-width: 1600px) {
                padding-top: 80px;
            }

            @media (max-width: 1199px) {
                padding-top: 50px;
            }

            @media (max-width: 767px) {
                padding-top: 30px;
            }
        }

        .wb-registry-card-wrp {
            padding-bottom: 150px;

            @media (max-width: 1600px) {
                padding-bottom: 120px;
            }

            @media (max-width: 992px) {
                padding-bottom: 80px;
            }

            @media (max-width: 767px) {
                padding-bottom: 30px;
                z-index: 9;
                position: relative;

            }

            .wb-cus-row {
                row-gap: 30px;

                @media (max-width: 1199px) {
                    row-gap: 20px;
                }

                @media (max-width: 767px) {
                    row-gap: 15px;
                }
            }

            .wb-col-3 {
                padding: 0 15px;
                flex: 0 0 auto;
                width: calc(100% / 4);

                @media (max-width: 1199px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 767px) {
                    width: calc(100% / 2);
                }

                @media (max-width: 567px) {
                    width: 100%;
                }
            }

            .wb-registry-card-item {
                border: 1px solid var(--Wb-registry-card-border);
                background: var(--Wb-registry-card-bg);
                padding: 30px;

                @media (max-width: 1024px) {
                    padding: 20px;
                }

                .wb-registry-card-img {
                    text-align: center;
                    border-radius: 0;
                    margin-bottom: 10px;
                    img {
                        width: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .wb-view-btn {
                    width: 100%;
                    background-color: var(--wb-registry-btn-bg);
                    color: var(--wb-registry-btn-text-color);

                    &:hover {
                        background-color: var(--wb-registry-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-registry-btn-hover-text-color);
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 35px;

                    @media (max-width: 1600px) {
                        font-size: 23px;
                        line-height: 33px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 21px;
                        line-height: 31px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 19px;
                        line-height: 29px;
                    }

                    @media (max-width: 767px) {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }

                h3 {
                    margin: 20px 0;
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    font-size: 28px;

                    @media (max-width: 1199px) {
                        font-size: 25px;
                    }

                    @media (max-width: 992px) {
                        font-size: 22px;
                    }

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    margin-bottom: 20px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    // common component styles
    //person view (wedding party card)
    .wb-cerise-wedding-party-section {
        position: relative;

        .wb-cerise-wedding-party-left-icon {
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(-272px, -15px);

            .cerise-rangoli-flower-icon {
                svg {
                    transform: rotate(145deg);
                    width: 600px;
                    overflow: hidden;

                    path {
                        fill: var(--wb-rangoli-color);
                    }
                }
            }
        }

        .wb-cerise-wedding-party-bottom-icon {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(-123px, 143px);

            .cerise-rangoli-flower-icon {
                svg {
                    width: 440px;
                    overflow: hidden;

                    path {
                        fill: var(--wb-rangoli-color);
                    }
                }
            }
        }

        .wb-cerise-wedding-party-right-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 350px;
            transform: translateY(50%);

            .cerise-rangoli-flower-icon {
                overflow: hidden;

                svg {

                    width: 600px;
                    overflow: hidden;

                    path {
                        fill: var(--wb-rangoli-color);
                    }
                }
            }
        }

        .wb-cerise-wedding-party-wrp {
            padding: 150px 0;

            .person-view-card {
                margin: 0;
                margin-top: 50px;
                position: relative;

                .wb-cus-row {
                    row-gap: 60px;
                    justify-content: center;

                    @media (max-width: 1440px) {
                        row-gap: 50px;
                    }

                    @media (max-width: 1366px) {
                        row-gap: 45px;
                    }

                    @media (max-width: 1199px) {
                        row-gap: 40px;
                    }

                    @media (max-width: 992px) {
                        row-gap: 35px;
                    }
                }

                .person-view-card-item {
                    padding: 0 15px;
                    width: calc(100% / 5);
                    text-align: center;

                    @media (max-width: 767px) {
                        width: calc(100% / 2);
                    }

                    img {
                        width: 100%;
                        max-width: 200px !important;
                        height: 300px !important;
                        object-fit: cover;

                        @media (max-width: 1366px) {
                            max-width: 180px !important;
                            height: 250px !important;
                        }

                        @media (max-width: 1024px) {
                            height: 185px !important;
                        }

                        @media (max-width: 992px) {
                            height: 200px !important;
                            max-width: 150px !important;

                        }

                        @media (max-width: 767px) {
                            height: auto !important;
                            max-width: auto !important;

                        }
                    }

                    h4 {
                        color: var(--wb-card-title-color);
                        font-family: var(--wb-primaryFont);
                        font-size: 26px;
                        font-weight: 400;
                        line-height: 35px;
                        margin: 10px 0 5px;

                        @media (max-width: 1600px) {
                            font-size: 24px;
                            line-height: 33px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 22px;
                            line-height: 31px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 20px;
                            line-height: 29px;
                        }

                        @media (max-width: 1199px) {
                            font-size: 18px;
                            line-height: 27px;
                        }

                        @media (max-width: 992px) {
                            line-height: 25px;
                        }

                        @media (max-width: 767px) {
                            line-height: 23px;
                        }
                    }

                    h6 {
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;

                        @media (max-width: 1600px) {
                            font-size: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }

                    p {
                        color: var(--wb-primary-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 14px;
                        font-weight: 400;
                        margin-top: 8px;
                    }
                }
            }
        }
    }

    // hotel view
    .wb-cerise-hotel-section {
        .single-hotel-transport-wrp {
            padding-bottom: 50px;

            @media (max-width:1440px) {
                padding-bottom: 40px;

            }

            @media (max-width:767px) {
                padding-bottom: 30px;

            }

            .wb-cus-row {
                display: flex;
                flex-wrap: nowrap;
                gap: 70px;

                @media (max-width:1440px) {
                    gap: 50px;
                }

                @media (max-width:1199px) {
                    gap: 40px;
                }

                @media (max-width:767px) {
                    flex-wrap: wrap;
                    gap: 20px;
                    flex-direction: column;

                }

            }



            .wb-hotel-view {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 40px 0;
                padding: 0 15px;
                width: calc(100% / 2);


                @media (max-width:767px) {
                    width: 100%;
                    margin: 0px 0;
                }

                .wb-hotel-image {
                    img {
                        width: 100% !important;
                        height: 400px !important;
                        max-width: 100% !important;
                        max-height: 100% !important;
                        object-fit: cover;
                        border-radius: 0;

                        @media (max-width: 1600px) {
                            height: 320px !important;
                        }

                        @media (max-width: 1366px) {
                            height: 280px !important;
                        }

                        @media (max-width: 767px) {
                            height: 200px !important;
                        }
                    }
                }

                h6 {
                    font-size: 16px;
                    color: var(--wb-travel-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 500;
                    line-height: 35px;
                    text-align: center;

                    @media (max-width: 1440px) {
                        line-height: 26px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }

                }

                h4 {
                    font-size: 25px;
                    font-weight: 600;
                    line-height: 40.4px;
                    color: var(--wb-travel-title-color);
                    font-family: var(--wb-primaryFont);
                    margin: 30px 0;
                    margin-bottom: 0;

                    @media (max-width: 1366px) {
                        margin: 25px 0;
                    }

                    @media (max-width: 1199px) {
                        font-size: 22px;
                        line-height: 28px;
                    }

                    @media (max-width: 767px) {
                        font-size: 18px;
                        line-height: 22px;
                        margin: 15px 0;
                    }
                }

                p {
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 35px;
                    color: var(--wb-travel-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    text-align: center;

                    @media (max-width: 1440px) {
                        font-size: 16px;
                        line-height: 25px;
                    }

                    @media (max-width: 992px) {
                        line-height: 25px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                .wb-view-btn {
                    margin-top: 30px;
                    border: 1px solid var(--Wb-travel-button-border);
                    color: var(--Wb-travel-button-text-color);
                    background: var(--Wb-travel-button-bg);
                    border-radius: 0;

                    &:hover {
                        background: var(--wb-view-button-hover-bg);
                        color: var(--wb-view-button-hover-text);
                        transition: background-color 0.3s ease;
                    }
                }

            }

            .wb-transport-image {
                img {
                    width: 100% !important;
                    height: 400px !important;
                    max-width: 100% !important;
                    max-height: 100% !important;
                    object-fit: cover;
                    border-radius: 0;

                    @media (max-width: 1600px) {
                        height: 320px !important;
                    }

                    @media (max-width: 1366px) {
                        height: 280px !important;
                    }

                    @media (max-width: 767px) {
                        height: 200px !important;
                    }

                }
            }

            .wb-transport-view {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 40px 0;
                padding: 0 15px;
                width: calc(100% / 2);
                height: 100%;

                @media (max-width:767px) {
                    width: 100%;

                }

                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 35px;
                    color: var(--wb-travel-sub-content-color);
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 1440px) {
                        line-height: 28px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                h4 {
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 40.4px;
                    color: var(--wb-travel-title-color);
                    font-family: var(--wb-secondaryFont);
                    margin: 30px 0;
                    margin-bottom: 0;

                    @media (max-width: 1366px) {
                        margin: 25px 0;
                    }

                    @media (max-width: 1199px) {
                        font-size: 22px;
                        line-height: 28px;
                    }

                    @media (max-width: 767px) {
                        font-size: 18px;
                        line-height: 24px;
                        margin: 15px 0;
                    }
                }


                p {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 35px;
                    color: var(--wb-travel-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    text-align: center;

                    @media (max-width: 1440px) {
                        font-size: 16px;
                        line-height: 25px;
                    }

                    @media (max-width: 992px) {
                        line-height: 30px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                .wb-view-btn {
                    margin-top: 30px;
                    border: 1px solid var(--Wb-travel-button-border);
                    color: var(--Wb-travel-button-text-color);
                    background-color: var(--Wb-travel-button-bg);
                    border-radius: 0;

                    &:hover {
                        background: var(--wb-view-button-hover-bg);
                        color: var(--wb-view-button-hover-text);
                        transition: background-color 0.3s ease;
                    }

                }

            }

            .wb-divider {
                display: none;
            }
        }
    }

    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);
                a {
                    color: inherit;
                }
                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a {
                        color: var(--wb-primary-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 35px;

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }

    // events
    .wb-cerise-event-section {
        padding-bottom: 150px;

        @media (max-width: 1600px) {
            padding-bottom: 120px;
        }


        @media (max-width: 1440px) {
            padding-bottom: 100px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 70px;
        }

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }

        .wb-website-event-wrp {
            margin-top: 50px;

            .wb-cus-row {
                row-gap: 30px;

                @media (max-width: 767px) {
                    row-gap: 15px;
                }
            }

            .wb-cus-col-2 {
                width: calc(100% / 2);
                padding: 0 15px;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .wb-website-event-card {
                background-color: var(--wb-website-event-card-bg);
                padding: 60px;
                height: 100%;

                @media (max-width: 1440px) {
                    padding: 40px;
                }

                @media (max-width: 767px) {
                    padding: 30px;
                }

                .wb-website-event-date-time {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid var(--wb-event-date-border-color);
                    padding-bottom: 30px;
                    margin-bottom: 30px;

                    @media (max-width: 1440px) {
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }

                    @media (max-width: 767px) {
                        gap: 10px;
                    }

                    .start-date-time {
                        text-align: center;
                    }

                    .end-date-time {
                        text-align: center;
                    }

                    h6 {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 26.8px;
                        text-transform: uppercase;

                        @media (max-width: 1440px) {
                            font-size: 17px;
                            line-height: 19.8px;
                        }

                        @media (max-width: 992px) {
                            font-size: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 12px;
                        }
                    }

                    h5 {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 47px;

                        @media (max-width: 1440px) {
                            font-size: 24px;
                            line-height: 38px;
                        }

                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 15px;
                            line-height: 18px;
                        }
                    }

                    span {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-event-span-text-color);
                        background: var(--wb-event-span-bg);
                        font-size: 20px;
                        font-weight: 600;
                        line-height: normal;
                        background-color: var(--wb-event-btn-bg);
                        padding: 20px;
                        text-transform: capitalize;

                        @media (max-width: 1440px) {
                            font-size: 18px;
                            padding: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 15px;
                            padding: 10px;
                        }
                    }
                }

                .wb-website-event-details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h5 {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 40px;
                        font-weight: 600;
                        line-height: 48.59px;
                        margin-bottom: 20px;

                        @media (max-width: 1440px) {
                            font-size: 30px;
                            line-height: 35.59px;
                        }

                        @media (max-width: 992px) {
                            font-size: 26px;
                            line-height: 30.59px;
                            margin-bottom: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                            line-height: 25.59px;
                        }
                    }

                    p {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 34px;
                        text-align: center;

                        @media (max-width: 1366px) {
                            font-size: 16px;
                            line-height: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }

                    .wb-view-btn {
                        margin-top: 30px;
                        text-transform: uppercase;
                        background-color: var(--wb-event-btn-bg);
                        color: var(--wb-event-btn-text-color);

                        @media (max-width: 992px) {
                            margin-top: 20px;
                        }

                        &:hover {
                            background-color: var(--wb-event-btn-hover-bg);
                            transition: background-color 0.3s ease;
                            color: var(--wb-event-btn-hover-text-color);
                        }
                    }
                }
            }
        }
    }


    // title view
    .wb-large-tile {
        h2 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }

        }

    }

    .wb-small-tile {
        h4 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-belleniaFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }


    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family: var(--wb-primaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-primary-text-color);
            margin-bottom: 20px;
            text-align: center;
            text-transform: capitalize;
        }

        p,
        .quil-text-block {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width:767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;

        }

        p {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .wb-view-btn {
                margin-top: 30px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width:1440px) {
                row-gap: 50px;
            }

            @media (max-width:1199px) {
                row-gap: 40px;
            }

            @media (max-width:992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width:767px) {
                width: 50%;
            }

            @media (max-width:640px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 0px;
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                margin: 10px 0 5px;
                text-transform: uppercase;

                @media (max-width:1366px) {
                    font-size: 22px;
                }

                @media (max-width:992px) {
                    font-size: 18px;
                    line-height: 25px;
                }

            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width:1366px) {
                    font-size: 18px;
                }

                @media (max-width:992px) {
                    font-size: 16px;
                }

            }
        }
    }
}