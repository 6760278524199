@mixin fonts {
    --wb-primaryFont: "Rouge Script";
}

.fountain-blue {
    @include fonts;

    --white: #fff;
    --color1: #7d5231;
    --color2: #e9edef;
    --color3: #f68861;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #e50303; //heart icon color
    --color8: #46b7bc;
    --color9: #262626;
    --color10: #2fb0b5;

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color8);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color9);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color10);
    --wb-event-head-text-color: var(--color1);
    --wb-event-content-text-color: var(--color9);
    --wb-place-text-color: var(--color9);
    --wb-footer-text-color: var(--color1);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color9);
    --wb-secondary-text-color: var(--color9);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: #33434a;
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--white);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color10);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color10);
    --wb-heart-icon-color: var(--color7);

    background: url("../../public/images/wedding-websites/fountain-blue-bg.png"), #ebebeb !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    background-size: 100%;

    .inner-wrapper {
        max-width: 90%;
        margin: auto;

        @media (max-width: 1366px) {
            padding: 50px;
        }

        @media (max-width: 767px) {
            padding: 20px;
            margin: 0px auto;
        }
    }

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/fountain-blue-mid-img.png") !important;
        background-repeat: no-repeat !important;
        background-size: 100%;
        width: 250px;
        position: absolute;
        height: 250px;
        background-position: top;
        z-index: 0;
        right: 0;
        bottom: 20%;
    }

    img {
        border-radius: 0 !important;
        border: 10px solid #ffffff !important;
    }

    .wb-home-gallery {
        img {
            border-width: 4px !important;
        }
    }

    span.ampersand-span {
        color: var(--color8);
    }

    .wb-event-date,
    .wb-event-content-block {
        &+ {
            .wb-divider {
                svg {
                    display: none;
                }
            }
        }
    }

    .wb-bride-groom-name-block h2 {
        flex-direction: row;
        justify-content: center;
        gap: 5px;
    }

    .wb-bride-groom-name-block h6 span {
        margin-top: 0;
    }

    .wb-event-content-block h6 {
        font-family: var(--wb-primaryFont);
        font-size: 50px;
        line-height: normal;

        @media (max-width: 1366px) {
            font-size: 45px;
        }

        @media (max-width: 1024px) {
            font-size: 40px;
        }

        @media (max-width: 992px) {
            font-size: 35px;
        }
    }

    .wb-footer-couple-name h5 {
        span {
            color: var(--color8);
        }
    }

    .wb-footer-couple-name h6 {
        color: var(--color9);
    }

    .wb-common-head h3 {
        color: var(--color1);
    }

    .wb-registry-card-wrp .wb-registry-card-item {
        border-radius: 0;
        border: none;
        padding: 10px;
        background: #fff;

        .wb-registry-card-img {
            text-align: center;
            background: none;
            border-radius: 0;
        }
    }

    .wb-cus-input {
        border: 1px solid #cacaca;
    }

    .wb-rsvp-form-wrp .wb-rsvp-form-title h3 {
        color: var(--color8);
    }

    .Web-navigation .links li a {
        border-radius: 6px;
    }

    .wb-activity-content-wrap .wb-activity-card h6 {
        color: var(--color3);
    }

    .wb-question-wrp h6 {
        color: var(--color3);
    }
}