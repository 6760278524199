@mixin fonts {
    --wb-primaryFont: "roadworthy", serif;
    --wb-secondaryFont: "Helixa";
    --wb-belleniaFont: "Bellenia Love";
}

.blue-lagoon-theme1 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #00778c; //secondary color
    --color3: #868686; //sub content color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #ACD5DC; // rangoli flower color
    --color8: #E3FBFF; //accordion bg
    --color9: #026F83; //venue,rsvp rangoli bg
    --color10: #EFF6F8; //travel rangoli color
    --color12: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color2);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--color2);
    --wb-title-bar-divider-color: var(--color2);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color8);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--white);
    --wb-venue-bg: var(--color2);
    --wb-venue-btn-text-color: var(--color2);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--white);
    --wb-venue-common-head-divider-color: var(--white);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-rsvp-form-rangoli-color: var(--color9);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color7);
    --wb-travel-rangoli-icon-color: var(--color10);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color12);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

.blue-lagoon-theme2 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #6C880E; //secondary color
    --color3: #868686; //sub content color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #BFCE90; // rangoli flower color
    --color8: #EEFCE3; //accordion bg
    --color9: #648301; //venue,rsvp rangoli bg
    --color10: #F2F5E8; //travel rangoli icon color
    --color11: #D6EB94; //section rangoli icon color
    --color12: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color2);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--color2);
    --wb-title-bar-divider-color: var(--color2);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color8);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--white);
    --wb-venue-bg: var(--color2);
    --wb-venue-btn-text-color: var(--color2);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--white);
    --wb-venue-common-head-divider-color: var(--white);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-travel-rangoli-icon-color: var(--color10);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-rsvp-form-rangoli-color: var(--color9);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color11);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color12);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);

}

.blue-lagoon-theme3 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #7F1C34; //secondary color
    --color3: #868686; //sub content color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #F6ABAF; // rangoli flower color
    --color8: #FEF2F2; //accordion bg
    --color9: #75132B; //venue,rsvp rangoli bg
    --color10: #FEF2F2; //travel rangoli icon color
    --color11: #F6ABAF; //section rangoli icon color
    --color12: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color2);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--color2);
    --wb-title-bar-divider-color: var(--color2);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color8);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--white);
    --wb-venue-bg: var(--color2);
    --wb-venue-btn-text-color: var(--color2);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--white);
    --wb-venue-common-head-divider-color: var(--white);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-travel-rangoli-icon-color: var(--color10);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-rsvp-form-rangoli-color: var(--color9);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color11);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color12);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

.blue-lagoon-theme4 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #5E277C; //secondary color
    --color3: #868686; //sub content color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #C890F0; // rangoli flower color
    --color8: #F5EAFD; //accordion bg
    --color9: #582574; //venue,rsvp rangoli bg
    --color10: #FBF6FE; //travel rangoli icon color
    --color11: #E3CBF6; //section rangoli icon color
    --color12: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color2);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--color2);
    --wb-title-bar-divider-color: var(--color2);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color8);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--white);
    --wb-venue-bg: var(--color2);
    --wb-venue-btn-text-color: var(--color2);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--white);
    --wb-venue-common-head-divider-color: var(--white);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-travel-rangoli-icon-color: var(--color10);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-rsvp-form-rangoli-color: var(--color9);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color11);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color12);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);

}

.blue-lagoon-theme5 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #334C8C; //secondary color
    --color3: #868686; //sub content color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #A1C8EF; // rangoli flower color
    --color8: #F5EAFD; //accordion bg
    --color9: #304784; //venue,rsvp rangoli bg
    --color10: #EAF4FF; //travel rangoli icon color
    --color11: #CBDFF6; //section rangoli icon color
    --color12: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color2);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--color2);
    --wb-title-bar-divider-color: var(--color2);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color8);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--white);
    --wb-venue-bg: var(--color2);
    --wb-venue-btn-text-color: var(--color2);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--white);
    --wb-venue-common-head-divider-color: var(--white);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-travel-rangoli-icon-color: var(--color10);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-rsvp-form-rangoli-color: var(--color9);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color11);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color12);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);

}


//   theme color end
.blue-lagoon {
    @include fonts;
    background-color: var(--white);

    // divider
    .blue-lagoon-divider {
        display: flex;
        align-items: center;

        svg {
            width: 100%;
            margin: 0 auto;

            @media (max-width: 767px) {
                width: 150px;

            }
        }
    }

    .wb-rangoli-bg-icon {
        svg {
            width: 100%;
            margin: 0 auto;
            height: auto;
        }
    }

    .wb-blue-lagoon-section-top-rangoli-bg {
        svg {
            path {
                fill: var(--wb-section-top-rangoli-icon-color);
            }
        }
    }

    // travel divider
    .travel-divider {
        svg {
            width: 90%;
            margin-top: 40px;

            @media (max-width: 767px) {
                width: 150px;
                margin-top: 20px;
            }

            path {
                fill: var(--wb-common-head-divider-color);
            }
        }
    }

    // common head divider
    .common-head-divider {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        @media (max-width: 1600px) {
            margin-bottom: 45px;
        }

        @media (max-width: 1440px) {
            margin-bottom: 40px;
        }

        @media (max-width: 1280px) {
            margin-bottom: 30px;
        }

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }

        svg {
            width: 90%;
            margin-top: 10px;

            @media (max-width: 767px) {
                width: 150px;
            }

            path {
                fill: var(--wb-common-head-divider-color);
            }
        }
    }

    // button
    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 16px;
        font-weight: 500;
        padding: 12px 20px 12px 20px;
        text-transform: uppercase;
        border-radius: 0;

        &:hover {
            background-color: var(--wb-rsvp-btn-hover-bg);
            transition: background-color 0.3s ease;
        }

        @media (max-width: 1366px) {
            padding: 10px 25px;
            font-size: 14px;
        }
    }

    // titlebar
    .blue-lagoon-title-bar-section {
        padding: 20px 0 30px;
        width: 100%;
        margin: 0 auto;

        @media (max-width: 767px) {
            padding: 15px 0 20px;
        }

        .wb-title-bar {
            h2 {
                color: var(--wb-title-bar-text-color);
                font-size: 60px;
                text-align: center;
                font-weight: 400;
                line-height: 81.6px;
                font-family: var(--wb-primaryFont);
                display: flex;
                align-items: baseline;
                justify-content: center;
                gap: 10px;

                @media (max-width: 767px) {
                    font-size: 40px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 27.2px;

                    @media (max-width: 767px) {
                        font-size: 15px;
                    }
                }
            }
        }

        .blue-lagoon-divider {
            svg {
                path {
                    fill: var(--wb-title-bar-divider-color);
                }
            }
        }
    }

    // section head(common head)

    .indo-western-common-head {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;


        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1600px) {
                font-size: 45px;
            }

            @media (max-width: 1440px) {
                font-size: 40px;
            }

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 1024px) {
                font-size: 32px;
            }

            @media (max-width: 992px) {
                font-size: 30px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-belleniaFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
    }

    // bride groom name
    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 10px 0 50px;
        margin: 0;

        @media (max-width: 1366px) {
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding: 25px 0;
        }

        @media (max-width: 580px) {
            padding: 20px 0;
        }

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            font-family: var(--wb-primaryFont);
            color: var(--wb-bridegroom-text-color);
            font-size: 80px;
            font-weight: 400;
            line-height: 92px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1600px) {
                font-size: 65px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                font-size: 60px;
            }

            @media (max-width: 1280px) {
                font-size: 55px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
                gap: 18px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 24px;
                flex-direction: column;
                gap: 3px;
            }

            @media (max-width: 420px) {
                font-size: 22px;
                flex-direction: column;
                gap: 3px;
            }

            span {
                text-align: center;
                text-align: left;
            }

            span:nth-child(1) {
                text-align: right;

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

            span:nth-child(2) {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 1024px) {
                    font-size: 20px;
                }

                @media (max-width: 1024px) {
                    font-size: 15px;
                }

                @media (max-width: 580px) {
                    font-size: 12px;
                }
            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 19px;
            margin-top: 10px;
            align-items: center;

            @media (max-width: 576px) {
                gap: 6px;
            }


            span {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 27.72px;
                color: var(--wb-banner-date-text-color);

                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 20.72px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: normal;
                }

                &:nth-child(2) {
                    width: 2px;
                    height: 18px;
                    background: var(--white);

                    @media (max-width: 767px) {
                        display: none;

                }
                }
            }
        }
    }

    // slider banner

    .indo-western-slider-banner-wrp {
        position: relative;
        z-index: 9;

        .indo-western-banner-slider-image {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                top: 0;
                opacity: 0.3;
                left: 0px;
                right: 0;
                margin: 0;
                border-radius: 63px;

                @media (max-width: 1366px) {
                    border-radius: 50px;
                }

                @media (max-width: 1199px) {
                    border-radius: 40px;
                }

                @media (max-width: 992px) {
                    border-radius: 30px;
                }

                @media (max-width: 767px) {
                    border-radius: 20px;
                }
            }

            img {
                object-fit: cover;
                height: 100vh !important;
            }
        }

        .indo-western-banner-slider-content {
            position: absolute;
            top: 25%;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            width: 100%;

            @media (max-width: 767px) {
                top: 20%;
            }

            h4 {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 20px;
                font-weight: 400;
                color: var(--wb-banner-text-color);
                margin-bottom: 30px;

                @media (max-width: 767px) {
                    font-size: 15px;
                    margin-bottom: 20px;
                }

                @media (max-width: 567px) {
                    font-size: 12px;
                }
            }

            .wb-view-btn {
                color: var(--wb-banner-button-border);
                background: var(--wb-view-button-bg);
                border: 1px solid var(--wb-banner-button-border);
                margin-top: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                letter-spacing: 3px;

                @media (max-width: 767px) {
                    padding: 10px 25px;
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
    }

    //   how we met

    .wb-blue-lagoon-how-we-met-wrp {
        position: relative;

        .wb-blue-lagoon-how-we-met-top-bg {
            .wb-rangoli-bg-icon {
                svg {
                    width: 100%;
                    margin: 0 auto;

                    path {
                        fill: var(--wb-how-we-met-bg-icon-color);
                    }
                }
            }
        }

        .wb-blue-lagoon-how-we-met-bottom-bg {
            position: absolute;
            bottom: -138px;
            left: -141px;

            @media (max-width:1280px) {
                bottom: -105px;
                left: -102px;
            }

            @media (max-width:1199px) {
                bottom: -92px;
                left: -86px;
            }

            .wb-rangoli-bg-icon {
                svg {
                    width: 100%;
                    margin: 0 auto;

                    @media (max-width:1366px) {
                        width: 80%;

                    }

                    @media (max-width:1199px) {
                        width: 70%;

                    }

                    @media (max-width:850px) {
                        width: 50%;

                    }

                    path {
                        fill: var(--wb-rangoli-icon-color);
                    }
                }
            }
        }

        .wb-container {
            padding: 150px 15px;

            @media (max-width: 1366px) {
                padding: 120px 15px;
            }

            @media (max-width: 992px) {
                padding: 90px 15px;
            }

            @media (max-width: 767px) {
                padding: 60px 15px;
            }

            .wb-cus-row {
                flex-wrap: nowrap;
                gap: 50px;

                @media (max-width: 767px) {
                    flex-direction: column;
                    margin: 0 auto;
                }

                @media (max-width: 1600px) {
                    gap: 45px;
                }

                @media (max-width: 1440px) {
                    gap: 40px;
                }

                @media (max-width: 1366px) {
                    gap: 35px;
                }

                @media (max-width: 1199px) {
                    gap: 25px;
                }

                @media (max-width: 992px) {
                    gap: 20px;
                }



                .blue-lagoon-content-sec {
                    width: 60%;
                    padding: 0 15px;

                    @media (max-width: 1199px) {
                        width: 50%;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    .how-we-mwt-divider {
                        margin-top: 40px;

                        @media (max-width: 1600px) {
                            margin-top: 35px;
                        }

                        @media (max-width: 992px) {
                            margin-top: 25px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 20px;
                        }

                        svg {
                            path {
                                fill: var(--wb-common-head-divider-color);
                            }
                        }
                    }

                    .blue-lagoon-how-we-met-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .indo-western-common-head-wrp {
                            .wb-container {
                                padding: 0 15px;
                            }

                            .blue-lagoon-common-head {
                                margin: 40px 0;

                                @media (max-width: 1600px) {
                                    margin: 30px 0;
                                }

                                @media (max-width: 1440px) {
                                    margin: 20px 0;
                                }


                                h3 {
                                    color: var(--wb-how-we-are-text-color);
                                    font-family: var(--wb-primaryFont);
                                    font-size: 50px;
                                    font-weight: 400;
                                    text-align: center;

                                    @media (max-width: 1600px) {
                                        font-size: 45px;
                                    }

                                    @media (max-width: 1440px) {
                                        font-size: 40px;
                                    }

                                    @media (max-width: 1366px) {
                                        font-size: 35px;
                                    }

                                    @media (max-width: 1024px) {
                                        font-size: 32px;
                                    }

                                    @media (max-width: 992px) {
                                        font-size: 27px;
                                    }

                                    @media (max-width: 420px) {
                                        font-size: 25px;
                                    }
                                }

                                p {
                                    color: var(--wb-secondary-text-color);
                                    font-family: var(--wb-secondaryFont);
                                    font-size: 18px;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    line-height: 35px;
                                    text-align: center;

                                    @media (max-width: 1440px) {
                                        line-height: 15px;
                                    }

                                    @media (max-width: 1366px) {
                                        font-size: 16px;
                                        line-height: 20px;
                                    }

                                    @media (max-width: 767px) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        p {
                            color: var(--wb-sub-content-color);
                            font-family: var(--wb-secondaryFont);
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 38px;
                            text-align: center;

                            @media (max-width: 1440px) {
                                font-size: 16px;
                                line-height: 30px;
                            }

                            @media (max-width: 767px) {
                                font-size: 14px;
                                line-height: 25px;
                            }
                        }

                    }
                }

                .blue-lagoon-image-sec {
                    width: 40%;
                    padding: 0 15px;
                    display: flex;
                    align-items: baseline;
                    position: relative;

                    @media (max-width: 767px) {
                        width: 100%;
                        justify-content: center;
                    }

                    .blue-lagoon-image-right-bg {
                        position: absolute;
                        bottom: -106px;
                        right: -88px;
                        z-index: 0;

                        @media (max-width:1366px) {
                            bottom: -80px;
                            right: -50px;

                        }

                        @media (max-width:1280px) {
                            bottom: -66px;
                            right: -16px;

                        }

                        @media (max-width:1199px) {
                            bottom: -67px;
                            right: 34px;

                        }

                        @media (max-width:992px) {
                            bottom: -67px;
                            right: 12px;

                        }

                        @media (max-width:850px) {
                            bottom: -54px;
                            right: -11px;

                        }

                        @media (max-width:767px) {
                            bottom: -66px;
                            right: 158px;
                        }

                        @media (max-width:480px) {
                            bottom: -37px;
                            right: -9px;
                        }

                        .blue-lagoon-how-we-met-right-bg {
                            svg {
                                width: 220px;

                                @media (max-width:1366px) {
                                    width: 165px;
                                }

                                @media (max-width:1280px) {
                                    width: 130px;
                                }

                                @media (max-width:480px) {
                                    width: 65px;
                                }

                                path {
                                    fill: var(--wb-rangoli-icon-color);
                                }
                            }
                        }
                    }

                    .blue-lagoon-bottom-image {
                        img {
                            width: 396px !important;
                            height: 522px !important;
                            max-width: 100% !important;
                            max-height: 100% !important;
                            object-fit: cover;
                            z-index: 9;

                            @media (max-width: 1600px) {
                                width: 360px !important;
                                height: 480px !important;
                            }

                            @media (max-width: 1366px) {
                                width: 320px !important;
                                height: 420px !important;
                            }

                            @media (max-width: 1199px) {
                                width: 100% !important;
                                height: 100% !important;
                                min-height: 400px !important;
                                max-width: 250px !important;
                            }

                            @media (max-width: 767px) {
                                max-height: 400px !important;
                            }
                        }
                    }

                    .blue-lagoon-top-image {
                        position: relative;
                        margin-left: -120px;
                        z-index: 9;

                        img {
                            width: 371px !important;
                            height: 380px !important;
                            max-width: 100% !important;
                            max-height: 100% !important;
                            object-fit: cover;

                            @media (max-width: 1600px) {
                                width: 340px !important;
                                height: 350px !important;
                            }

                            @media (max-width: 1366px) {
                                width: 300px !important;
                                height: 310px !important;
                            }

                            @media (max-width: 1280px) {
                                width: 240px !important;
                                height: 290px !important;
                            }

                            @media (max-width: 992px) {
                                max-height: 250px !important;
                            }

                            @media (max-width: 767px) {
                                width: 100% !important;
                                height: auto !important;
                                max-width: 200px !important;
                                min-height: 200px !important;

                            }
                        }

                    }


                }
            }
        }
    }

    // venue location
    .wb-blue-lagoon-venue-location-wrp {
        position: relative;
        background: var(--wb-venue-bg);
        overflow: hidden;

        .wb-blue-lagoon-venue-bg {
            position: absolute;
            height: 100%;
            width: 100%;

            .wb-rangoli-bg-icon {
                height: 100%;

                svg {
                    width: 100%;
                    height: 100%;

                    path {
                        fill: var(--wb-venue-rangoli-icon-color);
                        opacity: 0.4;
                    }
                }
            }
        }

        .wb-container {
            padding: 150px 15px;

            @media (max-width: 1366px) {
                padding: 120px 15px;
            }

            @media (max-width: 992px) {
                padding: 90px 15px;
            }

            @media (max-width: 767px) {
                padding: 60px 15px;
            }

            .wb-cus-row {
                align-items: center;

                @media (max-width:767px) {
                    flex-direction: column;
                    row-gap: 30px;
                }

                .wb-cus-col-3 {
                    padding: 0 15px;

                    @media (max-width:767px) {
                        width: 100%;

                    }

                }

                .col-order-1 {
                    @media (max-width:767px) {
                        order: 1;
                    }

                }

                .venue-left-image,
                .venue-right-image {
                    img {
                        width: 480px !important;
                        height: 610px !important;
                        max-width: 100% !important;
                        max-height: 100% !important;
                        object-fit: cover;

                        @media (max-width: 1600px) {
                            width: 300px !important;
                            height: 500px !important;
                        }

                        @media (max-width: 1366px) {
                            width: 320px !important;
                            height: 430px !important;
                        }

                        @media (max-width: 1024px) {
                            height: 360px !important;
                        }

                        @media (max-width: 767px) {
                            width: 100% !important;
                            height: 100% !important;
                            max-height: 400px !important;
                            object-fit: contain;
                        }
                    }
                }

                .venue-left-image {
                    padding-top: 9rem;

                    @media (max-width: 1600px) {
                        padding-top: 8.5rem;
                    }

                    @media (max-width: 1440px) {
                        padding-top: 8rem;
                    }

                    @media (max-width: 1366px) {
                        padding-top: 7.5rem;
                    }

                    @media (max-width: 1280px) {
                        padding-top: 7rem;
                    }

                    @media (max-width: 992px) {
                        padding-top: 6.5rem;
                    }

                    @media (max-width: 767px) {
                        padding-top: 0rem;
                    }
                }

                .venue-right-image {
                    padding-bottom: 9rem;

                    @media (max-width: 1600px) {
                        padding-bottom: 8.5rem;
                    }

                    @media (max-width: 1440px) {
                        padding-bottom: 8rem;
                    }

                    @media (max-width: 1366px) {
                        padding-bottom: 7.5rem;
                    }

                    @media (max-width: 1280px) {
                        padding-bottom: 7rem;
                    }

                    @media (max-width: 992px) {
                        padding-bottom: 6.5rem;
                    }

                    @media (max-width: 767px) {
                        padding-bottom: 0rem;
                    }
                }

                .wb-blue-lagoon-venue-info {
                    z-index: 9;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .indo-western-common-head-wrp {
                        .indo-western-common-head {

                            h6,
                            h3 {
                                color: var(--wb--venue-title-color);
                            }
                        }
                    }

                    .common-head-divider {
                        svg {
                            path {
                                fill: var(--wb-venue-common-head-divider-color);
                            }
                        }
                    }

                    .wb-view-btn {
                        color: var(--wb-venue-btn-text-color);
                        background: var(--wb-venue-btn-bg);
                        font-family: var(--wb-secondaryFont);
                        font-weight: 400;
                        font-size: 14px;
                        margin-top: 50px;

                        @media (max-width: 1600px) {
                            margin-top: 38px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 30px;
                        }

                        &:hover {
                            background-color: var(--wb-rsvp-btn-hover-bg);
                            transition: background-color 0.3s ease;
                            color: var(--wb-venue-btn-hover-text-color);
                        }
                    }

                    p {
                        color: var(--wb-venue-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 43px;
                        text-align: center;

                        @media (max-width: 1440px) {
                            font-size: 18px;
                            line-height: 35px;
                        }

                        @media (max-width: 767px) {
                            text-align: center;
                        }

                        @media (max-width: 640px) {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }

                    .venue-card-divider {
                        margin-top: 60px;

                        @media (max-width: 1600px) {
                            margin-top: 38px;
                        }

                        svg {
                            path {
                                fill: var(--wb-venue-common-head-divider-color);
                            }
                        }
                    }
                }
            }
        }
    }

    // countdown
    .indo-western-count-down-wrp {
        padding: 50px 0;

        @media (max-width: 1024px) {
            padding: 40px 0;
        }

        .wb-cus-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;

            .indo-western-count-down-content {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 576px) {
                    width: calc(100% / 2);
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-belleniaFont);
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 53.52px;
                    text-transform: capitalize;

                    @media (max-width: 1366px) {
                        font-size: 30px;
                        line-height: normal;
                    }

                    @media (max-width: 767px) {
                        font-size: 25px;
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 58px;

                    @media (max-width: 1366px) {
                        font-size: 45px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 40px;
                    }

                    @media (max-width: 992px) {
                        font-size: 35px;
                    }
                }

                .countdown-divider {
                    svg {
                        width: 70%;

                        path {
                            fill: var(--wb-secondary-text-color);
                        }
                    }
                }
            }
        }
    }
    // sub banner
    .wb-sub-banner {
        img {
            max-width: 100% !important;
            max-height: 100% !important;
            width: 100% !important;
            height: 570px !important;
            object-fit: cover;
            @media (max-width:767px) {
                height: auto !important;

            }
        }

    }
    //  faq
    .wb-blue-lagoon-q-and-a-section {
        position: relative;

        .wb-blue-lagoon-q-and-a-left-bg {
            position: absolute;
            left: -10%;
            top: 50%;
            width: auto;

            @media (max-width:1600px) {
                left: -8%;
            }


            .wb-rangoli-bg-icon {
                overflow: hidden;

                svg {
                    width: 100%;

                    @media (max-width:1600px) {
                        width: 65%;
                    }

                    @media (max-width:1366px) {
                        width: 55%;
                    }

                    @media (max-width:1280px) {
                        width: 40%;
                    }

                    @media (max-width:992px) {
                        width: 33%;
                    }

                    @media (max-width:767px) {
                        width: 30%;
                    }

                    path {
                        fill: var(--wb-rangoli-icon-color);
                    }
                }
            }
        }

        .wb-expandable-faq-wrp {
            padding: 150px 0;
            position: relative;

            @media (max-width: 1366px) {
                padding: 120px 0;
            }

            @media (max-width: 992px) {
                padding: 90px 0;
            }

            @media (max-width: 767px) {
                padding: 60px 0;
            }

            .wb-accordion-wrp {
                padding: 50px 0;

                @media (max-width: 1366px) {
                    padding: 40px 0;
                }

                @media (max-width: 1199px) {
                    padding: 30px 0;
                }

                @media (max-width: 992px) {
                    padding: 25px 30px;
                }

                @media (max-width: 767px) {
                    padding: 20px 0px;
                }

                .wb-accordion {
                    padding: 50px;
                    border-radius: 40px;
                    background: var(--wb-accordion-bg);
                    margin-bottom: 10px;

                    @media (max-width: 1366px) {
                        padding: 30px;
                        border-radius: 30px;
                    }

                    @media (max-width: 1199px) {
                        border-radius: 20px;
                    }

                    @media (max-width: 992px) {
                        padding: 20px;
                        border-radius: 15px;
                    }




                    .wb-accordion-header {
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h3 {
                            font-size: 30px;
                            color: var(--wb-accordion-title-color);
                            font-weight: 400;
                            line-height: normal;
                            margin: 30px 0;
                            font-family: var(--wb-primaryFont);


                            @media (max-width: 1440px) {
                                font-size: 26px;
                                margin: 26px 0;
                            }



                            @media (max-width: 1199px) {
                                font-size: 20px;
                                margin: 22px 0;
                            }



                            @media (max-width: 767px) {
                                font-size: 15px;
                                margin: 18px 0;
                            }
                        }

                        .wb-accordion-icon {
                            transition: transform 0.3s ease-in-out;

                            &.open {
                                transform: rotateX(180deg);
                            }

                            svg {
                                width: auto;
                                margin-right: 0;

                                @media (max-width: 767px) {
                                    width: 30px;
                                }

                                @media (max-width: 420px) {
                                    width: 25px;
                                }

                                path {
                                    fill: var(--wb-accordion-icon-color);
                                }
                            }
                        }
                    }

                    .wb-accordion-content {
                        max-height: 0;
                        overflow: hidden;

                        p {
                            color: var(--wb-accordion-text-color);
                            font-size: 18px;
                            font-weight: 300;
                            line-height: 38px;
                            font-family: var(--wb-secondaryFont);


                            @media (max-width: 1440px) {
                                font-size: 16px;
                                line-height: 34px;
                            }

                            @media (max-width: 767px) {
                                font-size: 14px;
                                line-height: 25px;
                            }
                        }

                        &.open {
                            max-height: 100%;
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
    }

    // image slider

    .full-image-carousel {
        margin: 0;
        padding: 0px;

        .swiper {
            @media(max-width: 767px) {
                margin: 0 !important;
            }

        }

        img {
            width: 100% !important;
            height: 350px !important;
            max-width: 100% !important;
            max-height: 100% !important;
            object-fit: cover;

            @media (max-width: 1600px) {
                height: 275px !important;

            }

            @media (max-width: 1024px) {
                height: 150px !important;

            }

        }
    }

    .blue-lagoon-full-image-carousel {
        img {
            width: 100%;
            height: 609px !important;
            max-width: 100% !important;
            max-height: 100% !important;
            object-fit: cover;

            @media (max-width:1600px) {
                height: 450px !important;
            }

            @media (max-width:1366px) {
                height: 380px !important;

            }

        }

        .swiper {
            margin-bottom: 0 !important;
        }
    }

    // registry
    .wb-blue-lagoon-registry-section {
        position: relative;

        .wb-blue-lagoon-registry-top-bg {
            .wb-rangoli-bg-icon {
                svg {
                    width: 100%;
                    margin: 0 auto;

                    path {
                        fill: var(--wb-how-we-met-bg-icon-color);
                    }
                }
            }
        }

        .indo-western-common-head-wrp {
            padding-top: 100px;

            @media (max-width: 1366px) {
                padding-top: 80px;
            }

            @media (max-width: 992px) {
                padding-top: 50px;
            }

            @media (max-width: 767px) {
                padding-top: 30px;
            }
        }

        .wb-registry-card-wrp {
            padding-bottom: 150px;

            @media (max-width: 1366px) {
                padding-bottom: 120px;
            }

            @media (max-width: 992px) {
                padding-bottom: 90px;
            }

            @media (max-width: 767px) {
                padding-bottom: 60px;
            }

            .wb-cus-row {
                row-gap: 30px;

                @media (max-width: 1199px) {
                    row-gap: 20px;
                }
                @media (max-width: 767px) {
                    row-gap: 15px;
                }
            }

            .wb-col-3 {
                padding: 0 15px;
                flex: 0 0 auto;
                width: calc(100% / 4);

                @media (max-width: 1199px) {
                    width: calc(100% / 3);
                }
                @media (max-width: 767px) {
                    width: calc(100% / 2);
                }
                @media (max-width: 567px) {
                    width: 100%;
                }
            }

            .wb-registry-card-item {
                border: 1px solid var(--Wb-registry-card-border);
                background: var(--Wb-registry-card-bg);
                padding: 30px;

                @media (max-width: 1024px) {
                    padding: 20px;
                }

                .wb-registry-card-img {
                    text-align: center;
                    margin-bottom: 10px;
                    img {
                        width: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .wb-view-btn {
                    width: 100%;
                    background-color: var(--wb-registry-btn-bg);
                    color: var(--wb-registry-btn-text-color);

                    &:hover {
                        background-color: var(--wb-registry-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-registry-btn-hover-text-color);
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 35px;

                    @media (max-width: 1600px) {
                        font-size: 23px;
                        line-height: 33px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 21px;
                        line-height: 31px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 19px;
                        line-height: 29px;
                    }

                    @media (max-width: 767px) {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }

                h3 {
                    margin: 20px 0;
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    font-size: 28px;

                @media (max-width: 1199px) {
                    font-size: 25px;
                }

                @media (max-width: 992px) {
                    font-size: 22px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    margin-bottom: 20px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    // footer
    .wb-blue-lagoon-footer-couple-name {
        position: relative;

        .wb-blue-lagoon-footer-bottom-bg {
            svg {
                transform: rotate(180deg);
            }
        }

        .wb-footer-couple-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 50px;

            @media (max-width: 1366px) {
                margin-top: 40px;
            }

            @media (max-width: 1280px) {
                margin-top: 35px;
            }

            @media (max-width: 1024px) {
                margin-top: 30px;
            }

            @media (max-width: 992px) {
                margin-top: 25px;
            }

            @media (max-width: 767px) {
                margin-top: 20px;
            }

            h5 {
                font-size: 60px;
                font-weight: 400;
                line-height: 69.23px;
                color: var(--wb-footer-text-color);
                font-family: var(--wb-primaryFont);
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: baseline;
                gap: 10px;
                text-transform: lowercase;

                @media (max-width: 1600px) {
                    font-size: 55px;
                    line-height: 63.46px;
                }

                @media (max-width: 1440px) {
                    font-size: 50px;
                    line-height: 57.69px;
                }

                @media (max-width: 1366px) {
                    font-size: 45px;
                    line-height: 51.92px;
                }

                @media (max-width: 1199px) {
                    font-size: 40px;
                    line-height: 46.15px;
                }

                @media (max-width: 992px) {
                    line-height: 40.38px;
                }

                @media (max-width: 767px) {
                    line-height: 34.61px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    line-height: 68.12px;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }

            h6 {
                display: none;
            }

            .wb-divider {
                display: none;
            }

        }

        .blue-lagoon-divider {
            margin-bottom: 50px;
            display: flex;
            align-items: center;

            @media (max-width: 1366px) {
                margin-bottom: 40px;
            }

            @media (max-width: 1280px) {
                margin-bottom: 35px;
            }

            @media (max-width: 1024px) {
                margin-bottom: 30px;
            }

            @media (max-width: 992px) {
                margin-bottom: 25px;
            }

            @media (max-width: 767px) {
                margin-bottom: 20px;
            }

            svg {
                width: 100%;

                @media (max-width: 767px) {
                    width: 150px;
                }

                path {
                    fill: var(--wb-footer-divider-color);
                }
            }
        }
    }

    // rsvp

    .wb-blue-lagoon-rsvp-section {
        position: relative;

        .wb-blue-lagoon-rsvp-right-bg {
            position: absolute;
            right: 0;
            top: 18%;
            width: 200px;

            @media (max-width:1366px) {
                width: 150px;
            }

            @media (max-width:1280px) {
                width: 120px;
            }

            @media (max-width:992px) {
                width: 100px;
            }

            @media (max-width:767px) {
                top: 64%;
            }

            .wb-rangoli-bg-icon {
                overflow: hidden;

                svg {
                    width: 400px;

                    @media (max-width:1366px) {
                        width: 320px;
                    }

                    @media (max-width:1280px) {
                        width: 230px;
                    }

                    @media (max-width:992px) {
                        width: 200px;
                    }


                    path {
                        fill: var(--wb-rsvp-form-rangoli-color);
                    }
                }
            }

        }

        .wb-cus-row {
            margin: 0;
            justify-content: space-between;

            @media (max-width:767px) {
                flex-direction: column;

            }

            .wb-blue-lagoon-rsvp-image {
                width: 50%;

                @media (max-width:767px) {
                    width: 100%;
                }

                img {
                    width: 100% !important;
                    height: 640px !important;
                    max-width: 100% !important;
                    max-height: 100% !important;
                    object-fit: cover;

                    @media (max-width: 1600px) {
                        height: 600px !important;
                    }

                    @media (max-width: 1440px) {
                        height: 560px !important;
                    }

                    @media (max-width: 1366px) {
                        height: 520px !important;
                    }

                    @media (max-width: 1280px) {
                        height: 480px !important;
                    }

                    @media (max-width: 767px) {
                        max-height: 400px !important;
                        height: auto !important;
                    }
                }
            }

            .wb-blue-lagoon-rsvp-form {
                background: var(--wb-rsvp-bg);
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 60px 200px;

                @media (max-width: 1440px) {
                    padding: 50px 160px;
                }

                @media (max-width: 1366px) {
                    padding: 45px 140px;
                }


                @media (max-width: 992px) {
                    padding: 35px 70px;
                }

                @media (max-width:767px) {
                    padding: 30px 50px;
                    width: 100%;
                }

                .indo-western-common-head-wrp {
                    .indo-western-common-head {

                        h6,
                        h3 {
                            color: var(--wb-rsvp-text-color);
                        }
                    }
                }

                .common-head-divider {
                    svg {
                        path {
                            fill: var(--wb-rsvp-title-divider-color);
                        }
                    }
                }

                .wb-blue-lagoon-form-divider {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;

                    svg {
                        path {
                            fill: var(--wb-rsvp-form-divider-color);
                        }
                    }
                }

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    position: relative;
                    z-index: 9;
                }

                .wb-cus-input {
                    background-color: transparent;
                    border: 1px solid #e7e7e7;
                    border-radius: 0px;
                    padding: 10px 16px;
                    margin-bottom: 10px;
                    color: var(--wb-input-text-color);
                    font-weight: 400;
                    font-size: 12px;
                    width: 80%;
                    height: 40px;
                    outline: none;
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    &::placeholder {
                        color: var(--wb-input-placeholder-color) !important;
                        font-family: var(--wb-secondaryFont);
                        line-height: 15.12px;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }

                .wb-view-btn {
                    width: fit-content;
                    text-transform: uppercase;
                    background-color: var(--wb-rsvp-btn-bg);
                    color: var(--wb-rsvp-btn-text-color);
                    font-family: var(--wb-secondaryFont);
                    margin: 30px 0;

                    @media (max-width:992px) {
                        margin: 20px 0;
                    }

                    &:hover {
                        background-color: var(--wb-rsvp-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-rsvp-btn-hover-text-color);
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                        margin: 20px 0;
                    }

                    @media (max-width: 560px) {
                        padding: 10px;
                        margin: 15px 0;
                    }
                }

            }
        }
    }

    // password access
    .wb-password-access-form-wrp {
        padding: 60px;
        background: var(--wb-rsvp-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        @media (max-width:1366px) {
            padding: 50px;
        }

        @media (max-width:767px) {
            padding: 30px;
        }

        .wb-access-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: var(--wb-rsvp-text-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width:1440px) {
                    font-size: 35px;
                    line-height: normal;

                }

                @media (max-width:1366px) {
                    font-size: 32px;
                }

                @media (max-width:1280px) {
                    font-size: 30px;
                }

                @media (max-width:992px) {
                    font-size: 24px;

                }
            }

        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin: 40px 0;

            @media (max-width:1366px) {
                margin: 30px 0;
            }

            @media (max-width:767px) {
                margin: 25px 0;
            }

            .wb-cus-input {
                background-color: #fff;
                height: 40px;
                padding: 20px;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                border: 1px solid #cfcfcf;

                @media (max-width:767px) {
                    width: 100%;

                }

            }
        }
    }

    // things to do
    .wb-blue-lagoon-things-to-do-section {
        padding-bottom: 150px;

        @media (max-width: 1366px) {
            padding-bottom: 120px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 100px;
        }

        @media (max-width: 992px) {
            padding-bottom: 80px;
        }

        @media (max-width: 767px) {
            padding-bottom: 50px;
        }

        .indo-western-common-head-wrp {
            padding: 100px 0 50px;

            @media (max-width: 1366px) {
                padding: 80px 0 40px;
            }

            @media (max-width: 1199px) {
                padding: 70px 0 40px;
            }

            @media (max-width: 992px) {
                padding: 60px 0 40;
            }

            @media (max-width: 767px) {
                padding: 50px 0 30px;
            }
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1024px) {
                gap: 30px 0;
            }

        }

        .wb-activity-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            width: calc(100% / 3);
            padding: 0 15px;

            @media (max-width:767px) {
                width: 100%;

            }
            .wb-activity-image{
                width: 100%;
            }
            img {
                width: 100%;
                max-width: 100% !important;
                height: 380px !important;
                object-fit: cover;

                @media (max-width:767px) {
                    min-height: 200px !important;
                    height: auto !important;
                    aspect-ratio: 1;
                }
            }

            h4 {
                color: var(--wb-things-to-do-card-title-color);
                font-family: var(--wb-primaryFont);
                font-size: 25px;
                font-weight: 400;
                line-height: 34.65px;
                padding-bottom: 10px;
                margin-top: 20px;


                @media (max-width: 1024px) {
                    font-size: 20px;
                    line-height: 25.65px;
                }


            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                padding-bottom: 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 35px;

                @media (max-width: 1366px) {
                    font-size: 16px;
                    line-height: 28px;
                }

                @media (max-width: 1024px) {
                    line-height: 28px;
                    padding-bottom: 10px;
                }
            }

            h5 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width: 1366px) {
                    font-size: 14px;
                }

                @media (max-width: 1024px) {
                    line-height: 25px;
                }
            }

            p {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                padding-bottom: 20px;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width: 1366px) {
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            .wb-view-btn {
                margin-top: 20px;
                background: var(--Wb-things-to-do-button-bg);
                color: var(--wb-things-to-do-button-text-color);
                font-size: 14px;
                font-weight: 400;

                &:hover {
                    background: var(--Wb-things-to-do-button-hover-bg);
                    color: var(--wb-things-to-do-button-hover-text-color);
                }
            }
        }
    }

    // common component font-style
    // person view card (wedding party card)
    .wb-blue-lagoon-wedding-party-section {
        padding: 150px 0;
        position: relative;

        @media (max-width: 1366px) {
            padding: 120px 0;
        }

        @media (max-width: 992px) {
            padding: 90px 0;
        }

        @media (max-width: 767px) {
            padding: 60px 0;
        }

        .indo-western-common-head-wrp {
            position: relative;
            z-index: 9;
        }

        .wb-blue-lagoon-wedding-party-top-bg {
            position: absolute;
            right: 0;
            width: 200px;
            top: 0;

            @media (max-width: 1600px) {
                width: 180px;
            }

            @media (max-width: 1440px) {
                width: 160px;
            }

            @media (max-width: 1366px) {
                width: 140px;
            }

            @media (max-width: 1280px) {
                width: 120px;
            }

            @media (max-width: 767px) {
                width: 70px;
            }

            @media (max-width: 640px) {
                width: 40px;
            }

            .wb-rangoli-bg-icon {
                overflow: hidden;

                svg {
                    width: 400px;

                    @media (max-width: 1440px) {
                        width: 360px;
                    }

                    @media (max-width: 1366px) {
                        width: 290px;
                    }

                    @media (max-width: 1280px) {
                        width: 270px;
                    }

                    @media (max-width: 992px) {
                        width: 250px;
                    }

                    @media (max-width: 767px) {
                        width: 140px;
                    }

                    @media (max-width: 640px) {
                        width: 80px;
                    }

                    path {
                        fill: var(--wb-rangoli-icon-color);
                    }
                }
            }
        }

        .person-view-card {
            margin: 0;
            position: relative;

            .wb-cus-row {
                row-gap: 60px;
                justify-content: center;

                @media (max-width: 1440px) {
                    row-gap: 50px;
                }

                @media (max-width: 1366px) {
                    row-gap: 45px;
                }

                @media (max-width: 1199px) {
                    row-gap: 40px;
                }

                @media (max-width: 992px) {
                    row-gap: 35px;
                }
            }

            .person-view-card-item {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 2);
                }

                img {
                    width: 100%;
                    max-width: 200px !important;
                    height: 300px !important;
                    object-fit: cover;
                    border-radius: 203px;

                    @media (max-width: 1366px) {
                        max-width: 180px !important;
                        height: 250px !important;
                    }

                    @media (max-width: 1024px) {
                        height: 185px !important;
                    }

                    @media (max-width: 992px) {
                        height: 200px !important;
                        max-width: 150px !important;
                        border-radius: 30px;
                    }

                    @media (max-width: 767px) {
                        height: auto !important;
                        max-width: auto !important;
                        border-radius: 20px;
                    }

                }

                h4 {
                    color: var(--wb-card-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 35px;
                    margin: 10px 0 5px;

                    @media (max-width: 1600px) {
                        font-size: 24px;
                        line-height: 33px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 22px;
                        line-height: 31px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 20px;
                        line-height: 29px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 27px;
                    }

                    @media (max-width: 992px) {
                        line-height: 25px;
                    }

                    @media (max-width: 767px) {
                        line-height: 23px;
                    }
                }

                h6 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 1600px) {
                        font-size: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                p {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 8px;
                }
            }
        }
    }

    // hotel view

    .wb-blue-lagoon-hotel-transport-section {
        position: relative;

        .wb-blue-lagoon-hotel-bg {
            position: absolute;
            height: 100%;
        }

        .wb-rangoli-bg-icon {
            svg {
                path {
                    fill: var(--wb-travel-rangoli-icon-color)
                }
            }
        }

        .indo-western-common-head-wrp {
            position: relative;
            z-index: 9;

            padding-top: 100px;

            @media (max-width: 1366px) {
                padding-top: 80px 0;
            }

            @media (max-width: 992px) {
                padding-top: 60px 0;
            }

            @media (max-width: 768px) {
                padding-top: 30px 0;
            }
        }

        .common-head-divider {
            position: relative;
            z-index: 9;
        }

        // hotel view
        .single-hotel-transport-wrp {
            position: relative;

            .wb-cus-row {
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 120px;

                @media (max-width: 1600px) {
                    gap: 110px;
                }

                @media (max-width: 1440px) {
                    gap: 100px;
                }

                @media (max-width: 1366px) {
                    gap: 90px;
                }

                @media (max-width: 1280px) {
                    gap: 80px;
                }

                @media (max-width: 1199px) {
                    gap: 70px;
                }

                @media (max-width: 992px) {
                    gap: 60px;
                }

                @media (max-width: 767px) {
                    gap: 50px;
                    flex-direction: column;
                }

            }

            .wb-hotel-view {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 40px 0;
                padding: 0 15px;
                width: calc(100% / 2);
                gap: 20px;

                @media (max-width: 1600px) {
                    gap: 45px;
                }

                @media (max-width: 1440px) {
                    gap: 40px;
                }

                @media (max-width: 1366px) {
                    gap: 35px;
                }

                @media (max-width: 1280px) {
                    gap: 30px;
                }

                @media (max-width: 1199px) {
                    gap: 25px;
                }

                @media (max-width: 992px) {
                    gap: 20px;
                }

                @media (max-width: 850px) {
                    gap: 15px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    flex-direction: column;
                    margin: 0px 0;
                }

                .wb-hotel-image {
                    width: 100%;
                    border: 2px dashed var(--wb-how-we-met-image-border-color);
                    border-radius: 100px;

                    @media (max-width: 1600px) {
                        border-radius: 90px;
                    }

                    @media (max-width: 1440px) {
                        border-radius: 80px;
                    }

                    @media (max-width: 1366px) {
                        border-radius: 70px;
                    }

                    @media (max-width: 1199px) {
                        border-radius: 60px;
                    }

                    @media (max-width: 1024px) {
                        border-radius: 50px;
                    }

                    @media (max-width: 992px) {
                        border-radius: 40px;
                    }

                    @media (max-width: 767px) {
                        border-radius: 30px;
                    }

                    img {
                        width: 100%;
                        min-height: 390px !important;
                        min-width: 100% !important;
                        object-fit: cover;

                        @media (max-width: 1600px) {

                            min-height: 400px !important;
                        }

                        @media (max-width: 1440px) {
                            min-height: 370px !important;
                        }

                        @media (max-width: 1366px) {
                            min-height: 340px !important;
                        }

                        @media (max-width: 1280px) {
                            min-height: 310px !important;
                        }

                        @media (max-width: 1199px) {
                            min-height: 280px !important;
                        }

                        @media (max-width: 992px) {
                            min-height: 250px !important;
                        }


                        @media (max-width: 767px) {
                            max-height: auto !important;
                        }
                    }
                }

                .wb-hotel-content {
                    padding: 0 15px;
                    width: 100%;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    h6 {
                        font-size: 16px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        font-weight: 400;
                        line-height: 35px;
                        text-align: center;

                        @media (max-width: 1600px) {
                            line-height: 34px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 15px;
                            line-height: 33px;
                        }

                        @media (max-width: 1366px) {
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 31px;
                        }

                        @media (max-width: 992px) {
                            line-height: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 29px;
                        }
                    }

                    h4 {
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 49px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-primaryFont);
                        margin: 30px 0;
                        margin-bottom: 0;

                        @media (max-width: 1600px) {
                            font-size: 23px;
                            line-height: 47px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 21px;
                            line-height: 45px;
                        }

                        @media (max-width: 1366px) {
                            line-height: 43px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 41px;
                        }

                        @media (max-width: 992px) {
                            line-height: 35px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                            line-height: 28px;
                            margin: 10px 0;
                        }
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        text-align: center;

                        @media (max-width: 1600px) {
                            font-size: 17px;
                            line-height: 36px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 16px;
                            line-height: 34px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 15px;
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 30px;
                        }

                        @media (max-width: 992px) {
                            line-height: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }

                    .wb-view-btn {
                        margin-top: 30px;
                        color: var(--Wb-travel-button-text-color);
                        background-color: var(--Wb-travel-button-bg);
                        border-radius: 15px;
                        padding: 20px;

                        &:hover {
                            background: var(--wb-view-button-hover-bg);
                            color: var(--wb-view-button-hover-text);
                            transition: background-color 0.3s ease;
                        }
                    }
                }
            }

            .wb-transport-view {
                text-align: center;
                margin: 40px 0;
                padding: 0 15px;
                width: calc(100% / 2);

                @media (max-width: 767px) {
                    margin: 35px 0;
                    width: 100%;
                    flex-direction: column;
                }

                .wb-transport-image {
                    width: 100%;
                    border: 2px dashed var(--wb-how-we-met-image-border-color);
                    border-radius: 100px;

                    @media (max-width: 1600px) {
                        border-radius: 90px;
                    }

                    @media (max-width: 1440px) {
                        border-radius: 80px;
                    }

                    @media (max-width: 1366px) {
                        border-radius: 70px;
                    }

                    @media (max-width: 1199px) {
                        border-radius: 60px;
                    }

                    @media (max-width: 1024px) {
                        border-radius: 50px;
                    }

                    @media (max-width: 992px) {
                        border-radius: 40px;
                    }

                    @media (max-width: 767px) {
                        border-radius: 30px;
                    }

                    img {
                        width: 100%;
                        min-height: 390px !important;
                        min-width: 100% !important;
                        object-fit: cover;

                        @media (max-width: 1600px) {
                            min-height: 400px !important;
                        }

                        @media (max-width: 1440px) {
                            min-height: 370px !important;
                        }

                        @media (max-width: 1366px) {
                            min-height: 340px !important;
                        }

                        @media (max-width: 1280px) {
                            min-height: 310px !important;
                        }

                        @media (max-width: 1199px) {
                            min-height: 280px !important;
                        }

                        @media (max-width: 992px) {
                            min-height: 250px !important;
                        }


                        @media (max-width: 767px) {
                            max-height: auto !important;
                        }
                    }
                }

                .wb-transport-content {
                    padding: 0 15px;
                    width: 100%;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    h6 {
                        font-size: 16px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        line-height: 35px;
                        text-align: center;

                        @media (max-width: 1600px) {
                            font-size: 15px;
                            line-height: 34px;
                        }

                        @media (max-width: 1440px) {
                            line-height: 33px;
                        }

                        @media (max-width: 1366px) {
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 31px;
                        }

                        @media (max-width: 992px) {
                            line-height: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    h4 {
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 49px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-primaryFont);
                        margin: 30px 0;
                        margin-bottom: 0;

                        @media (max-width: 1600px) {
                            font-size: 24px;
                            line-height: 47px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 23px;
                            line-height: 45px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 22px;
                            line-height: 43px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 41px;
                        }

                        @media (max-width: 992px) {
                            line-height: 35px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                            line-height: 37px;
                            margin: 10px 0;
                        }
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        text-align: center;

                        @media (max-width: 1600px) {
                            font-size: 17px;
                            line-height: 36px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 16px;
                            line-height: 34px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 15px;
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            font-size: 14px;
                            line-height: 30px;
                        }

                        @media (max-width: 992px) {
                            font-size: 13px;
                            line-height: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 12px;
                            line-height: 26px;
                        }
                    }

                    .wb-view-btn {
                        margin-top: 30px;
                        color: var(--Wb-travel-button-text-color);
                        background-color: var(--Wb-travel-button-bg);
                        border-radius: 15px;
                        padding: 20px;

                        &:hover {
                            background: var(--wb-view-button-hover-bg);
                            color: var(--wb-view-button-hover-text);
                            transition: background-color 0.3s ease;
                        }
                    }
                }
            }
        }


    }

    // title view
    .wb-large-tile {
        h2 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }

        }

    }

    .wb-small-tile {
        h4 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-belleniaFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }


    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family: var(--wb-primaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-primary-text-color);
            margin-bottom: 20px;
            text-align: center;
            text-transform: capitalize;
        }

        p,
        .quil-text-block {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width:767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;

        }

        p {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .wb-view-btn {
                margin-top: 30px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
                border-radius: 0;
                padding: 20px;
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width:1440px) {
                row-gap: 50px;
            }

            @media (max-width:1366px) {
                row-gap: 45px;
            }

            @media (max-width:1199px) {
                row-gap: 40px;
            }

            @media (max-width:992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width:767px) {
                width: 50%;
            }

            @media (max-width:576px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 0px;
                filter: grayscale(100%);
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                margin: 10px 0 5px;
                text-transform: uppercase;

                @media (max-width:1366px) {
                    font-size: 22px;
                }

                @media (max-width:1199px) {
                    font-size: 20px;
                }

                @media (max-width:992px) {
                    font-size: 18px;
                    line-height: normal;
                }

            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width:1366px) {
                    font-size: 18px;
                }

                @media (max-width:992px) {
                    font-size: 16px;
                }

            }
        }
    }
    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);
                  a{
                    color: inherit;
                }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a{
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 35px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                    }
                }
            }
        }
    }
    // events
    .wb-blue-lagoon-events-section{
        padding-bottom: 150px;

        @media (max-width: 1600px) {
            padding-bottom: 120px;
        }


        @media (max-width: 1440px) {
            padding-bottom: 100px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 70px;
        }

        @media (max-width: 767px) {
            padding-bottom: 40px;
        }
        .wb-website-event-wrp {
            .wb-cus-row {
                row-gap: 30px;
                 @media (max-width: 767px) {
                    row-gap: 15px;
                 }
             }
         .wb-cus-col-2 {
             width: calc(100% / 2);
             padding: 0 15px;
             @media (max-width: 767px) {
                 width: 100%;
             }
         }

         .wb-website-event-card {
             background-color: var(--wb-website-event-card-bg);
             padding: 60px;
             height: 100%;
             @media (max-width: 1440px) {
                 padding: 40px;
             }
             @media (max-width: 767px) {
                 padding: 30px;
             }

             .wb-website-event-date-time {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 border-bottom: 1px solid var(--wb-event-date-border-color);
                 padding-bottom: 30px;
                 margin-bottom: 30px;

                 @media (max-width: 1440px){
                    padding-bottom: 20px;
                    margin-bottom:20px;
                 }
                 @media (max-width: 767px) {
                     gap: 10px;
                 }

                 .start-date-time {
                     text-align: center;
                 }

                 .end-date-time {
                     text-align: c;
                 }

                 h6 {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 20px;
                     font-weight: 300;
                     line-height: 26.8px;
                     text-transform: uppercase;
                     @media (max-width: 1440px) {
                         font-size: 17px;
                         line-height: 19.8px;
                     }
                     @media (max-width: 992px) {
                         font-size: 15px;
                     }
                     @media (max-width: 767px) {
                          font-size: 12px;
                     }
                 }

                 h5 {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 28px;
                     font-weight: 600;
                     line-height: 47px;
                     @media (max-width: 1440px) {
                         font-size: 24px;
                         line-height: 38px;
                     }
                     @media (max-width: 992px) {
                         font-size: 18px;
                         line-height: 30px;
                     }
                     @media (max-width: 767px) {
                       font-size: 15px;
                      line-height: 18px;
                     }
                 }

                 span {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-event-span-text-color);
                     background: var(--wb-event-span-bg);
                     font-size: 20px;
                     font-weight: 600;
                     line-height: normal;
                     background-color: var(--wb-event-btn-bg);
                     padding: 20px;
                     text-transform: capitalize;
                     @media (max-width: 1440px) {
                         font-size: 18px;
                         padding: 15px;
                     }

                     @media (max-width: 767px) {
                         font-size: 15px;
                         padding: 10px;
                     }
                 }
             }

             .wb-website-event-details {
                 display: flex;
                 flex-direction: column;
                 align-items: center;

                 h5 {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 40px;
                     font-weight: 600;
                     line-height: 48.59px;
                     margin-bottom: 20px;
                     @media (max-width: 1440px) {
                         font-size: 30px;
                         line-height: 35.59px;
                     }
                     @media (max-width: 992px) {
                      font-size: 26px;
                      line-height: 30.59px;
                         margin-bottom: 15px;
                     }
                     @media (max-width: 767px) {
                         font-size: 20px;
                         line-height: 25.59px;
                     }
                 }

                 p {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 18px;
                     font-weight: 400;
                     line-height: 34px;
                     text-align: center;
                     @media (max-width: 1366px) {
                         font-size: 16px;
                         line-height: 28px;
                     }
                     @media (max-width: 767px) {
                         font-size: 14px;
                         line-height: 25px;
                     }
                 }

                 .wb-view-btn {
                     margin-top: 30px;
                     text-transform: uppercase;
                     background-color: var(--wb-event-btn-bg);
                     color: var(--wb-event-btn-text-color);
                     @media (max-width: 992px) {
                         margin-top: 20px;
                     }

                     &:hover {
                         background-color: var(--wb-event-btn-hover-bg);
                         transition: background-color 0.3s ease;
                         color: var(--wb-event-btn-hover-text-color);
                     }
                 }
             }
         }
     }
}
}