// using the "rosebud-kit-style' styles for default component styling

@mixin fonts {
    --wb-primaryFont: "Megalky", sans-serif;
    --wb-secondaryFont: "Megalky", sans-serif;
}

@mixin bleached-cedar-common-styels {
    @include fonts;

    * {
        font-weight: 400 !important;
    }

    .Web-navigation {
        padding: 0;

        .links li a {
            padding: 0 10px;
            min-width: auto;

            &.active {
                font-weight: 700;
            }

            @media (min-width: 1280px) {
                font-size: 20px;
            }

            @media (min-width: 1401px) {
                font-size: 24px;
            }
        }
    }

    .toggle-button {
        @media (max-width: 767px) {
            right: 40px;
        }
    }

    .ampersand-span {
        font-size: 45px;

        @media (max-width: 767px) {
            font-size: 24px;
        }
    }

    .wb-bride-groom-name-block {
        h2 {
            span {
                line-height: normal;
            }
        }

        h6 span {
            font-family: var(--wb-primaryFont);

            @media (max-width: 1366px) {
                margin-top: 0;
            }
        }
    }

    img {
        border: 1px solid #c693a1 !important;
        border-radius: 15px !important;
    }

    .banner-image img {
        border-radius: 0px !important;
    }

    .wb-view-btn {
        border-radius: 10px !important;
    }

    .wb-activity-content-wrap .wb-activity-card h6 {
        color: #c3ac92;
        line-height: normal;
    }

    .wb-registry-card-wrp .wb-registry-card-item {
        padding: 20px;
        border-radius: 15px !important;
        border: solid 1px var(--wb-registry-card-item-border-color);
    }

    .wb-rsvp-form-wrp {
        .wb-view-btn {
            background-color: var(--color1);
        }
    }

    .wb-rsvp-form-wrp .wb-rsvp-form-title h3 {
        color: var(--white);
    }

    .wb-rsvp-form-wrp .wb-rsvp-form-title p {
        color: var(--white);
    }

    .wb-rsvp-form-wrp form .wb-cus-input {
        border-radius: 10px !important;
    }

    .wb-footer-couple-name {
        margin-top: 80px;

        .wb-divider {
            width: 50%;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }

    .wb-transport-view-wrp,
    .wb-time-line-wrp,
    .wb-home-gallery,
    .wb-rsvp-form-wrp,
    .wb-activity-content-wrap,
    .wb-registry-card-wrp,
    .wb-photos-gallery-wrp {
        +.wb-divider {
            display: none;
        }
    }

    .wb-bride-groom-name-block h2 span:last-of-type {
        border-bottom: 1px solid #68173c;
        padding-bottom: 10px;
    }

    .wb-divider {
        height: 1px;
        width: 40%;
        background-color: #c693a1;
        margin-left: auto;
        margin-right: auto;

        svg {
            display: none;
        }
    }

    .wb-time-line-divider {
        line {
            stroke: #c693a1;
        }
    }

    .wb-hotel-view-wrp {
        +.wb-divider {
            width: 60%;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

    .wb-activity-content-wrap .wb-divider {
        height: auto;
        margin: 0;
        width: 1px;
    }

    .wb-footer-couple-name h6 {
        color: var(--black);
    }

    .wb-time-line-wrp .wb-timeline-content p {
        margin: 0 2rem;
    }

    @media (min-width: 1401px) {
        .wb-event-content-block h6 {
            font-size: 24px;
        }

        .wb-event-content-block p {
            font-size: 20px;
        }

        .wb-bride-groom-name-block h6 span {
            font-size: 24px;
        }

        .wb-time-line-wrp .wb-timeline-content p {
            font-size: 20px;
        }
         .wb-footer-couple-name h6 {
            font-size: 22px;
        }
    }
    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);
                a{
                    color: inherit;
                }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -3px;

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a{
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 35px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                    }
                }
            }
        }
    }
}

// bleached-cedar styles

.bleached-cedar {
    --white: #fff;
    --black: #000000;
    --color1: #33434a;
    --color2: #301a27;
    --color3: #68173c;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color2);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: #ffffff00;
    --wb-menu-active-bg: #ffffff00;
    --wb-menu-active-text-color: var(--color3);
    --wb-menu-text-color: var(--black);
    --wb-event-subtext-color: var(--color1);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: #663853;
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: #663853;
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: #b27095;
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: #663853;
    --wb-time-text-color: var(--white);
    --wb-heart-icon-color: var(--color7);
    --wb-time-bg: #c693a1;
    background-image: url("../../public/images/wedding-websites/bleached-cedar-bg.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100%;

    @include bleached-cedar-common-styels;

    .banner-image {
        width: 1000px;
        max-width: 90%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

// bleached-cedar styles

// bleached-cedar container template styles

.bleached-cedar-contained {
    --white: #fff;
    --black: #000000;
    --color1: #33434a;
    --color2: #301a27;
    --color3: #68173c;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color2);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: #ffffff00;
    --wb-menu-active-bg: #ffffff00;
    --wb-menu-active-text-color: var(--color3);
    --wb-menu-text-color: var(--black);
    --wb-event-subtext-color: var(--color1);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: #663853;
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: #663853;
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: #b27095;
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: #663853;
    --wb-time-text-color: var(--white);
    --wb-time-bg: #c693a1;
    --wb-heart-icon-color: var(--color7);

    padding: 0;
    background-image: url("../../public/images/wedding-websites/bleached-cedar-contained-bg.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100%;

    .inner-wrapper {
        background: #fbfffc;
        max-width: 80%;
        margin: auto;
        padding: 100px 80px;
        box-shadow: 0px 4px 100px 0px #ee6b8580;

        @media (max-width: 1366px) {
            padding: 60px 50px;
            max-width: 85%;
        }

        @media (max-width: 767px) {
            padding: 70px 20px 30px 20px;
            margin: 0px auto;
            max-width: 90%;
        }
    }

    @include bleached-cedar-common-styels;
}

// bleached-cedar container template styles