
$wb-primaryFont: "Yeseva One", serif;
$wb-secondaryFont: "Plus Jakarta Sans", sans-serif;


.toledo-theme1 {
  --white: #fff;
  --color1: #ff9200;
  --color2: #985c7f;
  --color3: #750045;
  --color4: #00000000;
  --color5: #550132;
  --color6: #BD488D;
  --color7: #a0487e;
  --color8: #2a071c;

  --wb-bridegroom-text-color: var(--white);
  --wb-date-text-color: var(--white);
  --wb-menu-bg: var(--color4);
  --wb-menu-active-bg: var(--color4);
  --wb-menu-active-border: var(--color1);
  --wb-menu-active-text-color: var(--color1);
  --wb-menu-text-color: var(--color2);
  --wb-event-subtext-color: var(--color1);
  --wb-event-date-text-color: var(--white);
  --wb-event-head-text-color: var(--color2);
  --wb-event-content-text-color: var(--white);
  --wb-place-text-color: var(--white);
  --wb-footer-text-color: var(--color1);
  --wb-template-bg: var(--color3);
  --wb-primary-text-color: var(--color2);
  --wb-secondary-text-color: var(--white);
  --wb-view-button-text-color: var(--color3);
  --wb-view-button-bg: var(--color1);
  --wb-view-button-hover-bg: var(--white);
  --wb-view-button-hover-text: var(--color1);
  --wb-rsvp-form-bg: var(--color4);
  --wb-rsvp-form-border: var(--color2);
  --wb-menu-toggle-line-bg: var(--color2);
  --wb-menu-toggle-bg: var(--white);
  --wb-mobile-menu-bg: var(--color3);
  --wb-icon-color: var(--white);
  --wb-divider-bg: var(--white);
  --wb-title-bar-bg: var(--color5);
  --wb-title-bar-text-color: var(--color7);
  --wb-sub-banner-title-color: var(--white);
  --wb-sub-banner-bg: var(--color8);
  --wb-common-head-color: var(--color2);
  --wb-close-btn-border: var(--white);
  --wb-close-btn-fill: var(--white);
  --wb-registry-card-item-border-color: var(--color6);
  --wb-card-border-color: var(--color6);
  --wb-card-title-color: var(--color1);
  --wb-travel-card-subtext-color: var(--color1);
  --wb-time-text-color:var(--white);
  --wb-time-bg:var(--color2);
  --wb-heart-icon-color: var(--white);
}
.toledo-theme2 {
  --white: #fff;
  --color1: #ff0084;
  --color2: #c899de;
  --color3: #4d0071;
  --color4: #00000000;
  --color5: #41005f;
  --color6: #BD488D;
  --color7: #971bd0;

  --wb-bridegroom-text-color: var(--white);
  --wb-date-text-color: var(--white);
  --wb-menu-bg: var(--color4);
  --wb-menu-active-bg: var(--color4);
  --wb-menu-active-border: var(--color1);
  --wb-menu-active-text-color: var(--color1);
  --wb-menu-text-color: var(--color2);
  --wb-event-subtext-color: var(--color1);
  --wb-event-date-text-color: var(--white);
  --wb-event-head-text-color: var(--color2);
  --wb-event-content-text-color: var(--white);
  --wb-place-text-color: var(--white);
  --wb-footer-text-color: var(--color1);
  --wb-template-bg: var(--color3);
  --wb-primary-text-color: var(--color2);
  --wb-secondary-text-color: var(--white);
  --wb-view-button-text-color: var(--color3);
  --wb-view-button-bg: var(--color1);
  --wb-view-button-hover-bg: var(--white);
  --wb-view-button-hover-text: var(--color1);
  --wb-rsvp-form-bg: var(--color4);
  --wb-rsvp-form-border: var(--color2);
  --wb-menu-toggle-line-bg: var(--color2);
  --wb-menu-toggle-bg: var(--white);
  --wb-mobile-menu-bg: var(--color3);
  --wb-icon-color: var(--white);
  --wb-divider-bg: var(--white);
  --wb-title-bar-bg: var(--color5);
  --wb-title-bar-text-color: var(--color7);
  --wb-sub-banner-title-color: var(--white);
  --wb-common-head-color: var(--color2);
  --wb-close-btn-border: var(--white);
  --wb-close-btn-fill: var(--white);
  --wb-registry-card-item-border-color: var(--color6);
  --wb-card-border-color: var(--color6);
  --wb-card-title-color: var(--color1);
  --wb-travel-card-subtext-color: var(--color1);
  --wb-time-text-color:var(--white);
  --wb-time-bg:var(--color2);
  --wb-heart-icon-color: var(--white);
}

.toledo-theme3 {
  --white: #fff;
  --color1: #d9ff00;
  --color2: #83b5ce;
  --color3: #00517a;
  --color4: #00000000;
  --color5: #003d5b;
  --color6: #BD488D;
  --color7: #2891c6;

  --wb-bridegroom-text-color: var(--white);
  --wb-date-text-color: var(--white);
  --wb-menu-bg: var(--color4);
  --wb-menu-active-bg: var(--color4);
  --wb-menu-active-border: var(--color1);
  --wb-menu-active-text-color: var(--color1);
  --wb-menu-text-color: var(--color2);
  --wb-event-subtext-color: var(--color1);
  --wb-event-date-text-color: var(--white);
  --wb-event-head-text-color: var(--color2);
  --wb-event-content-text-color: var(--white);
  --wb-place-text-color: var(--white);
  --wb-footer-text-color: var(--color1);
  --wb-template-bg: var(--color3);
  --wb-primary-text-color: var(--color2);
  --wb-secondary-text-color: var(--white);
  --wb-view-button-text-color: var(--color3);
  --wb-view-button-bg: var(--color1);
  --wb-view-button-hover-bg: var(--white);
  --wb-view-button-hover-text: var(--color1);
  --wb-rsvp-form-bg: var(--color4);
  --wb-rsvp-form-border: var(--color2);
  --wb-menu-toggle-line-bg: var(--color2);
  --wb-menu-toggle-bg: var(--white);
  --wb-mobile-menu-bg: var(--color3);
  --wb-icon-color: var(--white);
  --wb-divider-bg: var(--white);
  --wb-title-bar-bg: var(--color5);
  --wb-title-bar-text-color: var(--color7);
  --wb-sub-banner-title-color: var(--white);
  --wb-common-head-color: var(--color2);
  --wb-close-btn-border: var(--white);
  --wb-close-btn-fill: var(--white);
  --wb-registry-card-item-border-color: var(--color6);
  --wb-card-border-color: var(--color6);
  --wb-card-title-color: var(--color1);
  --wb-travel-card-subtext-color: var(--color1);
  --wb-time-text-color:var(--white);
  --wb-time-bg:var(--color2);
  --wb-heart-icon-color: var(--white);
}

.toledo-theme4 {
  --white: #fff;
  --color1: #ffc300;
  --color2: #c875d9;
  --color3: #630077;
  --color4: #00000000;
  --color5: #500061;
  --color6: #BD488D;
  --color7: #c34fdb;

  --wb-bridegroom-text-color: var(--white);
  --wb-date-text-color: var(--white);
  --wb-menu-bg: var(--color4);
  --wb-menu-active-bg: var(--color4);
  --wb-menu-active-border: var(--color1);
  --wb-menu-active-text-color: var(--color1);
  --wb-menu-text-color: var(--color2);
  --wb-event-subtext-color: var(--color1);
  --wb-event-date-text-color: var(--white);
  --wb-event-head-text-color: var(--color2);
  --wb-event-content-text-color: var(--white);
  --wb-place-text-color: var(--white);
  --wb-footer-text-color: var(--color1);
  --wb-template-bg: var(--color3);
  --wb-primary-text-color: var(--color2);
  --wb-secondary-text-color: var(--white);
  --wb-icon-bg: #faf2e9;
  --wb-view-button-text-color: var(--color3);
  --wb-view-button-bg: var(--color1);
  --wb-view-button-hover-bg: var(--white);
  --wb-view-button-hover-text: var(--color1);
  --wb-rsvp-form-bg: var(--color4);
  --wb-rsvp-form-border: var(--color2);
  --wb-menu-toggle-line-bg: var(--color2);
  --wb-menu-toggle-bg: var(--white);
  --wb-mobile-menu-bg: var(--color3);
  --wb-icon-color: var(--white);
  --wb-divider-bg: var(--white);
  --wb-title-bar-bg: var(--color5);
  --wb-title-bar-text-color: var(--color7);
  --wb-sub-banner-title-color: var(--white);
  --wb-common-head-color: var(--color2);
  --wb-close-btn-border: var(--white);
  --wb-close-btn-fill: var(--white);
  --wb-registry-card-item-border-color: var(--color6);
  --wb-card-border-color: var(--color6);
  --wb-card-title-color: var(--color1);
  --wb-travel-card-subtext-color: var(--color1);
  --wb-time-text-color:var(--white);
  --wb-time-bg:var(--color2);
  --wb-heart-icon-color: var(--white);
}
///  ----------toledo template-------------


.toledo {
  background: var(--wb-template-bg);
  padding: 0 0 8rem 0;
  min-height: 100%;
  .wb-cus-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .wb-nav-close-btn {
    background: transparent;
    position: absolute;
    top: 20px;
    right: 25px;
    height: 40px;
    width: 40px;
    border-radius: 25%;
    border: 1px solid var(--wb-close-btn-border);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 70%;
      fill: var(--wb-close-btn-fill);
      height: auto;
      margin: 0;
    }
  }

  .Web-navigation {
    background-color: var(--wb-menu-bg);
    padding: 10px;
    border-radius: 53px;

    @media (max-width: 767px) {
      display: none;
    }

    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        display: none;
        width: 100%;
      }

      li {
        list-style: none;

        a {
          padding: 12px 0;
          text-decoration: none;
          color: var(--wb-menu-text-color);
          font-size: 16px;
          font-weight: 700;
          font-family: $wb-secondaryFont;
          text-align: center;
          border-radius: 0;
          transition: background-color 0.3s ease;
          display: inline-block;

          @media (max-width: 1366px) {
            padding: 15px;
            font-size: 16px;
          }

          @media (max-width: 1280px) {
            font-size: 14px;
          }

          @media (max-width: 1024px) {
            padding: 15px;
          }

          @media (max-width: 992px) {
            padding: 8px;
          }

          @media (max-width: 767px) {
            width: 100%;
            padding: 12px 0;
          }

          &:hover {
            background-color: var(--wb-menu-active-bg);
            color: var(--wb-menu-active-text-color);
          }

          &.active {
            background-color: var(--wb-menu-active-bg);
            color: var(--wb-menu-active-text-color);
            border-bottom: 1px solid var(--wb-menu-active-border);
            border-radius: 0;
          }
        }
      }
    }
  }

  // mobilemenu
  .mobile-navigation {
    background-color: var(--wb-mobile-menu-bg);
    padding: 0;
    border-radius: 0;
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 3000;
    top: 0;
    right: 0;
    margin-right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: none;

    &.open {
      width: 100%;
      transition: 0.9s;

      @media (max-width: 767px) {
        display: block;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        margin-top: 60px;
      }

      li {
        list-style: none;
        width: 100%;

        @media (max-width: 992px) {
          text-align: center;
        }

        a {
          padding: 12px 0;
          text-decoration: none;
          color: var(--wb-menu-text-color);
          font-size: 18px;
          font-weight: 500;
          font-family: $wb-secondaryFont;
          text-align: center;
          border-radius: 34px;
          transition: background-color 0.3s ease;
          display: inline-block;

          &:hover {
            background-color: var(--wb-menu-active-bg);
            color: var(--wb-menu-active-text-color);
          }

          &.active {
            background-color: var(--wb-menu-active-bg);
            color: var(--wb-menu-active-text-color);
            border-bottom: 1px solid var(--wb-menu-active-border);
            border-radius: 0;
          }
        }
      }
    }
  }

  .toggle-button {
    display: none;
    font-size: 28px;
    cursor: pointer;
    background-color: var(--wb-menu-toggle-bg);
    color: white;
    padding: 8px 10px;
    border-radius: 10px;
    border: none;
    position: absolute;
    top: 25px;
    right: 25px;

    @media (max-width: 767px) {
      display: block;
    }

    span {
      width: 20px;
            height: 2px;
            display: block;
            background: var(--wb-menu-toggle-line-bg);
            margin: 4px 0;
    }
  }

  // banner css
  .banner-image {
    margin: 20px 0 60px;

    @media (max-width: 1366px) {
      margin: 20px 0 45px;
    }

    img {
      width: 100%;
      max-height: 737px;
      object-fit: contain;
    }
  }

  .wb-title-bar {
    background: var(--wb-title-bar-bg);
    padding: 15px;

    @media (max-width: 767px) {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      li {
        list-style: none;
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;
        font-size: 18px;
        font-weight: 700;
        line-height: 22.68px;

        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
    }

    .Wb-center-text {
      color: var(--wb-title-bar-text-color);
    }
  }

  .wb-bride-groom-name-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 50px 0;
    margin: 0;

    @media (max-width: 1366px) {
      padding: 30px 0;
    }

    @media (max-width: 767px) {
      padding: 85px 0 30px;
    }

    h2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 30px;
      font-family: $wb-primaryFont;
      color: var(--wb-bridegroom-text-color);
      font-size: 80px;
      font-weight: 400;
      line-height: 92px;
      text-align: center;
      text-transform: capitalize;

      @media (max-width: 1600px) {
        font-size: 65px;
        line-height: normal;
      }

      @media (max-width: 1366px) {
        font-size: 60px;
      }

      @media (max-width: 1280px) {
        font-size: 55px;
      }

      @media (max-width: 1024px) {
        font-size: 40px;
        gap: 12px;
      }

      @media (max-width: 992px) {
        font-size: 30px;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        word-break: break-word;
      }

      span {
        flex: 1;
        text-align: center;
      }

      span:nth-child(2) {
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        flex: 0;

        @media (max-width: 1366px) {
          font-size: 35px;
        }

        @media (max-width: 992px) {
          font-size: 25px;
        }
      }
    }

    h6 {
      display: none;
    }
  }

  // event date

  .wb-event-date {
    text-align: center;
    font-family: $wb-secondaryFont;

    span {
      color: var(--wb-event-subtext-color);
      font-weight: 700;
      font-size: 18px;
      line-height: 22.68px;

      @media (max-width: 1366px) {
        font-size: 17px;
        line-height: normal;
      }
    }

    h3 {
      color: var(--wb-event-date-text-color);
      font-size: 40px;
      font-weight: 700;
      margin: 15px 0 20px;
      font-family: $wb-secondaryFont;
      line-height: 50.4px;

      @media (max-width: 1366px) {
        font-size: 35px;
      }

      @media (max-width: 1280px) {
        font-size: 30px;
      }
    }

    h6 {
      font-size: 22px;
      font-weight: 700;
      line-height: 35px;
      color: var(--wb-place-text-color);
      font-family: $wb-secondaryFont;

      @media (max-width: 1366px) {
        font-size: 20px;
        line-height: normal;
      }

      @media (max-width: 1280px) {
        font-size: 18px;
      }

      @media (max-width: 992px) {
        font-size: 15px;
      }
    }
  }

  // how we met
  .wb-event-content-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    h6 {
      font-family: $wb-primaryFont;
      font-size: 40px;
      font-weight: 400;
      line-height: 46.2px;
      color: var(--wb-event-head-text-color);
      margin-bottom: 20px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 35px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 35px;
      text-align: center;
      color: var(--wb-event-content-text-color);
      font-family: $wb-secondaryFont;

      @media (max-width: 1280px) {
        font-size: 16px;
      }

      @media (max-width: 1199px) {
        font-size: 15px;
      }
    }
  }

  //
  .wb-flower-divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;

    @media (max-width: 1366px) {
      margin: 50px 0;
    }

    @media (max-width: 1199px) {
      margin: 40px 0;
    }

    @media (max-width: 767px) {
      margin: 25px 0;
    }

    svg {
      width: 100%;

      @media (max-width: 767px) {
        width: 40%;
      }
    }
  }

  .toledo-vertical-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;

    svg {
      width: 100%;
    }

    .wb-stopcolor {
      stop-color: var(--wb-divider-bg);
    }
  }

  .wb-divider {
    display: flex;
    align-items: center;
    margin: 60px 0;
    justify-content: center;

    @media (max-width: 1366px) {
      margin: 50px 0;
    }

    @media (max-width: 1280px) {
      margin: 45px 0;
    }

    @media (max-width: 992px) {
      margin: 35px 0;
    }

    svg {
      width: 100%;
      height: 100%;
      max-height: 200px;
      margin: 0;
    }

    .wb-divider-bg {
      stop-color: var(--wb-divider-bg);
    }
  }

  .wb-flowerdivider {
    svg {
      width: 100%;
    }
  }

  // gallery slider
  .wb-slider-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0;

    @media (max-width: 767px) {
      gap: 10px;
    }

    &.slider-margin {
      margin: 0 30px;
    }
  }

  .wb-footer-couple-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    h5 {
      font-size: 60px;
      font-weight: 400;
      line-height: 69.23px;
      color: var(--wb-footer-text-color);
      font-family: $wb-primaryFont;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      text-transform: uppercase;

      @media (max-width: 1366px) {
        font-size: 50px;
        line-height: normal;
      }

      @media (max-width: 992px) {
        font-size: 45px;
      }

      span {
        font-family: $wb-primaryFont;
        font-size: 50px;
        line-height: 68.12px;

        @media (max-width: 1366px) {
          font-size: 40px;
          line-height: normal;
        }

        @media (max-width: 992px) {
          font-size: 35px;
        }
      }
    }

    h6 {
      font-size: 20px;
      font-weight: 600;
      line-height: 25.2px;
      color: var(--wb-footer-text-color);
      font-family: $wb-secondaryFont;
      margin-top: 20px;
      text-align: center;

      @media (max-width: 1366px) {
        font-size: 18px;
        line-height: normal;
      }

      @media (max-width: 1024px) {
        font-size: 16px;
      }

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }

    .wb-divider {
      margin: 0px;
    }
  }

  // our story
  .wb-sub-banner {
    position: relative;
    margin: 20px 0 60px;

    &::after {
      content: "";
      background: var(--wb-sub-banner-bg);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.6;
    }

    img {
      object-fit: cover;
      max-height: 308px !important;
    }

    .wb-sub-banner-text {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 1;

      @media (max-width: 1366px) {
        top: 40%;
      }

      @media (max-width: 767px) {
        top: 40%;
      }

      h2 {
        font-size: 50px;
        color: var(--wb-sub-banner-title-color);
        font-weight: 400;
        line-height: normal;
        font-family: $wb-primaryFont;
        text-align: center;

        @media (max-width: 1600px) {
          font-size: 40px;
        }

        @media (max-width: 1280px) {
          font-size: 30px;
        }

        @media (max-width: 767px) {
          font-size: 22px;
        }
      }
    }
  }

  // photos

  .wb-common-head {
    margin: 60px 0;

    @media (max-width: 1366px) {
      margin: 50px 0;
    }

    @media (max-width: 1024px) {
      margin: 45px 0;
    }

    @media (max-width: 767px) {
      margin: 25px 0;
    }

    h3 {
      color: var(--wb-common-head-color);
      font-family: $wb-primaryFont;
      font-size: 40px;
      font-weight: 400;
      line-height: 46.2px;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: 1366px) {
        font-size: 35px;
      }

      @media (max-width: 1024px) {
        font-size: 30px;
      }

      @media (max-width: 992px) {
        font-size: 25px;
      }
    }

    h6 {
      color: var(--wb-secondary-text-color);
      font-family: $wb-secondaryFont;
      font-size: 18px;
      font-weight: 400;
      line-height: 35px;
      text-align: center;

      @media (max-width: 1366px) {
        font-size: 16px;
        line-height: 25px;
      }

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }

  .wb-photos-gallery {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 60px 0;
    @media (max-width:767px) {
      gap: 6px;
  }
    .wb-cus-row {
      margin: 0 -8px;
      @media (max-width:767px) {
        margin: 0 -6px;
    }
  }
    .gallery-image {
      display: flex;
      padding: 0 8px;
      @media (max-width:767px) {
        padding: 0 3px;
    }
  }

    .wb-col-1 {
      .gallery-image {
        width: calc(100%);
      }
    }

    .wb-col-2 {
      .gallery-image {
        width: calc(100% / 2);
      }
    }

    .wb-col-3 {
      .gallery-image {
        width: calc(100% / 3);
      }
    }

    img {
      border-radius: 30px;
      object-fit: cover;
    }
  }

  .gallery-image {
    padding: 0px 15px;

    @media (max-width: 1366px) {
      padding: 0 10px;
    }

    @media (max-width: 992px) {
      padding: 0 8px;
    }

    @media (max-width: 767px) {
      padding: 0 5px;
    }

    img {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;

    }
  }

  // wedding party card

  .person-view-card {
    margin: 60px 0;

    .wb-cus-row {
      row-gap: 60px;
      justify-content: center;
      @media (max-width: 1440px) {
        row-gap: 50px;
      }

      @media (max-width: 1366px) {
        row-gap: 45px;
      }

      @media (max-width: 1199px) {
        row-gap: 40px;
      }

      @media (max-width: 992px) {
        row-gap: 35px;
      }
    }

    .person-view-card-item {
      padding: 0 15px;
      width: calc(100% / 3);
      text-align: center;
      @media (max-width: 992px) {
        width: calc(100% / 2);
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      img {
        width: 100%;
        max-width: 420px !important;
        max-height: 460px !important;
        object-fit: cover;
        border-radius: 10px;
        object-position: center;
        @media (max-width: 767px) {
          max-width: 100% !important;
        }
      }

      h4 {
        color: var(--wb-card-title-color);
        font-family: $wb-primaryFont;
        font-size: 26px;
        font-weight: 400;
        line-height: 35px;
        margin: 30px 0 10px;
        text-transform: uppercase;

        @media (max-width: 1366px) {
          font-size: 22px;
          margin: 25px 0 10px;
        }

        @media (max-width: 1199px) {
          font-size: 20px;
          margin: 20px 0 10px;
        }

        @media (max-width: 992px) {
          font-size: 18px;
          margin: 20px 0 10px;
        }
      }

      h6 {
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.2px;

        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
      p{
        color: var(--wb-primary-text-color);
        font-family: $wb-secondaryFont;
        font-size: 14px;
        font-weight: 400;
        margin-top: 8px;
    }
    }
  }

  // registry
  .wb-view-btn {
    color: var(--wb-view-button-text-color);
    font-family: $wb-secondaryFont;
    background: var(--wb-view-button-bg);
    font-size: 16px;
    font-weight: 700;
    padding: 15px 40px;
    border-radius: 10px;
    text-transform: uppercase;

    &:hover {
      background: var(--wb-view-button-hover-bg);
      color: var(--wb-view-button-text-color);
      transition: background-color 0.3s ease;
    }

    @media (max-width: 1366px) {
      padding: 12px 30px;
      font-size: 14px;
    }
  }

  // registry

  .wb-registry-card-wrp {
    margin: 60px 0;

    .wb-cus-row {
      row-gap: 30px;
    }

    .wb-col-3 {
      padding: 0 15px;
      flex: 0 0 auto;
      width: calc(100% / 3);

      @media (max-width: 1366px) {
        width: calc(100% / 3);
    }
    @media (max-width: 992px) {
        width: calc(100% / 2);
    }

    @media (max-width: 767px) {
        width: 100%;
    }
    }

    .wb-registry-card-item {
      border-radius: 30px;
      border: solid 2px var(--wb-registry-card-item-border-color);
      padding: 30px;

      @media (max-width: 1366px) {
       padding: 20px;
      }

      .wb-registry-card-img {
        text-align: center;
        border-radius: 15px;
        img {
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
      }

      .wb-view-btn {
        width: 100%;
      }

      h3 {
        font-size: 30px;
        font-weight: 400;
        line-height: 34.65px;
        margin: 20px 0;
        color: var(--wb-card-title-color);
        font-family: $wb-primaryFont;

        @media (max-width: 1600px) {
          margin: 15px 0;
        }

        @media (max-width: 1366px) {
          margin: 10px 0;
          font-size: 26px;
        }

        @media (max-width: 767px) {
          font-size: 24px;
        }
      }

      h4 {
        font-size: 25px;
        font-weight: 500;
        line-height: 35px;
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;

        @media (max-width: 1600px) {
          font-size: 25px;
        }

        @media (max-width: 1366px) {
          font-size: 22px;
        }

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;
        margin-bottom: 20px;

        @media (max-width: 1366px) {
          font-size: 16px;
        }
      }
    }
  }

  //   q & a

  .wb-question-wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 0;

    @media (max-width: 1440px) {
      padding: 35px 0;
    }

    @media (max-width: 1366px) {
      padding: 25px 0;
    }

    @media (max-width: 992px) {
      padding: 16px 0;
    }

    h4 {
      font-size: 30px;
      font-weight: 400;
      line-height: 37.8px;
      color: var(--wb-secondary-text-color);
      font-family: $wb-secondaryFont;
      margin-bottom: 10px;

      @media (max-width: 1440px) {
        font-size: 28px;
        line-height: normal;
      }

      @media (max-width: 1280px) {
        font-size: 26px;
      }

      @media (max-width: 992px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 35px;
      color: var(--wb-secondary-text-color);
      font-family: $wb-secondaryFont;

      @media (max-width: 1440px) {
        font-size: 16px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 25px;
      }
    }
    h6{
      display: none;
    }
  }

  .wb-flower-line-divider {
    padding: 20px 0;
  }

  //   things to do

  .wb-activity-content-wrap {
    margin: 60px 0;
    @media (max-width: 767px) {
      margin: 30px 0;
     }
    .wb-cus-row {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 1024px) {
        gap: 30px 0;
      }

        .wb-activity-card {
          padding: 30px;
          border: 2px solid var(--wb-card-border-color);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          width: calc(100% / 3);
          margin: 0 15px;

          @media (max-width: 1366px) {
            padding: 25px;
          }

          @media (max-width: 1024px) {
            padding: 20px;
            width: calc(100% / 2);
          }
          @media (max-width: 767px) {
           width: 100%;
          }

          img {
            width: 100%;
            max-width: 330px !important;
            min-height: 253px !important;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            @media (max-width: 767px) {
              min-height: auto !important;
             }
          }

          h4 {
            color: var(--wb-card-title-color);
            font-family: $wb-primaryFont;
            font-size: 30px;
            font-weight: 400;
            line-height: 34.65px;
            padding-bottom: 10px;
            margin-top: 20px;

            @media (max-width: 1366px) {
              font-size: 28px;
            }

            @media (max-width: 1024px) {
              font-size: 24px;
              line-height: 25.65px;
            }

            @media (max-width: 767px) {
              font-size: 22px;
            }
          }

          h6 {
            color: var(--wb-secondary-text-color);
            font-family: $wb-secondaryFont;
            padding-bottom: 20px;
            font-size: 18px;
            font-weight: 500;
            line-height: 35px;

            @media (max-width: 1366px) {
              font-size: 16px;
              line-height: 28px;
            }

            @media (max-width: 1024px) {
              line-height: 28px;
            }
          }

         h5 {
            color: var(--wb-secondary-text-color);
            font-family: $wb-secondaryFont;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;

            @media (max-width: 1366px) {
              font-size: 14px;
            }

            @media (max-width: 1024px) {
              line-height: 25px;
            }
          }

          p {
            color: var(--wb-secondary-text-color);
            font-family: $wb-secondaryFont;
            padding-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;

            @media (max-width: 1366px) {
              font-size: 14px;
            }

            @media (max-width: 1024px) {
              line-height: 25px;
            }
          }
          .wb-view-btn {
            margin-top: 20px;
        }
        }

    }
  }
  // rsvp
  .wb-rsvp-form-wrp {
    padding: 60px;
    background: var(--wb-rsvp-form-bg);
    border: 1px solid var(--wb-rsvp-form-border);
    border-radius: 30px;
    max-width: 756px;
    width: 100%;
    margin: auto;

    @media (max-width: 1366px) {
      padding: 50px;
    }

    @media (max-width: 767px) {
      padding: 30px;
    }

    .wb-rsvp-form-title {
      text-align: center;

      h3 {
        font-size: 40px;
        font-weight: 400;
        line-height: 46px;
        color: var(--wb-card-title-color);
        font-family: $wb-primaryFont;
        margin-bottom: 10px;

        @media (max-width: 1440px) {
          font-size: 35px;
          line-height: normal;
        }

        @media (max-width: 1366px) {
          font-size: 32px;
        }

        @media (max-width: 1280px) {
          font-size: 30px;
        }

        @media (max-width: 992px) {
          font-size: 26px;
        }
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 35px;
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;

        @media (max-width: 1440px) {
          font-size: 16px;
        }

        @media (max-width: 1280px) {
          line-height: 35px;
        }

        @media (max-width: 992px) {
          font-size: 15px;
          line-height: 25px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin: 40px 0;

      @media (max-width: 1366px) {
        margin: 30px 0;
      }

      @media (max-width: 767px) {
        margin: 25px 0;
      }

      .wb-cus-input {
        width: 80%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
// password access
  .wb-password-access-form-wrp{
    padding: 60px;
    background: var(--wb-rsvp-form-bg);
    border: 1px solid var(--wb-rsvp-form-border);
    border-radius: 30px;
    max-width: 756px;
    width: 100%;
    margin: auto;

    @media (max-width: 1366px) {
      padding: 50px;
    }

    @media (max-width: 767px) {
      padding: 30px;
    }

    .wb-access-form-title {
      text-align: center;

      h3 {
        font-size: 40px;
        font-weight: 400;
        line-height: 46px;
        color: var(--wb-card-title-color);
        font-family: $wb-primaryFont;
        margin-bottom: 10px;

        @media (max-width: 1440px) {
          font-size: 35px;
          line-height: normal;
        }

        @media (max-width: 1366px) {
          font-size: 32px;
        }

        @media (max-width: 1280px) {
          font-size: 30px;
        }

        @media (max-width: 992px) {
          font-size: 26px;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin: 40px 0;

      @media (max-width: 1366px) {
        margin: 30px 0;
      }

      @media (max-width: 767px) {
        margin: 25px 0;
      }

      .wb-cus-input {
        width: 80%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  .wb-cus-input {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    height: 60px;
    padding: 20px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
  }

  // common style
  // story view
  .wb-story-view-card {
    margin: 30px 0;

    .wb-cus-row {
      row-gap: 60px;
      align-items: center;
      justify-content: center;

      @media (max-width: 1440px) {
        row-gap: 50px;
      }

      @media (max-width: 1366px) {
        row-gap: 45px;
      }

      @media (max-width: 1199px) {
        row-gap: 40px;
      }

      @media (max-width: 992px) {
        row-gap: 35px;
      }
    }

    .wb-story-view-card-item {
      padding: 0 15px;
      width: calc(100% / 3);
      text-align: center;

      @media (max-width:767px) {
        width: 50%;
    }
    @media (max-width:576px) {
        width: 100%;
    }

      img {
        width: 100%;
        max-width: 420px !important;
        height: 460px !important;
        object-fit: cover;
        border-radius: 10px;
        object-position: center;
        @media (max-width:767px) {
          height: auto !important;
      }
      }

      h4 {
        color: var(--wb-card-title-color);
        font-family: $wb-primaryFont;
        font-size: 26px;
        font-weight: 400;
        line-height: 35px;
        margin: 30px 0 10px;
        text-transform: uppercase;

        @media (max-width: 1366px) {
          font-size: 22px;
          margin: 25px 0 10px;
        }

        @media (max-width: 1199px) {
          font-size: 20px;
          margin: 20px 0 10px;
        }

        @media (max-width: 992px) {
          font-size: 18px;
          margin: 20px 0 0px;
        }
      }

      h6 {
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.2px;

        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
  }

  // lager text
  .wb-large-tile {
    margin: 25px 0;
    h2 {
      font-size: 40px;
      font-weight: 400;
      line-height: 46.2px;
      margin-bottom: 20px;
      color: var(--wb-primary-text-color);
      font-family: $wb-primaryFont;
      text-align: center;

      @media (max-width: 1366px) {
        font-size: 35px;
      }

      @media (max-width: 1024px) {
        font-size: 30px;
      }

      @media (max-width: 992px) {
        font-size: 25px;
      }
    }
  }

  //small text
  .wb-small-tile {
    margin: 25px 0;
    h4 {
      font-size: 18px;
      font-weight: 400;
      line-height: 35px;
      color: var(--wb-secondary-text-color);
      font-family: $wb-secondaryFont;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: 1366px) {
        font-size: 16px;
        line-height: 25px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  // title block

  .wb-title-block {
    margin: 25px 0;
    h3 {
      font-family: $wb-primaryFont;
      font-size: 40px;
      font-weight: 400;
      line-height: 46.2px;
      color: var(--wb-primary-text-color);
      margin-bottom: 20px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 35px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 35px;
      text-align: center;
      color: var(--wb-secondary-text-color);
      font-family: $wb-secondaryFont;

      @media (max-width: 1280px) {
        font-size: 16px;
      }

      @media (max-width: 1199px) {
        font-size: 15px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
  // media view
  .wb-media-view {
    margin: 40px 0;
    @media (max-width: 767px) {
      margin: 25px 0;
    }
  }
  // live stream
  .wb-live-stream-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    h4 {
      font-family: $wb-primaryFont;
      font-size: 40px;
      font-weight: 400;
      line-height: 46.2px;
      color: var(--wb-primary-text-color);
      margin-bottom: 20px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 35px;
      }
    }
    p {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: var(--wb-secondary-text-color);
      font-family: $wb-secondaryFont;
      line-height: 35px;
      padding: 20px 0;

      @media (max-width: 1280px) {
        font-size: 16px;
      }

      @media (max-width: 1199px) {
        font-size: 15px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 30px;
      }
    }
    a {
      font-size: 18px;
      font-weight: 600;
      color: var(--wb-primary-text-color);
      font-family: $wb-secondaryFont;
      text-align: center;
      padding: 25px 0;
      text-decoration: underline !important;
      @media (max-width: 1280px) {
        font-size: 16px;
      }

      @media (max-width: 1199px) {
        font-size: 15px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }

  //  hotel

  .wb-hotel-view-wrp {
    .wb-hotel-view {
      padding: 0 15px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      text-align: center;
      margin: 40px 0;
      gap: 40px;
      @media (max-width: 767px) {
        flex-direction: column;
        text-align: center;
      }

      h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 35px;
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;
        word-break: break-word;

        @media (max-width: 1440px) {
          line-height: normal;
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      h4 {
        font-size: 30px;
        color: var(--wb-secondary-text-color);
        font-family: $wb-primaryFont;
        font-weight: 400;
        line-height: 34.65px;
        text-transform: capitalize;
        margin: 00px 0 30px 0;
        word-break: break-word;

        @media (max-width: 1440px) {
          font-size: 28px;
        }

        @media (max-width: 1366px) {
          font-size: 25px;
          margin: 00px 0 20px 0;
        }

        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        color: var(--wb-secondary-text-color);
        font-family: $wb-secondaryFont;
        font-weight: 400;
        line-height: 35px;
        margin-bottom: 30px;


        @media (max-width: 1366px) {
          font-size: 14px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        @media (max-width: 1024px) {
          line-height: 25px;
        }
        @media (max-width: 767px) {
          margin-bottom: 15px;
          line-height: 25px;
        }
      }

      .wb-view-btn {
        margin-top: 30px;
      }
      .wb-hotel-content {
        flex: 1;
        text-align: left;
        @media (max-width: 767px) {
          text-align: center;
        }
      }
      .wb-hotel-image {
        flex: 1;
        img {
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }

    //  travel

    .wb-transport-view-wrp {
      .wb-transport-view {
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        margin: 40px 0;
        gap: 40px;
        @media (max-width: 767px) {
          flex-direction: column;
          text-align: center;
        }

        h6 {
          font-size: 16px;
          font-weight: 500;
          line-height: 35px;
          color: var(--wb-secondary-text-color);
          font-family: $wb-secondaryFont;
          word-break: break-word;

          @media (max-width: 1440px) {
            line-height: normal;
          }

          @media (max-width: 767px) {
            font-size: 14px;
          }
        }

        h4 {
          font-size: 30px;
          color: var(--wb-secondary-text-color);
          font-family: $wb-primaryFont;
          font-weight: 400;
          line-height: 34.65px;
          text-transform: capitalize;
          margin: 00px 0 30px 0;
          word-break: break-word;

          @media (max-width: 1440px) {
            font-size: 28px;
          }

          @media (max-width: 1366px) {
            font-size: 25px;
            margin: 00px 0 20px 0;
          }

          @media (max-width: 767px) {
            font-size: 22px;
          }
        }

        p {
          font-size: 18px;
          color: var(--wb-secondary-text-color);
          font-family: $wb-secondaryFont;
          font-weight: 400;
          line-height: 35px;
          margin-bottom: 30px;

          @media (max-width: 1366px) {
            font-size: 14px;
            line-height: 30px;
          }
          @media (max-width: 1024px) {
            line-height: 25px;
            margin-bottom: 20px;
          }
          @media (max-width: 767px) {
            margin-bottom: 15px;
            line-height: 25px;
          }
        }

        .wb-view-btn {
          margin-top: 30px;
        }
        .wb-transport-content {
          flex: 1;
          text-align: left;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
        .wb-transport-image {
          flex: 1;
          img {
            border-radius: 10px;
            object-fit: cover;

          }
        }
      }
    }

.wb-time-line-wrp{
  margin: 30px 0;
  .wb-timeline-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin: 30px 0;

      h3{
          font-family: var(--wb-secondaryFont);
          color: var(--wb-time-text-color);
          background: var(--wb-time-bg);
          font-weight: 500;
          font-size: 20px;
          line-height: 25.2px;
          text-align: center;
          width: fit-content;
          padding: 10px 20px;
          border-radius: 15px;

          @media (max-width:1366px) {
              font-size: 18px;

          }
          @media (max-width:767px) {
              font-size: 16px;
              padding: 8px 18px;

          }
      }
      p{
          color: var(--wb-primary-text-color);
          font-family: var(--wb-secondaryFont);
          font-size: 18px;
          font-weight: 400;
          line-height: 35px;
          text-align: center;
          margin: 0 13rem;

          @media (max-width:1366px) {
              margin: 0 3rem;
          }
          @media (max-width:991px) {
              font-size: 15px;
          }
          @media (max-width:767px) {
              font-size: 14px;
              line-height: 30px;
              margin: 0 ;

          }
      }
      .timeline-image {
          img{
              object-fit: cover;
              width: 100% !important;
              border-radius: 15px;
          }
      }
 }
 .wb-time-line-divider{
  &:last-of-type{
      display: none;
  }
 }
}
.wb-time-line-divider{
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
      width: 100%;
      margin: 0;
  }
}
.quil-text-block{
  p{
    color: #fff;
  }
}
// wbtemplate footer
.wb-template-footer {
    padding: 50px 0;

    @media (max-width:1440px) {
        padding: 40px 0;
    }

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .wb-template-footer-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        @media (max-width:1440px) {
            gap: 10px;
        }

        h4 {
            font-family: $wb-primaryFont;
            font-size: 40px;
            font-weight: 400;
            line-height: 46.4px;
            color: var(--wb-secondary-text-color);
            a {
              color: inherit;
          }

            @media (max-width: 1440px) {
                font-size: 35px;
                line-height: 40.6px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 32.8px;
            }
        }

        h6 {
            font-family: $wb-secondaryFont;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            color: var(--wb-secondary-text-color);
            display: flex;
            align-items: center;
            gap: 6px;

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 16.8px;
            }
        }

        .wb-template-heart-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3px;

            @media (max-width: 767px) {
                margin-top: 0px;
            }

            svg {
                width: 13px;

                path {
                    fill: var(--wb-heart-icon-color);
                }
            }
        }

        p {
            font-family: $wb-secondaryFont;
            font-size: 14px;
            font-weight: 400;
            line-height: 35px;
            color: var(--wb-secondary-text-color);

        }

        ul {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;

            @media (max-width: 767px) {
                row-gap: 0px;
                column-gap: 15px;
            }

            li {
                a{
                color: var(--wb-secondary-text-color);
                font-family: $wb-secondaryFont;
                font-size: 16px;
                font-weight: 500;
                line-height: 35px;
                text-decoration: underline !important;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 30px;
                }
                }
            }
        }
    }
}
}
