// Default fonts

// DM Serif Display
@font-face {
  font-family: 'DM Serif Display';
  src: url('fonts/dmserif/DMSerifDisplay-Regular.woff2') format('woff2'),
    url('fonts/dmserif/DMSerifDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


// Kantumruy Pro
@font-face {
  font-family: 'Kantumruy Pro';
  src: url('fonts/kantumruy/KantumruyPro-Bold.woff2') format('woff2'),
    url('fonts/kantumruy/KantumruyPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kantumruy Pro';
  src: url('fonts/kantumruy/KantumruyPro-Medium.woff2') format('woff2'),
    url('fonts/kantumruy/KantumruyPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kantumruy Pro';
  src: url('fonts/kantumruy/KantumruyPro-Light.woff2') format('woff2'),
    url('fonts/kantumruy/KantumruyPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kantumruy Pro';
  src: url('fonts/kantumruy/KantumruyPro-Regular.woff2') format('woff2'),
    url('fonts/kantumruy/KantumruyPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kantumruy Pro';
  src: url('fonts/kantumruy/KantumruyPro-SemiBold.woff2') format('woff2'),
    url('fonts/kantumruy/KantumruyPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


// Default fonts

// recoleta
@font-face {
  font-family: "recoleta";
  src: url("fonts/recoleta/recoleta-regular-webfont.woff2") format("woff2"),
    url("fonts/recoleta/recoleta-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


// helixa
@font-face {
  font-family: "helixablack";
  src: url("fonts/helixa/helixa-black-webfont.woff") format("woff2"),
    url("fonts/helixa/helixa-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// roadworthy
@font-face {
  font-family: "roadworthy";
  src: url("fonts/roadworthy/roadworthy.otf") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helixa";
  src: url("fonts/helixa/Helixa-Regular.woff2") format("woff2"),
    url("fonts/helixa/Helixa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// rose bud fonts

// Parisienne
@font-face {
  font-family: 'Parisienne';
  src: url('fonts/parisienne/Parisienne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Plus Jakarta Sans
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('fonts/plusJakartaSans/PlusJakartaSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('fonts/plusJakartaSans/PlusJakartaSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('fonts/plusJakartaSans/PlusJakartaSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('fonts/plusJakartaSans/PlusJakartaSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


// rose bud fonts

@font-face {
  font-family: 'Abel';
  src: url('fonts/abel/Abel-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yeseva One';
  src: url('fonts/yesevaOne/YesevaOne-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


// font for bleached-cedar
@font-face {
  font-family: 'Megalky';
  src: url('fonts/megalky/megalky-regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Halmahera';
  src: url('fonts/halmahera/Halmahera.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bellenia Love';
  src: url('fonts/belleniaLove/BelleniaLove.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Lora

@font-face {
  font-family: 'Lora';
  src: url('fonts/lora/Lora-SemiBold.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Alex Brush
@font-face {
  font-family: 'Alex Brush';
  src: url('fonts/alexBrush/AlexBrush-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


// Dancing Script

@font-face {
  font-family: 'Dancing Script';
  src: url('fonts/dancingScript/DancingScript-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}



// Rouge Script

@font-face {
  font-family: 'Rouge Script';
  src: url('fonts/rougeScript/RougeScript-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
