// using the "rosebud-kit-style' styles for default component styling



@mixin fonts {
    --wb-primaryFont: "Abel", sans-serif;
}

.aqua-island {
    @include fonts;

    --white: #fff;
    --black: #000000;
    --color1: #35533F;

    --color2: #e9edef;
    --color3: #A17700;
    --color4: #faf2e9;
    --color5: #E9EDEF;
    --color6: #33434ae3;
    --color7: #663853;
    --color8: #E50303; //heart icon color


    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--white);
    --wb-menu-active-bg: var(--white);
    --wb-menu-active-text-color: var(--color3);
    --wb-menu-text-color: #8C887B;
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color7);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color8);

    * {
        border-radius: 0 !important;

    }

    padding: 5rem 0;

    .inner-wrapper {
        background: #FBFFFC;
        max-width: 85%;
        margin: 50px auto;
        box-shadow: 0 4px 150px 0 #9cb0a261;
        padding: 80px;

        @media (max-width: 1366px) {
            padding: 50px;
        }

        @media (max-width: 767px) {
            padding: 20px;
            margin: 0px auto;
        }

    }

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/aqua-blue-top-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
        left: 0;
        right: 0;
    }

    &::after {
        content: "";
        background-image: url("../../public/images/wedding-websites/aqua-blue-bottom-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background-position: bottom;
        z-index: -2;
        left: 0;
        right: 0;
    }

    .fit-image {
        border: 1px solid #C0C0C0;
    }

    .Web-navigation .links li a {
        min-width: auto;

        &.active {
            font-weight: 700;
        }
    }

    .wb-bride-groom-name-block h2 span:last-of-type {
        border-bottom: 1px solid #D8BF79;
        padding-bottom: 10px;
    }

    .wb-divider {
        height: 1px;
        width: 100%;
        background-color: #D8BF79;

        svg {
            display: none;
        }
    }

    .wb-time-line-divider {
        line {
            stroke: #D8BF79;
        }
    }

    .wb-footer-couple-name {
        .wb-divider {
            width: 50%;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

    .wb-hotel-view-wrp {
        +.wb-divider {
            width: 60%;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

    .wb-activity-content-wrap .wb-divider {
        height: auto;
        margin: 0;
        width: 1px;
    }

    .wb-footer-couple-name h6 {
        color: var(--black);
    }
    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);
                a{
                    color: inherit;
                }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a{
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 35px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                    }
                }
            }
        }
    }
}