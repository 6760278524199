@mixin fonts {
    --wb-primaryFont: "Lora";
    --wb-secondaryFont: "helixa";
    --wb-subheadingFont: "Alex Brush";
}

.arya-theme1 {
    --white: #fff;
    --color1: #070707;
    --color2: #929292;
    --color3: #708c66; //theme color
    --color4: #00000000;
    --color5: #f2f5f6;
    --color7: #81a375e8;
    --color8: #979797;
    --color9: #373737;
    --color10: #f9f9f5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color4);
    --wb-view-button-hover-bg: var(--color7);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color3);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color5);
    --wb-registry-btn-bg: var(--color3);
    --wb-registry-btn-hover-bg: var(--color7);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color3);
    --wb-input-placeholder-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color9);
    --wb-sub-content-color: var(--color8);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color3);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-rsvp-bg: var(--color3);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color7);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --Wb-things-to-do-button-bg: var(--color3);
    --Wb-things-to-do-button-hover-bg: var(--color7);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color9);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color7);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --wb-heart-icon-color: var(--color11);
    --password-access-form-bg: var(--color3);
}

.arya-theme2 {
    --white: #fff;
    --color1: #070707;
    --color2: #929292;
    --color3: #CF734F; //theme color
    --color4: #00000000;
    --color5: #f2f5f6;
    --color7: #ce8568dd;
    --color8: #979797;
    --color9: #373737;
    --color10: #f9f9f5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color4);
    --wb-view-button-hover-bg: var(--color7);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color3);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color5);
    --wb-registry-btn-bg: var(--color3);
    --wb-registry-btn-hover-bg: var(--color7);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color3);
    --wb-input-placeholder-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color9);
    --wb-sub-content-color: var(--color8);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color3);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-rsvp-bg: var(--color3);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color7);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --Wb-things-to-do-button-bg: var(--color3);
    --Wb-things-to-do-button-hover-bg: var(--color7);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color9);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.arya-theme3 {
    --white: #fff;
    --color1: #070707;
    --color2: #929292;
    --color3: #018B9D; //theme color
    --color4: #00000000;
    --color5: #f2f5f6;
    --color7: #3695a2dd;
    --color8: #979797;
    --color9: #373737;
    --color10: #f9f9f5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color4);
    --wb-view-button-hover-bg: var(--color7);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color3);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color5);
    --wb-registry-btn-bg: var(--color3);
    --wb-registry-btn-hover-bg: var(--color7);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color3);
    --wb-input-placeholder-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color9);
    --wb-sub-content-color: var(--color8);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color3);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-rsvp-bg: var(--color3);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color7);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --Wb-things-to-do-button-bg: var(--color3);
    --Wb-things-to-do-button-hover-bg: var(--color7);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color9);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.arya-theme4 {
    --white: #fff;
    --color1: #070707;
    --color2: #929292;
    --color3: #B8963B; //theme color
    --color4: #00000000;
    --color5: #f2f5f6;
    --color7: #c5ab65;
    --color8: #979797;
    --color9: #373737;
    --color10: #f9f9f5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color4);
    --wb-view-button-hover-bg: var(--color7);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color3);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color5);
    --wb-registry-btn-bg: var(--color3);
    --wb-registry-btn-hover-bg: var(--color7);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color3);
    --wb-input-placeholder-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color9);
    --wb-sub-content-color: var(--color8);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color3);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-rsvp-bg: var(--color3);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color7);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --Wb-things-to-do-button-bg: var(--color3);
    --Wb-things-to-do-button-hover-bg: var(--color7);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color9);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.arya-theme5 {
    --white: #fff;
    --color1: #070707;
    --color2: #929292;
    --color3: #663399; //theme color
    --color4: #00000000;
    --color5: #f2f5f6;
    --color7: #7946abdd;
    --color8: #979797;
    --color9: #373737;
    --color10: #f9f9f5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color4);
    --wb-view-button-hover-bg: var(--color7);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color3);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color5);
    --wb-registry-btn-bg: var(--color3);
    --wb-registry-btn-hover-bg: var(--color7);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color3);
    --wb-input-placeholder-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color9);
    --wb-sub-content-color: var(--color8);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color3);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-rsvp-bg: var(--color3);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color7);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --Wb-things-to-do-button-bg: var(--color3);
    --Wb-things-to-do-button-hover-bg: var(--color7);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color9);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.arya-theme6 {
    --white: #fff;
    --color1: #070707;
    --color2: #929292;
    --color3: #B83A4F; //theme color
    --color4: #00000000;
    --color5: #f2f5f6;
    --color7: #c95b6eea;
    --color8: #979797;
    --color9: #373737;
    --color10: #f9f9f5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color4);
    --wb-view-button-hover-bg: var(--color7);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color3);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color5);
    --wb-registry-btn-bg: var(--color3);
    --wb-registry-btn-hover-bg: var(--color7);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--white);
    --wb-accordion-title-color: var(--white);
    --wb-accordion-text-color: var(--white);
    --wb-accordion-bg: var(--color3);
    --wb-input-placeholder-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color9);
    --wb-sub-content-color: var(--color8);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color3);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-rsvp-bg: var(--color3);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color7);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --Wb-things-to-do-button-bg: var(--color3);
    --Wb-things-to-do-button-hover-bg: var(--color7);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color9);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

// template theme colors end
.arya {
    @include fonts;
    background-color: var(--color10);

    .quil-text-block {
        font-family: var(--wb-secondaryFont);
    }

    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 14px;
        font-weight: 400;
        padding: 12px 20px;
        border-radius: 15px;
        text-transform: uppercase;

        &:hover {
            background-color: var(--wb-rsvp-btn-hover-bg);
            transition: background-color 0.3s ease;
        }

        @media (max-width: 767px) {
            padding: 10px 15px;
            font-size: 12px;
            border-radius: 10px;
        }
    }

    .wb-common-head {
        margin: 60px 0;
        display: flex;
        flex-direction: column-reverse;

        @media (max-width: 1440px) {
            margin: 40px 0;
        }

        @media (max-width: 1024px) {
            margin: 45px 0;
        }

        @media (max-width: 992px) {
            margin: 30px 0;
        }

        @media (max-width: 767px) {
            margin: 25px 0;
        }

        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1440px) {
                font-size: 40px;
                line-height: 60px;

            }

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 1024px) {
                font-size: 30px;
                line-height: 50px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 45px;
            }

            @media (max-width: 567px) {
                font-size: 25px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-subheadingFont);
            font-size: 40px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 51.99px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 30px;
                line-height: 30px;
            }

            @media (max-width: 1024px) {
                font-size: 25px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

    // titlebar
    .wb-title-bar {
        padding: 30px 0;
        width: 100%;

        @media (max-width: 767px) {
            padding: 15px 0;
        }

        h2 {
            color: var(--wb-title-bar-text-color);
            font-size: 60px;
            text-align: center;
            font-weight: 400;
            line-height: 81.6px;
            font-family: var(--wb-primaryFont);
            display: flex;
            align-items: baseline;
            justify-content: center;
            gap: 10px;

            @media (max-width: 767px) {
                font-size: 35px;
                line-height: normal;
            }

            span {
                font-family: var(--wb-primaryFont);
                font-size: 20px;
                font-weight: 400;
                line-height: 27.2px;

                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }
        }
    }

    // slider banner

    .wb-slider-banner-wrp {
        position: relative;
        max-width: 1720px;
        margin: auto;
        padding-bottom: 50px;
        width: 90%;

        @media (max-width: 1600px) {
            padding-bottom: 45px;
        }

        @media (max-width: 1440px) {
            max-width: 1200px;
            width: 100%;
            padding: 0 15px 40px 15px;
        }

        @media (max-width: 1366px) {
            padding-bottom: 35px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 25px;
        }

        @media (max-width: 992px) {
            padding-bottom: 15px;
        }


        .wb-view-btn {
            font-size: 16px;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }

        .wb-banner-slider-image {
            position: relative;
            border-radius: 100px;
            overflow: hidden;

            @media (max-width: 1440px) {
                border-radius: 80px;
            }

            @media (max-width: 1366px) {
                border-radius: 60px;
            }

            @media (max-width: 1199px) {
                border-radius: 50px;
            }

            @media (max-width: 767px) {
                border-radius: 30px;
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                top: 0;
                opacity: 0.4;
                left: 0px;
                right: 0;
                margin: 0;
            }

            img {
                object-fit: cover;
                height: 100vh !important;
            }
        }

        .wb-banner-slider-content {
            position: absolute;
            width: 100%;
            bottom: 18%;
            left: 0;
            right: 0;
            text-align: center;


            h4 {
                text-align: center;
                font-family: var(--wb-subheadingFont);
                font-size: 50px;
                font-weight: 400;
                color: var(--wb-banner-text-color);
                line-height: initial;

                @media (max-width:1440px) {
                    font-size: 35px;

                }

                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }

            .wb-view-btn {
                color: var(--wb-banner-button-border);
                background: var(--wb-view-button-bg);
                border: 1px solid var(--wb-banner-button-border);
                margin-top: 0px;
                border-radius: 0;
            }
        }
    }

    // bride groom name
    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 50px 0;
        margin: 0;

        @media (max-width: 1366px) {
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding: 15px 0;
        }


        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            font-family: var(--wb-primaryFont);
            color: var(--wb-bridegroom-text-color);
            font-size: 80px;
            font-weight: 400;
            line-height: 92px;
            text-align: center;
            text-transform: capitalize;
            word-break: break-word;

            @media (max-width: 1600px) {
                font-size: 65px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                font-size: 60px;
            }

            @media (max-width: 1280px) {
                font-size: 55px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
                gap: 18px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 25px;
                flex-direction: column;
                gap: 3px;
            }


            span {
                text-align: left;

                @media (max-width:767px) {
                    text-align: center;

                }
            }

            span:nth-child(1) {
                text-align: right;

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

            span:nth-child(2) {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                }

            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 19px;
            margin-top: 10px;
            align-items: center;
            word-break: break-word;

            @media (max-width: 767px) {
                gap: 0;
                margin-top: 0;
                flex-direction: column;
            }

            span {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 27.72px;
                color: var(--wb-banner-date-text-color);

                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: initial;
                }


                &:nth-child(1) {
                    flex: 1;
                    text-align: right;
                }

                &:nth-child(3) {
                    flex: 1;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 2px;
                    height: 18px;
                    background: var(--white);

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }

    // image carousel
    .partial-image-carousel {
        padding: 100px 0;

        @media (max-width: 1440px) {
            padding: 70px 0;
        }

        @media (max-width: 1366px) {
            padding: 50px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .swiper {
            margin-bottom: 0px !important;

            @media(max-width: 767px) {
                margin: 0 !important;
            }

        }

        img {
            height: 662px !important;
            width: 100% !important;
            max-width: 452px !important;
            object-fit: cover;
            border-radius: 80px;

            @media (max-width: 1600px) {
                height: 600px !important;
                max-width: 400px !important;
                border-radius: 70px;
            }

            @media (max-width: 1440px) {
                height: 550px !important;
                max-width: 370px !important;
                border-radius: 60px;
            }

            @media (max-width: 1366px) {
                height: 450px !important;
                max-width: 340px !important;
                border-radius: 55px;
            }

            @media (max-width: 1199px) {
                height: 420px !important;
                max-width: 310px !important;
                border-radius: 50px;
            }

            @media (max-width: 992px) {
                height: 240px !important;
                max-width: 280px !important;
                border-radius: 35px;
            }

            @media (max-width: 767px) {
                height: 350px !important;
                max-width: 250px !important;
                border-radius: 30px;
            }
        }


    }

    // how we met
    .wb-arya-how-we-met-wrp {
        padding: 100px 0;

        @media (max-width: 1440px) {
            padding: 70px 0;
        }

        @media (max-width: 1366px) {
            padding: 50px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-cus-row {
            flex-wrap: nowrap;
            gap: 30px;
            align-items: center;
            margin: 0 -15px;

            @media (max-width: 1600px) {
                gap: 20px;
            }

            @media (max-width: 1366px) {
                gap: 15px;
            }

            @media (max-width: 767px) {
                gap: 30px;
                flex-direction: column;
            }

            @media (max-width: 567px) {
                gap: 15px !important;
            }

            .wb-cus-col-3 {
                width: 100%;
                padding: 0 10px;
            }
        }

        .wb-arya-how-we-met-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .wb-common-head {
                margin: 40px 0;
                display: flex;
                flex-direction: column-reverse;

                @media (max-width: 1600px) {
                    margin: 30px 0;
                }

                @media (max-width: 1440px) {
                    margin: 20px 0;
                }

                @media (max-width: 1024px) {
                    margin: 35px 0;
                }

                @media (max-width: 992px) {
                    margin: 30px 0;
                }

                @media (max-width: 992px) {
                    margin: 5px 0;
                }

                h3 {
                    color: var(--wb-how-we-are-text-color);
                    font-family: var(--wb-primaryFont);
                    text-align: center;

                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-subheadingFont);
                    text-align: center;

                }
            }

            p {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 18px;
                font-weight: 400;
                line-height: 38px;
                text-align: center;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 25px;
                }

            }
        }

        .how-we-met-image {
            img {
                width: 440px !important;
                height: 540px !important;
                min-width: 100% !important;
                min-height: 100% !important;
                object-fit: cover;
                border-radius: 60px;

                @media (max-width: 1600px) {
                    width: 400px !important;
                    height: 500px !important;
                    border-radius: 55px;
                }

                @media (max-width: 1440px) {
                    width: 370px !important;
                    height: 470px !important;
                    border-radius: 50px;
                }

                @media (max-width: 1280px) {
                    width: 340px !important;
                    height: 440px !important;
                    border-radius: 45px;
                }

                @media (max-width: 1199px) {
                    width: 310px !important;
                    height: 410px !important;
                    border-radius: 40px;
                }

                @media (max-width: 992px) {
                    width: 280px !important;
                    height: 380px !important;
                    border-radius: 35px;
                }

                @media (max-width: 767px) {
                    height: auto !important;
                    width: 100% !important;
                    border-radius: 30px;
                }
            }
        }

        .how-we-met-right-image {
            padding-top: 5rem;

            @media (max-width: 767px) {
                padding-top: 0;
            }
        }

        .how-we-met-left-image {
            padding-bottom: 5rem;

            @media (max-width: 992px) {
                padding-bottom: 3rem;
            }

            @media (max-width: 767px) {
                padding-bottom: 0;
            }
        }
    }

    // venue location
    .wb-arya-venue-location-wrp {
        padding: 50px 0 100px;

        @media (max-width: 1600px) {
            padding: 40px 0 80px;
        }

        @media (max-width: 1366px) {
            padding: 20px 0 60px;
        }

        @media (max-width: 1280px) {
            padding: 10px 0 50px;
        }

        @media (max-width: 767px) {
            padding: 15px 0 40px;
        }

        .wb-cus-row {
            flex-wrap: nowrap;
            gap: 15px;
            align-items: flex-end;
            margin: 0 -15px;


            @media (max-width: 1440px) {
                gap: 5px;
            }

            @media (max-width: 992px) {
                gap: 0px;
            }

            @media (max-width: 767px) {
                gap: 25px !important;
                flex-direction: column;
            }

            @media (max-width: 567px) {
                gap: 15px !important;
            }

            .wb-cus-col-3 {
                width: 100%;
                padding: 0 15px;
            }

            .order-3 {
                @media (max-width: 767px) {
                    order: 3;
                }
            }
        }

        .wb-arya-venue-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 767px) {
                align-items: center;
            }

            .wb-container {
                padding: 0;
            }

            .wb-common-head {
                margin: 50px 0;
                display: flex;
                flex-direction: column-reverse;

                @media (max-width: 1440px) {
                    margin: 20px 0;
                }

                @media (max-width: 1024px) {
                    margin: 35px 0;
                }

                @media (max-width: 992px) {
                    margin: 30px 0;
                }

                @media (max-width: 767px) {
                    margin: 10px 0;
                }

                h3 {
                    color: var(--wb--venue-title-color);
                    font-family: var(--wb-primaryFont);
                    text-align: left;

                    @media (max-width: 767px) {
                        text-align: center;
                    }
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-subheadingFont);
                    text-align: left;

                    @media (max-width: 767px) {
                        text-align: center;
                    }

                }
            }

            .wb-view-btn {
                color: var(--wb-venue-btn-text-color);
                background: var(--wb-venue-btn-bg);
                font-family: var(--wb-secondaryFont);
                margin-top: 50px;

                @media (max-width: 767px) {
                    margin-top: 30px;
                    margin-bottom: 5px;
                }

                &:hover {
                    background-color: var(--wb-rsvp-btn-hover-bg);
                    transition: background-color 0.3s ease;
                }
            }

            p {
                color: var(--wb-venue-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 43px;
                text-align: left;

                @media (max-width: 1600px) {
                    font-size: 20px;
                    line-height: 41px;
                }

                @media (max-width: 1440px) {
                    font-size: 18px;
                    line-height: 39px;
                }

                @media (max-width: 1199px) {
                    font-size: 16px;
                    line-height: 37px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 35px;
                    text-align: center;
                }
            }
        }

        .venue-left-image {
            img {
                width: 430px !important;
                height: 700px !important;
                min-width: 100% !important;
                min-height: 100% !important;
                object-fit: cover;
                border-radius: 60px;

                @media (max-width: 1600px) {
                    width: 400px !important;
                    height: 650px !important;
                    border-radius: 55px;
                }

                @media (max-width: 1440px) {
                    width: 370px !important;
                    height: 600px !important;
                    border-radius: 50px;
                }

                @media (max-width: 1280px) {
                    width: 340px !important;
                    height: 550px !important;
                    border-radius: 45px;
                }

                @media (max-width: 1199px) {
                    width: 310px !important;
                    height: 500px !important;
                    border-radius: 40px;
                }

                @media (max-width: 992px) {
                    width: 280px !important;
                    height: 450px !important;
                    border-radius: 35px;
                }

                @media (max-width: 767px) {
                    max-height: 400px !important;
                    height: auto !important;
                    width: 100% !important;
                    border-radius: 30px;
                }
            }
        }

        .venue-right-image {
            img {
                width: 430px !important;
                height: 595px !important;
                min-width: 100% !important;
                min-height: 100% !important;
                object-fit: cover;
                border-radius: 60px;

                @media (max-width: 1600px) {
                    width: 400px !important;
                    height: 550px !important;
                    border-radius: 55px;
                }

                @media (max-width: 1440px) {
                    width: 370px !important;
                    height: 520px !important;
                    border-radius: 50px;
                }

                @media (max-width: 1280px) {
                    width: 340px !important;
                    height: 490px !important;
                    border-radius: 45px;
                }

                @media (max-width: 1199px) {
                    width: 310px !important;
                    height: 460px !important;
                    border-radius: 40px;
                }

                @media (max-width: 992px) {
                    width: 280px !important;
                    height: 430px !important;
                    border-radius: 35px;
                }

                @media (max-width: 850px) {
                    width: 280px !important;
                    height: 350px !important;
                    border-radius: 35px;
                }

                @media (max-width: 767px) {
                    max-height: 400px !important;
                    height: auto !important;
                    width: 100% !important;
                    border-radius: 30px;
                }
            }
        }
    }

    // countdown
    .wb-count-down-wrp {
        padding: 50px 0;

        @media (max-width: 1024px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 15px 0;
        }

        .wb-cus-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;

            .wb-count-down-content {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 3);
                }

                @media (max-width:576px) {
                    width: calc(100% / 2);
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 42.52px;
                    text-transform: uppercase;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: normal;
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 58px;

                    @media (max-width: 1366px) {
                        font-size: 45px;
                        line-height: 54px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 40px;
                        line-height: 48px;
                    }

                    @media (max-width: 992px) {
                        font-size: 35px;
                        line-height: 40px;
                    }

                    @media (max-width: 992px) {
                        font-size: 30px;
                        line-height: 35px;
                    }
                }
            }
        }
    }

    // sub banner
    .wb-sub-banner {
        padding: 50px 0;

        @media (max-width: 1024px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        img {
            max-height: 576px !important;
            height: auto !important;
            object-fit: cover !important;
        }
    }

    // faq accordion
    .wb-expandable-faq-wrp {
        background-color: var(--wb-accordion-bg);
        padding: 100px 0;
        margin: 50px 0 100px;

        @media (max-width: 1600px) {
            padding: 90px 0;
            margin: 50px 0 90px;
        }

        @media (max-width: 1440px) {
            padding: 70px 0;
            margin: 50px 0 70px;
        }

        @media (max-width: 1366px) {
            padding: 50px 0;
            margin: 50px 0 50px;
        }


        @media (max-width: 767px) {
            padding: 30px 0;
            margin: 30px 0 30px;
        }

        .wb-common-head {
            margin-bottom: 0;
            align-items: center;

            h3,
            h6 {
                color: var(--wb-accordion-title-color);
            }
        }
    }

    .wb-accordion-wrp {
        padding: 50px 0;

        @media (max-width: 1600px) {
            padding: 45px 0;
        }

        @media (max-width: 1440px) {
            padding: 40px 0;
        }

        @media (max-width: 1366px) {
            padding: 35px 0;
        }

        @media (max-width: 1199px) {
            padding: 30px 0;
        }

        @media (max-width: 992px) {
            padding: 25px 0;
        }

        @media (max-width: 767px) {
            padding: 20px 0;
        }

        .wb-accordion {
            margin-bottom: 0;
            padding: 30px;
            border-bottom: 1px solid var(--wb-accordion-border-color);
            border-left: 1px solid var(--wb-accordion-border-color);
            border-right: 1px solid var(--wb-accordion-border-color);

            @media (max-width: 1600px) {
                padding: 28px;
            }

            @media (max-width: 1440px) {
                padding: 26px;
            }

            @media (max-width: 1366px) {
                padding: 24px;
            }

            @media (max-width: 1280px) {
                padding: 22px;
            }

            @media (max-width: 1199px) {
                padding: 20px;
            }

            @media (max-width: 992px) {
                padding: 18px;
            }

            @media (max-width: 767px) {
                padding: 16px;
            }

            &:nth-child(1) {
                border-top: 1px solid var(--wb-accordion-border-color);
            }

            .wb-accordion-header {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h3 {
                    font-size: 30px;
                    font-family: var(--wb-primaryFont);
                    color: var(--wb-accordion-title-color);
                    font-weight: 400;
                    line-height: normal;
                    margin: 30px 0;

                    @media (max-width: 1600px) {
                        font-size: 28px;
                        margin: 28px 0;
                    }

                    @media (max-width: 1440px) {
                        font-size: 26px;
                        margin: 26px 0;
                    }

                    @media (max-width: 1366px) {
                        font-size: 24px;
                        margin: 24px 0;
                    }

                    @media (max-width: 1199px) {
                        font-size: 22px;
                        margin: 22px 0;
                    }

                    @media (max-width: 992px) {
                        font-size: 20px;
                        margin: 20px 0;
                    }

                    @media (max-width: 767px) {
                        font-size: 18px;
                        margin: 18px 0;
                    }
                }

                .wb-accordion-icon {
                    transition: transform 0.3s ease-in-out;

                    &.open {
                        transform: rotateX(180deg);
                    }

                    svg {
                        width: auto;
                        margin-right: 0;

                        @media (max-width: 767px) {
                            width: 30px;
                        }

                        @media (max-width: 420px) {
                            width: 25px;
                        }
                    }
                }
            }

            .wb-accordion-content {
                max-height: 0;
                overflow: hidden;

                p {
                    color: var(--wb-accordion-text-color);
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 38px;
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 1600px) {
                        font-size: 17px;
                        line-height: 36px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 16px;
                        line-height: 34px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 15px;
                        line-height: 32px;
                    }

                    @media (max-width: 1199px) {
                        line-height: 30px;
                    }

                    @media (max-width: 992px) {
                        line-height: 28px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }

                &.open {
                    max-height: 100%;
                    padding: 15px 0;
                }
            }
        }
    }

    // registry

    .wb-registry-card-wrp {
        padding-bottom: 100px;

        @media (max-width: 1440px) {
            padding-bottom: 80px;
        }

        @media (max-width: 1366px) {
            padding-bottom: 60px;
        }

        @media (max-width: 992px) {
            padding-bottom: 50px;
        }

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1199px) {
                row-gap: 20px;
            }

            @media (max-width: 767px) {
                row-gap: 15px;
            }
        }

        .wb-col-3 {
            padding: 0 15px;
            flex: 0 0 auto;
            width: calc(100% / 4);

            @media (max-width: 1199px) {
                width: calc(100% / 3);
            }

            @media (max-width: 767px) {
                width: calc(100% / 2);
            }

            @media (max-width: 567px) {
                width: 100%;
            }
        }

        .wb-registry-card-item {
            border-radius: 50px;
            background: var(--Wb-registry-card-bg);
            padding: 30px;
            border: 1px solid var(--wb-registry-card-border);
            height: 100%;
            display: flex;
            flex-direction: column;

            @media (max-width: 1024px) {
                padding: 20px;
            }

            .wb-registry-card-img {
                text-align: center;
                border-radius: 15px;
                margin-bottom: 10px;

                img {
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            .wb-registry-card-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

            }

            .wb-view-btn {
                width: 100%;
                background-color: var(--wb-registry-btn-bg);
                color: var(--wb-registry-btn-text-color);

                &:hover {
                    background-color: var(--wb-registry-btn-hover-bg);
                    transition: background-color 0.3s ease;
                    color: var(--wb-registry-btn-hover-text-color);
                }
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-weight: 400;
                font-size: 25px;
                line-height: 35px;

                @media (max-width: 1440px) {
                    font-size: 22px;
                    line-height: 31px;
                }

                @media (max-width: 1199px) {
                    font-size: 20px;
                    line-height: 28px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                    line-height: 25px;
                }
            }

            h3 {
                margin: 20px 0;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-weight: 400;
                font-size: 28px;

                @media (max-width: 1199px) {
                    font-size: 25px;
                }

                @media (max-width: 992px) {
                    font-size: 22px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 20px;

                @media (max-width: 1600px) {
                    font-size: 15px;
                    line-height: 28px;
                }

                @media (max-width: 1440px) {
                    font-size: 14px;
                    line-height: 26px;
                }

                @media (max-width: 1199px) {
                    font-size: 13px;
                    line-height: 24px;
                }

            }
        }
    }

    // rsvp
    .wb-rsvp-form-wrp {
        padding: 100px 0;
        background-image: url("../../public/images/wedding-websites/arya-rsvp-bg.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        min-height: 800px;
        height: 100%;

        @media (max-width: 1600px) {
            padding: 90px 0;
            min-height: 750px;
        }

        @media (max-width: 1440px) {
            padding: 70px 0;
            min-height: 700px;
        }

        @media (max-width: 1366px) {
            padding: 50px 0;
            min-height: 650px;
        }

        @media (max-width: 992px) {
            padding: 50px 0;
            min-height: 550px;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
            min-height: 350px;
        }

        .wb-rsvp-form-title {
            text-align: center;
            flex-direction: column-reverse;
            display: flex;
            gap: 10px;
            margin-bottom: 20px;

            h3 {
                font-size: 40px;
                font-weight: 400;
                line-height: 46px;
                color: var(--wb-rsvp-text-color);
                font-family: var(--wb-primaryFont);
                margin-bottom: 10px;

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: normal;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 26px;
                }
            }

            p {
                font-size: 30px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-rsvp-text-color);
                font-family: var(--wb-subheadingFont);

                @media (max-width: 1366px) {
                    line-height: 25px;
                }

                @media (max-width: 1280px) {
                    line-height: 35px;
                }

                @media (max-width: 767px) {
                    font-size: 20px;

                }
            }
        }

        .wb-rsvp {
            background: var(--wb-rsvp-bg);
            padding: 60px;
            max-width: 650px;
            margin: 0 0 0 auto;
            border-radius: 60px;
            margin-top: 8rem;

            @media (max-width: 1600px) {
                padding: 55px;
                max-width: 600px;
                border-radius: 55px;
                margin-top: 7.5rem;
            }

            @media (max-width: 1440px) {
                padding: 50px;
                max-width: 550px;
                border-radius: 50px;
                margin-top: 7rem;
            }

            @media (max-width: 1366px) {
                padding: 45px;
                max-width: 500px;
                border-radius: 45px;
                margin-top: 6.5rem;
            }

            @media (max-width: 1199px) {
                padding: 40px;
                max-width: 450px;
                border-radius: 40px;
                margin-top: 6rem;
            }

            @media (max-width: 992px) {
                padding: 35px;
                max-width: 400px;
                border-radius: 35px;
                margin-top: 5.5rem;
            }

            @media (max-width: 767px) {
                padding: 30px;
                max-width: 350px;
                border-radius: 30px;
                margin: auto;
            }

            form {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }

            .wb-cus-input {
                background-color: transparent;
                border: 1px solid #e7e7e7;
                border-radius: 0px;
                padding: 10px 16px;
                margin-bottom: 10px;
                color: var(--wb-primary-text-color);
                font-weight: 400;
                font-size: 12px;
                width: 80%;
                height: 40px;
                outline: none;
                font-family: var(--wb-secondaryFont);

                @media (max-width: 767px) {
                    width: 100%;
                }

                &::placeholder {
                    color: var(--wb-input-placeholder-color) !important;
                    font-family: var(--wb-secondaryFont);
                    line-height: 15.12px;
                    font-weight: 400;
                    font-size: 12px;
                }
            }

            .wb-view-btn {
                width: fit-content;
                text-transform: uppercase;
                background-color: var(--wb-rsvp-btn-bg);

                &:hover {
                    background-color: var(--wb-rsvp-btn-hover-bg);
                    transition: background-color 0.3s ease;
                    color: var(--wb-rsvp-btn-hover-text-color);
                }

            }
        }
    }

    .full-image-carousel {

        .swiper {
            @media(max-width: 767px) {
                margin: 0 !important;
            }

        }

        img {
            width: 100% !important;
            height: 100% !important;
            min-width: 100% !important;
            min-height: 313px !important;
            object-fit: cover;
            border-radius: 60px;

            @media (max-width: 1600px) {
                min-height: 290px !important;
                border-radius: 55px;
            }

            @media (max-width: 1440px) {
                min-height: 270px !important;
                border-radius: 50px;
            }

            @media (max-width: 1366px) {
                min-height: 250px !important;
                border-radius: 45px;
            }

            @media (max-width: 1199px) {
                min-height: 230px !important;
                border-radius: 40px;
            }

            @media (max-width: 992px) {
                min-height: 210px !important;
                border-radius: 35px;
            }

            @media (max-width: 767px) {
                min-height: 200px !important;
                height: auto !important;
                border-radius: 30px;
            }
        }
    }

    // password access
    .wb-password-access-form-wrp {
        padding: 60px;
        background: var(--password-access-form-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        .wb-view-btn {
            background-color: #fff;
            color: #000000;
            border-radius: 15px;
            padding: 15px 25px;

            &:hover {
                background-color: #f1f1f1e8;
            }
        }

        @media (max-width:1366px) {
            padding: 50px;
        }

        @media (max-width:767px) {
            padding: 20px;
        }

        .wb-access-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: #fff;
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width:1440px) {
                    font-size: 35px;
                    line-height: normal;

                }

                @media (max-width:1366px) {
                    font-size: 32px;
                }

                @media (max-width:1280px) {
                    font-size: 30px;
                }

                @media (max-width:992px) {
                    font-size: 24px;

                }
            }

        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            margin: 40px 0 0;

            @media (max-width:1366px) {
                margin: 30px 0 0;
                gap: 30px;
            }


            .wb-cus-input {
                background-color: #fff;
                border-radius: 10px;
                height: 60px;
                padding: 20px;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                border: 1px solid #cfcfcf;

                @media (max-width:767px) {
                    width: 100%;

                }

            }
        }
    }

    // footer name

    .wb-footer-couple-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 100px 0;

        @media (max-width: 1600px) {
            padding: 90px 0;
        }

        @media (max-width: 1440px) {
            padding: 70px 0;
        }

        @media (max-width: 1366px) {
            padding: 50px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        h5 {
            font-size: 60px;
            font-weight: 400;
            line-height: 69.23px;
            color: var(--wb-footer-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 15px;
            text-transform: lowercase;

            @media (max-width: 1600px) {
                font-size: 55px;
                line-height: 63.46px;
            }

            @media (max-width: 1440px) {
                font-size: 50px;
                line-height: 57.69px;
            }

            @media (max-width: 1366px) {
                font-size: 45px;
                line-height: 51.92px;
            }

            @media (max-width: 1199px) {
                font-size: 40px;
                line-height: 46.15px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
                line-height: 40.38px;
            }

            @media (max-width: 767px) {
                font-size: 30px;
                line-height: 34.61px;
            }

            span {
                font-family: var(--wb-primaryFont);
                font-size: 20px;
                line-height: 53.12px;

                @media (max-width: 1366px) {

                    line-height: normal;
                }

                @media (max-width: 1024px) {
                    font-size: 35px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }
        }

        h6 {
            display: none;
        }

        .wb-divider {
            display: none;
        }
    }

    //   things to do

    .wb-activity-content-wrap {
        margin: 60px 0;

        @media (max-width: 1199px) {
            margin: 50px 0;
        }

        @media (max-width: 767px) {
            margin: 30px 0;
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1024px) {
                gap: 30px 0;
            }

            .wb-activity-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100%;
                width: calc(100% / 3);
                padding: 0 15px;

                @media (max-width:767px) {
                    width: 100%;

                }

                .wb-activity-image {
                    width: 100%;
                }

                img {
                    width: 100%;
                    max-width: 100% !important;
                    height: 380px !important;
                    object-fit: cover;
                    border-radius: 60px;

                    @media (max-width: 1366px) {
                        border-radius: 50px;
                    }

                    @media (max-width: 992px) {
                        border-radius: 40px;
                    }

                    @media (max-width:767px) {
                        min-height: 200px !important;
                        height: auto !important;
                        aspect-ratio: 1;
                    }
                }

                h4 {
                    color: var(--wb-things-to-do-card-title-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 34.65px;
                    padding-bottom: 10px;
                    margin-top: 20px;


                    @media (max-width: 1024px) {
                        font-size: 20px;
                        line-height: 25.65px;
                    }


                }

                h6 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    padding-bottom: 20px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 35px;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 28px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 28px;
                        padding-bottom: 10px;
                    }
                }

                h5 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 25px;
                    }
                }

                p {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    padding-bottom: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 25px;
                    }
                }

                .wb-view-btn {
                    margin-top: 20px;
                    background: var(--Wb-things-to-do-button-bg);
                    color: var(--wb-things-to-do-button-text-color);

                    &:hover {
                        background: var(--Wb-things-to-do-button-hover-bg);
                        color: var(--wb-things-to-do-button-hover-text-color);
                    }
                }
            }

        }
    }

    // common style

    // wedding party card (person card)

    .person-view-card {
        margin: 0;
        padding: 0px 0 100px;

        @media (max-width: 1600px) {
            padding: 0px 0 90px;
        }

        @media (max-width: 1440px) {
            padding: 0px 0 70px;
        }

        @media (max-width: 1366px) {
            padding: 0px 0 50px;
        }

        @media (max-width: 767px) {
            padding: 20px 0 30px;
        }

        .wb-cus-row {
            row-gap: 60px;
            justify-content: center;

            @media (max-width: 1440px) {
                row-gap: 50px;
            }

            @media (max-width: 1366px) {
                row-gap: 40px;
            }

            @media (max-width: 992px) {
                row-gap: 35px;
            }

            @media (max-width: 767px) {
                row-gap: 25px;
            }
        }

        .wb-cus-row-4 {
            .person-view-card-item {
                @media (min-width: 1200px) {
                    width: calc(100% / 4);
                }
            }
        }

        .person-view-card-item {
            padding: 0 15px;
            width: calc(100% / 5);
            text-align: center;
            margin-bottom: 0;

            @media (max-width: 1199px) {
                width: calc(100% / 4);
            }

            @media (max-width: 992px) {
                width: calc(100% / 3);
            }

            @media (max-width: 767px) {
                width: calc(100% / 2);
            }

            img {
                max-width: 100%;
                width: 200px !important;
                height: 300px !important;
                object-fit: cover;
                border-radius: 40px;

                @media (max-width: 1024px) {
                    height: 185px !important;
                }

                @media (max-width: 992px) {
                    height: 200px !important;
                    width: 150px !important;
                    border-radius: 30px;
                }

                @media (max-width: 767px) {
                    border-radius: 20px;
                }

                @media (max-width: 576px) {
                    height: auto !important;
                }
            }

            h4 {
                color: var(--wb-card-title-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 400;
                line-height: 35px;
                margin: 10px 0 5px;

                @media (max-width: 1600px) {
                    font-size: 24px;
                    line-height: 33px;
                }

                @media (max-width: 1440px) {
                    font-size: 22px;
                    line-height: 31px;
                }

                @media (max-width: 1366px) {
                    font-size: 20px;
                    line-height: 29px;
                }

                @media (max-width: 1199px) {
                    font-size: 18px;
                    line-height: 27px;
                }

                @media (max-width: 992px) {
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    line-height: 23px;
                }
            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 1600px) {
                    font-size: 15px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }

            p {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                margin-top: 8px;
            }
        }
    }

    // (hotel view)
    .single-hotel-transport-wrp {
        padding: 50px 0;

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-hotel-view {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 40px 0;
            padding: 0 15px;
            width: 100%;

            @media (max-width: 1024px) {
                margin: 0px 0;
            }

            @media (max-width: 767px) {
                width: 100%;
                flex-direction: column;
            }

        }

        .wb-transport-view {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 40px 0;
            padding: 0 15px;
            width: 100%;

            @media (max-width: 767px) {
                margin: 35px 0;
                width: 100%;
                flex-direction: column;
            }


        }

        .wb-transport-image,
        .wb-hotel-image {
            padding: 0 15px;
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
            }

            img {
                width: 100%;
                height: 400px !important;
                object-fit: cover;
                border-radius: 60px;

                @media (max-width: 1600px) {
                    border-radius: 55px;
                    height: 320px !important;
                }

                @media (max-width: 1366px) {
                    border-radius: 40px;
                    height: 280px !important;
                }

                @media (max-width: 850px) {
                    border-radius: 25px;
                }

                @media (max-width: 767px) {
                    border-radius: 20px;
                    height: 200px !important;
                }
            }
        }

        .wb-hotel-content,
        .wb-transport-content {
            padding: 0 15px;
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
            }

            h6 {
                font-size: 16px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-weight: bold;
                line-height: 35px;
                text-align: center;

                @media (max-width: 1600px) {
                    line-height: 34px;
                }

                @media (max-width: 1440px) {
                    font-size: 15px;
                    line-height: 33px;
                }

                @media (max-width: 1366px) {
                    line-height: 32px;
                }

                @media (max-width: 1199px) {
                    line-height: 31px;
                }

                @media (max-width: 992px) {
                    line-height: 30px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 29px;
                }
            }

            h4 {
                font-size: 25px;
                font-weight: 400;
                line-height: 49px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                margin: 30px 0;
                margin-bottom: 0;

                @media (max-width: 1600px) {
                    font-size: 23px;
                    line-height: 47px;
                }

                @media (max-width: 1440px) {
                    font-size: 21px;
                    line-height: 45px;
                }

                @media (max-width: 1366px) {
                    line-height: 43px;
                }

                @media (max-width: 1199px) {
                    line-height: 41px;
                }

                @media (max-width: 992px) {
                    line-height: 35px;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 28px;
                    margin: 10px 0;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 38px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                text-align: center;

                @media (max-width: 1600px) {
                    font-size: 17px;
                    line-height: 36px;
                }

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media (max-width: 1366px) {
                    font-size: 15px;
                    line-height: 32px;
                }

                @media (max-width: 1199px) {
                    line-height: 30px;
                }

                @media (max-width: 992px) {
                    line-height: 28px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

        }

        .wb-view-btn {
            margin-top: 30px;
            color: var(--Wb-travel-button-text-color);
            background-color: var(--Wb-travel-button-bg);

            &:hover {
                background: var(--wb-view-button-hover-bg);
                color: var(--wb-view-button-hover-text);
                transition: background-color 0.3s ease;
            }
        }

        .wb-cus-row {
            flex-direction: column;
        }


        .wb-divider {
            display: none;
        }

    }

    // travel


    // title view
    .wb-large-tile {
        h2 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            text-align: center;

            @media (max-width: 1440px) {
                font-size: 40px;
            }

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 1024px) {
                font-size: 32px;
            }

            @media (max-width: 992px) {
                font-size: 30px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }
        }
    }

    .wb-small-tile {
        h4 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family: var(--wb-secondaryFont);
            font-size: 20px;
            font-weight: bold;
            line-height: 25.2px;
            color: var(--wb-event-head-text-color);
            margin-bottom: 20px;
            text-align: center;
        }


        p {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: 400;
            line-height: 38px;
            text-align: center;

            @media (max-width: 1600px) {
                font-size: 17px;
                line-height: 36px;
            }

            @media (max-width: 1440px) {
                font-size: 16px;
                line-height: 34px;
            }

            @media (max-width: 1280px) {
                font-size: 15px;
                line-height: 32px;
            }

            @media (max-width: 1199px) {
                font-size: 14px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                line-height: 28px;
            }

            @media (max-width: 640px) {
                font-size: 12px;
                line-height: 26px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width: 767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
        }

        p {
            color: var(--wb-how-we-are-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width: 1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .wb-view-btn {
                margin-top: 30px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1440px) {
                row-gap: 50px;
            }

            @media (max-width: 1366px) {
                row-gap: 45px;
            }

            @media (max-width: 1199px) {
                row-gap: 40px;
            }

            @media (max-width: 992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width:767px) {
                width: 50%;
            }

            @media (max-width:576px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                object-fit: cover;
                border-radius: 40px;

                @media (max-width: 1024px) {
                    height: 185px !important;
                }

                @media (max-width: 992px) {
                    height: 200px !important;
                    max-width: 150px !important;
                    border-radius: 30px;
                }

                @media (max-width: 767px) {
                    height: 185px !important;
                    max-width: 145px !important;
                    border-radius: 20px;
                }
            }

            h4 {
                color: var(--wb-card-title-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 400;
                line-height: 35px;
                margin: 10px 0 5px;
                text-transform: uppercase;

                @media (max-width: 1600px) {
                    font-size: 24px;
                    line-height: 33px;
                }

                @media (max-width: 1440px) {
                    font-size: 22px;
                    line-height: 31px;
                }

                @media (max-width: 1366px) {
                    font-size: 20px;
                    line-height: 29px;
                }

                @media (max-width: 1199px) {
                    font-size: 18px;
                    line-height: 27px;
                }

                @media (max-width: 992px) {
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    line-height: 23px;
                }
            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: normal;

                @media (max-width: 1600px) {
                    font-size: 15px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
    }

    // events
    .wb-website-event-wrp {
        padding: 50px 0 100px;

        @media (max-width: 1600px) {
            padding: 40px 0 80px;
        }

        @media (max-width: 1366px) {
            padding: 20px 0 50px;
        }

        @media (max-width: 1280px) {
            padding: 10px 0 50px;
        }

        @media (max-width: 767px) {
            padding: 15px 0 30px;
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 767px) {
                row-gap: 15px;
            }
        }

        .wb-cus-col-2 {
            width: calc(100% / 2);
            padding: 0 15px;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .wb-website-event-card {
            background-color: var(--wb-website-event-card-bg);
            border-radius: 60px;
            padding: 60px;
            height: 100%;

            @media (max-width: 1440px) {
                padding: 40px;
                border-radius: 40px;
            }

            @media (max-width: 767px) {
                padding: 30px;
                border-radius: 30px;
            }

            .wb-website-event-date-time {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid var(--wb-event-date-border-color);
                padding-bottom: 30px;
                margin-bottom: 30px;

                @media (max-width: 1440px) {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }

                @media (max-width: 767px) {
                    gap: 10px;
                }

                .start-date-time {
                    text-align: center;
                }

                .end-date-time {
                    text-align: center;
                }

                h6 {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 26.8px;
                    text-transform: uppercase;

                    @media (max-width: 1440px) {
                        font-size: 17px;
                        line-height: 19.8px;
                    }

                    @media (max-width: 992px) {
                        font-size: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }
                }

                h5 {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 47px;

                    @media (max-width: 1440px) {
                        font-size: 24px;
                        line-height: 38px;
                    }

                    @media (max-width: 992px) {
                        font-size: 18px;
                        line-height: 30px;
                    }

                    @media (max-width: 767px) {
                        font-size: 15px;
                        line-height: 18px;
                    }
                }

                span {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-event-span-text-color);
                    background: var(--wb-event-span-bg);
                    font-size: 20px;
                    font-weight: 600;
                    line-height: normal;
                    background-color: var(--wb-event-btn-bg);
                    padding: 20px;
                    text-transform: capitalize;
                    border-radius: 20px;

                    @media (max-width: 1440px) {
                        font-size: 18px;
                        border-radius: 15px;
                        padding: 15px;
                    }

                    @media (max-width: 992px) {
                        border-radius: 10px;
                    }

                    @media (max-width: 767px) {
                        font-size: 15px;
                        padding: 10px;
                    }
                }
            }

            .wb-website-event-details {
                display: flex;
                flex-direction: column;
                align-items: center;

                h5 {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 48.59px;
                    margin-bottom: 20px;

                    @media (max-width: 1440px) {
                        font-size: 30px;
                        line-height: 35.59px;
                    }

                    @media (max-width: 992px) {
                        font-size: 26px;
                        line-height: 30.59px;
                        margin-bottom: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                        line-height: 25.59px;
                    }
                }

                p {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 34px;
                    text-align: center;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 28px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }

                .wb-view-btn {
                    margin-top: 30px;
                    text-transform: uppercase;
                    background-color: var(--wb-event-btn-bg);
                    color: var(--wb-event-btn-text-color);

                    @media (max-width: 992px) {
                        margin-top: 20px;
                    }

                    &:hover {
                        background-color: var(--wb-event-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-event-btn-hover-text-color);
                    }
                }
            }
        }
    }

    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width: 1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width: 1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);

                a {
                    color: inherit;
                }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);
            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a {
                        color: var(--wb-primary-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 35px;

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
}