// Rose Bud Styles

@mixin fonts {
    --wb-primaryFont: "Parisienne", cursive;
    --wb-secondaryFont: "Plus Jakarta Sans", sans-serif;
}

// Rose Bud template background and theme colors starts

.rosebud-kit-styles {
    --white: #fff;
    --color1: #33434a;
    --color2: #e9edef;
    --color3: #c3ac92;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

.rose-bud-theme2 {
    --white: #fff;
    --color1: #570f00;
    --color2: #ffece9;
    --color3: #ffa257;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);
}

.rose-bud-theme3 {
    --white: #fff;
    --color1: #510038;
    --color2: #ffe5ea;
    --color3: #ff5757;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);
}

.rose-bud-theme4 {
    --white: #fff;
    --color1: #020030;
    --color2: #ececed;
    --color3: #e47aa8;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);
}

.rose-bud {
    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/top-bg-image.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
    }

    &::after {
        content: "";
        background-image: url("../../public/images/wedding-websites/footerimage.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background-position: bottom;
        z-index: -1;
    }
}

// Rose Bud template background and theme colors ends

// Power Blue template background and theme colors starts
.powder-blue {
    --white: #fff;
    --color1: #33434a;
    --color2: #ffece9;
    --color3: #916d3f;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/powder-blue-top-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
    }

    &::after {
        content: "";
        background-image: url("../../public/images/wedding-websites/powder-blue-bottom.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background-position: bottom;
        z-index: -1;
    }
}

.powder-blue-theme2 {
    --white: #fff;
    --color1: #1d3557;
    --color2: #ffece9;
    --color3: #e18e25;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);
}

.powder-blue-theme3 {
    --white: #fff;
    --color1: #69140e;
    --color2: #ffece9;
    --color3: #398caa;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);
}

.powder-blue-theme4 {
    --white: #fff;
    --color1: #004643;
    --color2: #ffece9;
    --color3: #858e00;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color3);
    --wb-view-button-hover-text: var(--color1);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color1);
    --wb-heart-icon-color: var(--color7);
}

// Power Blue template background and theme colors ends

// Rodeo Dust template background and theme colors starts

.rodeo-dust {
    --white: #fff;
    --color1: #33434a;
    --color2: #e9edef;
    --color3: #c3ac92;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/rodeo-dust-top-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
    }

    &::after {
        content: "";
        background-image: url("../../public/images/wedding-websites/rodeo-dust-bottom-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background-position: bottom;
        z-index: -1;
    }
}

.rodeo-dust-theme2 {
    --white: #fff;
    --color1: #cb8600;
    --color2: #e9edef;
    --color3: #e4754d;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #d49c2b;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

.rodeo-dust-theme3 {
    --white: #fff;
    --color1: #b8634d;
    --color2: #e9edef;
    --color3: #b89b4d;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #cd755f;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

.rodeo-dust-theme4 {
    --white: #fff;
    --color1: #720026;
    --color2: #e9edef;
    --color3: #b89b4d;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #890631;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

// Rodeo Dust template background and theme colors ends

// Jazz-berry Jam template background and theme colors starts

.jazz-berry-jam {
    --white: #fff;
    --color1: #d8882a;
    --color2: #f2ebe2;
    --color3: #e83f03;
    --color4: #ffe9cf;
    --color5: #ffe9cf;
    --color6: #e83f03;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);

    background: url("../../public/images/wedding-websites/jazz-berry-jam-bg.png"),
        linear-gradient(to bottom, #ffffff, #f3d0897a) !important;
    background-repeat: no-repeat !important;
    width: 100%;
    background-size: contain !important;

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/jazz-berry-jam-top-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
    }

    &::after {
        content: "";
        background-image: url("../../public/images/wedding-websites/jazz-berry-jam-bottom-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background-position: bottom;
        z-index: -1;
    }

    &.rosebud-kit-styles {
        @media (max-width: 1600px) and (min-width: 993px) {
            padding: 14rem 0;
        }
    }
}

.jazz-berry-jam-theme2 {
    --white: #fff;
    --color1: #630021;
    --color2: #f2ebe2;
    --color3: #e80303;
    --color4: #ffe9cf;
    --color5: #ffe9cf;
    --color6: #e80303;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

.jazz-berry-jam-theme3 {
    --white: #fff;
    --color1: #f05a24;
    --color2: #f2ebe2;
    --color3: #ff9500;
    --color4: #ffe9cf;
    --color5: #ffe9cf;
    --color6: #ff9500;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

.jazz-berry-jam-theme4 {
    --white: #fff;
    --color1: #96246b;
    --color2: #f2ebe2;
    --color3: #e80303;
    --color4: #ffe9cf;
    --color5: #ffe9cf;
    --color6: #e80303;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);
}

// Jazz-berry Jam template background and theme colors ends

// camelot template background and theme colors starts
.camelot {
    --white: #fff;
    --color1: #4F182B;
    --color2: #EAD4C7;
    --color3: #BF6124;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);

    background: #F2E4DA !important;
    width: 100%;

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/camelot-top-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
    }

    &::after {
        content: "";
        background-image: url("../../public/images/wedding-websites/camelot-bottom-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 100%;
        background-position: bottom;
        z-index: -1;
    }


     &.rosebud-kit-styles {
        padding: 15rem 0 30rem 0;

        @media (max-width: 1600px) {
            padding: 14rem 0 25rem 0;
        }
        @media (max-width: 1280px) {
            padding: 14rem 0 20rem 0;
        }
        @media (max-width: 991px) {
            padding: 10rem 0 12rem 0;
        }
        @media (max-width: 767px) {
            padding: 10rem 0 8rem 0;
        }
    }
}

// camelot template background and theme colors ends

// shiraz template background and theme colors starts
.shiraz{
    --white: #fff;
    --color1: #4F182B;
    --color2: #EAD4C7;
    --color3: #BF6124;
    --color4: #faf2e9;
    --color5: #e9edef;
    --color6: #33434ae3;
    --color7: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--color1);
    --wb-date-text-color: var(--color1);
    --wb-menu-bg: var(--color2);
    --wb-menu-active-bg: var(--color1);
    --wb-menu-active-text-color: var(--white);
    --wb-menu-text-color: var(--color1);
    --wb-event-subtext-color: var(--color3);
    --wb-event-date-text-color: var(--color1);
    --wb-event-head-text-color: var(--color3);
    --wb-event-content-text-color: var(--color1);
    --wb-place-text-color: var(--color1);
    --wb-footer-text-color: var(--color3);
    --wb-template-bg: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-icon-bg: var(--color4);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color1);
    --wb-view-button-hover-bg: var(--color6);
    --wb-view-button-hover-text: var(--white);
    --wb-rsvp-form-bg: var(--color4);
    --wb-menu-toggle-line-bg: var(--white);
    --wb-menu-toggle-bg: var(--color1);
    --wb-close-btn-border: var(--color1);
    --wb-close-btn-fill: var(--color1);
    --wb-icon-color: var(--color1);
    --wb-divider-bg: var(--color1);
    --wb-registry-card-item-border-color: var(--color5);
    --wb-time-text-color: var(--white);
    --wb-time-bg: var(--color3);
    --wb-heart-icon-color: var(--color7);

    background: url("../../public/images/wedding-websites/shiraz-bg.png"),#A60438 !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    width: 100%;

    &::before {
        content: "";
        background-image: url("../../public/images/wedding-websites/shiraz-top-bg.png") !important;
        background-repeat: no-repeat !important;
        background-size: contain;
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        background-position: top;
        z-index: -1;
    }


     &.rosebud-kit-styles {
        padding: 15rem 0 30rem 0;

        @media (max-width: 1600px) {
            padding: 14rem 0 25rem 0;
        }
        @media (max-width: 1280px) {
            padding: 14rem 0 20rem 0;
        }
        @media (max-width: 991px) {
            padding: 10rem 0 12rem 0;
        }
        @media (max-width: 767px) {
            padding: 10rem 0 8rem 0;
        }
    }
}
// shiraz template background and theme colors ends

.rosebud-kit-styles {
    @include fonts;
    position: relative;
    background: var(--wb-template-bg);
    z-index: 1;
    padding: 15rem 0;
    min-height: 100%;

    @media (max-width: 1600px) {
        padding: 4rem 0;
    }

    @media (max-width: 1024px) {
        padding: 13rem 0;
    }

    @media (max-width: 992px) {
        padding: 8rem 0;
    }

    span.wb-bg-image {
        position: absolute;
        inset: 0;
        top: 0;
        z-index: -1;

        img {
            width: 100%;
        }
    }

    .wb-cus-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        justify-content: center;
    }

    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-bottom: 100px;

        @media (max-width: 1441px) {
            margin-bottom: 90px;
        }

        @media (max-width: 1366px) {
            margin-bottom: 60px;
        }

        @media (max-width: 1024px) {
            margin-bottom: 70px;
        }

        @media (max-width: 992px) {
            margin-bottom: 50px;
        }

        h2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: var(--wb-primaryFont);
            font-size: 100px;
            font-weight: 400;
            line-height: 136.23px;
            color: var(--wb-bridegroom-text-color);
            text-align: center;
            text-transform: capitalize;
            word-break: break-word;

            @media (max-width: 1600px) {
                font-size: 85px;
                line-height: 120.23px;
            }

            @media (max-width: 1366px) {
                font-size: 75px;
                line-height: normal;
            }

            @media (max-width: 1280px) {
                font-size: 70px;
            }

            @media (max-width: 1199px) {
                font-size: 65px;
            }

            @media (max-width: 1024px) {
                font-size: 55px;
            }

            @media (max-width: 992px) {
                font-size: 45px;
            }

            @media (max-width: 767px) {
                font-size: 35px;
            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 8px;
            align-items: center;
            font-size: 20px;
            span {
                font-size: inherit;
                font-weight: 600;
                line-height: 25.2px;
                color: var(--wb-date-text-color);
                margin-top: 40px;
                text-align: center;

                &:nth-child(2) {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: var(--wb-primary-text-color);
                }

                @media (max-width: 767px) {
                    margin-top: 0px;
                }
            }

            @media (max-width: 1441px) {
                margin-top: 35px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                margin-top: 25px;
            }

            @media (max-width: 992px) {
                font-size: 16px;
            }

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
    }

    // navbar css

    .Web-navigation {
        padding: 10px;

        @media (max-width: 767px) {
            display: none;
        }

        .links {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            flex-wrap: wrap;

            @media (max-width: 767px) {
                flex-direction: column;
                display: none;
                width: 100%;
            }

            li {
                list-style: none;

                a {
                    padding: 15px 20px;
                    text-decoration: none;
                    color: var(--wb-menu-text-color);
                    font-size: 18px;
                    font-weight: 500;
                    font-family: var(--wb-secondaryFont);
                    text-align: center;
                    border-radius: 50px;
                    transition: background-color 0.3s ease;
                    display: inline-block;
                    background-color: var(--wb-menu-bg);
                    min-width: 100px;

                    @media (max-width: 1366px) {
                        padding: 15px;
                        font-size: 16px;
                    }

                    @media (max-width: 1280px) {
                        font-size: 14px;
                    }

                    @media (max-width: 1024px) {
                        padding: 15px;
                        min-width: 70px;
                    }

                    @media (max-width: 992px) {
                        padding: 8px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        padding: 12px 0;
                    }

                    &:hover {
                        background-color: var(--wb-menu-active-bg);
                        color: var(--wb-menu-active-text-color);
                    }

                    &.active {
                        background-color: var(--wb-menu-active-bg);
                        color: var(--wb-menu-active-text-color);
                    }
                }
            }
        }
    }

    // mobilemenu
    .mobile-navigation {
        background-color: var(--wb-menu-bg);
        padding: 0;
        border-radius: 0;
        height: 100%;
        width: 0%;
        position: fixed;
        z-index: 3000;
        top: 0;
        right: 0;
        margin-right: 0;
        overflow-x: hidden;
        overflow-y: auto;

        transition: 0.3s;
        &.open {
            width: 100%;
            transition: 0.3s;


        }

        .links {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 767px) {
                flex-direction: column;
                width: 100%;
                margin-top: 60px;
                gap: 15px;
            }

            li {
                list-style: none;
                width: 100%;

                @media (max-width: 992px) {
                    text-align: center;
                }

                a {
                    padding: 5px 20px;
                    text-decoration: none;
                    color: var(--wb-menu-text-color);
                    font-size: 14px;
                    font-weight: 500;
                    font-family: var(--wb-secondaryFont);
                    text-align: center;
                    border-radius: 34px;
                    transition: background-color 0.3s ease;
                    display: inline-block;

                    &:hover {
                        background-color: var(--wb-menu-active-bg);
                        color: var(--wb-menu-active-text-color);
                    }

                    &.active {
                        background-color: var(--wb-menu-active-bg);
                        color: var(--wb-menu-active-text-color);
                    }
                }
            }
        }
    }

    .toggle-button {
        display: none;
        font-size: 28px;
        cursor: pointer;
        background-color: var(--wb-menu-toggle-bg);
        color: white;
        padding: 8px 10px;
        border-radius: 10px;
        border: none;
        position: absolute;
        top: 25px;
        right: 25px;

        @media (max-width: 767px) {
            display: block;
        }

        span {
            width: 20px;
            height: 2px;
            display: block;
            background: var(--wb-menu-toggle-line-bg);
            margin: 4px 0;
        }
    }

    // banner css
    .banner-image {
        margin: 60px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1366px) {
            margin: 45px 0;
        }

        img {
            width: 100%;
            max-height: 737px !important;
            object-fit: cover;
        }
    }

    // event date

    .wb-event-date {
        text-align: center;
        font-family: var(--wb-secondaryFont);

        span {
            color: var(--wb-event-subtext-color);
            font-weight: 400;
            font-size: 18px;
            line-height: 22.68px;

            @media (max-width: 1366px) {
                font-size: 17px;
                line-height: 15.68px;
            }
        }

        h3 {
            color: var(--wb-event-date-text-color);
            font-size: 50px;
            font-weight: 500;
            line-height: normal;
            margin: 15px 0 20px;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1280px) {
                font-size: 40px;
            }

            @media (max-width: 1199px) {
                font-size: 35px;
            }

            @media (max-width: 991px) {
                font-size: 30px;
            }
        }

        h6 {
            font-size: 22px;
            font-weight: 500;
            line-height: 35px;
            color: var(--wb-place-text-color);

            @media (max-width: 1366px) {
                font-size: 20px;
                line-height: normal;
            }

            @media (max-width: 1280px) {
                font-size: 18px;
            }

            @media (max-width: 992px) {
                font-size: 15px;
            }
        }
    }

    //
    .wb-divider {
        display: flex;
        align-items: center;
        margin: 60px 0;
        justify-content: center;

        @media (max-width: 1366px) {
            margin: 50px 0;
        }

        @media (max-width: 1280px) {
            margin: 45px 0;
        }

        @media (max-width: 992px) {
            margin: 10px 0;
        }

        svg {
            width: 100%;
            margin-right: 0;
        }

        .wb-divider-bg {
            stop-color: var(--wb-divider-bg);
        }
    }

    .wb-event-content-block {
        display: flex;
        flex-direction: column;
        align-items: center;

        h6 {
            font-family: var(--wb-secondaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-event-head-text-color);
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            text-align: center;
            color: var(--wb-event-content-text-color);
            font-family: var(--wb-secondaryFont);

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }

    // image gallery
    .wb-home-gallery {
        .wb-container {
            .wb-cus-row {
                gap: 20px 0;
                justify-content: center;

                .wb-image-box {
                    width: calc(100% / 3);
                    padding: 0 15px;
                    flex: 0 0 auto;
                    max-height: 420px;

                    @media (max-width: 992px) {
                        width: calc(100% / 3);
                        max-height: 100%;
                        padding: 0 10px;
                    }

                    @media (max-width: 576px) {
                        width: calc(100% / 2);
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 15px;
                        height: 100%;
                    }
                }
            }
        }
    }

    .wb-footer-couple-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h5 {
            font-size: 100px;
            font-weight: 400;
            line-height: 136.23px;
            color: var(--wb-footer-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            text-transform: uppercase;

            @media (max-width: 1366px) {
                font-size: 70px;
                line-height: normal;
            }

            @media (max-width: 992px) {
                font-size: 55px;
            }

            span {
                font-family: var(--wb-primaryFont);
                font-size: 50px;
                line-height: 68.12px;

                @media (max-width: 1366px) {
                    font-size: 40px;
                    line-height: normal;
                }

                @media (max-width: 992px) {
                    font-size: 35px;
                }
            }
        }

        h6 {
            font-size: 20px;
            font-weight: 600;
            line-height: 25.2px;
            color: var(--wb-footer-text-color);
            font-family: var(--wb-secondaryFont);
            margin-top: 20px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 18px;
                line-height: normal;
            }

            @media (max-width: 1024px) {
                font-size: 16px;
            }

            @media (max-width: 992px) {
                font-size: 14px;
            }
        }

        .wb-divider {
            margin: 0px;
        }
    }

    .wb-footer-bg-image {
        position: absolute;
        bottom: 0;
        object-fit: contain;
        z-index: -1;
        right: 0;

        img {
            width: 100%;
        }
    }

    .wb-common-head {
        margin: 60px 0;

        @media (max-width: 1366px) {
            margin: 50px 0;
        }

        @media (max-width: 1024px) {
            margin: 45px 0;
        }

        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 992px) {
                font-size: 14px;
            }
        }
    }

    .wb-our-story-first-date {
        text-align: center;

        h3 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
        }

        p {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            margin-bottom: 60px;

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }

    .gallery-image {
        padding: 0px 15px;

        @media (max-width: 1366px) {
            padding: 0 10px;
        }

        @media (max-width: 992px) {
            padding: 0 8px;
        }

        @media (max-width: 767px) {
            padding: 0 5px;
        }

        img {
            width: 100%;
            border-radius: 15px;
            object-fit: cover;

            @media (max-width: 767px) {
                border-radius: 10px;
            }
        }
    }

    .wb-our-story-gallery {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 1366px) {
            gap: 15px;
        }

        @media (max-width: 992px) {
            gap: 10px;
        }

        @media (max-width: 767px) {
            gap: 5px;
        }

        .wb-col-1 {
            .gallery-image {
                width: calc(100%);
            }
        }

        .wb-col-2 {
            .gallery-image {
                width: calc(100% / 2);
            }
        }

        .wb-col-3 {
            .gallery-image {
                width: calc(100% / 3);
            }
        }
    }

    .wb-photos-gallery {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 767px) {
            gap: 6px;
        }

        .wb-cus-row {
            margin: 0 -8px;

            @media (max-width: 767px) {
                margin: 0 -6px;
            }
        }

        .gallery-image {
            display: flex;
            padding: 0 8px;

            @media (max-width: 767px) {
                padding: 0 3px;
            }
        }

        .wb-col-1 {
            .gallery-image {
                width: calc(100%);
            }
        }

        .wb-col-2 {
            .gallery-image {
                width: calc(100% / 2);
            }
        }

        .wb-col-3 {
            .gallery-image {
                width: calc(100% / 3);
            }
        }

        img {
            border-radius: 10px;
            object-fit: cover;
        }
    }

    // wedding party card
    .person-view-card {
        .wb-cus-row {
            row-gap: 60px;
            justify-content: center;

            @media (max-width: 1440px) {
                row-gap: 50px;
            }

            @media (max-width: 1366px) {
                row-gap: 45px;
            }

            @media (max-width: 1199px) {
                row-gap: 40px;
            }

            @media (max-width: 992px) {
                row-gap: 35px;
            }
        }

        .person-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width: 767px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 300px;
                height: 300px;
                object-fit: cover;
                border-radius: 15px;
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                margin: 30px 0 10px;
                text-transform: uppercase;

                @media (max-width: 1366px) {
                    font-size: 22px;
                    margin: 25px 0 10px;
                }

                @media (max-width: 1199px) {
                    font-size: 20px;
                    margin: 20px 0 10px;
                }

                @media (max-width: 992px) {
                    font-size: 18px;
                    margin: 20px 0 10px;
                }
            }

            h6 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 20px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width: 1366px) {
                    font-size: 18px;
                }

                @media (max-width: 992px) {
                    font-size: 16px;
                }
            }

            p {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                margin-top: 8px;
            }
        }
    }

    .wb-question-wrp {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 40px 0;

        @media (max-width: 1440px) {
            padding: 35px 0;
        }

        @media (max-width: 1366px) {
            padding: 25px 0;
        }

        @media (max-width: 992px) {
            padding: 16px 0;
        }

        h6 {
            font-size: 20px;
            font-weight: 700;
            line-height: 35px;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            margin-bottom: 15px;
            text-transform: uppercase;

            @media (max-width: 1440px) {
                font-size: 18px;
                line-height: normal;
            }

            @media (max-width: 1280px) {
                font-size: 16px;
            }
        }

        h4 {
            font-size: 30px;
            font-weight: 600;
            line-height: 37.8px;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-secondaryFont);
            margin-bottom: 10px;

            @media (max-width: 1440px) {
                font-size: 28px;
                line-height: normal;
            }

            @media (max-width: 1280px) {
                font-size: 26px;
            }

            @media (max-width: 992px) {
                font-size: 20px;
            }
        }

        p {
            font-size: 20px;
            font-weight: 400;
            line-height: 35px;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-secondaryFont);

            @media (max-width: 1440px) {
                font-size: 18px;
            }

            @media (max-width: 1280px) {
                font-size: 18px;
                line-height: 35px;
            }

            @media (max-width: 992px) {
                font-size: 15px;
                line-height: 25px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }

    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 16px;
        font-weight: 500;
        padding: 15px 40px;
        border-radius: 10px;

        &:hover {
            background: var(--wb-view-button-hover-bg);
            color: var(--wb-view-button-hover-text);
            transition: background-color 0.3s ease;
        }

        @media (max-width: 1366px) {
            padding: 12px 30px;
            font-size: 14px;
        }
    }

    .wb-map {
        width: 100%;
        max-height: 518px;

        img {
            width: 100%;
        }
    }

    // things todo
    .wb-activity-content-wrap {
        margin-bottom: 100px;

        .wb-cus-row {
            margin-bottom: 60px;

            @media (max-width: 992px) {
                row-gap: 50px;
                margin-bottom: 50px;
            }
        }

        .wb-todo-icon {
            background: var(--wb-icon-bg);
            border-radius: 80px;
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0px;

            @media (max-width: 1600px) {
                width: 135px;
                height: 135px;
            }

            @media (max-width: 1400px) {
                width: 125px;
                height: 125px;
            }

            @media (max-width: 992px) {
                width: 115px;
                height: 115px;
            }

            svg {
                width: 45px;
                height: 45px;

                @media (max-width: 1400px) {
                    width: 40px;
                    height: 40px;
                }

                g,
                path {
                    stroke: var(--wb-icon-color);
                    fill: var(--wb-icon-color);
                }

                .wb-bg-svg-icon-path {
                    fill: transparent;
                }
            }
        }

        .wb-activity-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex: 1 1;
            padding: 0 15px;

            @media (max-width: 767px) {
                flex: 1 1 100%;
            }

            h6 {
                line-height: 35px;
                color: var(--wb-secondary-text-color);
                font-family: var(--wb-secondaryFont);
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 400;

                @media (max-width: 1600px) {
                    font-size: 15px;
                    margin-bottom: 8px;
                }
            }

            h4 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50.4px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                margin: 30px 0;

                @media (max-width: 1600px) {
                    font-size: 38px;
                }

                @media (max-width: 1440px) {
                    font-size: 32px;
                    line-height: normal;
                    margin: 25px 0;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 24px;
                    margin: 15px 0;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                text-align: center;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 25px;
                }

                @media (max-width: 992px) {
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 30px;
                }
            }

            h5 {
                font-size: 18px;
                font-weight: 700;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                text-align: center;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 30px;
                }
            }

            .wb-view-btn {
                margin-top: 30px;
            }

            .wb-activity-image {
                border-radius: 15px;
                overflow: hidden;
            }
        }

        .wb-divider {
            height: 20%;
            margin: 0;
            width: 10%;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    // rsvp
    .wb-rsvp-form-wrp {
        padding: 60px;
        background: var(--wb-rsvp-form-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        @media (max-width: 1366px) {
            padding: 50px;
        }

        @media (max-width: 767px) {
            padding: 30px;
        }

        .wb-rsvp-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: normal;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 24px;
                }
            }

            p {
                font-size: 20px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);

                @media (max-width: 1440px) {
                    font-size: 16px;
                }

                @media (max-width: 1280px) {
                    line-height: 35px;
                }

                @media (max-width: 992px) {
                    font-size: 15px;
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin: 40px 0;

            @media (max-width: 1366px) {
                margin: 30px 0;
            }

            @media (max-width: 767px) {
                margin: 25px 0;
            }

            .wb-cus-input {
                width: 80%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }

    // password access
    .wb-password-access-form-wrp {
        padding: 60px;
        background: var(--wb-rsvp-form-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        @media (max-width: 1366px) {
            padding: 50px;
        }

        @media (max-width: 767px) {
            padding: 30px;
        }

        .wb-access-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: normal;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 24px;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin: 40px 0;

            @media (max-width: 1366px) {
                margin: 30px 0;
            }

            @media (max-width: 767px) {
                margin: 25px 0;
            }

            .wb-cus-input {
                width: 80%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }

    .wb-cus-input {
        background-color: #fff;
        border-radius: 10px;
        border: none;
        height: 60px;
        padding: 20px;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
    }

    // registry
    .wb-registry-card-wrp {
        .wb-cus-row {
            row-gap: 30px;
        }

        .wb-col-3 {
            padding: 0 15px;
            flex: 0 0 auto;
            width: calc(100% / 3);

            @media (max-width: 1366px) {
                width: calc(100% / 3);
            }
            @media (max-width: 992px) {
                width: calc(100% / 2);
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .wb-registry-card-item {
            border-radius: 15px;
            border: solid 2px var(--wb-registry-card-item-border-color);
            padding: 30px;

            .wb-registry-card-img {
                text-align: center;
                border-radius: 15px;
                img {
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            .wb-view-btn {
                width: 100%;
            }

            h3,
            h4 {
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);

                @media (max-width: 1600px) {
                    font-size: 25px;
                }

                @media (max-width: 1366px) {
                    font-size: 22px;
                }
            }

            h3 {
                margin: 20px 0;

                @media (max-width: 1600px) {
                    margin: 15px 0;
                }

                @media (max-width: 1366px) {
                    margin: 10px 0;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 20px;

                @media (max-width: 1366px) {
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }
    }

    .wb-nav-close-btn {
        background: transparent;
        position: absolute;
        top: 20px;
        right: 25px;
        height: 40px;
        width: 40px;
        border-radius: 25%;
        border: 1px solid var(--wb-close-btn-border);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 70%;
            fill: var(--wb-close-btn-fill);
            height: auto;
            margin: 0;
        }
    }

    // common component style
    // title view
    .wb-large-tile {
        margin: 25px 0;

        h2 {
            font-size: 50px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;

            @media (max-width: 1366px) {
                margin-bottom: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 45px;
                margin-bottom: 15px;
            }

            @media (max-width: 767px) {
                font-size: 38px;
            }
        }
    }

    .wb-small-tile {
        margin: 25px 0;

        h4 {
            font-size: 20px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            margin-bottom: 30px;

            @media (max-width: 1366px) {
                margin-bottom: 20px;
            }

            @media (max-width: 1024px) {
                margin-bottom: 15px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family: var(--wb-secondaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-event-head-text-color);
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-secondaryFont);
            line-height: 35px;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width: 767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
        }

        p {
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-secondaryFont);
            line-height: 35px;
            padding: 20px 0;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 30px;
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width: 1440px) {
                row-gap: 50px;
            }

            @media (max-width: 1366px) {
                row-gap: 45px;
            }

            @media (max-width: 1199px) {
                row-gap: 40px;
            }

            @media (max-width: 992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            .story-view-card-item-inner {
                max-width: 300px;
                margin: auto;
            }

            @media (max-width: 767px) {
                width: 50%;
            }

            @media (max-width: 576px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 300px;
                height: 300px;
                object-fit: cover;
                border-radius: 15px;
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                margin: 30px 0 10px;
                text-transform: uppercase;

                @media (max-width: 1366px) {
                    font-size: 22px;
                    margin: 25px 0 10px;
                }

                @media (max-width: 1199px) {
                    font-size: 20px;
                    margin: 20px 0 10px;
                }

                @media (max-width: 992px) {
                    font-size: 18px;
                    margin: 20px 0 10px;
                    line-height: normal;
                }
            }

            h6 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 20px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width: 1366px) {
                    font-size: 18px;
                }

                @media (max-width: 992px) {
                    font-size: 16px;
                }
            }
        }
    }

    //  hotel

    .wb-hotel-view-wrp {
        .wb-hotel-view {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 40px 0;
            flex: 1 1 50%;
            padding: 0 10px;

            @media (max-width: 767px) {
                flex-direction: column;
                margin: 20px 0;
            }

            h6 {
                font-size: 16px;
                font-weight: 700;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);

                @media (max-width: 1440px) {
                    line-height: normal;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 40px;
                font-weight: 600;
                line-height: 50.4px;
                text-transform: capitalize;
                margin: 30px 0;

                @media (max-width: 1440px) {
                    font-size: 28px;
                }

                @media (max-width: 1366px) {
                    font-size: 26px;
                }

                @media (max-width: 767px) {
                    font-size: 22px;
                    line-height: normal;
                }
            }

            p {
                font-size: 18px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-weight: 400;
                line-height: 35px;
                margin-bottom: 0px;

                @media (max-width: 1366px) {
                    font-size: 17px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: normal;
                }
            }

            .wb-view-btn {
                margin-top: 30px;
            }

            .wb-hotel-content {
                flex: 1;
                text-align: center;
            }

            .wb-hotel-image {
                img {
                    min-width: 500px !important;
                    border-radius: 10px;
                    object-fit: cover;

                    @media (max-width: 1366px) {
                        min-width: auto !important;
                        max-width: 500px !important;
                        max-height: 400px !important;
                    }

                    @media (max-width: 992px) {
                        max-width: 500px !important;
                        max-height: 250px !important;
                    }

                    @media (max-width: 767px) {
                        max-width: 100% !important;
                        max-height: initial !important;
                    }
                }
            }
        }
    }

    // travel
    .wb-transport-view-wrp {
        .wb-transport-view {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 40px 0;
            flex: 1 1 50%;
            padding: 0 10px;

            @media (max-width: 767px) {
                flex-direction: column;
                margin: 20px 0;
            }

            h6 {
                font-size: 16px;
                font-weight: 700;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);

                @media (max-width: 1440px) {
                    line-height: normal;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }

            h4 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50.4px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                margin: 30px 0;

                @media (max-width: 1600px) {
                    font-size: 38px;
                }

                @media (max-width: 1440px) {
                    font-size: 32px;
                    line-height: normal;
                    margin: 25px 0;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 24px;
                    margin: 15px 0;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: normal;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                text-align: center;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 25px;
                }

                @media (max-width: 992px) {
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: normal;
                }
            }

            .wb-view-btn {
                margin-top: 30px;
            }

            .wb-transport-content {
                flex: 1;
                text-align: center;
            }

            .wb-transport-image {
                img {
                    min-width: 500px !important;
                    border-radius: 10px;
                    object-fit: cover;

                    @media (max-width: 1366px) {
                        min-width: auto !important;
                        max-width: 500px !important;
                        max-height: 400px !important;
                    }

                    @media (max-width: 992px) {
                        max-width: 500px !important;
                        max-height: 250px !important;
                    }

                    @media (max-width: 767px) {
                        max-width: 100% !important;
                        max-height: initial !important;
                    }
                }
            }
        }
    }

    .wb-time-line-wrp {
        margin: 30px 0;

        .wb-timeline-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            margin: 30px 0;

            h3 {
                font-family: var(--wb-secondaryFont);
                color: var(--wb-time-text-color);
                background: var(--wb-time-bg);
                font-weight: 500;
                font-size: 20px;
                text-align: center;
                width: fit-content;
                padding: 12px 20px 10px 20px;
                border-radius: 15px;

                @media (max-width: 1366px) {
                    font-size: 18px;
                }

                @media (max-width: 767px) {
                    font-size: 16px;
                    padding: 10px 18px 8px 18px;
                }
            }

            p {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 18px;
                font-weight: 400;
                line-height: 35px;
                text-align: center;
                margin: 0 13rem;

                @media (max-width: 1366px) {
                    margin: 0 3rem;
                }

                @media (max-width: 991px) {
                    font-size: 15px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 30px;
                    margin: 0;
                }
            }

            .timeline-image {
                img {
                    object-fit: cover;
                    width: 100% !important;
                    border-radius: 15px;
                }
            }
        }

        .wb-time-line-divider {
            &:last-of-type {
                display: none;
            }
        }
    }

    .wb-time-line-divider {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 100%;
            margin: 0;
        }
    }
    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);
                  a {
                        color: inherit;
                    }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a{
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 35px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                    }
                }
            }
        }
    }
}

// Rose Bud Styles

// Time line