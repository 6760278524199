@mixin fonts {
    --wb-primaryFont: "Lora";
    --wb-secondaryFont: "Helixa";
    --wb-subheadingFont: "Dancing Script";
}

.kalyani-theme1 {
    --white: #fff;
    --color1: #43493f;
    --color2: #929292;
    --color3: #ddb24e; //theme color
    --color4: #00000000;
    --color5: #F2F5F6;
    --color6: #33434A;
    --color7: #DCDCDC;
    --color8: #868686;
    --color9: #373737;
    --color10: #E50303; //heart icon color


    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-icon-color: var(--color6);
    --wb-icon-bg: var(--color5);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--color2);
    --Wb-travel-button-bg: var(--color4);
    --Wb-registry-card-bg: var(--color5);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--color3);
    --wb-accordion-border-color: var(--color7);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color8);
    --wb-input-placeholder-color: var(--color8);
    --wb-how-we-are-text-color: var(--color8);
    --wb-venue-text-color: var(--color8);
    --wb-sub-content-color: var(--color8);
    --wb-label-text-color: var(--color1);
    --wb-things-to-do-card-title-color: var(--color9);
    --Wb-things-to-do-button-bg: var(--color4);
    --wb-things-to-do-button-text-color: var(--color2);
    --wb-things-to-do-button-border-color: var(--color2);
    --wb-heart-icon-color: var(--color10);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.kalyani-theme2 {
    --white: #fff;
    --color1: #43493f;
    --color2: #929292;
    --color3: #F19C79; //theme color
    --color4: #00000000;
    --color5: #F2F5F6;
    --color6: #33434A;
    --color7: #DCDCDC;
    --color8: #868686;
    --color9: #373737;
    --color10: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-icon-color: var(--color6);
    --wb-icon-bg: var(--color5);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--color2);
    --Wb-travel-button-bg: var(--color4);
    --Wb-registry-card-bg: var(--color5);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--color3);
    --wb-accordion-border-color: var(--color7);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color8);
    --wb-input-placeholder-color: var(--color8);
    --wb-how-we-are-text-color: var(--color8);
    --wb-venue-text-color: var(--color8);
    --wb-sub-content-color: var(--color8);
    --wb-label-text-color: var(--color1);
    --wb-things-to-do-card-title-color: var(--color9);
    --Wb-things-to-do-button-bg: var(--color4);
    --wb-things-to-do-button-text-color: var(--color2);
    --wb-things-to-do-button-border-color: var(--color2);
    --wb-heart-icon-color: var(--color10);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.kalyani-theme3 {
    --white: #fff;
    --color1: #43493f;
    --color2: #929292;
    --color3: #C1D662; //theme color
    --color4: #00000000;
    --color5: #F2F5F6;
    --color6: #33434A;
    --color7: #DCDCDC;
    --color8: #868686;
    --color9: #373737;
    --color10: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-icon-color: var(--color6);
    --wb-icon-bg: var(--color5);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--color2);
    --Wb-travel-button-bg: var(--color4);
    --Wb-registry-card-bg: var(--color5);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--color3);
    --wb-accordion-border-color: var(--color7);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color8);
    --wb-input-placeholder-color: var(--color8);
    --wb-how-we-are-text-color: var(--color8);
    --wb-venue-text-color: var(--color8);
    --wb-sub-content-color: var(--color8);
    --wb-label-text-color: var(--color1);
    --wb-things-to-do-card-title-color: var(--color9);
    --Wb-things-to-do-button-bg: var(--color4);
    --wb-things-to-do-button-text-color: var(--color2);
    --wb-things-to-do-button-border-color: var(--color2);
    --wb-heart-icon-color: var(--color10);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

.kalyani-theme4 {
    --white: #fff;
    --color1: #43493f;
    --color2: #929292;
    --color3: #06D6A0; //theme color
    --color4: #00000000;
    --color5: #F2F5F6;
    --color6: #33434A;
    --color7: #DCDCDC;
    --color8: #868686;
    --color9: #373737;
    --color10: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color3);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color3);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-icon-color: var(--color6);
    --wb-icon-bg: var(--color5);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--color2);
    --Wb-travel-button-bg: var(--color4);
    --Wb-registry-card-bg: var(--color5);
    --wb-footer-text-color: var(--color3);
    --wb-title-bar-text-color: var(--color3);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--color3);
    --wb-accordion-border-color: var(--color7);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color8);
    --wb-input-placeholder-color: var(--color8);
    --wb-how-we-are-text-color: var(--color8);
    --wb-venue-text-color: var(--color8);
    --wb-sub-content-color: var(--color8);
    --wb-label-text-color: var(--color1);
    --wb-things-to-do-card-title-color: var(--color9);
    --Wb-things-to-do-button-bg: var(--color4);
    --wb-things-to-do-button-text-color: var(--color2);
    --wb-things-to-do-button-border-color: var(--color2);
    --wb-heart-icon-color: var(--color10);
    --wb-website-event-card-bg: var(--color3);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color3);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color3);
    --password-access-form-bg: var(--color3);
}

// template theme colors end

.kalyani {
    @include fonts;
    background-color: var(--white);
    min-height: 100%;

    .quil-text-block {
        font-family: var(--wb-secondaryFont);
    }

    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 14px;
        font-weight: 400;
        padding: 12px 20px;
        text-transform: uppercase;

        &:hover {
            background: var(--wb-view-button-hover-bg);
            color: var(--wb-view-button-hover-text);
            transition: background-color 0.3s ease;
        }

        @media (max-width: 767px) {
            padding: 10px 15px;
            font-size: 13px;
        }
    }

    .wb-common-head {
        margin: 60px 0;
        display: flex;
        flex-direction: column-reverse;
        text-transform: capitalize;

        @media (max-width: 1366px) {
            margin: 50px 0;
        }

        @media (max-width: 1024px) {
            margin: 45px 0;
        }

        @media (max-width:992px) {
            margin: 30px 0;

        }

        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1440px) {
                font-size: 40px;
                line-height: 60px;

            }

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 1024px) {
                font-size: 30px;
                line-height: 50px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
            }

            @media (max-width: 567px) {
                font-size: 25px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-subheadingFont);
            font-size: 40px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 51.99px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 30px;
                line-height: 30px;
            }

            @media (max-width: 1024px) {
                font-size: 25px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

    .wb-cus-row {
        display: flex;
        margin: 0 -15px;
        justify-content: center;

        @media (max-width:767px) {
            flex-wrap: wrap;

        }
    }

    .wb-kalyani-row {
        display: flex;
        margin: 0 -15px;
    }


    .wb-cus-input {
        border: 1px solid #e7e7e7;
        border-radius: 0px;
        padding: 10px 16px;
        margin-bottom: 10px;
        color: var(--wb-primary-text-color);
        font-weight: 400;
        font-size: 12px;
        width: 100%;
        height: 40px;
        font-family: var(--wb-secondaryFont);

        &::placeholder {
            color: var(--wb-input-placeholder-color) !important;
            font-family: var(--wb-secondaryFont);
            line-height: 15.12px;
            font-weight: 400;
            font-size: 12px;
        }

    }


    // titlebar
    .wb-title-bar {
        padding: 30px 100px;

        @media (max-width:767px) {
            padding: 15px 40px;

        }

        h2 {
            color: var(--wb-title-bar-text-color);
            font-size: 60px;
            text-align: center;
            font-weight: 400;
            line-height: 81.6px;
            font-family: var(--wb-primaryFont);
            display: flex;
            align-items: baseline;
            justify-content: center;
            gap: 10px;

            @media (max-width:767px) {
                font-size: 35px;
                line-height: normal;
            }

            span {
                font-family: var(--wb-primaryFont);
                font-size: 20px;
                font-weight: 400;
                line-height: 27.2px;

                @media (max-width:767px) {
                    font-size: 15px;

                }

            }
        }

    }

    // slider banner

    .wb-slider-banner-wrp {
        position: relative;

        .wb-banner-slider-image {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                top: 0;
                opacity: 0.4;
                left: 0px;
                right: 0;
                margin: 0;
            }

            img {
                object-fit: cover;
                min-height: 100vh !important;
                @media (max-width:767px) {
                    height: 100vh !important;
                }
            }

        }

        .wb-banner-slider-content {
            position: absolute;
            width: 100%;
            top: 35%;
            left: 0;
            right: 0;
            text-align: center;


            h4 {
                text-align: center;
                font-family: var(--wb-subheadingFont);
                font-size: 50px;
                font-weight: 400;
                color: var(--wb-banner-text-color);
                line-height: initial;

                @media (max-width:1440px) {
                    font-size: 35px;

                }

                @media (max-width:767px) {
                    font-size: 15px;
                }


            }

            .wb-view-btn {
                color: var(--wb-banner-button-border);
                background: var(--Wb-travel-button-bg);
                border: 1px solid var(--wb-banner-button-border);
                margin-top: 0px;
                border-radius: 0;
                font-size: 16px;
                padding: 12px 20px;

                &:hover {
                    background: var(--wb-view-button-hover-bg);
                    color: var(--wb-view-button-hover-text);
                    transition: background-color 0.3s ease;
                }

                @media (max-width:767px) {
                    font-size: 14px;
                    padding: 8px 152px;
                }
            }
        }
    }

    // how we met
    .wb-kalyani-how-we-met-wrp {
        position: relative;
        padding: 100px 0 50px;

        @media (max-width:1024px) {
            padding: 55px 0 40px;

        }

        @media (max-width:767px) {
            padding: 30px 0 30px;

        }

        .wb-kalyani-row {
            @media (max-width:767px) {
                flex-direction: column-reverse;
                row-gap: 30px;

            }
        }

        .wb-kalyani-how-we-met-col {
            padding: 0 15px;
            width: calc(100% / 2);

            @media (max-width:767px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }

        }

        .wb-kalyani-how-we-met-image {
            .wb-kalyani-bottom-image {
                position: relative;

                @media (max-width:767px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    span {
                        width: 100% !important;
                    }
                }

                img {
                    max-width: 500px !important;
                    max-height: 800px !important;
                    object-fit: cover;

                    @media (max-width:1600px) {
                        max-height: 700px !important;
                    }

                    @media (max-width:1440px) {
                        max-height: 600px !important;
                    }

                    @media (max-width:1366px) {
                        max-height: 550px !important;
                    }

                    @media (max-width:1280px) {
                        max-height: 580px !important;
                    }

                    @media (max-width:1199px) {
                        max-height: 520px !important;
                    }

                    @media (max-width:1024px) {
                        max-height: 450px !important;
                    }

                    @media (max-width:767px) {
                        max-height: 360px !important;
                        width: 100%;
                    }

                    @media (max-width:420px) {
                        max-height: 260px !important;
                    }

                }
            }

            .wb-kalyani-top-image {
                position: absolute;
                top: 22%;
                left: 23%;

                @media (max-width:1680px) {
                    left: 20%;
                }

                @media (max-width:1600px) {
                    top: 22%;
                    left: 23%;
                }

                @media (max-width:1440px) {
                    top: 25%;
                    left: 21%;
                }

                @media (max-width:1366px) {
                    top: 24%;
                    left: 20%;

                }

                @media (max-width:1280px) {
                    top: 24%;
                    left: 22%;
                }

                @media (max-width:1280px) {
                    top: 28%;
                    left: 22%;
                }

                @media (max-width:1024px) {
                    top: 25%;
                    left: 22%;
                }

                @media (max-width:767px) {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }


                img {
                    max-width: 500px !important;
                    max-height: 600px !important;
                    height: 100% !important;
                    width: 100% !important;
                    object-fit: cover;

                    @media (max-width:1600px) {
                        max-height: 485px !important;
                    }

                    @media (max-width:1440px) {
                        max-height: 460px !important;
                    }

                    @media (max-width:1366px) {
                        max-height: 390px !important;
                    }

                    @media (max-width:1199px) {
                        max-height: 350px !important;
                    }

                    @media (max-width:1024px) {
                        max-height: 290px !important;
                    }

                    @media (max-width:767px) {
                        max-height: 255px !important;
                    }

                    @media (max-width:420px) {
                        max-height: 180px !important;
                        object-fit: contain;
                    }
                }
            }

        }

        .wb-kalyani-how-we-met-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 2rem;

            @media (max-width:1366px) {
                margin-left: 0rem;

            }

            @media (max-width:767px) {
                margin-left: 0rem;

            }

            h6 {
                color: var(--wb-secondary-text-color);
                font-family: var(--wb-subheadingFont);
                font-size: 40px;
                text-transform: capitalize;
                font-weight: 400;
                line-height: 51.99px;
                text-align: center;

                @media (max-width: 1366px) {
                    font-size: 30px;
                    line-height: 30px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 20px;
                }

            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                font-size: 50px;
                font-weight: 400;
                line-height: 68px;

                @media (max-width: 1440px) {
                    font-size: 40px;
                    line-height: 60px;

                }

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 30px;
                    line-height: 50px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                }

                @media (max-width: 567px) {
                    font-size: 25px;
                }
            }

            p {
                color: var(--wb-how-we-are-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 18px;
                font-weight: normal;
                line-height: 38px;

                @media (max-width:1024px) {
                    font-size: 16px;
                    line-height: 30px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 28px;
                }
            }

        }

    }

    // bride groom name
    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 30px 0 50px 0;
        margin: 0;

        @media (max-width: 1366px) {
            padding: 20px 0 30px 0;
        }

        @media (max-width: 767px) {
            padding: 15px 0;
        }

        @media (max-width: 580px) {
            padding: 5px 0;
        }

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            font-family: var(--wb-primaryFont);
            color: var(--wb-bridegroom-text-color);
            font-size: 80px;
            font-weight: 400;
            line-height: 92px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1600px) {
                font-size: 65px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                font-size: 60px;
            }

            @media (max-width: 1280px) {
                font-size: 55px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
                gap: 18px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 25px;
                flex-direction: column;
                gap: 3px;
            }


            span {
                text-align: left;

                @media (max-width:767px) {
                    text-align: center;

                }
            }

            span:nth-child(1) {
                text-align: right;

                @media (max-width:767px) {
                    text-align: center;

                }
            }

            span:nth-child(2) {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 19px;
            margin-top: 10px;
            align-items: center;

            @media (max-width: 580px) {
                gap: 6px;
            }

            span {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 27.72px;
                color: var(--wb-banner-date-text-color);

                @media (max-width:767px) {
                    font-size: 15px;
                    line-height: initial;

                }

                @media (max-width:580px) {
                    font-size: 12px;

                }

                &:nth-child(1) {
                    flex: 1;
                    text-align: right;
                }

                &:nth-child(3) {
                    flex: 1;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 2px;
                    height: 18px;
                    background: var(--white);

                    @media (max-width: 767px) {
                        display: none;

                    }


                }
            }

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }
    }

    //   venue location
    .wb-kalyani-venue-location-wrp {
        text-align: center;
        padding: 50px 0;

        @media (max-width:1024px) {
            padding: 40px 0;

        }

        @media (max-width:1024px) {
            padding: 30px 0;

        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-subheadingFont);
            font-size: 40px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 51.99px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 30px;
                line-height: 30px;
            }

            @media (max-width: 1024px) {
                font-size: 25px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 20px;
            }
        }

        h4 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 58px;

            @media (max-width: 1440px) {
                font-size: 40px;
                line-height: 60px;

            }

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 1024px) {
                font-size: 30px;
                line-height: 50px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
            }

            @media (max-width: 567px) {
                font-size: 25px;
            }
        }

        p {
            color: var(--wb-venue-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 24px;
            line-height: 40px;
            font-weight: normal;

            @media (max-width: 1366px) {
                font-size: 22px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
            }

            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }

            @media (max-width: 420px) {
                font-size: 15px;
                line-height: 24px;
            }
        }

        .wb-view-btn {
            margin-top: 30px;
            border: 1px solid var(--Wb-travel-button-border);
            color: var(--Wb-travel-button-text-color);
            background-color: var(--Wb-travel-button-bg);
            border-radius: 0;

            &:hover {
                background: var(--wb-view-button-hover-bg);
                color: var(--wb-view-button-hover-text);
            }
        }

        .fit-image {
            margin: 30px 0;

            img {
                object-fit: cover;
                width: 100% !important;
                height: 100% !important;
                max-height: 500px !important;

                @media (max-width:767px) {
                    max-height: 100% !important;
                }
            }
        }

    }

    // countdown
    .wb-count-down-wrp {
        padding: 50px 0;

        @media (max-width:1024px) {
            padding: 40px 0;

        }

        @media (max-width:767px) {
            padding: 20px 0;

        }

        .wb-cus-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;

            .wb-count-down-content {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width:767px) {
                    width: calc(100% / 3);
                }

                @media (max-width:576px) {
                    width: calc(100% / 2);
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-subheadingFont);
                    font-size: 40px;
                    text-transform: capitalize;
                    font-weight: 400;
                    line-height: 51.99px;
                    text-align: center;

                    @media (max-width: 1366px) {
                        font-size: 35px;
                        line-height: 40px;
                    }

                    @media (max-width: 767px) {
                        font-size: 25px;
                        line-height: normal;
                    }

                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 58px;

                    @media (max-width: 1366px) {
                        font-size: 45px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 40px;
                    }

                    @media (max-width: 992px) {
                        font-size: 35px;
                    }

                }

            }

        }
    }

    // sub banner
    .wb-sub-banner {
        padding: 50px 0;

        @media (max-width:1024px) {
            padding: 40px 0;

        }

        @media (max-width:767px) {
            padding: 30px 0;

        }

        img {
            max-width: 100% !important;
            max-height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            height: auto !important;
        }

    }

    // wedding party
    // wedding party card

    .person-view-card {
        margin: 0;
        padding-bottom: 50px;

        @media (max-width: 1024px) {
            padding-bottom: 40px;
        }

        @media (max-width:767px) {
            padding: 30px 0;

        }

        .wb-cus-row {
            row-gap: 60px;
            justify-content: center;

            @media (max-width: 1440px) {
                row-gap: 50px;
            }

            @media (max-width: 1366px) {
                row-gap: 45px;
            }

            @media (max-width: 1199px) {
                row-gap: 40px;
            }

            @media (max-width: 992px) {
                row-gap: 35px;
            }
        }

        .person-view-card-item {
            padding: 0 15px;
            width: calc(100% / 5);
            text-align: center;

            @media (max-width: 1199px) {
                width: calc(100% / 4);
            }

            @media (max-width: 992px) {
                width: calc(100% / 3);
            }

            @media (max-width: 767px) {
                width: calc(100% / 2);
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                object-fit: cover;
                border-radius: 0px;

                @media (max-width:1024px) {
                    height: 185px !important;
                    object-fit: contain;
                }

                @media (max-width: 576px) {
                    height: auto !important;
                }
            }

            h4 {
                color: var(--wb-card-title-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 400;
                line-height: 35px;
                margin: 10px 0 5px;

                @media (max-width: 1366px) {
                    font-size: 22px;
                }

                @media (max-width: 1199px) {
                    font-size: 20px;
                }

                @media (max-width: 1024px) {
                    font-size: 18px;
                }
            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }

            p {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                margin-top: 8px;
            }
        }
    }

    // faq accordion
    .wb-accordion-wrp {
        padding: 50px 0;

        @media (max-width: 1024px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-accordion {
            margin-bottom: 10px;
            padding: 30px 0;
            border-bottom: 1px solid var(--wb-accordion-border-color);

            @media (max-width: 1366px) {
                padding: 10px 0;
            }

            &:nth-child(1) {
                border-top: 1px solid var(--wb-accordion-border-color);
            }

            .wb-accordion-header {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h3 {
                    font-size: 30px;
                    font-family: var(--wb-primaryFont);
                    color: var(--wb-accordion-title-color);
                    font-weight: 400;
                    line-height: 34.8px;
                    margin: 30px 0;

                    @media (max-width: 1366px) {
                        font-size: 28px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 26px;
                    }

                    @media (max-width: 992px) {
                        font-size: 24px;
                        margin: 20px 0;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                        margin: 15px 0;
                        line-height: 25.8px;
                    }

                    @media (max-width: 420px) {
                        font-size: 16px;
                        margin: 15px 0;
                        line-height: 23.8px;
                    }

                }

                .wb-accordion-icon {
                    transition: transform 0.3s ease-in-out;

                    &.open {
                        transform: rotateX(180deg);
                    }

                    svg {
                        width: auto;
                        margin-right: 0;

                        @media (max-width:767px) {
                            width: 55px;

                        }

                        @media (max-width:420px) {
                            width: 35px;

                        }
                    }
                }
            }

            .wb-accordion-content {
                max-height: 0;
                overflow: hidden;

                p {
                    color: var(--wb-accordion-text-color);
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 38px;
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 1024px) {
                        font-size: 16px;
                        line-height: 32px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                &.open {
                    max-height: 100%;
                    padding: 15px 0;
                }
            }

        }

    }



    //    slider
    .partial-image-carousel {
        padding: 100px 0;

        @media (max-width:1440px) {
            padding: 50px 0;
        }

        @media (max-width:991px) {
            padding: 30px 0;
        }

        .swiper {
            margin-bottom: 0px !important;

            @media(max-width: 767px) {
                margin: 0 !important;
            }

        }

        img {
            height: 750px !important;
            width: 100% !important;
            object-fit: cover;

            @media (max-width:1600px) {
                height: 600px !important;
            }

            @media (max-width:1024px) {
                height: 500px !important;
            }

            @media (max-width:767px) {
                height: auto !important;
            }
        }
    }

    // registry

    .wb-registry-card-wrp {
        padding-bottom: 50px;

        @media (max-width:1024px) {
            padding-bottom: 40px;
        }

        @media (max-width:767px) {
            padding-bottom: 30px;
        }

        .wb-cus-row {
            row-gap: 30px;
            flex-wrap: wrap;

            @media (max-width: 1199px) {
                row-gap: 20px;
            }

            @media (max-width: 767px) {
                row-gap: 15px;
            }
        }

        .wb-col-3 {
            padding: 0 15px;
            flex: 0 0 auto;
            width: calc(100% / 4);

            @media (max-width: 1199px) {
                width: calc(100% / 3);
            }

            @media (max-width: 767px) {
                width: calc(100% / 2);
            }

            @media (max-width: 567px) {
                width: 100%;
            }
        }

        .wb-registry-card-item {
            border-radius: 0px;
            border: 0;
            background: var(--Wb-registry-card-bg);
            padding: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;

            @media (max-width: 1024px) {
                padding: 20px;

            }

            .wb-registry-card-img {
                text-align: center;
                margin-bottom: 10px;
                img {
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            .wb-registry-card-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

            }

            .wb-view-btn {
                width: 100%;
                border-radius: 0;
                text-transform: uppercase;
            }

            h3,
            h4 {
                font-size: 28px;

                @media (max-width: 1199px) {
                    font-size: 25px;
                }

                @media (max-width: 992px) {
                    font-size: 22px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }

            h4 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
            }

            h3 {
                margin: 20px 0;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);


                @media (max-width: 1600px) {
                    margin: 15px 0;
                }

                @media (max-width: 1366px) {
                    margin: 10px 0;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 20px;
                height: 100%;

                @media (max-width: 1366px) {
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }
    }

    // rsvp
    .wb-rsvp-form-wrp {
        padding: 100px 0;
        margin: 50px 0;
        background-image: url("../../public/images/wedding-websites/kalyani-rsvp-bg.jpg");
        background-position: right, top;
        background-repeat: no-repeat;

        @media (max-width:1024px) {
            padding: 80px 0;
            margin: 0px 0;
        }

        @media (max-width:767px) {
            padding: 30px 0;
        }

        .wb-rsvp-form-title {
            text-align: center;
            flex-direction: column-reverse;
            display: flex;
            gap: 10px;
            margin-bottom: 20px;

            h3 {
                font-size: 40px;
                font-weight: 400;
                line-height: 46px;
                color: var(--wb-card-title-color);
                font-family: var(--wb-primaryFont);
                margin-bottom: 10px;

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: normal;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 26px;
                }
            }

            p {
                color: var(--wb-secondary-text-color);
                font-family: var(--wb-subheadingFont);
                font-size: 30px;
                text-transform: capitalize;
                font-weight: 400;
                line-height: 51.99px;
                text-align: center;

                @media (max-width: 1366px) {
                    font-size: 25px;
                    line-height: 40px;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: normal;
                }
            }
        }

        .wb-rsvp {
            background: #fff;
            padding: 100px;
            max-width: 790px;
            margin: 0 auto;

            @media (max-width:1366px) {
                padding: 80px 90px;

            }

            @media (max-width:1024px) {
                padding: 70px 60px;

            }

            @media (max-width:992px) {
                padding: 55px 35px;
                max-width: 600px;
            }

            @media (max-width:420px) {
                padding: 35px 13px;
            }

            form {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }

            .wb-cus-input {
                width: 80%;

                @media (max-width:767px) {
                    width: 100%;

                }
            }

            .wb-view-btn {
                margin-top: 15px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
                border-radius: 0;

                @media (max-width:767px) {
                    padding: 15px;

                }

                @media (max-width:560px) {
                    padding: 10px;

                }

                &:hover {
                    background: var(--wb-view-button-hover-bg);
                    color: var(--wb-view-button-hover-text);
                }
            }

        }
    }

    // password access
    .wb-password-access-form-wrp {
        padding: 60px;
        background: var(--password-access-form-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        .wb-view-btn {
            background-color: #fff;
            color: #000000;
            border-radius: 15px;
            padding: 15px 25px;

            &:hover {
                background-color: #f1f1f1e8;
            }
        }

        @media (max-width:1366px) {
            padding: 50px;
        }

        @media (max-width:767px) {
            padding: 20px;
        }

        .wb-access-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: #fff;
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width:1440px) {
                    font-size: 35px;
                    line-height: normal;

                }

                @media (max-width:1366px) {
                    font-size: 32px;
                }

                @media (max-width:1280px) {
                    font-size: 30px;
                }

                @media (max-width:992px) {
                    font-size: 24px;

                }
            }

        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            margin: 40px 0 0;

            @media (max-width:1366px) {
                margin: 30px 0 0;
                gap: 30px;
            }


            .wb-cus-input {
                background-color: #fff;
                border-radius: 10px;
                height: 60px;
                padding: 20px;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                border: 1px solid #cfcfcf;

                @media (max-width:767px) {
                    width: 100%;

                }

            }
        }
    }

    // footer name

    .wb-footer-couple-name {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 100px 0;

        @media (max-width:1024px) {
            padding: 50px 0;
        }

        @media (max-width:767px) {
            padding: 30px 0;
        }

        h5 {
            font-size: 60px;
            font-weight: 400;
            line-height: 69.23px;
            color: var(--wb-footer-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 15px;
            text-transform: lowercase;

            @media (max-width: 1366px) {
                font-size: 50px;
                line-height: normal;
            }

            @media (max-width: 1024px) {
                font-size: 45px;
            }

            @media (max-width: 767px) {
                font-size: 35px;
                gap: 10px;
            }

            span {
                font-family: var(--wb-primaryFont);
                font-size: 20px;
                line-height: 53.12px;

                @media (max-width: 1366px) {
                    line-height: 41.12px;
                }

                @media (max-width: 767px) {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }

        h6 {
            display: none;
        }

        .wb-divider {
            display: none;
        }
    }

    .wb-expandable-faq-wrp {
        padding: 50px;

        @media (max-width:1024px) {
            padding: 40px;
        }

        @media (max-width:767px) {
            padding: 30px;
        }

        .wb-common-head {
            margin-bottom: 0;
            align-items: flex-start;
        }
    }


    .full-image-carousel {
        .swiper {
            @media(max-width: 767px) {
                margin: 0 !important;
            }

        }

        img {
            max-width: 320px !important;
            min-height: 100% !important;
            object-fit: cover;
            width: 100% !important;
            height: 353px !important;

            @media (max-width:767px) {
                max-width: 100% !important;
                min-height: 250px !important;
                height: auto !important;
            }
        }
    }

    //   things to do

    .wb-activity-content-wrap {
        padding: 0 0 50px 0;

        @media (max-width:1366px) {
            padding: 0 0 40px 0;
        }

        @media (max-width:767px) {
            padding: 0 0 30px 0;
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1024px) {
                gap: 30px 0;
            }

            .wb-activity-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100%;
                width: calc(100% / 3);
                padding: 0 15px;

                .wb-activity-image {
                    width: 100%;
                }

                @media (max-width:767px) {
                    width: 100%;

                }

                img {
                    width: 100%;
                    max-width: 100% !important;
                    height: 380px !important;
                    height: 100%;
                    object-fit: cover;

                    @media (max-width:767px) {
                        min-height: 200px !important;
                        height: auto !important;
                        aspect-ratio: 1;
                    }
                }

                h4 {
                    color: var(--wb-things-to-do-card-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 34.65px;
                    padding-bottom: 10px;
                    margin-top: 12px;


                    @media (max-width: 1024px) {
                        font-size: 20px;
                        line-height: 25.65px;
                    }


                }

                h6 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    padding-bottom: 20px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 35px;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 28px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 28px;
                        padding-bottom: 10px;
                    }
                }

                h5 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 25px;
                    }
                }

                p {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    padding-bottom: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width: 1366px) {
                        font-size: 14px;
                    }

                    @media (max-width: 1024px) {
                        line-height: 25px;
                    }
                }

                .wb-view-btn {
                    margin-top: 20px;
                    background: var(--Wb-things-to-do-button-bg);
                    color: var(--wb-things-to-do-button-text-color);
                    border: 1px solid var(--wb-things-to-do-button-border-color);
                    border-radius: 0;

                    &:hover {
                        background: var(--wb-view-button-hover-bg);
                        color: var(--wb-view-button-hover-text);
                    }
                }
            }

        }
    }

    // common style
    // (hotel view)
    // (hotel view)
    .single-hotel-transport-wrp {

        padding: 0 0 50px;

        @media (max-width:1024px) {
            padding: 0 0 40px;

        }

        @media (max-width:767px) {
            padding: 0 0 30px;

        }
        .wb-transport-view ,
        .wb-hotel-view {
            text-align: center;
            margin: 40px 0;
            padding: 0 40px;
            width: calc(100% / 2);
            display: flex;
            flex-direction: column;
            @media (max-width:767px) {
                margin: 20px 0;
                width: 100%;
                padding: 0 15px;
            }
        }


        .wb-hotel-image,
        .wb-transport-image {
            img {
                width: 100%;
                height: 400px !important;
                object-fit: cover;
                border-radius: 0;

                @media (max-width: 1600px) {
                    height: 320px !important;
                }

                @media (max-width: 1366px) {
                    height: 280px !important;
                }

                @media (max-width: 767px) {
                    height: 200px !important;
                }
            }
        }

        .wb-hotel-content,
        .wb-transport-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            h6 {
                font-size: 16px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-weight: 500;
                line-height: 35px;
                text-align: center;

                @media (max-width: 1440px) {
                    line-height: normal;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }

            h4 {
                font-size: 40px;
                line-height: 50.4px;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                margin: 30px 0;

                @media (max-width: 1600px) {
                    font-size: 38px;
                }

                @media (max-width: 1440px) {
                    font-size: 32px;
                    line-height: 35px;
                    margin: 25px 0;
                }

                @media (max-width: 1366px) {
                    font-size: 32px;
                }

                @media (max-width: 1280px) {
                    font-size: 30px;
                }

                @media (max-width: 992px) {
                    font-size: 24px;
                    margin: 15px 0;
                    line-height: 30px;
                }

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }



            p {
                font-size: 18px;
                font-weight: normal;
                line-height: 35px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                text-align: center;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 25px;
                }

                @media (max-width: 992px) {
                    line-height: 25px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
        .hotel-transport-btn-wrp {
            margin-top: auto;
        }
        .wb-view-btn {
            margin-top: 30px;
            border: 1px solid var(--Wb-travel-button-border);
            color: var(--Wb-travel-button-text-color);
            background-color: var(--Wb-travel-button-bg);
            border-radius: 0;

            &:hover {
                background: var(--wb-view-button-hover-bg);
                color: var(--wb-view-button-hover-text);
            }

        }



        .wb-divider {
            display: none;
        }
    }

    // travel



    // travel

    // title view
    .wb-large-tile {
        h2 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }

        }

    }

    .wb-small-tile {
        h4 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }


    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family: var(--wb-secondaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-event-head-text-color);
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            color: var(--wb-how-we-are-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width:767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;

        }

        p {
            color: var(--wb-how-we-are-text-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        a {
            font-size: 18px;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .wb-view-btn {
                margin-top: 30px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
                border-radius: 0;
                padding: 20px;
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width:1440px) {
                row-gap: 50px;
            }

            @media (max-width:1366px) {
                row-gap: 45px;
            }

            @media (max-width:1199px) {
                row-gap: 40px;
            }

            @media (max-width:992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width:767px) {
                width: 50%;
            }

            @media (max-width:576px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 0px;
                filter: grayscale(100%);
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                line-height: 35px;
                margin: 10px 0 5px;
                text-transform: uppercase;

                @media (max-width:1366px) {
                    font-size: 22px;
                }

                @media (max-width:1199px) {
                    font-size: 20px;
                }

                @media (max-width:992px) {
                    font-size: 18px;
                    line-height: normal;
                }

            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width:1366px) {
                    font-size: 18px;
                }

                @media (max-width:992px) {
                    font-size: 16px;
                }

            }
        }
    }

    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width: 1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width: 1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);

                a {
                    color: inherit;
                }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);
            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a {
                        color: var(--wb-primary-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 16px;
                        line-height: 35px;

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }

    // events
    .wb-website-event-wrp {
        padding-bottom: 50px;

        @media (max-width:1024px) {
            padding-bottom: 40px;
        }

        @media (max-width:767px) {
            padding-bottom: 30px;
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 767px) {
                row-gap: 15px;
            }
        }

        .wb-cus-col-2 {
            width: calc(100% / 2);
            padding: 0 15px;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .wb-website-event-card {
            background-color: var(--wb-website-event-card-bg);
            padding: 60px;
            height: 100%;

            @media (max-width: 1440px) {
                padding: 40px;
            }

            @media (max-width: 767px) {
                padding: 30px;
            }

            .wb-website-event-date-time {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid var(--wb-event-date-border-color);
                padding-bottom: 30px;
                margin-bottom: 30px;

                @media (max-width: 1440px) {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }

                @media (max-width: 767px) {
                    gap: 10px;
                }

                .start-date-time {
                    text-align: center;
                }

                .end-date-time {
                    text-align: center;
                }

                h6 {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 26.8px;
                    text-transform: uppercase;

                    @media (max-width: 1440px) {
                        font-size: 17px;
                        line-height: 19.8px;
                    }

                    @media (max-width: 992px) {
                        font-size: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }
                }

                h5 {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 28px;
                    line-height: 47px;
                    font-weight: 600;

                    @media (max-width: 1440px) {
                        font-size: 24px;
                        line-height: 38px;
                    }

                    @media (max-width: 992px) {
                        font-size: 18px;
                        line-height: 30px;
                    }

                    @media (max-width: 767px) {
                        font-size: 15px;
                        line-height: 18px;
                    }
                }

                span {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-event-span-text-color);
                    background: var(--wb-event-span-bg);
                    font-size: 20px;
                    line-height: normal;
                    background-color: var(--wb-event-btn-bg);
                    padding: 20px;
                    text-transform: capitalize;

                    @media (max-width: 1440px) {
                        font-size: 18px;
                        padding: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 15px;
                        padding: 10px;
                    }
                }
            }

            .wb-website-event-details {
                display: flex;
                flex-direction: column;
                align-items: center;

                h5 {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 40px;
                    line-height: 48.59px;
                    margin-bottom: 20px;
                    font-weight: 600;

                    @media (max-width: 1440px) {
                        font-size: 30px;
                        line-height: 35.59px;
                    }

                    @media (max-width: 992px) {
                        font-size: 26px;
                        line-height: 30.59px;
                        margin-bottom: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                        line-height: 25.59px;
                    }
                }

                p {
                    font-family: var(--wb-secondaryFont);
                    color: var(--wb-website-event-text-color);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 34px;
                    text-align: center;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 28px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }

                .wb-view-btn {
                    margin-top: 30px;
                    text-transform: uppercase;
                    background-color: var(--wb-event-btn-bg);
                    color: var(--wb-event-btn-text-color);

                    @media (max-width: 992px) {
                        margin-top: 20px;
                    }

                    &:hover {
                        background-color: var(--wb-event-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-event-btn-hover-text-color);
                    }
                }
            }
        }
    }
}