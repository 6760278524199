
@import "font";


// Colors

$primaryColor: #D1468C;
$linkColor: #CC0066;
$bodyTextColor: #000000;
$secondaryColor: #edb457;
$boxbgColor: #FAF8F5;
$grayText:#5e5e5e;
$darkRed:#DE0000;


$black: #000;
$white: #fff;
$lightWhite: #ffffffb3;
$darkgrey: #131313;
$mediumGrey: #898989;
$lightGrey: #e2dede;
$transparentBlack: #00000063;
$fbBlue: #3c5998;
$dropdown: #1c1c1c;
$text: #ababab;
$placeholder: #ffffff;
$red: #D93386;
$darkmedium: #353535;
$formContainer: #181818;
$formControl: #222;
$whiteformGrey: #ccc;


$fontAmiri: "Amiri", serif;
$fontAllura: "Allura", cursive;

// for bold view in Kantumruy
$primary-font-weight:600;

$primaryFont: "Kantumruy Pro", sans-serif;
$secondaryFont: "DM Serif Display", serif;

body {
    font-family: $primaryFont;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    background-color: #F6F1EA;
    color: $bodyTextColor;
}

* {
    box-sizing: border-box;
}
a {
    text-decoration: none !important;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

a:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    padding: 0;
    margin: 0;
    line-height: 1.2;
}

p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #000000;
    line-height: 1.2;
}

ul,
ol {
    margin: 0;
    padding: 0;
}
.list-unstyled {
    list-style: none;
}
.img-fluid {
    max-width: 100%;
}
button {
    border: none;
}
figure {
    margin: 0;
}
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-track {
    background: #eef2f500;
    border-radius: 20px;
}
::-webkit-scrollbar-thumb {
    background: #cf3180;
    border-radius: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: rgb(0, 0, 0) !important;


}
input:-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}
button {
    font-family: $primaryFont;
}
input[type="date"] {
    background-image: url("/images/calendarIcon.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 95% center !important;
    background-size: 20px !important;
    &::-webkit-calendar-picker-indicator {
        // display: none;
        -webkit-appearance: none;
        opacity: 0;
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
}
select {
    -webkit-appearance: none;
    background-image: url("/images/selectArrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 93% center !important;
    background-size: 14px !important;

}
.form-control{
    &:focus-visible {
        outline: none;
    }
}
