@mixin fonts {
    --wb-primaryFont: "roadworthy", serif;
    --wb-secondaryFont: "Helixa";
    --wb-belleniaFont: "Bellenia Love";
}

.brown-rust-theme1 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #bb593b; //secondary color
    --color3: #868686; //sub content color
    --color4: #f4d8cf; //section bg color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #cca89c;
    --color8: #f4d8cf;
    --color9: #cba598;
    --color10: #efe3df;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color4);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--color3);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-bg: var(--color4);
    --wb-venue-card-bg: var(--white);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--white);
    --wb-rsvp-text-color: var(--color1);
    --wb-rsvp-btn-bg: var(--color2);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-image-border-color: var(--color7);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color9);
    --wb-faq-rangoli-icon-color: var(--color9);
    --wb-person-view-rangoli-icon-color: var(--color10);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-faq-common-head-subtext-color: var(--color2);
    --wb-faq-common-head-text-color: var(--color1);
    --wb-faq-common-head-divider-color: var(--color2);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

.brown-rust-theme2 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #026f83; //secondary color
    --color3: #868686; //sub content color
    --color4: #c4e2fb; //section bg color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #acd5dc;
    --color8: #f4d8cf;
    --color9: #41bacf;
    --color10: #cde4e9;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color2);
    --wb-accordion-title-color: var(--color2);
    --wb-accordion-text-color: var(--color2);
    --wb-accordion-bg: var(--color4);
    --wb-accordion-icon-color: var(--color2);
    --wb-input-placeholder-color: var(--color3);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-bg: var(--color4);
    --wb-venue-card-bg: var(--white);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-image-border-color: var(--color2);
    --wb-rsvp-bg: var(--white);
    --wb-rsvp-text-color: var(--color1);
    --wb-rsvp-btn-bg: var(--color2);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-image-border-color: var(--color7);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-faq-rangoli-icon-color: var(--color9);
    --wb-person-view-rangoli-icon-color: var(--color10);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-faq-common-head-subtext-color: var(--color2);
    --wb-faq-common-head-text-color: var(--color2);
    --wb-faq-common-head-divider-color: var(--color2);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

.brown-rust-theme3 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #b07503; //secondary color
    --color3: #868686; //sub content color
    --color4: #f0f5db; //section bg color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #d9b061;
    --color8: #f4d8cf;
    --color9: #d9b061;
    --color10: #efe3df;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color4);
    --wb-accordion-icon-color: var(--color2);
    --wb-input-placeholder-color: var(--color3);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-bg: var(--color4);
    --wb-venue-card-bg: var(--white);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-image-border-color: var(--color2);
    --wb-rsvp-bg: var(--white);
    --wb-rsvp-text-color: var(--color1);
    --wb-rsvp-btn-bg: var(--color2);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-image-border-color: var(--color7);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-faq-rangoli-icon-color: var(--color9);
    --wb-person-view-rangoli-icon-color: var(--color9);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-faq-common-head-subtext-color: var(--color2);
    --wb-faq-common-head-text-color: var(--color1);
    --wb-faq-common-head-divider-color: var(--color2);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

.brown-rust-theme4 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #bf2629; //secondary color
    --color3: #868686; //sub content color
    --color4: #FFDFE0; //section bg color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #FF8284;
    --color8: #f4d8cf;
    --color9: #ffb08e;
    --color10: #ffd8c7;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color4);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--color3);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-bg: var(--color4);
    --wb-venue-card-bg: var(--white);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-image-border-color: var(--color9);
    --wb-rsvp-bg: var(--white);
    --wb-rsvp-text-color: var(--color1);
    --wb-rsvp-btn-bg: var(--color2);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-image-border-color: var(--color9);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-faq-rangoli-icon-color: var(--color9);
    --wb-person-view-rangoli-icon-color: var(--color7);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-faq-common-head-subtext-color: var(--color2);
    --wb-faq-common-head-text-color: var(--color1);
    --wb-faq-common-head-divider-color: var(--color2);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

.brown-rust-theme5 {
    --white: #fff;
    --color1: #43493f; //primary color
    --color2: #AD00A9; //secondary color
    --color3: #868686; //sub content color
    --color4: #ECD1EB; //section bg color
    --color5: #00000000; //transparent color
    --color6: #f2f5f6; //card border color
    --color7: #E9B5E0;
    --color8: #f4d8cf;
    --color9: #C264B3;
    --color10: #FBD9F5;
    --color11: #E50303; //heart icon color

    --wb-bridegroom-text-color: var(--white);
    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-view-button-text-color: var(--color1);
    --wb-view-button-bg: var(--color5);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color6);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-accordion-border-color: var(--color1);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color4);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--color3);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-bg: var(--color4);
    --wb-venue-card-bg: var(--white);
    --wb-venue-btn-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-image-border-color: var(--color9);
    --wb-rsvp-bg: var(--white);
    --wb-rsvp-text-color: var(--color1);
    --wb-rsvp-btn-bg: var(--color2);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-bottom-bg-icon-color: var(--color2);
    --wb-how-we-met-image-border-color: var(--color7);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-faq-rangoli-icon-color: var(--color9);
    --wb-person-view-rangoli-icon-color: var(--color10);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-faq-common-head-subtext-color: var(--color2);
    --wb-faq-common-head-text-color: var(--color1);
    --wb-faq-common-head-divider-color: var(--color2);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-heart-icon-color: var(--color11);
    --wb-website-event-card-bg:var(--color2);
    --wb-website-event-text-color:var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color:var(--color2);
}

// theme color end
.brown-rust {
    @include fonts;
    background-color: var(--white);

    .brown-dust-divider {
        margin: 0px;

        svg {
            width: 100%;
            margin: 0 auto;
        }
    }

    .wb-rangoli-bg-icon {
        width: 100%;
        margin: 0 auto;

        svg {
            width: 100%;

            path {
                fill: var(--wb-rangoli-icon-color);
            }
        }
    }

    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 14px;
        font-weight: 400;
        padding: 12px 20px;
        text-transform: uppercase;
        border-radius: 15px;

        &:hover {
            background-color: var(--wb-rsvp-btn-hover-bg);
            transition: background-color 0.3s ease;
        }

        @media (max-width: 1366px) {
            padding: 10px 25px;
            font-size: 14px;
        }


        @media (max-width: 767px) {
            border-radius: 10px;
        }
    }

    // section head(common head)

    .indo-western-common-head {
        margin: 0;
        display: flex;
        flex-direction: column;


        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1440px) {
                font-size: 35px;
            }

            @media (max-width: 992px) {
                font-size: 30px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-belleniaFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
    }

    // divider
    .common-head-divider {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        @media (max-width: 1600px) {
            margin-bottom: 45px;
        }

        @media (max-width: 1440px) {
            margin-bottom: 40px;
        }

        @media (max-width: 1366px) {
            margin-bottom: 35px;
        }

        @media (max-width: 1280px) {
            margin-bottom: 30px;
        }

        @media (max-width: 1199px) {
            margin-bottom: 25px;
        }

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }


        svg {
            width: 90%;
            margin-top: 20px;

            @media (max-width: 991px) {
                margin-top: 10px;
            }

            @media (max-width: 767px) {
                width: 150px;
            }

            path {
                fill: var(--wb-secondary-text-color);
            }
        }
    }

    // titlebar
    .brown-rust-title-bar-section {
        padding: 30px 0;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 99;
        margin: 0 auto;

        @media (max-width: 767px) {
            padding: 15px 0;
        }

        .wb-title-bar {
            h2 {
                color: var(--wb-title-bar-text-color);
                font-size: 60px;
                text-align: center;
                font-weight: 400;
                line-height: 81.6px;
                font-family: var(--wb-primaryFont);
                display: flex;
                align-items: baseline;
                justify-content: center;
                gap: 10px;

                @media (max-width: 767px) {
                    font-size: 40px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 27.2px;

                    @media (max-width: 767px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    // bride groom name
    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 10px 0 50px;
        margin: 0;

        @media (max-width: 1366px) {
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding: 25px 0;
        }

        @media (max-width: 580px) {
            padding: 20px 0;
        }

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            font-family: var(--wb-primaryFont);
            color: var(--wb-bridegroom-text-color);
            font-size: 80px;
            font-weight: 400;
            line-height: 92px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1600px) {
                font-size: 65px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                font-size: 60px;
            }

            @media (max-width: 1280px) {
                font-size: 55px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
                gap: 18px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 25px;
                flex-direction: column;
                gap: 3px;
            }

            span {
                text-align: center;
                text-align: left;
            }

            span:nth-child(1) {
                text-align: right;

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

            span:nth-child(2) {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 1024px) {
                    font-size: 20px;
                }

                @media (max-width: 1024px) {
                    font-size: 15px;
                }

                @media (max-width: 580px) {
                    font-size: 12px;
                }
            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 19px;
            margin-top: 10px;
            align-items: center;

            @media (max-width: 576px) {
                gap: 6px;
            }


            span {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 27.72px;
                color: var(--wb-banner-date-text-color);

                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 20.72px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: normal;
                }

                &:nth-child(2) {
                    width: 2px;
                    height: 18px;
                    background: var(--white);

                    @media (max-width: 767px) {
                        display: none;

                }
                }
            }
        }
    }

    // slider banner

    .indo-western-slider-banner-wrp {
        position: relative;
        padding-bottom: 100px;
        z-index: 9;

        @media (max-width: 1600px) {
            padding-bottom: 45px;
        }

        @media (max-width: 1366px) {
            padding-bottom: 35px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 25px;
        }

        @media (max-width: 850px) {
            padding-bottom: 15px;
        }

        .wb-view-btn {
            @media (max-width: 576px) {
                padding: 7px 12px;
                font-size: 10px;
            }
        }

        .indo-western-banner-slider-image {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                top: 0;
                opacity: 0.3;
                left: 0px;
                right: 0;
                margin: 0;
                border-radius: 63px;

                @media (max-width: 1366px) {
                    border-radius: 50px;
                }

                @media (max-width: 1199px) {
                    border-radius: 40px;
                }

                @media (max-width: 992px) {
                    border-radius: 30px;
                }

                @media (max-width: 767px) {
                    border-radius: 20px;
                }
            }

            img {
                object-fit: cover;
                border-bottom-left-radius: 63px;
                border-bottom-right-radius: 63px;
                height: 100vh !important;

                @media (max-width: 1366px) {
                    border-bottom-left-radius: 50px;
                    border-bottom-right-radius: 50px;
                }

                @media (max-width: 1199px) {
                    border-bottom-left-radius: 40px;
                    border-bottom-right-radius: 40px;
                }

                @media (max-width: 992px) {
                    border-bottom-left-radius: 30px;
                    border-bottom-right-radius: 30px;
                }

                @media (max-width: 767px) {
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }

        .indo-western-banner-slider-content {
            position: absolute;
            bottom: 18%;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            width: 100%;

            @media (max-width: 767px) {
                top: 41%;
            }

            h4 {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 20px;
                font-weight: 400;
                color: var(--wb-banner-text-color);
                margin-bottom: 30px;

                @media (max-width: 767px) {
                    font-size: 15px;
                    margin-bottom: 20px;
                }

                @media (max-width: 567px) {
                    font-size: 12px;
                }
            }

            .wb-view-btn {
                color: var(--wb-banner-button-border);
                background: var(--wb-view-button-bg);
                border: 1px solid var(--wb-banner-button-border);
                margin-top: 0px;
                border-radius: 10px;
                font-size: 16px;
                line-height: 19.2px;
                letter-spacing: 3px;

                @media (max-width: 767px) {
                    padding: 10px 25px;
                    font-size: 14px;
                    line-height: normal;
                }
            }
        }
    }

    // how we met
    .wb-brown-rust-how-we-met-wrp {
        padding: 100px 0 150px;
        position: relative;

        @media (max-width: 1440px) {
            padding: 80px 0 130px;
        }

        @media (max-width: 1366px) {
            padding: 70px 0 120px;
        }

        @media (max-width: 1199px) {
            padding: 60px 0 110px;
        }

        @media (max-width: 767px) {
            padding: 30px 0 30px;
        }

        .wb-cus-row {
            flex-wrap: nowrap;
            gap: 30px;
            align-items: center;
            margin: 0 -15px;

            @media (max-width: 1600px) {
                gap: 20px;
            }

            @media (max-width: 1440px) {
                gap: 15px;
            }

            @media (max-width: 1366px) {
                gap: 10px;
            }

            @media (max-width: 767px) {
                gap: 30px;
                flex-direction: column;
            }

            @media (max-width: 420px) {
                gap: 15px !important;
            }

            .wb-cus-col-3 {
                width: 100%;
                padding: 0 10px;
            }
        }

        .wb-brown-rust-how-we-met-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 9;
            position: relative;

            p {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 18px;
                font-weight: 400;
                line-height: 38px;
                text-align: center;

                @media (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 32px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }

        .how-we-met-divider {
            svg {
                width: 100%;
                margin-top: 40px;

                path {
                    fill: var(--wb-secondary-text-color);
                }
            }
        }

        .how-we-met-image {
            img {
                width: 440px !important;
                height: 540px !important;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                border-radius: 80px;

                @media (max-width: 1600px) {
                    width: 400px !important;
                    height: 500px !important;
                    border-radius: 75px;
                }

                @media (max-width: 1440px) {
                    width: 370px !important;
                    height: 470px !important;
                    border-radius: 70px;
                }

                @media (max-width: 1280px) {
                    width: 340px !important;
                    height: 440px !important;
                    border-radius: 45px;
                }

                @media (max-width: 1199px) {
                    width: 310px !important;
                    height: 410px !important;
                    border-radius: 60px;
                }

                @media (max-width: 992px) {
                    width: 250px !important;
                    height: 310px !important;
                    border-radius: 40px;
                }

                @media (max-width: 767px) {
                    width: 100% !important;
                    height: 300px !important;
                    border-radius: 25px;
                }
            }
        }

        .how-we-met-left-image,
        .how-we-met-right-image {
            @media (max-width: 767px) {
                width: fit-content;
                margin: 0 auto;
            }
        }

        .how-we-met-right-image {
            margin-top: 5rem;
            border: 2px dashed var(--wb-how-we-met-image-border-color);
            padding: 20px;
            border-radius: 100px;

            @media (max-width: 1600px) {
                border-radius: 90px;
            }

            @media (max-width: 1440px) {
                border-radius: 80px;
            }

            @media (max-width: 1366px) {
                border-radius: 70px;
            }

            @media (max-width: 1199px) {
                border-radius: 60px;
            }

            @media (max-width: 1024px) {
                border-radius: 50px;
            }

            @media (max-width: 992px) {
                border-radius: 40px;
                padding: 15px;
            }

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 15px;
                border-radius: 30px;
            }
        }

        .how-we-met-left-image {
            margin-bottom: 5rem;
            border: 2px dashed var(--wb-how-we-met-image-border-color);
            padding: 20px;
            border-radius: 100px;

            @media (max-width: 1440px) {
                margin-bottom: 4.5rem;
                border-radius: 90px;
            }

            @media (max-width: 1366px) {
                margin-bottom: 4rem;
                border-radius: 80px;
            }

            @media (max-width: 1199px) {
                margin-bottom: 3.5rem;
                border-radius: 70px;
            }

            @media (max-width: 992px) {
                margin-bottom: 3rem;
                border-radius: 40px;
                padding: 15px;
            }

            @media (max-width: 767px) {
                margin-bottom: 0.5rem;
                border: 2px dashed var(--wb-how-we-met-image-border-color);
                padding: 15px;
                border-radius: 50px;
            }
        }

        // top bottom bg
        .brown-rust-how-we-met-top-bg {
            position: absolute;
            top: -54%;
            margin: 0 auto;
            left: 42%;
            z-index: 0;
            right: unset;
            bottom: 0;
            height: auto;
            width: 300px;

            @media (max-width: 1600px) {
                top: -51%;
                left: 42%;
                width: 250px;
            }

            @media (max-width: 1440px) {
                top: -54%;
                left: 42%;
                width: 220px;
            }

            @media (max-width: 1366px) {
                top: -60%;
            }

            @media (max-width: 1199px) {
                top: -65%;
            }

            @media (max-width: 1024px) {
                top: -68%;
                left: 38%;
            }

            @media (max-width: 992px) {
                top: -73%;
            }

            @media (max-width: 850px) {
                top: -63%;
                width: 140px;
                left: 42%;
            }

            @media (max-width: 767px) {
                top: -37%;
                left: 41%;
            }

            @media (max-width: 580px) {
                left: 37%;
            }

            @media (max-width: 380px) {
                left: 30%;
            }

            svg {
                width: 100%;
                margin: 0 auto;

                path {
                    fill: var(--wb-how-we-met-bg-icon-color);
                }
            }
        }

        .brown-rust-how-we-met-bottom-bg {
            position: absolute;
            bottom: -60%;
            left: 0;
            right: 0;
            width: -moz-fit-content;
            width: 580px;
            margin: 0 auto;
            display: flex;

            @media (max-width: 1600px) {
                bottom: -58%;
                left: 0;
                right: 0;
                width: 400px;
            }

            @media (max-width: 1440px) {
                width: 380px;
                bottom: -60%;
            }

            @media (max-width: 1366px) {
                width: 330px;
                bottom: -61%;
            }

            @media (max-width: 1280px) {
                bottom: -63%;
            }

            @media (max-width: 1199px) {
                bottom: -67%;
            }

            @media (max-width: 1024px) {
                bottom: -70%;
            }

            @media (max-width: 992px) {
                width: 278px;
            }

            @media (max-width: 767px) {
                bottom: -42%;
            }

            svg {
                width: 100%;
                margin: 0 auto;

                path {
                    fill: var(--wb-how-we-met-bottom-bg-icon-color);
                }
            }
        }
    }

    // venue location
    .wb-brown-rust-venue-location-wrp {
        padding: 150px 0;
        z-index: 9;
        position: relative;
        background: var(--wb-venue-bg);


        @media (max-width: 1366px) {
            padding: 120px 0;
        }

        @media (max-width: 1199px) {
            padding: 80px 0;
        }

        @media (max-width: 992px) {
            padding: 50px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-container {
            position: relative;
        }

        .venue-bg-icon-top,
        .venue-bg-icon-bottom {
            svg {
                width: 100%;

                path {
                    fill: var(--wb-venue-rangoli-icon-color);
                }
            }
        }

        .venue-bg-icon-top {
            position: absolute;
            top: -85px;
            left: -86px;
            right: unset;
            width: 250px;

            @media (max-width: 1280px) {
                top: -85px;
                left: -34px;
                right: unset;
                width: 165px;
            }

            @media (max-width: 1199px) {
                left: 0px;
            }

            @media (max-width: 992px) {
                left: 5px;
                top: -130px;
            }

            @media (max-width: 850px) {
                left: 3px;
                top: -134px;
            }

            @media (max-width: 767px) {
                left: 32px;
                right: unset;
                width: 122px;
                top: -169px;
            }

            @media (max-width: 640px) {
                left: 0;
                right: unset;
                width: 122px;
                top: -200px;
            }
        }

        .venue-bg-icon-bottom {
            position: absolute;
            bottom: -104px;
            left: unset;
            right: -128px;
            width: 350px;

            @media (max-width: 1440px) {
                bottom: -160px;
                left: unset;
                right: -99px;
                width: 264px;
            }

            @media (max-width: 1366px) {
                bottom: -175px;
                left: unset;
                right: -70px;
            }

            @media (max-width: 1280px) {
                bottom: -157px;
                left: unset;
                right: -14px;
                width: 190px;
            }

            @media (max-width: 1199px) {
                right: 45px;
            }

            @media (max-width: 1024px) {
                right: 27px;
                bottom: -177px;
            }

            @media (max-width: 767px) {
                bottom: -208px;
                left: unset;
                right: 119px;
                width: 135px;
            }
        }

        .wb-cus-row {
            flex-wrap: nowrap;
            gap: 15px;
            align-items: center;
            margin: 0 -15px;

            @media (max-width: 1600px) {
                gap: 10px;
            }

            @media (max-width: 1440px) {
                gap: 5px;
            }

            @media (max-width: 992px) {
                gap: 0px;
            }

            @media (max-width: 767px) {
                gap: 25px !important;
                flex-direction: column;
            }

            @media (max-width: 420px) {
                gap: 15px !important;
            }
        }

        .wb-brown-rust-venue-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            right: 0;
            padding: 0 15px;

            @media (max-width: 1280px) {
                right: 25px;
            }

            @media (max-width: 1199px) {
                right: 105px;
            }

            @media (max-width: 1024px) {
                right: 56px;
            }

            @media (max-width: 767px) {
                position: unset;
                z-index: 9;
            }

            .wb-brown-rust-venue-info {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: var(--wb-venue-card-bg);
                border-radius: 100px;
                padding: 100px;
                max-width: 550px;
                width: 100%;
                max-height: 650px;
                height: 100%;

                @media (max-width: 1600px) {
                    padding: 80px;
                    max-width: 520px;
                    max-height: 560px;
                }

                @media (max-width: 1440px) {
                    padding: 70px;
                    max-width: 500px;
                    max-height: 500px;
                    border-radius: 80px;
                }

                @media (max-width: 1024px) {
                    padding: 56px;
                    max-width: 500px;
                    max-height: 465px;
                    border-radius: 70px;
                }

                @media (max-width: 767px) {
                    padding: 70px;
                    border-radius: 20px;
                }

                .wb-container {
                    padding: 0;
                }

                .wb-view-btn {
                    color: var(--wb-venue-btn-text-color);
                    background: var(--wb-venue-btn-bg);
                    font-family: var(--wb-secondaryFont);
                    margin-top: 50px;

                    @media (max-width: 1600px) {
                        margin-top: 38px;
                    }

                    @media (max-width: 767px) {
                        margin-top: 30px;
                    }

                    &:hover {
                        background-color: var(--wb-rsvp-btn-hover-bg);
                        transition: background-color 0.3s ease;
                    }
                }

                p {
                    color: var(--wb-venue-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 43px;
                    text-align: center;

                    @media (max-width: 1440px) {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                        text-align: center;
                    }
                }

                .venue-card-divider {
                    margin-top: 60px;

                    @media (max-width: 1600px) {
                        margin-top: 38px;
                    }

                    svg {
                        path {
                            fill: var(--wb-secondary-text-color);
                        }
                    }
                }
            }
        }

        .wb-brown-rust-image-wrap {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            padding: 0 15px;

            @media (max-width: 767px) {
                gap: 20px;
                justify-content: center;
                flex-direction: column;
            }

            .venue-left-image {
                border: 2px dashed var(--wb-venue-image-border-color);
                padding: 20px;
                border-radius: 100px;
                width: 550px;

                @media (max-width: 1600px) {
                    padding: 18px;
                    border-radius: 90px;
                }

                @media (max-width: 1440px) {
                    padding: 16px;
                    border-radius: 80px;
                    width: 465px;
                }

                @media (max-width: 1366px) {
                    padding: 14px;
                    border-radius: 70px;
                }

                @media (max-width: 1280px) {
                    padding: 12px;
                    border-radius: 60px;
                }

                @media (max-width: 1199px) {
                    padding: 10px;
                    border-radius: 50px;
                    margin-left: 35px;
                    width: 390px;
                }

                @media (max-width: 992px) {
                    padding: 8px;
                    border-radius: 40px;
                    width: 365px;
                }

                @media (max-width: 850px) {
                    padding: 8px;
                    border-radius: 40px;
                    width: 360px;
                }

                @media (max-width: 767px) {
                    margin-left: 0px;
                    width: 300px;
                }

                img {
                    width: 500px !important;
                    height: 600px !important;
                    max-width: 100% !important;
                    max-height: 100% !important;
                    object-fit: cover;
                    border-radius: 86px;

                    @media (max-width: 1600px) {
                        height: 480px !important;
                        border-radius: 80px;
                    }

                    @media (max-width: 1440px) {
                        height: 440px !important;
                        border-radius: 75px;
                    }

                    @media (max-width: 1366px) {
                        height: 400px !important;
                        border-radius: 70px;
                    }

                    @media (max-width: 1280px) {
                        height: 400px !important;
                        border-radius: 65px;
                    }

                    @media (max-width: 1199px) {
                        height: 400px !important;
                        border-radius: 60px;
                    }

                    @media (max-width: 992px) {
                        height: 400px !important;
                        border-radius: 55px;
                    }

                    @media (max-width: 767px) {
                        border-radius: 30px;
                    }
                }
            }

            .venue-right-image {
                position: relative;
                margin-left: -120px;
                border: 2px dashed var(--wb-venue-image-border-color);
                padding: 20px;
                border-radius: 100px;
                width: 600px;

                @media (max-width: 1600px) {
                    padding: 18px;
                    border-radius: 90px;
                }

                @media (max-width: 1440px) {
                    padding: 16px;
                    border-radius: 80px;
                    width: 480px;
                    margin-left: -80px;
                }

                @media (max-width: 1366px) {
                    padding: 14px;
                    border-radius: 70px;
                    width: 450px;
                }

                @media (max-width: 1280px) {
                    padding: 12px;
                    border-radius: 60px;
                }

                @media (max-width: 1199px) {
                    padding: 10px;
                    border-radius: 50px;
                }

                @media (max-width: 1024px) {
                    width: 395px;
                }

                @media (max-width: 992px) {
                    padding: 8px;
                    border-radius: 40px;
                }

                @media (max-width: 850px) {
                    margin-left: -142px;
                    width: 390px;
                }

                @media (max-width: 767px) {
                    margin-left: 0;
                    width: 300px;
                    border-radius: 30px;
                }

                img {
                    width: 612px !important;
                    height: 760px !important;
                    max-width: 100% !important;
                    max-height: 100% !important;
                    object-fit: cover;
                    border-radius: 86px;

                    @media (max-width: 1600px) {
                        height: 640px !important;
                        border-radius: 80px;
                    }

                    @media (max-width: 1440px) {
                        height: 570px !important;
                        border-radius: 75px;
                    }

                    @media (max-width: 1366px) {
                        height: 550px !important;
                        border-radius: 70px;
                    }

                    @media (max-width: 1280px) {
                        height: 600px !important;
                        border-radius: 65px;
                    }

                    @media (max-width: 1199px) {
                        height: 580px !important;
                        border-radius: 60px;
                    }

                    @media (max-width: 992px) {
                        height: 540px !important;
                        border-radius: 55px;
                    }

                    @media (max-width: 992px) {
                        height: 400px !important;
                        border-radius: 30px;
                    }
                }
            }
        }
    }
      // sub banner
    .wb-sub-banner {
        img {
            max-width: 100% !important;
            width: 100% !important;
            max-height: 576px !important;
            height: 100% 1 !important;
            object-fit: cover !important;
            @media (max-width:767px) {
                height: auto !important;

            }
        }

    }
    // countdown
    .indo-western-count-down-wrp {
        padding: 50px 0;

        @media (max-width: 1024px) {
            padding: 40px 0;
        }
        @media (max-width: 767px) {
            padding: 30px 0;
        }
        .wb-cus-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;

            .indo-western-count-down-content {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 576px) {
                    width: calc(100% / 2);
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-belleniaFont);
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 53.52px;
                    text-transform: capitalize;

                    @media (max-width: 1366px) {
                        font-size: 30px;
                        line-height: normal;
                    }

                    @media (max-width: 767px) {
                        font-size: 25px;
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 58px;

                    @media (max-width: 1366px) {
                        font-size: 45px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 40px;
                    }

                    @media (max-width: 992px) {
                        font-size: 35px;
                    }
                }

                .countdown-divider {
                    svg {
                        width: 70%;

                        path {
                            fill: var(--wb-secondary-text-color);
                        }
                    }
                }
            }
        }
    }




    // faq accordion
    .brown-rust-expandable-faq-section {
        position: relative;

        .indo-western-common-head {
            h3 {
                color: var(--wb-faq-common-head-text-color);
            }

            h6 {
                color: var(--wb-faq-common-head-subtext-color);
            }

            .common-head-divider {
                svg {
                    path {
                        fill: var(--wb-faq-common-head-divider-color);
                    }
                }
            }
        }

        .wb-rangoli-bg-icon {
            overflow: hidden;

            svg {
                width: 170px;
                height: auto;

                path {
                    fill: var(--wb-faq-rangoli-icon-color);
                }

                @media (max-width: 767px) {
                    width: 90px;
                }
            }
        }

        .indo-western-faq-bg-icon-top {
            position: absolute;
            top: 150px;
            right: 0;
            transform: translateY(-50%);
            width: 90px;
            height: auto;
            z-index: 9;

            @media (max-width: 767px) {
                width: 90px;
                top: 74px;
            }
        }

        .indo-western-faq-bg-icon-bottom {
            position: absolute;
            bottom: 80px;
            left: 0;
            transform: rotate(180deg);
            width: 90px;
            z-index: 9;

            @media (max-width: 767px) {
                bottom: 0;
                width: 90px;
                left: -40px;
            }
        }

        .wb-expandable-faq-wrp {
            background-color: var(--wb-accordion-bg);
            padding: 90px 0 100px;
            position: relative;

            @media (max-width: 1440px) {
                padding: 70px 0 80px;
            }

            @media (max-width: 1199px) {
                padding: 50px 0 60px;
            }

            @media (max-width: 992px) {
                padding: 30px 0 40px;
            }

            @media (max-width: 767px) {
                padding: 20px 0 30px;
            }

            .wb-accordion-wrp {
                padding: 50px 0;

                @media (max-width: 1440px) {
                    padding: 40px 0;
                }


                @media (max-width: 1199px) {
                    padding: 30px 0;
                }

                @media (max-width: 767px) {
                    padding: 20px 0;
                }

                .wb-accordion {
                    margin-bottom: 0;
                    padding: 50px;
                    border-radius: 40px;
                    border-bottom: 1px solid var(--wb-accordion-border-color);
                    border-left: 1px solid var(--wb-accordion-border-color);
                    border-right: 1px solid var(--wb-accordion-border-color);

                    @media (max-width: 1366px) {
                        padding: 30px;
                        border-radius: 30px;
                    }

                    @media (max-width: 992px) {
                        padding: 20px;
                        border-radius: 20px;
                    }

                    &:nth-child(1) {
                        border-top: 1px solid var(--wb-accordion-border-color);
                    }

                    .wb-accordion-header {
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h3 {
                            font-size: 30px;
                            color: var(--wb-accordion-title-color);
                            font-weight: 400;
                            line-height: normal;
                            margin: 30px 0;
                            font-family: var(--wb-primaryFont);
                            @media (max-width: 1440px) {
                                font-size: 26px;
                                margin: 26px 0;
                            }

                            @media (max-width: 1199px) {
                                font-size: 22px;
                                margin: 22px 0;
                            }

                            @media (max-width: 992px) {
                                font-size: 20px;
                                margin: 20px 0;
                            }

                            @media (max-width: 767px) {
                                font-size: 15px;
                                margin: 18px 0;
                            }
                        }

                        .wb-accordion-icon {
                            transition: transform 0.3s ease-in-out;

                            &.open {
                                transform: rotateX(180deg);
                            }

                            svg {
                                width: auto;
                                margin-right: 0;

                                @media (max-width: 767px) {
                                    width: 30px;
                                }

                                @media (max-width: 420px) {
                                    width: 25px;
                                }

                                path {
                                    fill: var(--wb-accordion-icon-color);
                                }
                            }
                        }
                    }

                    .wb-accordion-content {
                        max-height: 0;
                        overflow: hidden;

                        p {
                            color: var(--wb-accordion-text-color);
                            font-size: 18px;
                            font-weight: 300;
                            line-height: 38px;
                            font-family: var(--wb-secondaryFont);

                            @media (max-width: 1440px) {
                                font-size: 16px;
                                line-height: 34px;
                            }

                            @media (max-width: 767px) {
                                font-size: 14px;
                                line-height: 26px;
                            }
                        }

                        &.open {
                            max-height: 100%;
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
    }

    // rsvp
    .indo-western-rsvp-form {
        position: relative;

        .indo-western-rsvp-form-wrp {
            padding: 100px 0;
            background-image: url("../../public/images/wedding-websites/brown-rust-rsvp-bg.png");
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            min-height: 526px;
            margin-top: 10rem;
            height: 100%;

            @media (max-width: 1600px) {
                padding: 90px 0;
            }

            @media (max-width: 1440px) {
                padding: 80px 0;
                min-height: 480px;
            }

            @media (max-width: 1366px) {
                padding: 70px 0;
                min-height: 450px;
            }

            @media (max-width: 1199px) {
                padding: 60px 0;
                min-height: 400px;
            }

            @media (max-width: 992px) {
                padding: 50px 0;
                min-height: 355px;
            }

            @media (max-width: 767px) {
                padding: 40px 0;
            }

            .indo-western-rsvp-form-title {
                text-align: center;
                flex-direction: column;
                display: flex;
                margin-bottom: 20px;

                h3 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    text-align: center;

                    @media (max-width: 1440px) {
                        font-size: 40px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 35px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 32px;
                    }

                    @media (max-width: 992px) {
                        font-size: 30px;
                    }

                    @media (max-width: 420px) {
                        font-size: 28px;
                    }
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 400;
                    line-height: 35px;
                    text-align: center;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }
            }

            .indo-western-rsvp {
                background: var(--wb-rsvp-bg);
                padding: 60px;
                max-width: 650px;
                margin: 0 auto;
                border-radius: 60px;
                margin-top: -17rem;

                @media (max-width: 1600px) {
                    padding: 55px;
                    max-width: 600px;
                    margin-top: -13.5rem;
                }

                @media (max-width: 1440px) {
                    padding: 50px;
                    max-width: 550px;
                    border-radius: 50px;
                    margin-top: -10rem;
                }

                @media (max-width: 1366px) {
                    padding: 45px;
                    max-width: 500px;
                    margin-top: -9rem;
                }

                @media (max-width: 1199px) {
                    padding: 40px;
                    max-width: 450px;
                    border-radius: 40px;
                    margin-top: -7rem;
                }

                @media (max-width: 992px) {
                    padding: 35px;
                    max-width: 400px;
                    margin-top: -9.5rem;
                }

                @media (max-width: 767px) {
                    padding: 30px;
                    max-width: 350px;
                    border-radius: 25px;
                }

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    margin-top: 50px;
                }

                .wb-cus-input {
                    background-color: transparent;
                    border: 1px solid #e7e7e7;
                    border-radius: 0px;
                    padding: 10px 16px;
                    margin-bottom: 10px;
                    color: var(--wb-primary-text-color);
                    font-weight: 400;
                    font-size: 12px;
                    width: 80%;
                    height: 40px;
                    outline: none;
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    &::placeholder {
                        color: var(--wb-input-placeholder-color) !important;
                        font-family: var(--wb-secondaryFont);
                        line-height: 15.12px;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }

                .wb-view-btn {
                    width: fit-content;
                    text-transform: uppercase;
                    background-color: var(--wb-rsvp-btn-bg);
                    color: var(--wb-rsvp-btn-text-color);
                    font-family: var(--wb-secondaryFont);
                    margin: 30px 0;

                    &:hover {
                        background-color: var(--wb-rsvp-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-rsvp-btn-hover-text-color);
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                        margin: 20px 0;
                    }

                    @media (max-width: 560px) {
                        padding: 10px;
                        margin: 15px 0;
                    }
                }
            }
        }
    }

    // image carousel
    .brown-rust-image-carousel {
        padding-top: 100px;
    }

    //footer name
    .brown-rust-footer-couple-name {
        padding: 40px 0;
        @media (max-width:767px) {
            padding: 30px 0;
        }
        .brown-dust-divider {
            svg {
                width: 100%;
                margin: 0 auto;

                path {
                    fill: var(--wb-footer-divider-color);
                }
            }
        }

        .wb-footer-couple-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h5 {
                font-size: 60px;
                font-weight: 400;
                line-height: 69.23px;
                color: var(--wb-footer-text-color);
                font-family: var(--wb-primaryFont);
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: baseline;
                gap: 15px;
                text-transform: lowercase;


                @media (max-width: 1440px) {
                    font-size: 45px;
                    line-height: 51.92px;
                }

                @media (max-width: 1199px) {
                    font-size: 40px;
                    line-height: 42.15px;
                }

                @media (max-width: 767px) {
                    font-size: 35px;
                    line-height: 34.61px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    line-height: 68.12px;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }

            }
            h6{
                display: none;
            }
            .wb-divider {
                display: none;
            }
        }
    }

    .brown-rust-image-carousel-section {
        padding: 100px 0;

        @media (max-width: 1600px) {
            padding: 90px 0;
        }

        @media (max-width: 1440px) {
            padding: 80px 0;
        }

        @media (max-width: 1366px) {
            padding: 70px 0;
        }

        @media (max-width: 1280px) {
            padding: 60px 0;
        }

        @media (max-width: 1199px) {
            padding: 50px 0;
        }

        @media (max-width: 992px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .swiper-slide-prev,
        .swiper-slide-duplicate-next {
            @media (max-width: 767px) {
                margin-right: 0 !important;
            }
        }

        .wb-container {
            position: relative;

            @media (max-width: 1199px) {
                padding: 0 70px;
            }
        }

        .full-image-carousel {
            margin: 0;
            padding: 0px;
        }

        .indo-western-carousel-bg-icon-left,
        .indo-western-carousel-bg-icon-right {
            position: absolute;
            top: 13%;
            width: 250px;

            @media (max-width: 1600px) {
                top: 3%;
                width: 195px;
            }

            @media (max-width: 1366px) {
                top: -5%;
                width: 150px;
            }

            @media (max-width: 1280px) {
                top: -8%;
                width: 135px;
            }

            @media (max-width: 1199px) {
                top: -20%;
                width: 134px;
            }

            @media (max-width: 767px) {
                top: -38%;
            }
        }

        .indo-western-carousel-bg-icon-left {
            left: -8%;

            @media (max-width: 1600px) {
                left: -6%;
            }

            @media (max-width: 1366px) {
                left: -5%;
            }

            @media (max-width: 1280px) {
                left: -2%;
            }

            @media (max-width: 1199px) {
                left: 1%;
            }
        }

        .indo-western-carousel-bg-icon-right {
            right: -7%;

            @media (max-width: 1600px) {
                right: -5.5%;
            }

            @media (max-width: 1366px) {
                right: -4%;
            }

            @media (max-width: 1280px) {
                right: -2%;
            }

            @media (max-width: 1199px) {
                right: 1%;
            }
        }

        .wb-rangoli-bg-icon {
            overflow: hidden;

            svg {
                width: 100%;
            }
        }

        .brown-rust-image-carousel {
            .swiper {
                border-radius: 60px;

                @media (max-width: 1440px) {
                    border-radius: 40px;
                }

                @media (max-width: 1024px) {
                    border-radius: 35px;
                }

                @media (max-width: 992px) {
                    border-radius: 30px;
                }

                @media (max-width: 767px) {
                    border-radius: 25px;
                }
            }

            img {
                width: 100% !important;
                height: 662px !important;
                max-width: 100% !important;
                min-height: 100% !important;
                object-fit: cover;

                @media (max-width: 1600px) {
                    height: 520px !important;
                }

                @media (max-width: 1366px) {
                    height: 430px !important;
                }


                @media (max-width: 1199px) {
                    height: 340px !important;
                }

                @media (max-width: 767px) {
                    height: auto !important;
                    min-height: 250px !important;
                }
            }
        }
    }

    // full image footer
    .full-image-carousel {
        margin: 0 40px;
        padding-bottom: 40px;
        @media (max-width: 767px) {
            margin: 0 20px;
            padding-bottom: 30px;
        }

        .swiper {
            border-radius: 60px;

            @media (max-width: 1440px) {
                border-radius: 40px;
            }

            @media (max-width: 1024px) {
                border-radius: 35px;
            }

            @media (max-width: 992px) {
                border-radius: 30px;
            }

            @media (max-width: 767px) {
                border-radius: 25px;
            }
        }

        img {
            width: 100% !important;
            height: 313px !important;
            max-width: 100% !important;
            min-height: 100% !important;
            object-fit: cover;

            @media (max-width: 1600px) {
                height: 290px !important;
            }

            @media (max-width: 1366px) {
                height: 250px !important;
            }

            @media (max-width: 767px) {
                height: auto !important;
                min-height: 270px !important;
            }
        }
    }

    // registry
    .brown-rust-registry-section {
        position: relative;

        // left right bg
        .brown-rust-registry-bg-icon-wrp {
            .wb-rangoli-bg-icon {
                overflow: hidden;

                svg {
                    width: 300px;
                    margin: 0 auto;
                    height: auto;

                    @media (max-width: 767px) {
                        width: 265px;
                    }

                    path {
                        fill: var(--wb-how-we-met-bg-icon-color);
                    }
                }
            }

            .brown-rust-registry-bg-right-icon,
            .brown-rust-registry-bg-left-icon {
                width: 77px;
                height: auto;

                @media (max-width: 767px) {
                    width: 50px;
                }
            }

            .brown-rust-registry-bg-left-icon {
                position: absolute;
                top: unset;
                left: 0;
                transform: rotate(180deg);
            }

            .brown-rust-registry-bg-right-icon {
                position: absolute;
                top: unset;
                right: 0px;
                transform: translateY(0%);
                bottom: unset;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

    .wb-registry-card-wrp {
        padding-bottom: 150px;
        @media (max-width: 1600px) {
            padding-bottom: 120px;
        }

        @media (max-width: 1440px) {
            padding-bottom: 100px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 70px;
        }

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }

        .wb-cus-row {
            row-gap: 30px;
        }

        .wb-col-3 {
            padding: 0 15px;
            flex: 0 0 auto;
            width: calc(100% / 4);

            @media (max-width: 1199px) {
                width: calc(100% / 3);
            }
            @media (max-width: 767px) {
                width: calc(100% / 2);
            }
            @media (max-width: 567px) {
                width: 100%;
            }
        }

        .wb-registry-card-item {
            border-radius: 50px;
            border: 1px solid var(--Wb-registry-card-border);
            background: var(--Wb-registry-card-bg);
            padding: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            @media (max-width: 1024px) {
                padding: 20px;
            }

            .wb-registry-card-img {
                text-align: center;
                border-radius: 15px;
                margin-bottom: 10px;
                img {
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            .wb-view-btn {
                width: 100%;
                background-color: var(--wb-registry-btn-bg);
                color: var(--wb-registry-btn-text-color);

                &:hover {
                    background-color: var(--wb-registry-btn-hover-bg);
                    transition: background-color 0.3s ease;
                    color: var(--wb-registry-btn-hover-text-color);
                }
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-weight: 400;
                font-size: 25px;
                line-height: 35px;

                @media (max-width: 1600px) {
                    font-size: 23px;
                    line-height: 33px;
                }

                @media (max-width: 1440px) {
                    font-size: 21px;
                    line-height: 31px;
                }

                @media (max-width: 1199px) {
                    font-size: 19px;
                    line-height: 29px;
                }

                @media (max-width: 767px) {
                    font-size: 17px;
                    line-height: 27px;
                }
            }

            h3 {
                margin: 20px 0;
                color: var(--wb-primary-text-color);
                font-family: var(--wb-secondaryFont);
                font-weight: 400;
                font-size: 28px;

                @media (max-width: 1199px) {
                    font-size: 25px;
                }

                @media (max-width: 992px) {
                    font-size: 22px;
                }

                @media (max-width: 767px) {
                    font-size: 18px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 20px;


                @media (max-width: 1440px) {
                    font-size: 14px;
                    line-height: 26px;
                }

            }
        }
    }

    // password access
    .wb-password-access-form-wrp {
        padding: 60px;
        background: var(--wb-rsvp-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        @media (max-width:1366px) {
            padding: 50px;
        }

        @media (max-width:767px) {
            padding: 30px;
        }

        .wb-access-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: var(--wb-rsvp-text-color);
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width:1440px) {
                    font-size: 35px;
                    line-height: normal;

                }

                @media (max-width:1366px) {
                    font-size: 32px;
                }

                @media (max-width:1280px) {
                    font-size: 30px;
                }

                @media (max-width:992px) {
                    font-size: 24px;

                }
            }

        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin: 40px 0;

            @media (max-width:1366px) {
                margin: 30px 0;
            }

            @media (max-width:767px) {
                margin: 25px 0;
            }

            .wb-cus-input {
                background-color: #fff;
                height: 40px;
                padding: 20px;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                border: 1px solid #cfcfcf;

                @media (max-width:767px) {
                    width: 100%;

                }

            }

            .wb-view-btn {
                color: var(--wb-rsvp-btn-text-color);
                background: var(--wb-rsvp-btn-bg);

                &:hover {
                    color: var(--wb-rsvp-btn-hover-text-color);
                    background: var(--wb-rsvp-btn-hover-bg);
                }

            }
        }
    }

    // things to do
    .brown-rust-things-to-do-section {
        padding: 50px 0 150px;

        @media (max-width: 1366px) {
            padding: 40px 0 120px;
        }

        @media (max-width: 1199px) {
            padding: 40px 0 100px ;
        }

        @media (max-width: 992px) {
            padding: 40px 0  80px;
        }

        @media (max-width: 767px) {
            padding: 30px 0 30px;
        }

        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1024px) {
                gap: 30px 0;
            }

        .wb-activity-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            width: calc(100% / 3);
            padding: 0 15px;

            @media (max-width:767px) {
                width: 100%;

            }
            .wb-activity-image{
                width: 100%;
            }
            img {
                width: 100%;
                max-width: 100% !important;
                height: 380px !important;
                object-fit: cover;
                border-radius: 60px;

                @media (max-width: 1366px) {
                    border-radius: 50px;
                }

                @media (max-width: 992px) {
                    border-radius: 40px;
                }

                @media (max-width:767px) {
                    border-radius: 30px;
                    min-height: 200px !important;
                    height: auto !important;
                    aspect-ratio: 1;

                }
            }

            h4 {
                color: var(--wb-things-to-do-card-title-color);
                font-family: var(--wb-primaryFont);
                font-size: 25px;
                font-weight: 400;
                line-height: 34.65px;
                padding-bottom: 10px;
                margin-top: 20px;


                @media (max-width: 1024px) {
                    font-size: 20px;
                    line-height: 25.65px;
                }


            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                padding-bottom: 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 35px;

                @media (max-width: 1366px) {
                    font-size: 16px;
                    line-height: 28px;
                }

                @media (max-width: 1024px) {
                    line-height: 28px;
                    padding-bottom: 10px;
                }
            }

            h5 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width: 1366px) {
                    font-size: 14px;
                }

                @media (max-width: 1024px) {
                    line-height: 25px;
                }
            }

            p {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                padding-bottom: 20px;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width: 1366px) {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            .wb-view-btn {
                margin-top: 20px;
                background: var(--Wb-things-to-do-button-bg);
                color: var(--wb-things-to-do-button-text-color);

                &:hover {
                    background: var(--Wb-things-to-do-button-hover-bg);
                    color: var(--wb-things-to-do-button-hover-text-color);
                }
            }
        }
      }
    }

    // common style

    //wedding party card (person card)
    .brown-rust-wedding-party-section {
        position: relative;
        padding: 90px 0 150px;

        @media (max-width: 1440px) {
            padding: 70px 0 130px;
        }

        @media (max-width: 1280px) {
            padding: 40px 0 100px;
        }

        @media (max-width: 992px) {
            padding: 30px 0 90px;
        }

        @media (max-width: 767px) {
            padding: 30px 0 30px;
        }

        // left right bg
        .brown-rust-person-card-bg-icon-wrp {

            .brown-rust-person-card-bg-left-icon,
            .brown-rust-person-card-bg-right-icon {
                width: 85px;

                @media (max-width: 1366px) {
                    width: 80px;
                }

                @media (max-width: 767px) {
                    width: 50px;
                }

                .wb-rangoli-bg-icon {
                    overflow: hidden;

                    svg {
                        width: 265px;
                        height: auto;

                        @media (max-width: 1366px) {
                            width: 220px;
                        }

                        @media (max-width: 767px) {
                            width: 120px;
                        }

                        path {
                            fill: var(--wb-person-view-rangoli-icon-color);
                        }
                    }
                }
            }

            .brown-rust-person-card-bg-left-icon {
                position: absolute;
                top: 50%;
                left: 0;
                transform: rotate(180deg);
            }

            .brown-rust-person-card-bg-right-icon {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(0%);
                display: flex;
            }
        }

        .person-view-card {
            margin: 0;
            position: relative;

            .wb-cus-row {
                row-gap: 60px;
                justify-content: center;

                @media (max-width: 1440px) {
                    row-gap: 50px;
                }

                @media (max-width: 1366px) {
                    row-gap: 45px;
                }

                @media (max-width: 1199px) {
                    row-gap: 40px;
                }

                @media (max-width: 992px) {
                    row-gap: 35px;
                }
            }

            .person-view-card-item {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 2);
                }

                img {
                    width: 100%;
                    max-width: 200px !important;
                    height: 300px !important;
                    object-fit: cover;
                    border-radius: 40px;

                    @media (max-width: 1024px) {
                        height: 185px !important;
                    }

                    @media (max-width: 992px) {
                        height: 200px !important;
                        max-width: 150px !important;
                        border-radius: 30px;
                    }

                    @media (max-width: 767px) {
                        height: 185px !important;
                        max-width: 145px !important;
                        border-radius: 20px;
                    }
                    @media (max-width: 576px) {
                        height: auto !important;
                    }
                }

                h4 {
                    color: var(--wb-card-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 35px;
                    margin: 10px 0 5px;

                    @media (max-width: 1600px) {
                        font-size: 24px;
                        line-height: 33px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 22px;
                        line-height: 31px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 20px;
                        line-height: 29px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 27px;
                    }

                    @media (max-width: 992px) {
                        line-height: 25px;
                    }

                    @media (max-width: 767px) {
                        line-height: 23px;
                    }
                }

                h6 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 1600px) {
                        font-size: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                p {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 8px;
                }
            }
        }
    }

    // (hotel view)
    .brown-rust-hotel-section {
        position: relative;
        padding-top: 90px;

        @media (max-width: 1600px) {
            padding-top: 80px;
        }

        @media (max-width: 1440px) {
            padding-top: 70px;
        }

        @media (max-width: 1366px) {
            padding-top: 60px;
        }

        @media (max-width: 1199px) {
            padding-top: 50px;
        }

        @media (max-width: 1024px) {
            padding-top: 40px;
        }

        @media (max-width: 992px) {
            padding-top: 30px;
        }


        .indo-western-hotel-view-divider {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 34%;
            width: fit-content;
            margin: 0 auto;

            @media (max-width: 767px) {
                top: 46%;
            }

            svg {
                width: 100%;

                path {
                    fill: var(--wb-rangoli-icon-color);
                }
            }
        }

        .single-hotel-transport-wrp {
            position: relative;

            .wb-cus-row {
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 150px;

                @media (max-width: 767px) {
                    gap: 130px;
                    flex-direction: column;
                }
            }

            .wb-hotel-view {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 40px 0;
                padding: 0 15px;
                width: 100%;
                gap: 20px;

                @media (max-width: 1600px) {
                    gap: 45px;
                }

                @media (max-width: 1440px) {
                    gap: 40px;
                }

                @media (max-width: 1366px) {
                    gap: 35px;
                }

                @media (max-width: 1280px) {
                    gap: 30px;
                }

                @media (max-width: 1199px) {
                    gap: 25px;
                }

                @media (max-width: 992px) {
                    gap: 20px;
                }

                @media (max-width: 850px) {
                    gap: 15px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    flex-direction: column;
                    margin: 0px 0;
                }

                .wb-hotel-image {
                    width: 100%;
                    border: 2px dashed var(--wb-how-we-met-image-border-color);
                    padding: 20px;
                    border-radius: 100px;

                    @media (max-width: 1600px) {
                        border-radius: 90px;
                    }

                    @media (max-width: 1440px) {
                        border-radius: 80px;
                    }

                    @media (max-width: 1366px) {
                        border-radius: 70px;
                    }

                    @media (max-width: 1199px) {
                        border-radius: 60px;
                    }

                    @media (max-width: 1024px) {
                        border-radius: 50px;
                    }

                    @media (max-width: 992px) {
                        border-radius: 40px;
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                        border-radius: 30px;
                    }

                    img {
                        width: 100%;
                        min-height: 390px !important;
                        min-width: 100% !important;
                        object-fit: cover;
                        border-radius: 60px;

                        @media (max-width: 1600px) {
                            border-radius: 55px;
                            min-height: 400px !important;
                        }

                        @media (max-width: 1440px) {
                            border-radius: 50px;
                            min-height: 370px !important;
                        }

                        @media (max-width: 1366px) {
                            border-radius: 45px;
                            min-height: 340px !important;
                        }

                        @media (max-width: 1280px) {
                            border-radius: 40px;
                            min-height: 310px !important;
                        }

                        @media (max-width: 1199px) {
                            border-radius: 35px;
                            min-height: 280px !important;
                        }

                        @media (max-width: 992px) {
                            border-radius: 30px;
                            min-height: 250px !important;
                        }

                        @media (max-width: 850px) {
                            border-radius: 25px;
                        }

                        @media (max-width: 767px) {
                            border-radius: 20px;
                            max-height: auto !important;
                        }
                    }
                }

                .wb-hotel-content {
                    padding: 0 15px;
                    width: 100%;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    h6 {
                        font-size: 16px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        font-weight: 400;
                        line-height: 35px;
                        text-align: center;

                        @media (max-width: 1600px) {
                            line-height: 34px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 15px;
                            line-height: 33px;
                        }

                        @media (max-width: 1366px) {
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 31px;
                        }

                        @media (max-width: 992px) {
                            line-height: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 29px;
                        }
                    }

                    h4 {
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 49px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-primaryFont);
                        margin: 30px 0;
                        margin-bottom: 0;

                        @media (max-width: 1600px) {
                            font-size: 23px;
                            line-height: 47px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 21px;
                            line-height: 45px;
                        }

                        @media (max-width: 1366px) {
                            line-height: 43px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 41px;
                        }

                        @media (max-width: 992px) {
                            line-height: 35px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                            line-height: 28px;
                            margin: 10px 0;
                        }
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        text-align: center;

                        @media (max-width: 1600px) {
                            font-size: 17px;
                            line-height: 36px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 16px;
                            line-height: 34px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 15px;
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 30px;
                        }

                        @media (max-width: 992px) {
                            line-height: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }

                    .wb-view-btn {
                        margin-top: 30px;
                        color: var(--Wb-travel-button-text-color);
                        background-color: var(--Wb-travel-button-bg);
                        padding: 20px;

                        &:hover {
                            background: var(--wb-view-button-hover-bg);
                            color: var(--wb-view-button-hover-text);
                            transition: background-color 0.3s ease;
                        }
                    }
                }
            }
        }
    }

    // travel
    .brown-rust-transport-section {
        position: relative;

        .indo-western-transport-view-divider {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 25%;
            width: fit-content;
            margin: 0 auto;

            @media (max-width: 767px) {
                top: 47%;
            }

            svg {
                path {
                    fill: var(--wb-rangoli-icon-color);
                }
            }
        }

        .single-hotel-transport-wrp {
            position: relative;

            .wb-cus-row {
                flex-direction: row;
                gap: 150px;
                flex-wrap: nowrap;

                @media (max-width: 1200px) {
                    gap: 100px;
                }

                @media (max-width: 992px) {
                    gap: 75px;
                }

                @media (max-width: 767px) {
                    gap: 50px;
                    flex-direction: column;
                }

                @media (max-width: 576px) {
                    gap: 25px;
                }
            }

            .wb-transport-view {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 40px 0;
                padding: 0 15px;
                width: calc(100% / 2);

                @media (max-width: 767px) {
                    margin: 35px 0;
                    width: 100%;
                    flex-direction: column;
                }

                .wb-transport-image {
                    width: 100%;
                    border: 2px dashed var(--wb-how-we-met-image-border-color);
                    padding: 20px;
                    border-radius: 100px;

                    @media (max-width: 1600px) {
                        border-radius: 90px;
                    }

                    @media (max-width: 1440px) {
                        border-radius: 80px;
                    }

                    @media (max-width: 1366px) {
                        border-radius: 70px;
                    }

                    @media (max-width: 1199px) {
                        border-radius: 60px;
                    }

                    @media (max-width: 1024px) {
                        border-radius: 50px;
                    }

                    @media (max-width: 992px) {
                        border-radius: 40px;
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                        border-radius: 30px;
                    }

                    img {
                        width: 100%;
                        min-height: 390px !important;
                        min-width: 100% !important;
                        object-fit: cover;
                        border-radius: 60px;

                        @media (max-width: 1600px) {
                            border-radius: 55px;
                            min-height: 400px !important;
                        }

                        @media (max-width: 1440px) {
                            border-radius: 50px;
                            min-height: 370px !important;
                        }

                        @media (max-width: 1366px) {
                            border-radius: 45px;
                            min-height: 340px !important;
                        }

                        @media (max-width: 1280px) {
                            border-radius: 40px;
                            min-height: 310px !important;
                        }

                        @media (max-width: 1199px) {
                            border-radius: 35px;
                            min-height: 280px !important;
                        }

                        @media (max-width: 992px) {
                            border-radius: 30px;
                            min-height: 250px !important;
                        }

                        @media (max-width: 850px) {
                            border-radius: 25px;
                        }

                        @media (max-width: 767px) {
                            max-height: auto !important;
                            border-radius: 20px;
                        }
                    }
                }

                .wb-transport-content {
                    padding: 0 15px;
                    width: 100%;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    h6 {
                        font-size: 16px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        line-height: 35px;
                        text-align: center;

                        @media (max-width: 1600px) {
                            font-size: 15px;
                            line-height: 34px;
                        }

                        @media (max-width: 1440px) {
                            line-height: 33px;
                        }

                        @media (max-width: 1366px) {
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 31px;
                        }

                        @media (max-width: 992px) {
                            line-height: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    h4 {
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 49px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-primaryFont);
                        margin: 30px 0;
                        margin-bottom: 0;

                        @media (max-width: 1600px) {
                            font-size: 24px;
                            line-height: 47px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 23px;
                            line-height: 45px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 22px;
                            line-height: 43px;
                        }

                        @media (max-width: 1199px) {
                            line-height: 41px;
                        }

                        @media (max-width: 992px) {
                            line-height: 35px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                            line-height: 37px;
                            margin: 10px 0;
                        }
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        color: var(--wb-sub-content-color);
                        font-family: var(--wb-secondaryFont);
                        text-align: center;

                        @media (max-width: 1600px) {
                            font-size: 17px;
                            line-height: 36px;
                        }

                        @media (max-width: 1440px) {
                            font-size: 16px;
                            line-height: 34px;
                        }

                        @media (max-width: 1366px) {
                            font-size: 15px;
                            line-height: 32px;
                        }

                        @media (max-width: 1199px) {
                            font-size: 14px;
                            line-height: 30px;
                        }

                        @media (max-width: 992px) {
                            font-size: 13px;
                            line-height: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 12px;
                            line-height: 26px;
                        }
                    }

                    .wb-view-btn {
                        margin-top: 30px;
                        color: var(--Wb-travel-button-text-color);
                        background-color: var(--Wb-travel-button-bg);
                        padding: 20px;

                        &:hover {
                            background: var(--wb-view-button-hover-bg);
                            color: var(--wb-view-button-hover-text);
                            transition: background-color 0.3s ease;
                        }
                    }
                }
            }

            .wb-divider {
                display: block;
            }
        }
    }

    // travel


    // title view
    .wb-large-tile {
        h2 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }

        }

    }

    .wb-small-tile {
        h4 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-belleniaFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }


    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family:var( --wb-primaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-primary-text-color);
            margin-bottom: 20px;
            text-align: center;
            text-transform: capitalize;
        }

        p,
        .quil-text-block {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width:767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;

        }

        p {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .wb-view-btn {
                margin-top: 30px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
                padding: 20px;
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width:1440px) {
                row-gap: 50px;
            }

            @media (max-width:1366px) {
                row-gap: 45px;
            }

            @media (max-width:1199px) {
                row-gap: 40px;
            }

            @media (max-width:992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width:767px) {
                width: 50%;
            }

            @media (max-width:576px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 0px;
                filter: grayscale(100%);
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                margin: 10px 0 5px;
                text-transform: uppercase;

                @media (max-width:1366px) {
                    font-size: 22px;
                }

                @media (max-width:1199px) {
                    font-size: 20px;
                }

                @media (max-width:992px) {
                    font-size: 18px;
                    line-height: normal;
                }

            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width:1366px) {
                    font-size: 18px;
                }

                @media (max-width:992px) {
                    font-size: 16px;
                }

            }
        }
    }
    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);
                a {
                        color: inherit;
                    }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a{
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 35px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 30px;
                    }
                    }
                }
            }
        }
    }
    // events
    .brown-rust-events-section{
        padding-bottom: 150px;

        @media (max-width: 1600px) {
            padding-bottom: 120px;
        }


        @media (max-width: 1440px) {
            padding-bottom: 100px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 70px;
        }

        @media (max-width: 767px) {
            padding-bottom: 30px;
        }
        .wb-website-event-wrp {
            .wb-cus-row {
                row-gap: 30px;
                 @media (max-width: 767px) {
                    row-gap: 15px;
                 }
             }

         .wb-cus-col-2 {
             width: calc(100% / 2);
             padding: 0 15px;
             @media (max-width: 767px) {
                 width: 100%;
             }
         }

         .wb-website-event-card {
             background-color: var(--wb-website-event-card-bg);
             padding: 60px;
             height: 100%;
             @media (max-width: 1440px) {
                 padding: 40px;
             }
             @media (max-width: 767px) {
                 padding: 30px;
             }

             .wb-website-event-date-time {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 border-bottom: 1px solid var(--wb-event-date-border-color);
                 padding-bottom: 30px;
                 margin-bottom: 30px;

                 @media (max-width: 1440px){
                    padding-bottom: 20px;
                    margin-bottom:20px;
                 }
                 @media (max-width: 767px) {
                     gap: 10px;
                 }

                 .start-date-time {
                     text-align: center;
                 }

                 .end-date-time {
                     text-align: center;
                 }

                 h6 {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 20px;
                     font-weight: 300;
                     line-height: 26.8px;
                     text-transform: uppercase;
                     @media (max-width: 1440px) {
                         font-size: 17px;
                         line-height: 19.8px;
                     }
                     @media (max-width: 992px) {
                         font-size: 15px;
                     }
                     @media (max-width: 767px) {
                          font-size: 12px;
                     }
                 }

                 h5 {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 28px;
                     font-weight: 600;
                     line-height: 47px;
                     @media (max-width: 1440px) {
                         font-size: 24px;
                         line-height: 38px;
                     }
                     @media (max-width: 992px) {
                         font-size: 18px;
                         line-height: 30px;
                     }
                     @media (max-width: 767px) {
                       font-size: 15px;
                      line-height: 18px;
                     }
                 }

                 span {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-event-span-text-color);
                     background: var(--wb-event-span-bg);
                     font-size: 20px;
                     font-weight: 600;
                     line-height: normal;
                     background-color: var(--wb-event-btn-bg);
                     padding: 20px;
                     border-radius: 15px;
                     text-transform: capitalize;
                     @media (max-width: 1440px) {
                         border-radius: 10px;
                         font-size: 18px;
                         padding: 15px;
                     }

                     @media (max-width: 767px) {
                         font-size: 15px;
                         padding: 10px;
                        border-radius: 8px;
                     }
                 }
             }

             .wb-website-event-details {
                 display: flex;
                 flex-direction: column;
                 align-items: center;

                 h5 {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 40px;
                     font-weight: 600;
                     line-height: 48.59px;
                     margin-bottom: 20px;
                     @media (max-width: 1440px) {
                         font-size: 30px;
                         line-height: 35.59px;
                     }
                     @media (max-width: 992px) {
                      font-size: 26px;
                      line-height: 30.59px;
                         margin-bottom: 15px;
                     }
                     @media (max-width: 767px) {
                         font-size: 20px;
                         line-height: 25.59px;
                     }
                 }

                 p {
                     font-family: var(--wb-secondaryFont);
                     color: var(--wb-website-event-text-color);
                     font-size: 18px;
                     font-weight: 400;
                     line-height: 34px;
                     text-align: center;
                     @media (max-width: 1366px) {
                         font-size: 16px;
                         line-height: 28px;
                     }
                     @media (max-width: 767px) {
                         font-size: 14px;
                         line-height: 25px;
                     }
                 }

                 .wb-view-btn {
                     margin-top: 30px;
                     text-transform: uppercase;
                     background-color: var(--wb-event-btn-bg);
                     color: var(--wb-event-btn-text-color);
                     @media (max-width: 992px) {
                         margin-top: 20px;
                     }

                     &:hover {
                         background-color: var(--wb-event-btn-hover-bg);
                         transition: background-color 0.3s ease;
                         color: var(--wb-event-btn-hover-text-color);
                     }
                 }
             }
         }
     }
}
}