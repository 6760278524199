@mixin fonts {
    --wb-primaryFont: "Lora";
    --wb-secondaryFont: "Helixa";
    --wb-subheadingFont: "Alex Brush";
}

// theme color
.sandhya-theme1 {
    --white: #fff;
    --color1: #070707; //primary color
    --color2: #eda033; //theme color
    --color3: #868686; //sub content color
    --color4: #43493F; //person card head color
    --color5: #00000000; //transparent color
    --color6: #FFF1DD; //section bg
    --color8: #E3D0B5; //qa rangoli flower color
    --color9: #FBEFDD;
    --color10: #BD9E77; // section top icon bg
    --color11: #F2F5F6; //card border
    --color12: #EDA033; //carousel rangoli color
    --color13: #E50303; //heart icon color


    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-titlebar-bg: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-title-bar-divider-color: var(--white);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-view-button-bg: var(--color5);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-banner-view-button-hover-bg: var(--color2);
    --wb-banner-common-divider-color: var(--white);
    --wb-bridegroom-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color2);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-accordion-border-color: var(--color3);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color6);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-btn-text-color: var(--white);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-common-head-divider-color: var(--color2);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-color: var(--white);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color7);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-count-down-bg: var(--color6);
    --wb-person-card-title-color: var(--color4);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-travel-title-color: var(--color1);
    --wb-travel-bg-icon-color: var(--color9);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color11);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-full-carousel-bg-icon-color: var(--color12);
    --wb-qa-rangoli-flower-color: var(--color8);
    --wb-section-top-bg-color: var(--color10);
    --wb-heart-icon-color: var(--color13);
    --wb-website-event-card-bg: var(--color2);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color2);
    --password-access-form-bg: var(--color2);
}

.sandhya-theme2 {

    --white: #fff;
    --color1: #070707; //primary color
    --color2: #D82A2A; //theme color
    --color3: #868686; //sub content color
    --color4: #43493F; //person card head color
    --color5: #00000000; //transparent color
    --color6: #FDE3E3; //section bg
    --color8: #FDCBCB; //qa rangoli flower color
    --color9: #FEF2F2;
    --color10: #FBB7B7; // section top icon bg
    --color11: #F2F5F6; //card border
    --color12: #F57474; //carousel rangoli color
    --color13: #E50303; //heart icon color



    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-titlebar-bg: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-title-bar-divider-color: var(--white);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-view-button-bg: var(--color5);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-banner-view-button-hover-bg: var(--color2);
    --wb-banner-common-divider-color: var(--white);
    --wb-bridegroom-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color2);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-accordion-border-color: var(--color3);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color6);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-btn-text-color: var(--white);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-common-head-divider-color: var(--color2);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-color: var(--white);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color7);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-count-down-bg: var(--color6);
    --wb-person-card-title-color: var(--color4);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-travel-title-color: var(--color1);
    --wb-travel-bg-icon-color: var(--color9);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color11);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-full-carousel-bg-icon-color: var(--color12);
    --wb-qa-rangoli-flower-color: var(--color8);
    --wb-section-top-bg-color: var(--color10);
    --wb-heart-icon-color: var(--color13);
    --wb-website-event-card-bg: var(--color2);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color2);
    --password-access-form-bg: var(--color2);
}

.sandhya-theme3 {

    --white: #fff;
    --color1: #070707; //primary color
    --color2: #00A36C; //theme color
    --color3: #868686; //sub content color
    --color4: #43493F; //person card head color
    --color5: #00000000; //transparent color
    --color6: #CDFEE5; //section bg
    --color8: #3DE7AD; //qa rangoli flower color
    --color9: #EAFFF5;
    --color10: #3DE7AD; // section top icon bg
    --color11: #F2F5F6; //card border
    --color12: #3DE7AD; //carousel rangoli color
    --color13: #E50303; //heart icon color



    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-titlebar-bg: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-title-bar-divider-color: var(--white);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-view-button-bg: var(--color5);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-banner-view-button-hover-bg: var(--color2);
    --wb-banner-common-divider-color: var(--white);
    --wb-bridegroom-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color2);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-accordion-border-color: var(--color3);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color6);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-btn-text-color: var(--white);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-common-head-divider-color: var(--color2);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-color: var(--white);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color7);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-count-down-bg: var(--color6);
    --wb-person-card-title-color: var(--color4);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-travel-title-color: var(--color1);
    --wb-travel-bg-icon-color: var(--color9);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color11);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-full-carousel-bg-icon-color: var(--color12);
    --wb-qa-rangoli-flower-color: var(--color8);
    --wb-section-top-bg-color: var(--color10);
    --wb-heart-icon-color: var(--color13);
    --wb-website-event-card-bg: var(--color2);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color2);
    --password-access-form-bg: var(--color2);
}

.sandhya-theme4 {

    --white: #fff;
    --color1: #070707; //primary color
    --color2: #0778C2; //theme color
    --color3: #868686; //sub content color
    --color4: #43493F; //person card head color
    --color5: #00000000; //transparent color
    --color6: #C8E9FF; //section bg
    --color8: #80CBF9; //qa rangoli flower color
    --color9: #F0F8FF;
    --color10: #80CBF9; // section top icon bg
    --color11: #F2F5F6; //card border
    --color12: #80CBF9; //carousel rangoli color
    --color13: #E50303; //heart icon color



    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-titlebar-bg: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-title-bar-divider-color: var(--white);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-view-button-bg: var(--color5);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-banner-view-button-hover-bg: var(--color2);
    --wb-banner-common-divider-color: var(--white);
    --wb-bridegroom-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color2);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-accordion-border-color: var(--color3);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color6);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-btn-text-color: var(--white);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-common-head-divider-color: var(--color2);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-color: var(--white);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color7);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-count-down-bg: var(--color6);
    --wb-person-card-title-color: var(--color4);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-travel-title-color: var(--color1);
    --wb-travel-bg-icon-color: var(--color9);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color11);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-full-carousel-bg-icon-color: var(--color12);
    --wb-qa-rangoli-flower-color: var(--color8);
    --wb-section-top-bg-color: var(--color10);
    --wb-heart-icon-color: var(--color13);
    --wb-website-event-card-bg: var(--color2);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color2);
    --password-access-form-bg: var(--color2);
}

.sandhya-theme5 {

    --white: #fff;
    --color1: #070707; //primary color
    --color2: #DB2363; //theme color
    --color3: #868686; //sub content color
    --color4: #43493F; //person card head color
    --color5: #00000000; //transparent color
    --color6: #FEE5EA; //section bg
    --color8: #FF99B4; //qa rangoli flower color
    --color9: #FFF1F4;
    --color10: #FF99B4; // section top icon bg
    --color11: #F2F5F6; //card border
    --color12: #FF99B4; //carousel rangoli color
    --color13: #E50303; //heart icon color


    --wb-primary-text-color: var(--color1);
    --wb-secondary-text-color: var(--color2);
    --wb-titlebar-bg: var(--color2);
    --wb-title-bar-text-color: var(--white);
    --wb-title-bar-divider-color: var(--white);
    --wb-common-head-divider-color: var(--color2);
    --wb-banner-view-button-bg: var(--color5);
    --wb-banner-button-border: var(--white);
    --wb-banner-button-text-color: var(--white);
    --wb-banner-date-text-color: var(--white);
    --wb-banner-text-color: var(--white);
    --wb-banner-view-button-hover-bg: var(--color2);
    --wb-banner-common-divider-color: var(--white);
    --wb-bridegroom-text-color: var(--white);
    --wb-sub-content-color: var(--color3);
    --wb-view-button-text-color: var(--white);
    --wb-view-button-bg: var(--color2);
    --wb-view-button-hover-bg: var(--color1);
    --wb-view-button-hover-text: var(--white);
    --wb-accordion-border-color: var(--color3);
    --wb-accordion-title-color: var(--color1);
    --wb-accordion-text-color: var(--color3);
    --wb-accordion-bg: var(--color6);
    --wb-accordion-icon-color: var(--color1);
    --wb-input-placeholder-color: var(--white);
    --wb-input-text-color: var(--white);
    --wb-how-we-are-text-color: var(--color1);
    --wb-venue-text-color: var(--color1);
    --wb-sub-content-color: var(--color3);
    --wb-card-title-color: var(--color1);
    --wb-venue-btn-bg: var(--color2);
    --wb-venue-btn-text-color: var(--white);
    --wb-venue-btn-hover-text-color: var(--white);
    --wb--venue-title-color: var(--color1);
    --wb-venue-common-head-divider-color: var(--color2);
    --wb-venue-rangoli-icon-color: var(--color9);
    --wb-venue-image-border-color: var(--white);
    --wb-rsvp-bg: var(--color2);
    --wb-rsvp-text-color: var(--white);
    --wb-rsvp-btn-bg: var(--white);
    --wb-rsvp-btn-hover-bg: var(--color1);
    --wb-rsvp-btn-hover-text-color: var(--white);
    --wb-rsvp-btn-text-color: var(--color2);
    --wb-rsvp-title-color: var(--white);
    --wb-rsvp-title-divider-color: var(--white);
    --wb-rsvp-form-divider-color: var(--white);
    --wb-how-we-met-bg-icon-color: var(--color7);
    --wb-how-we-met-divider-color: var(--color2);
    --wb-footer-divider-color: var(--color2);
    --wb-rangoli-icon-color: var(--color7);
    --wb-section-top-rangoli-icon-color: var(--color7);
    --Wb-things-to-do-button-bg: var(--color2);
    --Wb-things-to-do-button-hover-bg: var(--color1);
    --wb-things-to-do-button-text-color: var(--white);
    --wb-things-to-do-button-hover-text-color: var(--white);
    --wb-things-to-do-card-title-color: var(--color1);
    --wb-count-down-bg: var(--color6);
    --wb-person-card-title-color: var(--color4);
    --Wb-travel-button-border: var(--color2);
    --Wb-travel-button-text-color: var(--white);
    --Wb-travel-button-bg: var(--color2);
    --Wb-travel-title-color: var(--color1);
    --wb-travel-bg-icon-color: var(--color9);
    --Wb-registry-card-bg: var(--white);
    --Wb-registry-card-border: var(--color11);
    --wb-registry-btn-bg: var(--color2);
    --wb-registry-btn-hover-bg: var(--color1);
    --wb-registry-btn-text-color: var(--white);
    --wb-registry-btn-hover-text-color: var(--white);
    --wb-footer-text-color: var(--color2);
    --wb-full-carousel-bg-icon-color: var(--color12);
    --wb-qa-rangoli-flower-color: var(--color8);
    --wb-section-top-bg-color: var(--color10);
    --wb-heart-icon-color: var(--color13);
    --wb-website-event-card-bg: var(--color2);
    --wb-website-event-text-color: var(--white);
    --wb-event-btn-bg: var(--white);
    --wb-event-btn-hover-bg: var(--color1);
    --wb-event-btn-hover-text-color: var(--white);
    --wb-event-btn-text-color: var(--color2);
    --wb-event-date-border-color: var(--white);
    --wb-event-span-text-color: var(--color2);
    --password-access-form-bg: var(--color2);
}

// theme color end
.sandhya {
    @include fonts;
    background-color: var(--white);

    .quil-text-block {
        font-family: var(--wb-secondaryFont);
    }

    // divider
    .sandhya-divider {
        display: flex;

        svg {
            width: 100%;
            margin: 0 auto;

            path {
                fill: var(--wb-common-head-divider-color);
            }
        }
    }

    //
    .common-head-divider {
        margin-bottom: 50px;

        @media (max-width:1440px) {
            margin-bottom: 40px;
        }

        @media (max-width:767px) {
            margin-bottom: 20px;
        }

        svg {
            width: 130px;

            @media (max-width: 767px) {
                width: 100px;
            }

            path {
                fill: var(--wb-common-head-divider-color);
            }
        }
    }

    // travel divider
    .travel-divider {
        svg {
            width: 90%;
            margin-top: 40px;

            @media (max-width: 767px) {
                width: 150px;
                margin-top: 20px;
            }

            path {
                fill: var(--wb-common-head-divider-color);
            }
        }
    }

    //
    .wb-sandhya-rangoli-bg-icon {
        display: flex;

        svg {
            width: 100%;
            margin: 0 auto;
            height: auto;
        }
    }

    .wb-sandhya-rangoli-top-bg-icon {
        svg {
            width: 100%;
            margin: 0 auto;
            height: auto;

            path {
                stroke: var(--wb-section-top-bg-color);
            }

            .rangoli-color-fill {
                fill: var(--wb-section-top-bg-color);
            }
        }
    }

    // button
    .wb-view-btn {
        color: var(--wb-view-button-text-color);
        font-family: var(--wb-secondaryFont);
        background: var(--wb-view-button-bg);
        font-size: 14px;
        font-weight: 400;
        padding: 12px 20px;
        text-transform: uppercase;
        border-radius: 0;

        &:hover {
            background-color: var(--wb-view-button-hover-bg);
            transition: background-color 0.3s ease;
        }

        @media (max-width:1366px) {
            padding: 8px 15px;
        }

        @media (max-width:767px) {
            font-size: 13px;
        }
    }

    //  title bar
    .wb-sandhya-title-bar-section {
        background-color: var(--wb-titlebar-bg);
        padding: 5px 0;



        .wb-sandhya-title-bar-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 23px;

            @media (max-width: 767px) {
                gap: 15px;

            }

            .title-bar-left-divider,
            .title-bar-right-divider {
                margin-top: 15px;

                @media (max-width:767px) {
                    margin-top: 0px;
                }

                svg {
                    path {
                        fill: var(--wb-title-bar-divider-color);
                    }

                    @media (max-width:767px) {
                        width: 80px;
                    }
                }
            }

            .title-bar-right-divider {
                transform: rotate(180deg);

            }

            .wb-title-bar {
                h2 {
                    color: var(--wb-title-bar-text-color);
                    font-size: 60px;
                    text-align: center;
                    font-weight: 400;
                    line-height: 69.6px;
                    font-family: var(--wb-primaryFont);
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    gap: 10px;

                    @media (max-width: 767px) {
                        font-size: 26px;
                        line-height: normal;
                    }

                    span {
                        font-family: var(--wb-primaryFont);
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 23.2px;

                        @media (max-width: 767px) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    // bride groom name
    .wb-bride-groom-name-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 10px 0 50px;
        margin: 0;

        @media (max-width: 1366px) {
            padding: 30px 0;
        }

        @media (max-width: 767px) {
            padding: 25px 0;
        }

        @media (max-width: 580px) {
            padding: 20px 0;
        }

        h2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 30px;
            font-family: var(--wb-primaryFont);
            color: var(--wb-bridegroom-text-color);
            font-size: 80px;
            font-weight: 400;
            line-height: 92px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1600px) {
                font-size: 65px;
                line-height: normal;
            }

            @media (max-width: 1366px) {
                font-size: 60px;
            }

            @media (max-width: 1280px) {
                font-size: 55px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
                gap: 18px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 767px) {
                font-size: 25px;
                flex-direction: column;
                gap: 3px;
            }


            span {
                text-align: left;

                @media (max-width:767px) {
                    text-align: center;

                }
            }

            span:nth-child(1) {
                text-align: right;

                @media (max-width: 767px) {
                    text-align: center;
                }
            }

            span:nth-child(2) {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;

                @media (max-width: 1366px) {
                    font-size: 35px;
                }

                @media (max-width: 1024px) {
                    font-size: 25px;
                }

                @media (max-width: 1024px) {
                    font-size: 20px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                }

            }
        }

        h6 {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 19px;
            margin-top: 10px;
            align-items: center;

            @media (max-width: 580px) {
                gap: 6px;
            }

            span {
                text-align: center;
                font-family: var(--wb-secondaryFont);
                font-size: 22px;
                font-weight: 400;
                line-height: 27.72px;
                color: var(--wb-banner-date-text-color);

                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 20.72px;
                }

                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: normal;
                }

                &:nth-child(1) {
                    flex: 1;
                    text-align: right;
                }

                &:nth-child(3) {
                    flex: 1;
                    text-align: left;
                }

                &:nth-child(2) {
                    width: 2px;
                    height: 18px;
                    background: var(--white);

                    @media (max-width: 767px) {
                        display: none;

                    }
                }
            }
        }
    }

    // slider banner

    .indo-western-slider-banner-wrp {
        position: relative;
        z-index: 9;

        .indo-western-banner-slider-image {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000;
                top: 0;
                opacity: 0.3;
                left: 0px;
                right: 0;
                margin: 0;
            }

            img {
                object-fit: cover;
                min-height: 100vh !important;
                @media (max-width:767px) {
                    height: 100vh !important;
                }
            }
        }

        .indo-western-banner-slider-content {
            position: absolute;
            width: fit-content;
            top: 25%;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;

            @media (max-width: 767px) {
                top: 20%;
            }

            .sandhya-divider {
                svg {
                    width: 100%;

                    @media (max-width: 767px) {
                        width: 120px;
                    }

                    path {
                        fill: var(--wb-banner-common-divider-color);
                    }
                }
            }

            h4 {
                text-align: center;
                font-family: var(--wb-subheadingFont);
                font-size: 50px;
                font-weight: 400;
                color: var(--wb-banner-text-color);
                margin-bottom: 20px;

                @media (max-width:1440px) {
                    font-size: 35px;
                }

                @media (max-width: 767px) {
                    font-size: 25px;
                    margin-bottom: 0;
                }
            }


            .wb-view-btn {
                color: var(--wb-banner-button-text-color);
                background: var(--wb-banner-view-button-bg);
                border: 1px solid var(--wb-banner-button-border);
                margin-top: 0px;
                font-size: 16px;
                line-height: 19.2px;
                letter-spacing: 3px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: normal;
                }

                &:hover {
                    background-color: var(--wb-banner-view-button-hover-bg);
                    transition: background-color 0.3s ease;
                }
            }
        }
    }

    // section head(common head)

    .indo-western-common-head {
        margin: 0;
        display: flex;
        flex-direction: column;


        h3 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            text-align: center;
            line-height: 58px;
            text-transform: capitalize;

            @media (max-width: 1366px) {
                font-size: 35px;
            }

            @media (max-width: 992px) {
                font-size: 30px;
            }

            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 25px;
            }

            @media (max-width: 640px) {
                font-size: 22px;
                line-height: 35px;
            }
        }

        h6 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-subheadingFont);
            font-size: 40px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 40.14px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 30px;
                line-height: 30px;
            }

            @media (max-width: 1024px) {
                font-size: 25px;
            }

            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

    // how we met
    .wb-sandhya-how-we-met-section {
        .common-head-divider {
            margin-bottom: 0px;
        }

        .wb-sandhya-how-we-met-wrp {
            padding: 150px 0;

            @media (max-width: 1600px) {
                padding: 120px 0;
            }

            @media (max-width: 1440px) {
                padding: 100px 0;
            }

            @media (max-width: 1199px) {
                padding: 70px 0;
            }

            @media (max-width: 767px) {
                padding: 30px 0;
            }

            .sandhya-divider {
                svg {
                    path {
                        fill: var(--wb-how-we-met-divider-color);
                    }
                }
            }

            .how-we-met-rangoli-flower {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 40px;

                @media (max-width: 1440px) {
                    margin-bottom: 30px;
                }

                @media (max-width: 767px) {
                    margin-bottom: 20px;
                }

                svg {
                    width: 70px;
                    height: auto;

                    @media (max-width: 1440px) {
                        width: 70px;

                    }

                    @media (max-width: 767px) {
                        width: 50px;

                    }

                    path {
                        fill: var(--wb-common-head-divider-color);
                    }
                }
            }

            .wb-container {

                .wb-cus-row {
                    gap: 20px;
                    flex-wrap: nowrap;
                    align-items: center;

                    @media (max-width:1199px) {
                        margin: 0;
                        gap: 0;

                    }

                    @media (max-width:767px) {
                        flex-wrap: wrap;
                        flex-direction: column-reverse;
                        gap: 20px;

                    }

                    .wb-col-2 {
                        padding: 0 15px;
                        flex: 0 0 auto;
                        width: calc(100% / 2);

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }

                    .sandhya-image-wrp {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 40px;

                        @media (max-width:1199px) {

                            gap: 20px;

                        }

                        @media (max-width: 767px) {
                            flex-direction: column;
                            gap: 15px;
                        }

                        img {
                            width: 383px !important;
                            height: 450px !important;
                            max-width: 100% !important;
                            min-height: 100% !important;
                            object-fit: cover;

                            @media (max-width: 1440px) {
                                height: 380px !important;
                            }

                            @media (max-width: 992px) {
                                height: 300px !important;
                            }

                            @media (max-width: 767px) {
                                width: 100% !important;
                                height: auto !important;
                                min-height: 300px !important;

                            }
                        }

                        .how-we-met-left-img {
                            padding-top: 4rem;

                            @media (max-width:767px) {
                                padding-top: 0;
                            }
                        }

                        .how-we-met-right-img {
                            padding-bottom: 4rem;

                            @media (max-width:767px) {
                                padding-bottom: 0;
                            }
                        }
                    }


                    p {
                        font-family: var(--wb-secondaryFont);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 38px;
                        color: var(--wb-sub-content-color);
                        margin: 40px 0;
                        text-align: center;

                        @media (max-width:1440px) {
                            font-size: 16px;
                            line-height: 30px;

                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 25px;
                            margin: 20px 0;
                        }
                    }

                }
            }
        }
    }

    // venue location

    .wb-sandhya-venue-location-wrp {
        position: relative;
        overflow: hidden;
        padding: 150px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1600px) {
            padding: 120px 0;
        }

        @media (max-width: 1440px) {
            padding: 100px 0;
        }

        @media (max-width: 1199px) {
            padding: 70px 0;
        }

        @media (max-width: 767px) {
            padding: 40px 0;
        }

        .common-head-divider {
            margin-bottom: 0px;
        }

        .wb-sandhya-venue-bg {
            position: absolute;
            width: 100%;
            height: 100%;

            .wb-sandhya-rangoli-bg-icon {
                height: 100%;
                width: 100%;

                svg {
                    path {
                        fill: var(--wb-venue-rangoli-icon-color);
                    }
                }
            }
        }

        .wb-container {
            .wb-cus-row {
                align-items: center;
                gap: 40px;
                flex-wrap: nowrap;

                @media (max-width:767px) {
                    flex-direction: column;
                    row-gap: 30px;
                }

                .wb-cus-col-3 {
                    padding: 0 15px;
                    width: calc(100% / 3);

                    @media (max-width:767px) {
                        width: 100%;

                    }

                }

                .col-order-1 {
                    @media (max-width:767px) {
                        order: 1;
                    }

                }

                .venue-left-image,
                .venue-right-image {
                    img {
                        width: 100% !important;
                        height: 500px !important;
                        max-width: 100% !important;
                        min-height: 100% !important;
                        object-fit: cover;

                        @media (max-width:1440px) {
                            height: 400px !important;


                        }

                        @media (max-width:992px) {
                            height: 350px !important;


                        }

                        @media (max-width:767px) {
                            height: auto !important;
                            min-height: 350px !important;

                        }
                    }
                }

                .venue-left-image {
                    padding-top: 6rem;

                    @media (max-width: 767px) {
                        padding-top: 0;
                    }

                }

                .venue-right-image {
                    padding-bottom: 6rem;

                    @media (max-width: 767px) {
                        padding-bottom: 0;
                    }
                }

                .wb-sandhya-venue-info {
                    z-index: 9;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;


                    .common-head-divider {
                        svg {
                            path {
                                fill: var(--wb-venue-common-head-divider-color);
                            }
                        }
                    }

                    .wb-view-btn {
                        margin-top: 60px;

                        @media (max-width: 1600px) {
                            margin-top: 50px;
                        }

                        @media (max-width: 1366px) {
                            margin-top: 40px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 30px;
                        }
                    }

                    p {
                        color: var(--wb-venue-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 43px;
                        text-align: center;
                        margin-top: 50px;

                        @media (max-width: 1440px) {
                            font-size: 18px;
                            line-height: 35px;
                            margin-top: 30px;
                        }


                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 25px;
                            text-align: center;
                            margin-top: 20px;
                        }
                    }

                    .venue-card-divider {
                        margin-top: 60px;

                        @media (max-width: 1600px) {
                            margin-top: 50px;
                        }

                        @media (max-width: 1366px) {
                            margin-top: 40px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 30px;
                        }

                        svg {
                            path {
                                fill: var(--wb-venue-common-head-divider-color);
                            }
                        }
                    }
                }
            }

            .sandhya-venue-divider {
                margin-top: 60px;
            }
        }
    }


    // countdown
    .indo-western-count-down-wrp {
        padding: 50px 0;
        background: var(--wb-count-down-bg);

        @media (max-width: 1024px) {
            padding: 40px 0;
        }

        .wb-cus-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 20px;

            .indo-western-count-down-content {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 767px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 576px) {
                    width: calc(100% / 2);
                }

                h6 {
                    color: var(--wb-secondary-text-color);
                    font-family: var(--wb-subheadingFont);
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 53.52px;
                    text-transform: capitalize;

                    @media (max-width: 1440px) {
                        font-size: 30px;
                        line-height: normal;
                    }

                    @media (max-width: 767px) {
                        font-size: 25px;
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 58px;

                    @media (max-width: 1440px) {
                        font-size: 40px;
                        line-height: 45px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 40px;
                    }

                    @media (max-width: 767px) {
                        font-size: 30px;
                    }
                }

                .countdown-divider {
                    svg {
                        width: 60%;

                        @media (max-width: 767px) {
                            width: 30%;

                        }

                        path {
                            fill: var(--wb-common-head-divider-color);
                        }
                    }
                }
            }
        }
    }

    //sub banner
    .wb-sub-banner {
        img {
            max-width: 100% !important;
            max-height: 100% !important;
            width: 100% !important;
            height: auto !important;
            object-fit: cover;
        }

    }

    //  faq
    .wb-sandhya-q-and-a-section {
        position: relative;
        background: var(--wb-accordion-bg);

        .wb-sandhya-q-and-a-rangoli-left {
            position: absolute;
            left: -6%;
            top: 50%;
            transform: translateY(-50%);

            .sandhya-q-and-a-rangoli-flower {
                width: 150px;

                svg {
                    width: 200px;

                    @media (max-width:1440px) {
                        width: 150px;
                    }

                    @media (max-width:1199px) {
                        width: 100px;
                    }

                    @media (max-width:992px) {
                        width: 70px;
                    }

                    @media (max-width:767px) {
                        width: 60px;
                    }

                    path {
                        fill: var(--wb-qa-rangoli-flower-color);
                    }
                }
            }
        }

        .indo-western-common-head {

            h6,
            h3 {
                text-align: left;

                @media (max-width:767px) {
                    text-align: center;

                }
            }
        }

        .wb-expandable-faq-wrp {
            padding: 150px 0;
            position: relative;

            @media (max-width: 1600px) {
                padding: 120px 0;
            }

            @media (max-width: 1440px) {
                padding: 100px 0;
            }

            @media (max-width: 1199px) {
                padding: 70px 0;
            }


            @media (max-width: 767px) {
                padding: 30px 0;
            }

            .wb-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 50px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }
            }

            .wb-accordion-wrp {
                width: 80%;

                @media (max-width: 767px) {
                    width: 100%;
                }

                .wb-accordion {
                    margin-bottom: 0;
                    padding: 50px;
                    border-bottom: 1px solid var(--wb-accordion-border-color);
                    border-left: 1px solid var(--wb-accordion-border-color);
                    border-right: 1px solid var(--wb-accordion-border-color);

                    @media (max-width: 1366px) {
                        padding: 30px;
                    }


                    @media (max-width: 992px) {
                        padding: 20px;

                    }

                    &:nth-child(1) {
                        border-top: 1px solid var(--wb-accordion-border-color);
                    }

                    .wb-accordion-header {
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h3 {
                            font-size: 30px;
                            color: var(--wb-accordion-title-color);
                            font-weight: 400;
                            line-height: normal;
                            font-family: var(--wb-primaryFont);


                            @media (max-width: 1440px) {
                                font-size: 26px;
                            }


                            @media (max-width: 1199px) {
                                font-size: 20px;

                            }

                            @media (max-width: 767px) {
                                font-size: 15px;

                            }
                        }

                        .wb-accordion-icon {
                            transition: transform 0.3s ease-in-out;

                            &.open {
                                transform: rotateX(180deg);
                            }

                            svg {
                                width: auto;
                                margin-right: 0;

                                @media (max-width: 767px) {
                                    width: 30px;
                                }

                                @media (max-width: 420px) {
                                    width: 25px;
                                }

                                path {
                                    fill: var(--wb-accordion-icon-color);
                                }
                            }
                        }
                    }

                    .wb-accordion-content {
                        max-height: 0;
                        overflow: hidden;

                        p {
                            color: var(--wb-accordion-text-color);
                            font-size: 18px;
                            font-weight: 300;
                            line-height: 38px;
                            font-family: var(--wb-secondaryFont);


                            @media (max-width: 1440px) {
                                font-size: 16px;
                                line-height: 34px;
                            }

                            @media (max-width: 767px) {
                                font-size: 14px;
                                line-height: 25px;
                            }
                        }

                        &.open {
                            max-height: 100%;
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
    }

    // image slider
    .wb-sandhya-image-carousel-section {
        padding: 150px 0;
        position: relative;
        overflow: hidden;

        @media (max-width: 1600px) {
            padding: 120px 0;
        }


        @media (max-width: 1440px) {
            padding: 100px 0;
        }

        @media (max-width: 1199px) {
            padding: 70px 0;
        }


        @media (max-width: 767px) {
            padding: 40px 0;
        }

        .wb-container {
            @media (max-width:1199px) {
                padding: 0 50px;
            }

            @media (max-width:767px) {
                padding: 0 30px;
            }
        }

        .wb-sandhya-image-carousel-rangoliflower-left,
        .wb-sandhya-image-carousel-rangoliflower-right {
            svg {
                width: 200px;
                margin-right: 0;

                @media (max-width:1440px) {
                    width: 110px;
                }

                @media (max-width:1280px) {
                    width: 100px;
                }

                @media (max-width:1199px) {
                    width: 85px;
                }

                @media (max-width:767px) {
                    width: 75px;
                }

                @media (max-width:580px) {
                    width: 50px;
                }

                path {
                    fill: var(--wb-full-carousel-bg-icon-color);
                }
            }

        }

        .wb-sandhya-image-carousel-rangoliflower-left {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);


        }

        .wb-sandhya-image-carousel-rangoliflower-right {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) translateX(50%);
            overflow: hidden;

        }

        .full-image-carousel {

            img {
                width: 100% !important;
                height: 600px !important;
                max-width: 100% !important;
                min-height: 100% !important;
                object-fit: cover;

                @media (max-width: 1600px) {
                    height: 500px !important;

                }

                @media (max-width: 1199px) {
                    height: 420px !important;

                }

                @media (max-width: 767px) {
                    min-height: 250px !important;
                    height: auto !important;

                }

            }
        }
    }

    .full-image-carousel {
        margin: 0;
        padding: 0px;

        .swiper {
            @media(max-width: 767px) {
                margin: 0 !important;
            }

        }

    }

    .wb-sandhya-footer-full-image-carousel-section {

        .sandhya-footer-full-image-carousel {
            padding: 40px;

            @media (max-width: 767px) {
                padding: 20px;

            }

            img {
                width: 100% !important;
                height: 273px !important;
                max-width: 100% !important;
                min-height: 100% !important;
                object-fit: cover;

                @media (max-width:992px) {
                    height: 200px !important;

                }

                @media (max-width: 767px) {
                    min-height: 120px !important;
                    height: auto !important;

                }


            }

            .swiper {
                margin-bottom: 0 !important;
            }
        }
    }

    // things to do
    .wb-sandhya-things-to-do-section {
        padding-bottom: 100px;
        padding-top: 50px;

        @media (max-width: 1600px) {
            padding-bottom: 70px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 50px;
        }


        .wb-cus-row {
            row-gap: 30px;

            @media (max-width: 1024px) {
                gap: 30px 0;
            }
        }

        .wb-activity-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            width: calc(100% / 3);
            padding: 0 15px;

            @media (max-width:767px) {
                width: 100%;

            }

            .wb-activity-image {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 100% !important;
                height: 380px !important;
                object-fit: cover;

                @media (max-width:767px) {
                    min-height: 200px !important;
                    height: auto !important;
                    aspect-ratio: 1;
                }
            }

            h4 {
                color: var(--wb-things-to-do-card-title-color);
                font-family: var(--wb-primaryFont);
                font-size: 25px;
                font-weight: 400;
                line-height: 34.65px;
                padding-bottom: 10px;
                margin-top: 50px;

                @media (max-width: 1366px) {
                    margin-top: 30px;
                }

                @media (max-width: 1024px) {
                    font-size: 20px;
                    line-height: 25.65px;
                }

                @media (max-width: 1366px) {
                    margin-top: 20px;
                }

            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                padding-bottom: 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 35px;

                @media (max-width: 1366px) {
                    font-size: 16px;
                    line-height: 28px;
                }

                @media (max-width: 1024px) {
                    line-height: 28px;
                    padding-bottom: 10px;
                }
            }

            h5 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            p {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                padding-bottom: 20px;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;

                @media (max-width: 1366px) {
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 25px;
                }
            }

            .wb-view-btn {
                margin-top: 20px;
                background: var(--Wb-things-to-do-button-bg);
                color: var(--wb-things-to-do-button-text-color);
                font-size: 14px;
                font-weight: 400;

                &:hover {
                    background: var(--Wb-things-to-do-button-hover-bg);
                    color: var(--wb-things-to-do-button-hover-text-color);
                }
            }
        }
    }

    // footer
    .wb-sandhya-footer-couple-name {
        position: relative;
        padding: 40px 0 0;

        @media (max-width:767px) {
            padding: 30px 0 0;

        }

        .wb-container {
            padding: 0;
        }

        .wb-sandhya-footer-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 23px;

            .footer-left-divider,
            .footer-right-divider {
                svg {
                    path {
                        fill: var(--wb-footer-divider-color);
                    }

                    @media (max-width:767px) {
                        width: 80px;
                    }
                }
            }

            .footer-right-divider {
                transform: rotate(180deg);

            }
        }

        .wb-footer-couple-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h5 {
                font-size: 60px;
                font-weight: 400;
                line-height: 69.23px;
                color: var(--wb-footer-text-color);
                font-family: var(--wb-primaryFont);
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: baseline;
                gap: 10px;
                text-transform: lowercase;


                @media (max-width: 1440px) {
                    font-size: 45px;
                    line-height: 51.69px;
                }

                @media (max-width: 1199px) {
                    font-size: 40px;
                    line-height: 46.15px;
                }

                @media (max-width: 767px) {
                    font-size: 30px;
                    line-height: 34.61px;
                }

                span {
                    font-family: var(--wb-primaryFont);
                    font-size: 20px;
                    line-height: 68.12px;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
            }

            h6 {
                display: none;
            }

            .wb-divider {
                display: none;
            }

        }


    }

    // rsvp
    .sandhya-rsvp-form {
        position: relative;

        .sandhya-rsvp-form-bg {
            img {
                max-width: 100% !important;
                max-height: 580px !important;
                width: 100% !important;
                height: auto !important;
                object-fit: cover;

                @media (max-width:767px) {
                    height: auto !important;
                    max-height: 100% !important;

                }

            }
        }

        .sandhya-rsvp-form-wrp {
            position: relative;
            z-index: 9;

            .sandhya-rsvp-form-title {
                text-align: center;
                flex-direction: column;
                display: flex;
                margin-bottom: 20px;

                h3 {
                    color: var(--wb-rsvp-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 50px;
                    font-weight: 400;
                    text-align: center;

                    @media (max-width: 1440px) {
                        font-size: 40px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 35px;
                    }

                    @media (max-width: 1024px) {
                        font-size: 32px;
                    }

                    @media (max-width: 992px) {
                        font-size: 30px;
                    }

                    @media (max-width: 420px) {
                        font-size: 28px;
                    }
                }

                h6 {
                    color: var(--wb-rsvp-title-color);
                    font-family: var(--wb-subheadingFont);
                    font-size: 18px;
                    text-transform: capitalize;
                    font-weight: 400;
                    line-height: 35px;
                    text-align: center;

                    @media (max-width: 1366px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }
            }

            .sandhya-rsvp {
                background: var(--wb-rsvp-bg);
                padding: 60px;
                max-width: 650px;
                margin: 0 auto;
                margin-top: -17rem;


                @media (max-width: 1440px) {
                    padding: 45px;
                    max-width: 550px;
                    margin-top: -14rem;
                }

                @media (max-width: 992px) {
                    padding: 35px;
                    max-width: 450px;
                    margin-top: -9.5rem;
                }

                @media (max-width: 767px) {
                    padding: 30px;
                    max-width: 350px;
                    margin-top: -4.5rem;
                }

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    margin-top: 50px;

                    @media (max-width:767px) {
                        gap: 15px;
                        margin-top: 20px;
                    }
                }

                .wb-cus-input {
                    background-color: transparent;
                    border: 1px solid #e7e7e7;
                    border-radius: 0px;
                    padding: 10px 16px;
                    margin-bottom: 10px;
                    color: var(--wb-rsvp-text-color);
                    font-weight: 400;
                    font-size: 14px;
                    width: 80%;
                    height: 40px;
                    outline: none;
                    font-family: var(--wb-secondaryFont);

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    &::placeholder {
                        color: var(--wb-input-placeholder-color) !important;
                        font-family: var(--wb-secondaryFont);
                        line-height: 15.12px;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }

                .wb-view-btn {
                    width: fit-content;
                    text-transform: uppercase;
                    background-color: var(--wb-rsvp-btn-bg);
                    color: var(--wb-rsvp-btn-text-color);
                    font-family: var(--wb-secondaryFont);
                    margin: 30px 0;

                    &:hover {
                        background-color: var(--wb-rsvp-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-rsvp-btn-hover-text-color);
                    }

                    @media (max-width: 767px) {
                        padding: 15px;
                        margin: 20px 0;
                    }

                    @media (max-width: 560px) {
                        padding: 10px;
                        margin: 15px 0;
                    }
                }
            }
        }
    }


    // registry
    .wb-sandhya-registry-section {
        position: relative;

        .indo-western-common-head-wrp {
            padding-top: 100px;

            @media (max-width: 1600px) {
                padding-top: 80px;
            }

            @media (max-width: 1199px) {
                padding-top: 50px;
            }

            @media (max-width: 767px) {
                padding-top: 30px;
            }
        }

        .wb-registry-card-wrp {
            padding-bottom: 150px;

            @media (max-width: 1600px) {
                padding-bottom: 120px;
            }

            @media (max-width: 1366px) {
                padding-bottom: 80px;
            }

            @media (max-width: 992px) {
                padding-bottom: 50px;
            }

            @media (max-width: 767px) {
                padding-bottom: 30px;
            }

            .wb-cus-row {
                row-gap: 30px;

                @media (max-width: 1199px) {
                    row-gap: 20px;
                }

                @media (max-width: 767px) {
                    row-gap: 15px;
                }
            }

            .wb-col-3 {
                padding: 0 15px;
                flex: 0 0 auto;
                width: calc(100% / 4);

                @media (max-width: 1199px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 767px) {
                    width: calc(100% / 2);
                }

                @media (max-width: 567px) {
                    width: 100%;
                }
            }

            .wb-registry-card-item {
                border: 1px solid var(--Wb-registry-card-border);
                background: var(--Wb-registry-card-bg);
                padding: 30px;

                @media (max-width: 1024px) {
                    padding: 20px;
                }

                .wb-registry-card-img {
                    text-align: center;
                    margin-bottom: 10px;
                    img {
                        width: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .wb-view-btn {
                    width: 100%;
                    background-color: var(--wb-registry-btn-bg);
                    color: var(--wb-registry-btn-text-color);

                    &:hover {
                        background-color: var(--wb-registry-btn-hover-bg);
                        transition: background-color 0.3s ease;
                        color: var(--wb-registry-btn-hover-text-color);
                    }
                }

                h4 {
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 35px;

                    @media (max-width: 1600px) {
                        font-size: 23px;
                        line-height: 33px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 21px;
                        line-height: 31px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 19px;
                        line-height: 29px;
                    }

                    @media (max-width: 767px) {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }

                h3 {
                    margin: 20px 0;
                    color: var(--wb-primary-text-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    font-size: 28px;

                    @media (max-width: 1199px) {
                        font-size: 25px;
                    }

                    @media (max-width: 992px) {
                        font-size: 22px;
                    }

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    margin-bottom: 20px;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    // common component
    // person view card (wedding party card)
    .wb-sandhya-wedding-party-section {

        .indo-western-common-head-wrp {
            position: relative;
            z-index: 9;
        }

        .wb-sandhya-wedding-party-wrp {
            padding: 150px 0;
            position: relative;

            @media (max-width: 1600px) {
                padding: 120px 0;
            }


            @media (max-width: 1440px) {
                padding: 100px 0;
            }

            @media (max-width: 1199px) {
                padding: 70px 0;
            }

            @media (max-width: 767px) {
                padding: 30px 0;
            }

        }

        .person-view-card {
            margin: 0;
            position: relative;

            @media (max-width:1440px) {
                padding-top: 40px;
            }

            @media (max-width:1199px) {
                padding-top: 10px;
            }

            @media (max-width:767px) {
                padding-top: 0px;

            }

            .wb-cus-row {
                row-gap: 60px;
                justify-content: center;

                @media (max-width: 1440px) {
                    row-gap: 50px;
                }

                @media (max-width: 1366px) {
                    row-gap: 45px;
                }

                @media (max-width: 1199px) {
                    row-gap: 40px;
                }

                @media (max-width: 992px) {
                    row-gap: 35px;
                }
            }

            .person-view-card-item {
                padding: 0 15px;
                width: calc(100% / 5);
                text-align: center;

                @media (max-width: 1199px) {
                    width: calc(100% / 4);
                }

                @media (max-width: 992px) {
                    width: calc(100% / 3);
                }

                @media (max-width: 767px) {
                    width: calc(100% / 2);
                }

                img {
                    width: 100%;
                    max-width: 200px !important;
                    height: 200px !important;
                    object-fit: cover;

                    @media (max-width: 767px) {
                        height: auto !important;
                        max-width: auto !important;
                    }
                }

                h4 {
                    color: var(--wb-person-card-title-color);
                    font-family: var(--wb-primaryFont);
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 35px;
                    margin: 10px 0 5px;

                    @media (max-width: 1600px) {
                        font-size: 24px;
                        line-height: 33px;
                    }

                    @media (max-width: 1440px) {
                        font-size: 22px;
                        line-height: 31px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 20px;
                        line-height: 29px;
                    }

                    @media (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 27px;
                    }

                    @media (max-width: 992px) {
                        line-height: 25px;
                    }

                    @media (max-width: 767px) {
                        line-height: 23px;
                    }
                }

                h6 {
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;

                    @media (max-width: 1600px) {
                        font-size: 15px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                p {
                    display: none;
                }
            }
        }
    }

    // hotel view

    .wb-sandhya-hotel-transport-section {
        position: relative;
        padding: 50px 0;

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-sandhya-hotel-rangoliflower-bg {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-67%);

            @media (max-width: 1199px) {
                top: 42%;

            }

            @media (max-width: 767px) {
                top: 55%;

            }

            svg {
                width: 45%;

                path {
                    fill: var(--wb-travel-bg-icon-color);
                }
            }
        }

        .wb-sandhya-hotel-bg {
            position: absolute;
            height: 100%;
        }

        .wb-rangoli-bg-icon {
            svg {
                path {
                    fill: var(--wb-travel-rangoli-icon-color)
                }
            }
        }

        .indo-western-common-head-wrp {
            position: relative;
            z-index: 9;
            padding-top: 100px;

            @media (max-width: 1366px) {
                padding-top: 80px;
            }

            @media (max-width: 992px) {
                padding-top: 60px;
            }

            @media (max-width: 768px) {
                padding-top: 40px;
            }
        }

        .common-head-divider {
            position: relative;
            z-index: 9;
        }

        // hotel view
        .single-hotel-transport-wrp {
            position: relative;

            .wb-cus-row {
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 120px;

                @media (max-width: 1440px) {
                    gap: 90px;
                }

                @media (max-width: 1280px) {
                    gap: 50px;
                }

                @media (max-width: 992px) {
                    gap: 30px;
                }

                @media (max-width: 767px) {
                    gap: 20px;
                    flex-wrap: wrap;
                    flex-direction: column;
                }

            }
            .wb-transport-view ,
            .wb-hotel-view {
                margin: 40px 0;
                padding: 0 15px;
                width: calc(100% / 2);
                text-align: center;
                  display: flex;
            flex-direction: column;
                @media (max-width: 767px) {
                    width: 100%;
                    margin: 20px 0;
                }
            }

            .wb-hotel-image,
            .wb-transport-image {
                width: 100%;

                img {
                    width: 100%;
                    height: 390px !important;
                    max-height: 100% !important;
                    max-width: 100% !important;
                    object-fit: cover;

                    @media (max-width: 1600px) {
                        height: 320px !important;
                    }

                    @media (max-width: 1366px) {
                        height: 280px !important;
                    }

                    @media (max-width: 767px) {
                        height: 200px !important;
                    }
                }
            }

            .wb-hotel-content,
            .wb-transport-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-grow: 1;

                @media (max-width: 767px) {
                    width: 100%;
                }

                h6 {
                    font-size: 16px;
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    font-weight: 400;
                    line-height: 35px;
                    text-align: center;

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 29px;
                    }
                }

                h4 {
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 49px;
                    color: var(--Wb-travel-title-color);
                    font-family: var(--wb-primaryFont);
                    margin: 30px 0;
                    margin-bottom: 0;

                    @media (max-width: 1440px) {
                        font-size: 22px;
                        line-height: 30px;
                        margin: 20px 0;
                    }

                    @media (max-width: 767px) {
                        font-size: 20px;
                        line-height: 25px;
                        margin: 10px 0;
                    }

                }

                p {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 38px;
                    color: var(--wb-sub-content-color);
                    font-family: var(--wb-secondaryFont);
                    text-align: center;

                    @media (max-width:1440px) {
                        line-height: 28px;
                    }

                    @media (max-width: 1366px) {
                        font-size: 16px;
                    }

                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }

            }
            .hotel-transport-btn-wrp {
                margin-top: auto;
            }
            .wb-view-btn {
                margin-top: 30px;
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);

                &:hover {
                    background: var(--wb-view-button-hover-bg);
                    color: var(--wb-view-button-hover-text);
                    transition: background-color 0.3s ease;
                }
            }
        }


    }

    // title view
    .wb-large-tile {
        h2 {
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            font-size: 50px;
            font-weight: 400;
            line-height: 68.12px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 45px;
            }

            @media (max-width: 1024px) {
                font-size: 40px;
            }

            @media (max-width: 992px) {
                font-size: 35px;
            }

            @media (max-width: 420px) {
                font-size: 28px;
            }

        }

    }

    .wb-small-tile {
        h4 {
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-subheadingFont);
            font-size: 30px;
            text-transform: capitalize;
            font-weight: 400;
            line-height: 35px;
            text-align: center;

            @media (max-width: 1366px) {
                font-size: 25px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 20px;
                line-height: 20px;
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }


    }

    // title block

    .wb-title-block {
        margin: 25px 0;

        h3 {
            font-family: var(--wb-primaryFont);
            font-size: 20px;
            font-weight: 500;
            line-height: 25.2px;
            color: var(--wb-primary-text-color);
            margin-bottom: 20px;
            text-align: center;
            text-transform: capitalize;
        }

        p,
        .quil-text-block {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }

    // media view
    .wb-media-view {
        margin: 40px 0;

        @media (max-width:767px) {
            margin: 25px 0;
        }
    }

    // live stream
    .wb-live-stream-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 20px;
            font-weight: 400;
            color: var(--wb-primary-text-color);
            font-family: var(--wb-primaryFont);
            text-align: center;

        }

        p {
            color: var(--wb-sub-content-color);
            font-family: var(--wb-secondaryFont);
            font-size: 18px;
            font-weight: normal;
            line-height: 38px;
            text-align: center;

            @media (max-width:1024px) {
                font-size: 16px;
                line-height: 30px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 28px;
            }
        }

        a {
            font-size: 18px;
            font-weight: 600;
            color: var(--wb-secondary-text-color);
            font-family: var(--wb-secondaryFont);
            text-align: center;
            padding: 25px 0;
            text-decoration: underline !important;

            @media (max-width: 1280px) {
                font-size: 16px;
            }

            @media (max-width: 1199px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .wb-view-btn {
                margin-top: 30px;
                border: 1px solid var(--Wb-travel-button-border);
                color: var(--Wb-travel-button-text-color);
                background-color: var(--Wb-travel-button-bg);
            }
        }
    }

    // story view
    .wb-story-view-card {
        margin: 30px 0;

        .wb-cus-row {
            row-gap: 60px;
            align-items: center;
            justify-content: center;

            @media (max-width:1440px) {
                row-gap: 50px;
            }

            @media (max-width:1199px) {
                row-gap: 40px;
            }

            @media (max-width:992px) {
                row-gap: 35px;
            }
        }

        .wb-story-view-card-item {
            padding: 0 15px;
            width: calc(100% / 3);
            text-align: center;

            @media (max-width:767px) {
                width: 50%;
            }

            @media (max-width:640px) {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 200px !important;
                height: 300px !important;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 0px;
            }

            h4 {
                color: var(--wb-primary-text-color);
                font-family: var(--wb-primaryFont);
                font-size: 26px;
                font-weight: 700;
                line-height: 35px;
                margin: 10px 0 5px;
                text-transform: uppercase;

                @media (max-width:1366px) {
                    font-size: 22px;
                }

                @media (max-width:992px) {
                    font-size: 18px;
                    line-height: 25px;
                }

            }

            h6 {
                color: var(--wb-sub-content-color);
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 25.2px;

                @media (max-width:1366px) {
                    font-size: 18px;
                }

                @media (max-width:992px) {
                    font-size: 16px;
                }

            }
        }
    }

    // wbtemplate footer
    .wb-template-footer {
        padding: 50px 0;

        @media (max-width:1440px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        .wb-template-footer-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width:1440px) {
                gap: 10px;
            }

            h4 {
                font-family: var(--wb-primaryFont);
                font-size: 40px;
                font-weight: 400;
                line-height: 46.4px;
                color: var(--wb-primary-text-color);

                a {
                    color: inherit;
                }

                @media (max-width: 1440px) {
                    font-size: 35px;
                    line-height: 40.6px;
                }

                @media (max-width: 767px) {
                    font-size: 28px;
                    line-height: 32.8px;
                }
            }

            h6 {
                font-family: var(--wb-secondaryFont);
                font-size: 16px;
                font-weight: 400;
                line-height: 19.2px;
                color: var(--wb-primary-text-color);
                display: flex;
                align-items: center;
                gap: 6px;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 16.8px;
                }
            }

            .wb-template-heart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3px;

                @media (max-width: 767px) {
                    margin-top: 0px;
                }

                svg {
                    width: 13px;

                    path {
                        fill: var(--wb-heart-icon-color);
                    }
                }
            }

            p {
                font-family: var(--wb-secondaryFont);
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: var(--wb-primary-text-color);

            }

            ul {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    row-gap: 0px;
                    column-gap: 15px;
                }

                li {
                    a {
                        color: var(--wb-primary-text-color);
                        font-family: var(--wb-secondaryFont);
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 35px;

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }

    // events
    .wb-sandhya-events-section {
        padding-bottom: 100px;
        padding-top: 50px;

        @media (max-width: 1600px) {
            padding-bottom: 70px;
        }

        @media (max-width: 1199px) {
            padding-bottom: 50px;
        }

        .wb-website-event-wrp {
            .wb-cus-row {
                row-gap: 30px;

                @media (max-width: 767px) {
                    row-gap: 15px;
                }
            }

            .wb-cus-col-2 {
                width: calc(100% / 2);
                padding: 0 15px;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .wb-website-event-card {
                background-color: var(--wb-website-event-card-bg);
                padding: 60px;
                height: 100%;

                @media (max-width: 1440px) {
                    padding: 40px;
                }

                @media (max-width: 767px) {
                    padding: 30px;
                }

                .wb-website-event-date-time {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid var(--wb-event-date-border-color);
                    padding-bottom: 30px;
                    margin-bottom: 30px;

                    @media (max-width: 1440px) {
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }

                    @media (max-width: 767px) {
                        gap: 10px;
                    }

                    .start-date-time {
                        text-align: center;
                    }

                    .end-date-time {
                        text-align: center;
                    }

                    h6 {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 26.8px;
                        text-transform: uppercase;

                        @media (max-width: 1440px) {
                            font-size: 17px;
                            line-height: 19.8px;
                        }

                        @media (max-width: 992px) {
                            font-size: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 12px;
                        }
                    }

                    h5 {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 47px;

                        @media (max-width: 1440px) {
                            font-size: 24px;
                            line-height: 38px;
                        }

                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 30px;
                        }

                        @media (max-width: 767px) {
                            font-size: 15px;
                            line-height: 18px;
                        }
                    }

                    span {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-event-span-text-color);
                        background: var(--wb-event-span-bg);
                        font-size: 20px;
                        font-weight: 600;
                        line-height: normal;
                        background-color: var(--wb-event-btn-bg);
                        padding: 20px;
                        text-transform: capitalize;

                        @media (max-width: 1440px) {
                            font-size: 18px;
                            padding: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 15px;
                            padding: 10px;
                        }
                    }
                }

                .wb-website-event-details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h5 {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 40px;
                        font-weight: 600;
                        line-height: 48.59px;
                        margin-bottom: 20px;

                        @media (max-width: 1440px) {
                            font-size: 30px;
                            line-height: 35.59px;
                        }

                        @media (max-width: 992px) {
                            font-size: 26px;
                            line-height: 30.59px;
                            margin-bottom: 15px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                            line-height: 25.59px;
                        }
                    }

                    p {
                        font-family: var(--wb-secondaryFont);
                        color: var(--wb-website-event-text-color);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 34px;
                        text-align: center;

                        @media (max-width: 1366px) {
                            font-size: 16px;
                            line-height: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }

                    .wb-view-btn {
                        margin-top: 30px;
                        text-transform: uppercase;
                        background-color: var(--wb-event-btn-bg);
                        color: var(--wb-event-btn-text-color);

                        @media (max-width: 992px) {
                            margin-top: 20px;
                        }

                        &:hover {
                            background-color: var(--wb-event-btn-hover-bg);
                            transition: background-color 0.3s ease;
                            color: var(--wb-event-btn-hover-text-color);
                        }
                    }
                }
            }
        }
    }


    .wb-password-access-form-wrp {
        padding: 60px;
        background: var(--password-access-form-bg);
        border-radius: 30px;
        max-width: 756px;
        width: 100%;
        margin: auto;

        .wb-view-btn {
            background-color: #fff;
            color: #000000;
            border-radius: 15px;
            padding: 15px 25px;

            &:hover {
                background-color: #f1f1f1e8;
            }
        }

        @media (max-width:1366px) {
            padding: 50px;
        }

        @media (max-width:767px) {
            padding: 20px;
        }

        .wb-access-form-title {
            text-align: center;

            h3 {
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                color: #fff;
                font-family: var(--wb-secondaryFont);
                margin-bottom: 10px;

                @media (max-width:1440px) {
                    font-size: 35px;
                    line-height: normal;

                }

                @media (max-width:1366px) {
                    font-size: 32px;
                }

                @media (max-width:1280px) {
                    font-size: 30px;
                }

                @media (max-width:992px) {
                    font-size: 24px;

                }
            }

        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            margin: 40px 0 0;

            @media (max-width:1366px) {
                margin: 30px 0 0;
                gap: 30px;
            }


            .wb-cus-input {
                background-color: #fff;
                border-radius: 10px;
                height: 60px;
                padding: 20px;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                border: 1px solid #cfcfcf;

                @media (max-width:767px) {
                    width: 100%;

                }

            }
        }
    }
}