@import "~stream-chat-react/dist/scss/v2/index.scss";
@import "_vars.scss";
// @import "_grid.scss";

@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin border {
    border: 1px solid #edb457;
    border-radius: 15px;
    margin-bottom: 10px;
}

.D-flex {
    @include flex;
}

.justify-center {
    justify-content: center;
}
// 30-09-24
// asked to change the "gradientBg" color as static
.gradientBg {
    background: #CE3B85;
}

.bgBtn {
    background: rgb(237, 181, 89);
    background: linear-gradient(50deg, rgba(237, 181, 89, 1) 0%, rgba(236, 156, 77, 1) 100%);
    color: #ffffff;
    font-size: 15px;
    font-weight: $primary-font-weight;
    border: none;
    width: 100%;
    border-radius: 60px;
    height: 40px;
    min-height: 40px;
    font-family: $primaryFont;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
        font-size: 13px;
    }
}
.orangebgBtn{
    background: #edb559;
    background: linear-gradient(50deg, #edb559, #ec9c4d);
}

.borderBtn {
    border: 1px solid rgb(236 160 79);
    color: #eda351;
    font-size: 15px;
    font-weight: $primary-font-weight;
    width: 100%;
    border-radius: 60px;
    height: 40px;
    min-height: 40px;
    font-family: $primaryFont;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;

    @media (max-width: 767px) {
        font-size: 13px;
    }
}
.pinkBtn{
    font-size: 14px;
    border: 1px solid;
    border-radius: 30px;
    background: none;
    padding: 0px 12px;
    height: 26px;
    background-color: #d1468c;
    color: #fff;
    @media (max-width: 767px) {
        font-size: 13px;
    }

}
.no-style-btn{
    padding: 0;
    background: none;
    .infotext{
        top:33px !important;
    }

}
.auto-width-btn{
    width: auto;
    padding: 5px 40px;
}
.text-right{
    text-align: right;
}
.pink-edit-icon{
    width: 14px !important;
    height: auto;
    path{
        stroke: #c06;
    }
}
///====HEAD=======///

.removeImage {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: -9px;
    right: 0;
    left: 0;
    margin: auto;
    font-size: 15px;
    cursor: pointer;
}

.sliderWrapper {
    padding: 10px;
    margin-bottom: 30px;

    &.rateOne {
        .progress {
            background-color: #f15908;
        }

        .slider {
            background: #f6e5cb;
        }

        h4 {
            color: #f15908 !important;
        }
    }

    &.rateTwo {
        .progress {
            background-color: #fa7044;
        }

        .slider {
            background: #f9efe2;
        }

        h4 {
            color: #fa7044 !important;
        }
    }

    &.rateThree {
        .progress {
            background-color: #f3b240;
        }

        .slider {
            background: #f9efe2;
        }

        h4 {
            color: #f3b240 !important;
        }
    }

    &.rateFour {
        .progress {
            background-color: #cedf52;
        }

        .slider {
            background: #f9efe2;
        }

        h4 {
            color: #cedf52 !important;
        }
    }

    &.rateFive {
        .progress {
            background-color: #56c625;
        }

        .slider {
            background: #f6e5cb;
        }

        h4 {
            color: #56c625 !important;
        }
    }

    .fixedHeight {
        height: 137px;
    }

    span.progress {
        height: 10px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 4px;
        margin: auto;
        border-radius: 15px 0 0 15px;
    }

    .slide {
        position: relative;
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 10px;
        outline: none;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        border-radius: 25px;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: #fff;
        border: solid 2px #ccc;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        z-index: 10;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #04aa6d;
        cursor: pointer;
    }

    .labelName {
        display: flex;
        width: 100%;
        justify-content: space-between;

        span {
            &:first-child {
                color: #f15908;
            }

            &:last-child {
                color: #56c625;
            }
        }
    }
}

.formField {
    max-width: 768px;
    margin: 0 auto;
    position: relative;
    display: contents;

    @media (max-width: 767px) {
        max-width: 600px;
        width: 100%;
    }

    .genderSelection {
        padding: 0 8px;
        margin-bottom: 20px;

        @media (max-width: 767px) {
            padding: 0;
        }
    }
}

.flag {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        margin-right: 5px;

        g {
            stroke: none !important;
        }
    }
    img{
        width: 20px;
        margin-right: 5px;
        margin-bottom: 0 !important;
    }
    span {
        color: $bodyTextColor;
        font-size: 15px;
        padding-top: 3px;
    }
}

.phone-field.form-control {
    padding: 8px 15px 5px 60px !important;
}

.fileds {
    display: flex;
    flex-wrap: wrap;

    .cmnInput {
        width: calc(100% / 2);
        margin-bottom: 15px;
        padding: 0 8px;

        &.checkField {
            width: calc(100%);

            .form-field {
                display: flex;

                p {
                    margin-top: 4px;
                }
            }
        }

        .labelOption {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                border: none;
                background: transparent;
                color: $secondaryColor;

                &.tooltipMsg {
                    width: auto;
                    height: auto;
                    padding: 0;

                    svg {
                        margin-right: 0;
                    }
                }
            }
        }

        .mandatoryIcon {
            color: $darkRed !important;
        }

        @media (max-width: 767px) {
            width: calc(100%);
            padding: 0;
        }

        .checkField {
            display: flex;
            align-items: flex-start;
        }

        .form-field {
            position: relative;
        }

        label {
            font-size: 14px;
            color: $bodyTextColor;
            margin: 0 0px 8px 5px;
            display: block;
        }

        .check {
            min-width: 20px;
            height: 20px;
            padding: 5px;
            margin: 0;
            margin-right: 15px;
            border-color: #edaf56;
            box-shadow: 0 0 0 #edaf56;
        }

        .form-control {
            display: block;
            border: 1px solid rgba(237, 180, 87, 0.7019607843) !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: 25px;
            width: 100%;
            height: 44px;
            background-color: #F6F1EA;
            padding: 5px 15px;
            color: $bodyTextColor;
            font-size: 15px;
            line-height: 33px;
        }

        textarea {
            display: block;
            border: 1px solid rgba(237, 180, 87, 0.7019607843);
            border-radius: 20px;
            width: 100%;
            background: none;
            padding: 25px !important;
            color: $bodyTextColor;
            font-size: 15px;
            resize: none;
        }

        .errortxt {
            float: left;
            margin: 10px 0 0 6px;
        }
    }
}

.cmnInput {
    width: calc(100%);
    margin-bottom: 15px;
    padding: 0 8px;

    &.checkField {
        width: calc(100%);

        .form-field {
            display: flex;

            p {
                margin-top: 4px;
            }
        }
    }

    @media (max-width: 767px) {
        width: calc(100%);
        padding: 0;
    }

    .checkField {
        display: flex;
        align-items: flex-start;
    }

    .form-field {
        position: relative;
    }

    label {
        font-size: 14px;
        color: $bodyTextColor;
        margin: 0 0px 8px 5px;
        display: block;
    }

    .form-control {
        display: block;
        border: 1px solid rgba(237, 180, 87, 0.7019607843);
        border-radius: 25px;
        width: 100%;
        height: 44px;
        background-color: transparent;
        padding: 5px 15px;
        color: $bodyTextColor;
        font-size: 15px;
        font-family: $primaryFont;
        line-height: 44px;

        &.readOnly {
            border-color: transparent;
            color: $bodyTextColor;
            padding: 0;
            border-radius: 0;
            height: auto;
            pointer-events: none;
            font-size: 15px;

            &::placeholder {
                color: $grayText;
            }
        }

        &input[type="date"] {
            text-align: left;
        }
    }
}
input{
    &::placeholder {
        color: $grayText !important;
        opacity: 1 !important;
      }

      &::-ms-input-placeholder {
        color: $grayText !important;
      }
}

.customSelect-wrp {
    text-align: initial;

    .customSelect__control {
        .customSelect__value-container {
            padding: 0 !important;
            margin-right: 30px !important;
        }
    }

    .customSelect__menu {
        z-index: 1111;
        margin-top: 0px;
    }

    .customSelect__control {
        border: 1px solid rgba(237, 180, 87, 0.7019607843) !important;
        border-radius: 50px;
        width: 100%;
        height: 44px;
        background: none;
        padding: 5px 15px;
        color: #fff;
        font-size: 15px;
        box-shadow: none;
        cursor: pointer;
        background-color: #F6F1EA;
    }

    .customSelect__option {
        font-size: 15px;
        color: $bodyTextColor;
        padding: 12px 12px;
    }

    .customSelect__option--is-selected {
        color: #fff;
    }

    .customSelect__indicator-separator {
        display: none;
    }

    input {
        height: auto;
    }

    .customSelect__indicator {
        color: #f2d098;
        padding: 0 !important;

        svg {
            margin-right: 0 !important;
        }
    }

    .customSelect__option--is-disabled {
        background-color: #80808045;
    }
    .customSelect__placeholder,
    .customSelect__input-container,
    .customSelect__single-value{
        color: $grayText;
    }

}

// COMMON STYLES
// ---------------------- SPLASH SCREEN ----------------------
.splash-wrp {
    position: relative;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        width: 100%;
        height: 190px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../public/images/flower-top.svg);
    }

    .logo {
        width: 200px;
        height: 200px;
        background-color: #ffffff00 !important;

        img {
            background-color: #ffffff00 !important;
        }
    }

    .splashBottom {
        position: absolute;
        bottom: 40px;
        width: 100%;
        left: 0;

        figure {
            margin-top: auto;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 15px;
            color: $secondaryColor;
            font-weight: $primary-font-weight;
            margin-bottom: 10px;

            @media (min-width: 1200px) {
                font-size: 18px;
            }
        }

        h5 {
            font-size: 15px;
            font-weight: $primary-font-weight;
            color: #ffeac6;

            @media (min-width: 1200px) {
                font-size: 18px;
            }
        }
    }
}

// ---------------------- SPLASH SCREEN ----------------------

// ---------------------- LANDING PAGE ----------------------

.landing-wrp {
    position: relative;
    text-align: center;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &::after {
        content: "";
        width: 100%;
        height: 190px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../public/images/flower-top.svg);
    }

    .landingWrap {
        width: calc(100% - 20px);
    }

    h4 {
        font-size: 38px;
        color: #e2f4fc;
        font-family: $secondaryFont;
    }

    h5 {
        font-size: 24px;
        color: #fff;
        font-weight: $primary-font-weight;
        margin-bottom: 20px;
    }

    p {
        color: #fff;

        @media (min-width: 1200px) {
            font-size: 16px;
        }
    }

    .logo {
        margin-top: auto;
        margin-bottom: 25px;

        img {
            max-width: 180px;
        }

        @media (min-width: 1200px) {
            margin: auto auto 25px;
        }
    }
}

.loginBtn-wrp {
    text-align: center;
    width: 500px;
    margin: 5vh auto 0;
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
    max-width: 300px;
    z-index: 10;

    @media (max-width: 767px) {
        right: 40px;
        position: absolute;
        bottom: 0;
        padding-bottom: 60px;
        left: 40px;
        margin-top: 0px;
        max-width: 100%;
        width: auto;
    }

    h6 {
        font-size: 15px;
        color: #ffffff;
        margin-top: 10px;

        a {
            color: #030303;
            cursor: pointer;
            border-bottom: 1px solid;
        }

        @media (min-width: 1200px) {
            font-size: 16px;
        }
    }

    &.direction {
        flex-direction: column;
        max-width: 430px;
        padding-bottom: 20px;
    }
    .bgBtn {
        background: #edb559;
        background: linear-gradient(50deg, #edb559, #ec9c4d);
    }
}

.bottomBtns-wrp {
    text-align: center;
    margin-top: 60px;
    padding: 0 8px;

    @media (max-width: 767px) {
        margin-top: 6vh;
        padding: 0;
    }

    @media (max-width: 375px) and (max-height: 667px) {
        margin-top: 5vh;
    }
}

// ---------------------- LANDING PAGE ----------------------

// ---------------------- LOGIN ----------------------

.login-wrp {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: row;
    min-height: 700px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        height: auto;
        min-height: 100vh;
    }

    &.directionColumn {
        flex-direction: column !important;
    }

    &::after {
        content: "";
        width: 100%;
        height: 190px;
        position: absolute;
        bottom: -40px;
        left: 0;
        background-image: url(../public/images/flower-bot.svg);
    }

    .loginLeft {
        width: 50%;
        height: 100%;
        position: relative;

        .logo {
            display: none;

            @media screen and (max-width: 767px) {
                display: block;
                text-align: center;
                position: absolute;
                width: 180px;
            }
        }

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 50vh;

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: -5px;
                left: 0;
                background-repeat: no-repeat;
                background-position: bottom;
                background-size: 100%;
                background-image: url(../public/images/login-shape.svg);
            }
        }

        figure {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .loginRight {
        width: 50%;
        padding: 40px;
        position: relative;
        z-index: 1;
        flex-grow: 1;
        overflow: auto;
        height: 100%;

        @media screen and (max-width: 767px) {
            width: 100%;
            padding-top: 0;
            height: auto;
            overflow: visible;
        }

        .loginForm {
            max-width: 430px;
            width: 100%;
            margin: auto;

            .text-end {
                text-align: end;
                padding: 0 8px;
            }

            .mandatoryIcon {
                color: #fff !important;
            }
        }

        .logo {
            display: block;
            text-align: center;
            margin: auto;
            width: 180px;
            position: unset;
            margin-bottom: 25px;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }

    .logo {
        text-align: center;
        position: absolute;
        top: 30px;
        bottom: 0;
        margin: auto;
        left: 30px;
        width: 180px;

        @media screen and (max-width: 767px) {
            top: auto;
            margin: auto;
            bottom: 40px;
            z-index: 1;
            right: 0;
            left: 0;

            img {
                width: 100%;
                max-width: 160px;
                margin: auto;
            }
        }
    }

    .cmnInput {
        label {
            color: #fff;
            -webkit-text-fill-color: #fff;
        }
    }

    .onBoardingimage {
        flex-grow: 1;
        max-height: 100vh;
        min-width: 50%;
        position: relative;
        min-height: 100vh;
        max-width: 50%;

        @media (max-width: 767px) {
            max-width: 100%;
            max-height: 48vh;
            min-width: 100%;
            position: relative;
            min-height: 48vh;
        }

        figure {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        svg {
            display: none;

            @media (max-width: 767px) {
                display: block;
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: auto;

                path {
                    fill: #d53183;
                }
            }
        }
    }

    .onBoardingtext {
        position: relative;
        flex-grow: 1;
        padding: 0px 40px;
        text-align: center;
        z-index: 10;
        display: flex;
        align-items: center;
        max-width: 450px;
        margin: 0 auto;

        @media (min-width: 768px) {
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width: 767px) {
            align-items: flex-start;
        }

        @media (max-width: 350px) {
            padding: 0px 20px;
        }

        h4 {
            font-size: 32px;
            color: #e2f4fc;
            font-family: $secondaryFont;
        }

        h5 {
            font-size: 32px;
            color: #ffca74;
            font-family: $secondaryFont;
            margin-top: -5px;
        }

        p {
            font-size: 15px;
            color: #ffeac6;
            margin-top: 15px;
            min-height: 70px;
        }

        .logo {
            top: -110px;
            bottom: auto;
            width: 100px;

            @media (min-width: 768px) {
                position: initial;
                margin: 0 0 30px;
            }
        }
    }

    &.onbaording-container {
        .loginBtn-wrp {
            @media (max-width: 767px) {
                margin-top: 0px;
                position: absolute;
            }
        }
    }

    .loginBtn-wrp {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 40px;

        @media (max-width: 767px) {
            left: 0;
            width: 100%;
        }

        .skip {
            width: 65px;
            margin-right: 10px;
            border: solid 1px #eeb560 !important;
            background: transparent;
            color: #ffca74;
        }

        .next {
            width: 140px;
        }

        .md {
            width: 215px;
        }

        .progressBtn {
            list-style: none;
            display: flex;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 0px;

            li {
                width: 6px;
                height: 6px;
                background: #fff;
                border-radius: 50%;
                margin: 3px;
                opacity: 0.5;
                cursor: pointer;

                &.active {
                    opacity: 1;
                }
            }
        }
    }
}

.d-none {
    display: none;
}

.forgotLink {
    font-size: 15px;
    display: inline-block;
    color: #030303;
    border-bottom: 1px solid;
}

.validationCheck {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

// ---------------------- LOGIN ----------------------
// ---------------------- PROFILE ----------------------

.bottom-shape {
    &::before {
        content: "";
        width: 100%;
        height: 175px;
        position: absolute;
        bottom: -40px;
        left: 0;
        background-image: url(../public/images/bottom-shape.svg);
        pointer-events: none;
    }
}

.innerHeader {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 11;
    top: 0;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    background: #e94c9b;

    @media (max-width: 767px) {
        background: transparent;
    }

    &.active {
        background: #fcf9f7;
        border-bottom: solid 1px #ffecce;
        box-shadow: 0 -15px 45px #0000000f;

        h1 {
            color: #e23b8f;
        }

        .backButton {
            path {
                stroke: #e23b8f;
            }
        }
    }

    h1 {
        font-size: 20px;
        color: #ffffff;
    }

    .backButton {
        background: none;
        position: absolute;
        left: 30px;
        padding: 0;
    }

    .notification-btn {
        background-color: transparent;
        padding: 0;
        position: absolute;
        right: 30px;

        @media (max-width: 767px) {
            right: 25px;
        }

        svg {
            width: 24px;
            height: 24px;
            margin-right: 0;

            path {
                fill: #e23b8f;
                stroke-width: 0;
            }
        }
    }
}

.createprofile-wrp {
    position: relative;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    padding: 65px 30px 0px 30px;
    overflow: hidden;
    height: 100vh;

    &.shape-h {
        height: 100vh !important;

        @media (max-width: 767px) {
            height: 100vh !important;
        }
    }

    &.backGround-1 {
        @media (max-width: 767px) {
            background-image: url(../public/images/profile-shape.svg);
            background-repeat: no-repeat;
            background-size: 100%;
            height: auto;
        }
    }

    &.backGround-2 {
        text-align: center;

        @media (max-width: 767px) {
            background-image: url(../public/images/background-2.svg);
            background-repeat: no-repeat;
            background-size: 100%;
        }

        .bottomBtns-wrp {
            position: absolute;
            bottom: 30px;
            width: 100%;
            left: 0;
            padding: 30px;

            button {
                max-width: 400px;
            }
        }

        .fileds {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            position: absolute;
            top: 0;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: auto;
            width: 80%;
            height: 100%;
            max-width: 400px;
            justify-content: center;

            h4 {
                font-family: $primaryFont;
                font-weight: $primary-font-weight;
                font-size: 18px;
                color: #e94c9b;
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 7px;

                span {
                    white-space: nowrap;
                    color: #e94c9b;
                    font-weight: $primary-font-weight;
                    font-size: 15px;
                }
            }

            .cmnInput {
                width: 100%;
            }

            label {
                text-align: left;
            }
        }

        .code-row {
            display: flex;

            .form-control {
                padding-right: 15px !important;
                margin: 0 5px;
                text-align: center;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .fileds {
        .cmnInput {
            .form-field {
                .form-control {
                    padding-right: 15px;
                }
            }
        }
    }

    .social-icons-wrp {
        padding: 0 8px;

        h5 {
            span {
                background-color: #F6F1EA;
                color: $linkColor;
            }

            &::before {
                background-color: $linkColor;
            }
        }

        .social-buttons button {
            border: 1px solid $linkColor;
            color: $linkColor;
            background-color: #F6F1EA;
        }
    }

    .TandC-P a {
        color: $linkColor;
        border-bottom: 1px solid;
    }
}

.formBox {
    margin-top: 30px;
    width: 100%;
}

.genderSelection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        font-size: 16px;
        color: $bodyTextColor;
        border: 1px solid #f2ca8980;
        border-radius: 50px;
        background: $boxbgColor;
        width: calc(100% / 2 - 8px);
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.active {
            color: #fff;
            background: $primaryColor;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            margin-right: 10px;
        }
    }
}

.profileImg {
    width: 215px;
    height: 215px;
    background-image: url(../public/images/profile-pic-bg.svg);
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;

    cursor: pointer;

    &.uploadImg {
        width: 125px;
        height: 125px;
        background-image: url(../public/images/proImg-bg.svg);
        position: relative;

        img {
            width: 98px;
            height: 98px;
        }

        .camicon {
            position: absolute;
            bottom: -4%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 50%;
            width: 32px;
            height: 32px;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    p {
        font-size: 41px !important;
        color: #cf2a7e !important;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 2px !important;
        width: calc(100% - 100px);
        height: calc(100% - 100px);
    }

    img {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border-radius: 50%;
    }
}

// ---------------------- PROFILE ----------------------

// ---------------------- PARTNER PROFILE SETUP ----------------------

.appContainer {
    position: relative;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 100px 30px 80px;
    overflow: hidden;

    h1 {
        font-size: 32px;
        color: #e23b8f;
        font-family: $secondaryFont;
        text-align: center;
    }

    p {
        text-align: center;
        margin: 15px 0 30px 0;

        em {
            font-style: initial;
            margin: 10px 0;
            display: inline-block;
        }
    }
}

// ---------------------- PARTNER PROFILE SETUP ----------------------

// ---------------------- Create profile popup ----------------------
.Modal-Open {
    overflow: hidden;
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 85%);
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-items: center;
    justify-content: center;
    z-index: 9999 !important;

    &.show {
        display: flex;
    }

    .popup {
        width: 95%;
        max-width: 420px;
        min-height: 150px;
        padding: 35px 25px 30px;
        border-radius: 25px;
        background-color: #fcf9f7;
        text-align: center;
        font-size: 15px;
        font-family: $primaryFont;
        position: relative;
        z-index: 9999;
        margin: 10px 0;
        overflow: hidden;

        h2 {
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            padding-bottom: 10px;
            color: $red;
        }

        .cropWrap {
            border: solid 1px #ccc;
            border-radius: 15px;
            margin-bottom: 15px;
            overflow: hidden;
            display: inline-flex;
        }

        &.eventPopup {
            background-image: url("/images/evetpopupbackground.svg");
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 100%;
            border: none;
            padding-top: 130px;

            @media (max-width: 767px) {
                padding-top: 95px;

                .closeBtn {
                    top: 12px;
                    right: 12px;

                    &:after,
                    &:before {
                        width: 16px;
                    }
                }
            }
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        .fileds {
            margin-top: 15px;

            .cmnInput {
                width: 100%;
                padding: 0;

                label {
                    text-align: left;
                }
            }
        }

        .closeBtn {
            border: none;
            background: transparent;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: absolute;
            top: 20px;
            right: 20px;

            &:after,
            &:before {
                content: "";
                position: absolute;
                top: 0;
                width: 18px;
                height: 1.5px;
                background-color: #f2d098;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            &:after {
                transform: rotate(45deg);
            }

            &:before {
                transform: rotate(-45deg);
            }
        }

        img {
            margin-top: -50%;
            margin-bottom: 10px;
        }

        h4 {
            font-size: 28px;
            color: #e23b8f;
            font-family: $secondaryFont;
            text-align: center;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                color: #e23b8f;
            }
        }

        .guestCountFiled {
            margin-top: 20px;
            margin: 35px 0 35px 0;
        }

        .vendorlock {
            ul {
                margin-bottom: 27px;

                li {
                    list-style: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 10px;

                    .disabled {
                        opacity: 0.5;
                    }
                }
            }

            img {
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 5px;
            }
        }

        .no-break {
            word-break: break-word;
        }
    }
}

// ---------------------- Create profile popup ----------------------

.eventCard {
    padding: 25px;
    border: solid 1px #f1c886;
    border-radius: 25px;
    width: 100%;
}

.borderLessCard {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 30px 0;

    li {
        text-align: center;
        background-image: url("/images/designedBorder.png");
        background-repeat: no-repeat;
        background-position: center bottom;
        background-origin: content-box;
        margin-bottom: 20px;
        padding: 25px;
        border: solid 1px #f1c886;
        border-radius: 25px;
        position: relative;
    }

    .event-block {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;

        .action-button:hover {
            cursor: pointer;
        }

        svg {
            width: 28px;

            path,
            polyline,
            line {
                stroke: #edb057;
            }
        }
    }

    h3 {
        font-family: $primaryFont;
        color: $secondaryColor;
        font-size: 32px;
        font-weight: $primary-font-weight;

        small {
            color: $red;
            font-size: 20px;
            font-family: $secondaryFont;
            display: block;
            margin-bottom: 0px;
            font-weight: 400;
        }
    }

    h4 {
        font-family: $primaryFont;
        color: $secondaryColor;
        font-size: 18px;
        font-weight: $primary-font-weight;
    }
}

.eventSet {
    display: flex;
    flex-direction: column;

    .fileds {
        flex-grow: 0;
    }

    .borderLessCard {
        flex-grow: 1;
        max-height: 100%;
        overflow-y: auto;
    }
}

.footer {
    background: #fcf9f7;
    border-bottom: solid 1px #ffecce;
    box-shadow: 0 15px 45px rgb(0 0 0 / 28%);
    padding: 15px 30px 30px;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 11;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    button {
        width: 100%;
        max-width: 600px;
    }

    .SetBtn {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.onboardingWrapper {
    display: flex;
    width: 100%;
    height: 80vh;
    overflow: hidden;

    .mb-20 {
        margin-bottom: 20px;
        display: block;
    }

    @media (max-width: 767px) {
        height: 100vh;
        flex-direction: column;

        .mb-20 {
            margin-bottom: 10px;
        }
    }
}

.onbaording-container {
    @media (max-width: 767px) {
        .Mt-40 {
            margin-top: 30px;
        }
    }
}

.loaderContainer-Desiweds {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #d93386d9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .loader-wrapper {
        width: 80px;
        height: 80px;
        transition: all cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s;
        animation: bounce 2s infinite;
    }
}

@keyframes bounce {
    0% {
        transform: translatex(-100px) rotate(0deg);
    }

    50% {
        transform: translatex(100px) rotate(360deg);
    }

    100% {
        transform: translatex(-100px) rotate(0deg);
    }
}

.circleLoader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;

    .cirecleLoadericon {
        width: 20px;
        height: 20px;
        border: solid 2px #ffffff5e;
        border-top: solid 2px #fff;
        border-radius: 66px;
        transition: all ease-in-out 0.3s;
        -webkit-animation: rotate 1s infinite;
        animation: rotate 1s infinite;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.dashboardContainer {
    width: 100%;

    .dashboardHeader {
        button {
            width: 50px;
            height: 50px;

            &.Notification-btn {
                width: 45px;
                height: 45px;
                padding: 5px;

                span {
                    background-color: #fff;
                    top: 3px;
                    right: -8px;
                    color: $bodyTextColor;
                }

                @media (max-width: 767px) {
                    width: 50px;
                    height: 50px;
                    padding: 10px;
                    margin-left: 10px;

                    span {
                        background-color: #fff;
                        top: 5px;
                        right: -3px;
                        color: $bodyTextColor;
                    }
                }
            }
        }

        .Hanburger-Btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F6F1EA;
            border-radius: 50px;
            width: 45px;
            height: 45px;
            padding: 12px;

            @media (max-width: 767px) {
                width: 40px;
                height: 40px;
            }

            svg {
                margin-right: 0;
                width: 34px;
                height: 34px;

                g {
                    stroke: #d23282;
                    fill: none;
                }
            }
        }

        .Updateedit-Btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F6F1EA;
            border-radius: 50px;
            width: 45px;
            height: 45px;
            padding: 12px;
            border: none;

            @media (max-width: 767px) {
                width: 40px;
                height: 40px;
            }

            @media (min-width: 768px) {
                display: none;
            }

            svg {
                margin-right: 0;
                width: 20px;
                height: 20px;

                g {
                    stroke: #d23282;
                    fill: none;
                }
            }
        }

        &.fixed {
            .Hanburger-Btn {
                background: transparent;
            }

            .Updateedit-Btn {
                background: transparent;
            }
        }
    }
}

.subscription-Wrp {
    .dashboardHeader {
        svg {
            g {
                stroke: #e23b8f;
                fill: none;
            }
        }
    }
}

.dashboardHeader {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 101;
    top: 0;
    left: 0;
    padding: 40px;

    svg {
        g {
            stroke: #fff;
            fill: none;
        }
    }

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #fcf9f7 !important;
        border-bottom: solid 1px #f7e1bf3d;
        padding: 8px 35px;

        @media (max-width: 767px) {
            padding: 8px 15px;
        }

        .Notification-btn span {
            color: #fff !important;
            background-color: #e23baa !important;
        }
    }

    @media (max-width: 767px) {
        padding: 20px;
    }

    .navigationContainer {
        position: fixed;
        top: 0;
        left: -340px;
        width: 100%;
        background: #000;
        height: 100%;
        z-index: 100;
        padding: 40px 50px;
        background: url("/images/navBackground.jpg");
        background-size: cover;
        background-position: top left;
        justify-content: center;
        flex-direction: column;
        max-width: 340px;
        box-shadow: 0 15px 15px #0000004d;
        transition: all 0.4s;
        opacity: 0.2;

        @media (max-width: 767px) {
            max-width: 100%;
            box-shadow: none;
            padding: 30px;
            left: -100%;
            opacity: 0.3;
        }

        &.menuOpen {
            transition: all 0.4s;
            overflow: auto;
            left: 0px;
            opacity: 1;
        }

        &::after {
            content: "";
            width: 100%;
            height: 190px;
            position: absolute;
            bottom: 0px;
            left: 0;
            background-image: url(/_next/static/media/flower-bot.5dba8af9.svg);
        }

        button.n_close {
            position: absolute;
            left: 40px;
            top: 40px;
            padding: 0;
            width: 40px;
            height: 40px;
            z-index: 3;

            @media (max-width: 767px) {
                left: 25px;
                top: 25px;
            }
        }

        .logoSection {
            display: flex;
            justify-content: center;
            margin: 0 auto;

            .logo {
                width: 130px;
            }
        }

        ul {
            list-style: none;
            z-index: 2;
            position: relative;

            li {
                margin: 6px 0;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 600;
                color: #fff;

                a {
                    display: flex;
                    color: #fff;
                    align-items: center;
                    padding: 8px;
                    border-radius: 5px;

                    &:hover {
                        background: rgba(255, 255, 255, 0.082);
                    }

                    .iconWrap {
                        width: 20px;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                    }
                }

                &:last-child {
                    margin-top: 10px;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 2px;
            border-radius: 00%;
        }

        &::-webkit-scrollbar-track {
            height: 10px;
            padding: 60px;
            margin: 60px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #cf3180;
        }
    }

    button {
        width: 60px;
        height: 60px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }

    .option {
        display: flex;
        align-items: center;
    }

    .Updateedit-Btn {
        position: unset;
    }
}

.dashboardContainer {
    .shapeDasboard {
        bottom: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        left: 0;

        &.Shape {
            bottom: -1px;
        }
    }

    .dashboardSummary {
        width: 100%;
        background: url("/images/dashboardBackground.jpg");
        background-position: top center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-size: cover;
        min-height: 365px;
        padding: 50px 110px 102px 110px;

        @media (min-width: 768px) {
            background-color: #d93386;
            background-image: none !important;
            padding-top: 25px;
            justify-content: flex-start;
        }

        @media (max-width: 767px) {
            justify-content: center;
            padding: 80px 15px 60px 15px;
            min-height: 310px;
        }

        &::after {
            background-image: url(../public/images/flower-top.svg);
            content: "";
            width: 100%;
            height: 190px;
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: 767px) {
                background-image: none;
            }
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(#d93386, #c5307b);
            opacity: 0.7;
            z-index: 0;
        }

        h1 {
            position: relative;
            text-align: center;
            font-family: $secondaryFont;
            color: $white;
            font-size: 5em;
            font-weight: 200;
            @media (max-width: 1440px) {
                font-size: 4em;
            }

            @media (max-width: 767px) {
                font-size: 2.5em;
            }

            small {
                display: block;
                font-family: $secondaryFont;
                font-size: 40%;

                @media (max-width: 767px) {
                    font-size: 60%;
                }
            }
        }

        ul {
            position: relative;
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            margin: 25px auto 50px auto;

            @media (max-width: 767px) {
                margin: 15px auto 30px auto;
            }

            li {
                padding: 20px 30px;
                position: relative;

                @media (max-width: 767px) {
                    padding: 15px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 0.5px;
                    height: 15px;
                    background-color: $white;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                h4 {
                    font-family: $primaryFont;
                    color: $white;
                    font-size: 1.5em;
                    font-weight: $primary-font-weight;
                    text-align: center;

                    small {
                        display: block;
                        font-size: 50%;
                        font-weight: 300;
                    }
                }
            }
        }

        h3 {
            margin: 15px auto 60px auto;
            color: #ffff;
            z-index: 11;
            font-size: 20px;
            font-weight: 600;
        }

        a {
            margin: 15px auto 60px auto;
            color: #ffff;
            z-index: 11;
            font-size: 20px;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .dashboardContent {
        padding: 20px 30px;
        padding-bottom: 100px;
        max-width: 768px;
        margin: auto;

        @media (max-width: 767px) {
            max-width: 600px;
            padding: 20px;
            padding-bottom: 100px;
        }

        .vendor-name {
            h3 {
                font-size: 14px;
            }
        }
    }
}

.lineBtn {
    width: 100%;
    padding: 7px 15px 5px 15px;
    border: solid 1.5px $secondaryColor;
    color: $secondaryColor;
    border-radius: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $primary-font-weight;
    font-size: 16px;
    background: transparent;
    margin-bottom: 20px;
}

.btnHolder {
    list-style: none;
    display: flex;
    min-width: 100%;
    margin: 0 -5px;
    margin-bottom: 20px;

    button {
        margin: 0 6px;
    }

    li {
        flex-grow: 1;
        margin: 5px;
        padding: 12px 15px 12px 15px;
        border: solid 1.5px $primaryColor;
        background: #fff;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        cursor: pointer;

        &.active {
            border: solid 1.5px $primaryColor;
            background: $primaryColor;
            color: $white;

            &:first-child {
                .number {
                    background: #fff;
                    color: $primaryColor;
                }
            }
        }

        &:first-child {
            justify-content: space-between;

            .number {
                min-width: 20px;
                height: 20px;
                background: #edb5592e;
                border-radius: 25px;
                color: #edb559;
                padding: 2px;
                text-align: center;
            }
        }
    }
}

.cardYellow {
    width: 100%;
    padding: 20px;
    border: solid 1.5px $secondaryColor;
    background: $boxbgColor;
    border-radius: 6px;
    margin-bottom: 20px;

    .cardHeader {
        display: flex;
        align-items: center;

        h4 {
            margin-left: 10px;
            font-weight: 700;
            font-size: 16px;
            font-size: $secondaryFont;
            color: $secondaryColor;
        }
    }

    .cardBody {
        padding: 20px 0 0;

        p {
            font-size: 15px;
            margin-bottom: 0;
        }
    }
}

.qrCode-wrapper {
    width: 150px;
    height: 150px;
    margin: 0 auto;

    img {
        width: 100%;
    }
}

.btnWrap {
    margin: 30px auto;
    display: flex;

    button.roundBtn {
        width: 50px;
        height: 50px;
        padding: 0;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        box-shadow: none;

        img {
            margin: 0;
        }
    }
}

.mt-guest {
    margin-top: 30px !important;
}

.copyWrap {
    width: 100%;
    display: flex;
    border: solid 1px #edb258;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyText {
        padding: 0 0 0 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }

    button.roundBtn {
        width: 50px;
        height: 50px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        box-shadow: none;

        img {
            margin: 0;
        }
    }
}

.stickyNav {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    justify-content: center;
    box-shadow: 0 -5px 35px #00000021;
    z-index: 11;
    background: #ffffff;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 59px;
        width: calc(50% - 50px);
        background-color: #fff;
    }

    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 59px;
        width: calc(50% - 50px);
        background-color: #fff;
    }

    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 2;

        li {
            width: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #fff;
            height: 59px;
            opacity: 0.5;

            img {
                max-width: 100%;
                width: 25px;
            }

            span {
                font-size: 15px;
            }

            &.homeBtn {
                background: url("/images/footerBanShape.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center top;
                width: 101px;
                padding: 0 21px 47px;
                opacity: 1;
                @media (max-width: 374px) {
                    width: 89px;
                    padding: 0 15px 47px;
                }


                img {
                    width: auto;
                }
            }
            &:hover{
                opacity: 1;
            }
        }

        .Menu-active {
            opacity: 1;
        }
    }
}

.guescardtList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li {
        width: calc(100% / 2 - 10px);
        margin: 5px;

        @media (max-width: 767px) {
            width: 100%;
            margin: 5px 0;
        }
    }
}

.guestCard {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: solid 1px #f7e1bf;
    border-radius: 20px;

    width: 100%;

    h4 {
        font-weight: $primary-font-weight;
        font-size: 16px;
        font-family: $primaryFont;
        color: #d93386;
        margin-bottom: 15px;
        text-align: left;

        small {
            display: block;
            color: #dcc39a;
            font-weight: 400;
            font-size: 13px;
            margin-top: 5px;
        }
    }

    .valueThumb {
        list-style: none;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #faf0f3;
            width: calc(100% / 2 - 5px);
            border-radius: 10px;
            position: relative;
            height: 26px;

            span {
                font-size: 14px;
                color: $bodyTextColor;
                display: block;
                padding: 5px;

                &.val {
                    background-color: #c5307b;
                    color: #fff;
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                }
            }
        }
    }

    ul.thumb {
        list-style: none;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-top: solid 1px #edb358;
        padding-top: 15px;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .number {
                width: 60px;
                height: 60px;
                background: #e23b8f;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #fff;
                font-size: 18px;
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    top: 5px;
                    bottom: 5px;
                    left: 5px;
                    right: 5px;
                    border: solid 1px rgba(255, 255, 255, 0.473);
                    border-radius: 50%;
                }
            }

            span {
                padding: 10px 0;
            }
        }
    }
}

.cardWrap {
    width: calc(100%) !important;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        margin: 0 -30px;
        width: calc(100% + 60px) !important;
        margin-bottom: 20px;
    }
}

.cardContainer {
    padding: 20px;
    background: $boxbgColor;

    color: $bodyTextColor;
    border-radius: 5px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 1px #f7e6cc;

    .card-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 14px;

        h4 {
            font-family: $secondaryFont;
            font-size: 18px;
            color: $grayText;

            small {
                font-family: $primaryFont;
                font-size: 15px;
                margin-left: 8px;
            }
        }

        h3 {
            font-family: $primaryFont;

            font-weight: 500;
            font-size: 15px;

            display: flex;
            align-items: center;
            color: $grayText;

            small {
                font-family: $secondaryFont;
                font-size: 18px;
                font-weight: 300;
                margin-left: 6px;
                margin-right: 8px;
            }
        }
    }

    .card-matter {
        position: relative;
        z-index: 2;
        display: flex;

        h3 {
            font-size: 18px;
            font-weight: $primary-font-weight;
            color: $white;
            margin-bottom: 10px;
        }

        p {
            color: $bodyTextColor;
            font-size: 15px;
            margin-top: 14px;
            font-weight: 600;
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }
}

.DashBoardTodoBtn {
    margin-top: 30px;
    .btnHolder {
        list-style: none;
        display: flex;
        min-width: 100%;
        margin: 0 -5px;
        margin-bottom: 20px;

        button {
            margin: 0 6px;
        }

        li {
            flex-grow: 1;
            margin: 5px 0 5px 0;
            padding: 12px 15px 10px 15px;
            border: solid 1.5px #ffffff00;
            border-bottom: solid 1.5px $primaryColor;
            background: transparent;
            border-radius: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;

            &.active {
                border: solid 1.5px #ffffff00;
                background: #ffffff00;
                color: $primaryColor;
                border-bottom: solid 2px $primaryColor;
                border-radius: 0;

                span {
                    color: $primaryColor;
                }

                &:first-child {
                    .number {
                        min-width: 20px;
                        height: 20px;
                        background: $primaryColor;
                        border-radius: 25px;
                        color: #fff;
                        padding: 2px;
                        text-align: center;
                    }
                }
            }

            &:first-child {
                justify-content: space-around;
                align-items: baseline;

                .number {
                    min-width: 20px;
                    height: 20px;
                    background: #edb5592e;
                    border-radius: 25px;
                    color: #edb559;
                    padding: 2px;
                    text-align: center;
                }
            }

            span {
                color: $grayText;
            }
        }
    }
}

.cardHeaderDash {
    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
    }

    h4 {
        color: #d93386 !important;
    }
}

.vendorsH4 {
    font-weight: $primary-font-weight;
    font-size: 16px;
    color: $red;
    margin-bottom: 15px;
}

.vendorcardContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;

    &.disabled {
        opacity: 0.5;
    }

    .vendor-image {
        width: 75px;
    }

    .vendor-name {
        h3 {
            margin-top: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
            font-size: 15px;
            text-align: center;
        }
    }

    .vendorProfileName {
        word-break: break-all;
    }

    .ProfileVendorimg {
        width: 100%;
        text-align: center;
    }
}

.fixed {
    nav {
        svg {
            g {
                stroke: #c5307b;
                fill: none;
            }
        }
    }

    .Updateedit-Btn {
        svg {
            g {
                stroke: #fff;
            }
        }
    }

    .option {
        svg {
            path {
                fill: none;
                stroke: #c5307b;
            }
        }
    }
}

.errormsg,
.errormsg .errortxt {
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 13px;
    position: absolute;
}

.errormsg {
    bottom: 0;
    cursor: pointer;
    height: 24px;
    margin: auto;
    margin-top: 5px !important;
    right: 12px;
    text-align: right;
    top: 8px;
    width: 24px;

    &.rightSpan {
        right: 38px !important;

        @media (max-width: 767px) {
            right: 35px !important;
        }
    }

    svg {
        z-index: 1;
        position: relative;
        margin-right: 0;
    }
}

.errormsg .errortxt {
    background: #edb157;
    margin-top: 10px;
    min-width: 180px;
    opacity: 0;
    padding: 4px 12px;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 9999;

    @media (hover: none) {
        &.open {
            opacity: 1 !important;
        }

        &.close {
            opacity: 0 !important;
        }
    }
}

.errormsg,
.errormsg .errortxt {
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 13px;
    position: absolute;
}

.errormsg .errortxt:after {
    border-bottom: 7px solid #edb157;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    left: auto;
    position: absolute;
    right: 5px;
    top: -6px;
}

.errormsg:hover .errortxt,
.errormsg:active .errortxt {
    opacity: 1;
    pointer-events: all;

    @media (max-width: 767px) {
        pointer-events: none;
    }
}

.ErrDiv {
    width: 40px;
    height: 40px;
    background-color: #d8338600 !important;
    position: absolute;
    top: -11px;
    left: -10px;
    z-index: 0;
}

.textCenter {
    text-align: center !important;
}

.guestMgt {
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;

    &.centerContent {
        align-items: center;
        text-align: center;
        width: 100%;
    }

    img {
        margin-bottom: 30px;
    }

}
.guestUpload{
    h5{
        font-size: 14px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        width: 100%;
    text-align: left;
    margin-bottom: 15px;
    }
    .link-button-pink {
        margin-top: 0;
        border-bottom: 1px solid;
        margin-bottom: 35px;
    }
    &.guestMgt img {
        margin-bottom: 15px;
        cursor: pointer;
    }
    .vendorsH4 {
        font-size: 20px;
    }
    .appContainer {
      padding-bottom: 20px;
    }
}
.intsruction-popup{

    @media (min-width: 577px) {
        &.Vendor-Unlock .modal-header {
            height: 134px;
            background-size: cover;
        }
        .popup {
            max-width: 550px;
            h4 {
                margin-top: 20px;
            }
        }
    }

    ul{
        text-align: left;
        margin-left: 20px;
        li{
            margin-bottom: 10px;
        }
    }
    .intsruction-content{
        padding-right: 10px;
        max-height: 45vh;
        overflow: auto;
        margin-bottom: 35px;
    }
}
.guest-btns-wrp{
    display: flex;
}
.file-choose {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.action-btn-holder {
    width: 100%;
    margin-top: 60px;

    button {
        margin-bottom: 10px;
    }

    .goldenLineBtn {
        background: transparent;
        font-size: 15px;
        font-weight: $primary-font-weight;
        width: 100%;
        border-radius: 60px;
        height: 40px;
        font-family: $primaryFont;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: solid 2px #edb559;
        color: #edb559;
    }
}

.uploadBtncam {
    width: 150px;
    border-radius: 25px;
    margin: -10px auto 30px auto;
    border: solid 1px #ccc;
    min-height: 36px;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    justify-content: center;
    background: rgb(237, 181, 89);
    color: #ffffff;

    &:hover {
        background-color: #e94c9b;
        transition-delay: 0.1s;
        transition-duration: 1s;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    svg {
        width: 18px;
        margin-right: 10px;

        g {
            stroke: #ccc;
        }
    }
}

svg {
    width: 18px;
    margin-right: 10px;
}

.showDraganddrop {
    border: dashed 1px #ccc;
    background-color: #ffffff87;
    border-radius: 25px;
    position: relative;

    h2 {
        position: absolute;
        font-size: 18px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profileImg {
        opacity: 0.3;
    }
}

.uploadedFile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background: #fff;
    border-radius: 5px;

    span {
        color: #dcc39a;
        font-size: 15px;
        font-family: $primaryFont;
    }

    .removeFile {
        background: transparent;
        padding: 0;
        min-height: auto;
        margin-left: 10px;
        display: flex;
        align-items: center;

        img {
            margin-bottom: 0;
        }
    }
}

.budget {
    ul.chartResult {
        list-style: none;
        padding: 0;
        margin-bottom: 30px;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            div {
                display: flex;
                align-items: center;

                .text {
                    color: $grayText;
                    display: inline-block;
                    padding-top: 3px;
                }
            }

            .colorCode {
                display: block;
                width: 22px;
                height: 22px;
                border-radius: 30px;
                background: $bodyTextColor;
                margin-right: 10px;

                &.orange {
                    background: #fa7044 !important;
                }

                &.purple {
                    background: #e3368e !important;
                }

                &.blue {
                    background: #009fde !important;
                }
            }

            h4 {
                font-size: 16px;
                font-weight: $primary-font-weight;
                color: $secondaryColor;
            }
        }
    }

    .chartArea {
        width: 250px;
        margin: 0 auto 30px;
        position: relative;

        .detailsWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;

            span {
                color: $grayText;
                font-size: 13px;
            }

            h3 {
                font-family: $primaryFont;
                font-size: 20px !important;
                color: #edb457;
                font-weight: 600;
                margin: 7px 0;
            }

            button {
                padding: 0;
                width: 30px;
                height: 17px;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 100%;
                }
            }
        }
    }

    h1 {
        font-size: 48px;
        color: $secondaryColor;
        margin: 30px auto;
    }

    .btnHolder {
        li {
            font-weight: 600;
            text-align: center;
            color: $secondaryColor;
            background-color: transparent;

            &:first-child {
                justify-content: center;
            }

            &.active {
                background-color: #edb559;
                border-color: #edb559;
                color: #fff;
            }
        }
    }
}

.contractsCard {
    padding: 6px 6px 10px 6px;
    background: $boxbgColor;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    margin-left: 0;
    -webkit-tap-highlight-color: transparent;
    .videoCard {
        width: 100%;
        height: 100%;

        .videoWrap {
            width: 100%;
            height: 100%;
            border-radius: 25px !important;
            overflow: hidden;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            max-width: 374px;
            max-height: 185px;
            object-fit: cover;
            min-height: 185px;
            vertical-align: middle;

            @media (max-width: 767px) {
                min-height: 140px;
                max-height: 140px;
            }

            @media (max-width: 320px) {
                min-height: 140px;
                max-height: 140px;
            }
        }
    }
    .contract-image {
        border-radius: 10px;
        overflow: hidden;
    }

    .vendor-name {
        padding: 10px;

        h3 {
            font-family: $primaryFont;
            font-size: 16px;
            color: $secondaryColor;
            font-weight: 600;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-right: 6px;
            max-height: 19.17px;

            small {
                display: block;
                font-size: 15px;
                font-family: $secondaryFont;
                color: $red;
                margin-top: 5px;
                font-weight: 400;
            }
        }

        ul {
            list-style: none;
            display: flex;

            li {
                flex-grow: 1;
                position: relative;

                h4 {
                    color: $grayText;
                    font-size: 13px;
                    font-family: $primaryFont;

                    small {
                        display: block;
                        margin-top: 5px;
                        color: #fa7044;
                        font-size: 13px;
                        font-weight: 600;
                    }
                }

                &:last-child {
                    padding-left: 20px;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        width: 2px;
                        height: 15px;
                        background-color: $secondaryColor;
                        opacity: 0.5;
                    }

                    h4 {
                        small {
                            color: #e23b8f;
                        }
                    }
                }
            }
        }
    }
    .fav-icon{
      margin-left: auto;
    }
}

.swiper {
    width: auto !important;
    margin-bottom: 25px !important;

    @media (max-width: 767px) {
        margin: 0 -20px 20px !important;
    }

    .vendor-image {
        img {
            width: 75px !important;
            height: 75px;
            border: solid 1px #fff;
            background-color: #ffffff73;
            border-radius: 50px;
            object-fit: fill;
        }

        .ImageVendor {
            width: 100%;
            height: 100%;
            padding: 15px;
            min-width: 160px;
            max-height: 196px;
            object-fit: cover;
            border-radius: 80px;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////EM1379
.todo-wrapper {
    @media (max-width: 767px) {
        margin: 0;
    }

    .cardContainer {
        margin-bottom: 15px;
    }

    .btnHolder {
        list-style: none;
        display: flex;
        min-width: 100%;
        margin: 0 -5px;
        margin-bottom: 20px;

        button {
            margin: 0 6px;
        }

        li {
            justify-content: space-between;

            &.active {
                border: solid 1.5px $primaryColor;
                background:$primaryColor;
                color: $white;

                .number {
                    background: #fff;
                    color: $primaryColor;
                }
            }

            .number {
                min-width: 20px;
                height: 20px;
                background: $primaryColor;
                border-radius: 25px;
                color: #fff;
                padding: 2px;
                text-align: center;
                line-height: 17px;
                font-size: 14px;
            }
        }
    }
}

.date-pick {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .calenderIcon {
        margin-top: -12px;

        svg {
            width: 34px;
            height: 34px;
        }
    }
}

.edit-todo-section {
    .pendingBtn {
        font-size: 15px;
        background: #e23b8f;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 25px 3px 25px;
        margin-bottom: 32px;

        @media (max-width: 767px) {
            padding: 3px 15px 3px 15px;
        }
    }

    .completeBtn {
        width: 50%;
        font-size: 15px;
        background: #2aacdf;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 25px 3px 25px;
        margin-bottom: 32px;

        @media (max-width: 767px) {
            padding: 3px 15px 3px 15px;
        }
    }

    .duedateH5 {
        font-weight: 400;
        font-size: 14px;
        color: #d93386;
    }

    p {
        font-weight: 400;
        font-size: 15px;
        color: $bodyTextColor;
        margin: 13px 0 27px 0;
        text-align: left;
    }
}

.required-confirmBtnSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    position: relative;

    @media (max-width: 767px) {
        padding: 0;
    }

    .notRequiredBtn {
        width: 48%;
        color: #edb559;
        background-color: #fff;

        border: 1px solid #e59200;
        font-size: 15px;
        font-weight: $primary-font-weight;
        border-radius: 50px;
        padding: 6px 10px;
        height: 40px;
    }

    .confirmBtn {
        width: 48%;
        color: #fff;
        background: linear-gradient(50deg, rgb(237, 181, 89) 0%, rgb(236, 156, 77) 100%);
        border: 1px solid #edb559;
        font-size: 15px;
        font-weight: $primary-font-weight;
        border-radius: 50px;
        padding: 6px 10px;
        height: 40px;
    }

    .btnLoader {
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: center;
    }
}

.fileds {
    .mtaskInput {
        padding: 0;
    }
}

.TodoFormHead {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .TodoFormH2 {
        h2 {
            font-size: 15px;
            font-weight: 400;
            color: #e23b8f;
        }

        p {
            text-align: left;
        }
        a{
    color: $linkColor;
    border-bottom: 1px solid;
    background: none  !important;
    padding: 0;

        }
    }

    .pendingBtn {
        font-size: 14px;
        background: #e23b8f;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 25px 3px 25px;
        margin-bottom: 32px;

        @media (max-width: 767px) {
            padding: 0px 15px 0px 15px;
        }
    }
}

.todoFrm {
    .fileds {
        .cmnInput {
            @media (max-width: 767px) {
                margin-bottom: 24px;
            }

            input {
                position: relative;
            }

            input::-webkit-calendar-picker-indicator {
                display: block;
                top: 0;
                left: 0;
                background: #0000;
                position: absolute;
                transform: scale(50);
            }
        }

        &.CmpeletCheck {
            margin-left: 16px;

            .custom-checkbox {
                p {
                    margin: 5px 0 0 10px;
                }
            }
        }
    }
}

.todoCard {
    .required-confirmBtnSec {
        @media (max-width: 767px) {
            margin-top: 18vh;
            padding: 0;
        }
    }

    .reOpenBtnsec {
        @media (max-width: 767px) {
            margin-top: 18vh;
            padding: 0;
        }
    }

    .fileds {
        &.CmpeletCheck {
            margin-left: 16px;

            .custom-checkbox {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                position: relative;

                .checkbox-label {
                    display: block;
                    position: relative;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    input:checked~.checkmark {
                        background-color: #e23b8f;
                    }

                    input:checked~.checkmark:after {
                        display: block;
                    }

                    .checkmark {
                        position: absolute;
                        top: -8px;
                        right: 0;
                        left: auto;
                        height: 18px;
                        width: 18px;
                        background-color: #ffffff;
                        border: 1px solid #f7e1bf;
                        border-radius: 5px;

                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                            left: 6px;
                            top: 3px;
                            width: 3px;
                            height: 6px;
                            border: solid #ffffff;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                            cursor: pointer;
                        }
                    }
                }

                p {
                    margin: 5px 0 0 10px;
                }
            }
        }
    }
}

.wbgBtn {
    width: 100%;
    color: #edb559;
    background-color: #F6F1EA;

    border: 1px solid #e59200;
    font-size: 15px;
    font-weight: $primary-font-weight;
    border-radius: 50px;
    height: 40px;

    @media (max-width: 767px) {
        font-size: 13px;
        line-height: 13px;
    }
}

.budgetFooter {
    justify-content: center;

    button {
        max-width: 200px;
        margin: auto 10px;

        @media (max-width: 767px) {
            width: 100%;
            margin: auto 6px;
        }
    }
}

.planBudget {
    h1 {
        font-family: $primaryFont;
        font-size: 24px;
        margin: 5px 0 30px 0;
    }
}

.budGethead {
    h1 {
        font-family: $primaryFont;
        font-size: 28px;
        margin: 5px 0 3px 0;
    }
}

.budGetH1 {
    h1 {
        font-family: $primaryFont;
        font-size: 48px;
        margin: 40px 0 40px 0;

        small {
            font-size: 30px;
        }

        span {
            opacity: 0.4;
        }
    }

    .wbgBtn {
        margin-top: 12px;
    }

    .disabled {
        opacity: 0.5;
    }
}

.budgeth4 {
    font-size: 18px;
}

.budGetDiv {
    span {
        color: #d93386;
    }
}

.valueSec {
    margin: 8px 0 30px 0;

    button {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 50px;
        margin-bottom: 6px;
    }

    .revisedValueBtn {
        padding: 10px 25px 10px 25px;
        background: #d93386;

        span {
            color: #fff;
            font-size: 15px;
            font-weight: $primary-font-weight;
        }
    }

    .setValueBtn {
        padding: 10px 25px 10px 25px;
        background: #ffffff;
        border: 1px solid #d93386;

        span {
            color: #d93386;
            font-size: 15px;
            font-weight: $primary-font-weight;
        }
    }
}

.priceSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
        color: #efbc6a;
        font-size: 24px;
        font-weight: 400;
    }

    .fileds {
        width: 70%;

        @media (max-width: 360px) {
            width: 67%;
        }

        .cmnInput {
            margin-bottom: 0;

            .form-field {
                display: flex;
                align-items: center;

                .form-control {
                    border: none !important;
                    color: #efbc6a;
                    font-size: 24px;
                    font-weight: 400;
                    padding: 0;
                    padding-left: 5px;

                    &::placeholder {
                        color: #efbc6a;
                        font-size: 24px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.priceChangeDiv {
    display: flex;
    flex-wrap: wrap;

    button {
        margin: 0 4px;
    }

    .minusIconBtn {
        background-color: #fff;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #edae56;
        width: 32px;
        height: 32px;

        svg {
            margin-right: 0%;
            width: 100%;
        }
    }

    .addIconBtn {
        background: linear-gradient(50deg, rgb(237, 181, 89) 0%, rgb(236, 156, 77) 100%);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        svg {
            margin-right: 0%;
            width: 100%;
        }
    }
}

.budgetValueList {
    margin-bottom: 28px;
    padding: 0 5px;
}

//---------------Onboarding---------------//
.OnboardMain {
    margin: 8rem 0 5rem 0;
    text-align: center;

    @media (max-width: 767px) {
        margin: 2rem 0 5rem 0;
    }

    h4 {
        font-size: 18px;
        margin: 42px 0 32px 0;
    }

    small {
        color: #eca24f;
        font-size: 13px;
        margin-top: 30px;
    }
}

.mt-0 {
    margin-top: 40px;
}

.mt-5px {
    margin-top: 5px;
}
.ml-2px {
    margin-left: 2px;
}
.ml-10px{
    margin-left: 10px;
}
.my-0{
    margin-top: 0;
    margin-bottom: 0;
}
.unlock-text {
    margin: 0 5px 15px 5px !important;
}

.QuestionnaireSec {
    h4 {
        font-size: 28px;
        color: #f15908;
        font-weight: 400;
        font-family: $secondaryFont;
    }

    p {
        margin-bottom: 37px;
    }

    .MCQsec {
        .cmnInput {
            .form-field {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #edb457;
                border-radius: 50px;
                padding: 17px 25px;

                .check {
                    min-width: 20px;
                    height: 20px;
                    padding: 5px;
                    margin: 0;
                    margin-left: 15px;
                    border-color: #edaf56;
                    box-shadow: 0 0 0 #edaf56;
                }

                p {
                    text-align: left;
                    margin: 0;
                }
            }
        }
    }
}

.QuestionnaireContainer {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* padding: 65px 30px 50px 30px; */
    overflow: hidden;

    .QuestionnaireContent {
        padding: 20px 30px;
        padding-bottom: 100px;
        max-width: 600px;
        margin: 0 auto 132px auto;

        @media (max-width: 767px) {
            margin: 0 auto;
        }

        .bottomBtns-wrp {
            position: absolute;
            bottom: 20px;
            width: 100%;
            left: 0;
            padding: 30px;

            button {
                max-width: 400px;
            }
        }

        h1 {
            font-family: $secondaryFont;
        }
    }

    .shapeDasboard {
        bottom: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        left: 0;
    }

    .dashboardSummary {
        width: 100%;
        height: 30vh;
        background: url("/images/onboardShape.svg");
        background-position: top center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        background-size: contain;

        @media (max-width: 767px) {
            justify-content: center;
            height: 25vh;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: -2px;
            left: 0;
            background: linear-gradient(#d93386, #c5307b);
            z-index: -1;
        }

        ul {
            position: relative;
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            margin: 25px auto 50px auto;

            @media (max-width: 767px) {
                margin: 15px auto 0px auto;
            }

            li {
                padding: 0;
                position: relative;
                background-color: #fff;
                width: 30px;
                opacity: 0.4;
                height: 5px;
                margin-right: 3px;
                border-radius: 50px;

                @media (max-width: 767px) {
                    padding: 0;
                }

                span {
                    background-color: #fff;
                }
            }

            .active {
                background-color: #fff;
                opacity: 1;
            }
        }

        p {
            margin: 45px 25px 10px 25px;
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-weight: $primary-font-weight;

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
    }
}

.venderContainer {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 65px 30px 50px 30px;
    overflow: hidden;

    h1 {
        font-family: $secondaryFont;
    }

    p {
        margin-bottom: 35px;
    }
}

.IdentifyQuizWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    .identityCheckbox {
        width: 47%;
        position: relative;
        overflow: hidden;

        .ImgCheckbox {
            position: absolute;
            width: 100%;
            height: 96%;
            top: 0;
            left: -3px;
            appearance: none;
        }

        .BrideImg {
            width: 100%;
            position: relative;
            cursor: pointer;

            img {
                height: 100%;
                width: 100%;
            }
        }

        :checked:before {
            content: url("/images/checkmark.svg");
            transform: scale(1);
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

//---------------Vendors---------------//

.vendor {
    .action-btn-holder {
        z-index: 11;

        @media (max-width: 767px) {
            margin-top: 50px;
        }
    }

    .OnboardMain {
        margin: 3rem 0 0rem 0;

        @media (max-width: 767px) {
            margin: 1rem 0 0rem 0;
        }

        p {
            margin: 15px 0 30px 0;
        }

        .action-btn-holder {
            margin-top: 40px;
        }
    }
}

.VendarListingcontainer {
    .VendrBanner {
        width: 100%;
        min-height: 15vh;
        background-image: none;
        background-color: #cf1f77;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        @media (max-width: 768px) {
            width: 100%;
            height: 37vh;
            background-image: url(/images/Vendorlistbackground.svg);
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: transparent;
        }

        p {
            font-weight: 400;
            color: #fff;
            font-size: 15px;
            margin: 55px 25px 10px 25px;
            text-align: center;
        }
    }

    .dashboardHeader {
        padding: 8px 40px;

        @media (max-width: 768px) {
            padding: 8px 40px 8px 13px;
        }

        .changehead {
            h1 {
                color: #fff !important;
            }
        }

        &.fixed {
            .changehead {
                h1 {
                    color: #e23b8f !important;
                }
            }
        }
    }

    .appContainer {
        padding: 40px 30px 50px 30px;

        @media (max-width: 768px) {
            padding: 20px 30px 50px 30px;
        }

        .VendorWrap {
            margin: 0 auto;
            min-width: 50%;
        }
    }
}

.VendorListcardContainer {
    .VendorListCard {
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        .VendorImage {
            width: 72px;
            height: 72px;
            border-radius: 50px !important;
            object-fit: cover !important;
            background-color: #ffffff73;
            vertical-align: middle;

            @media (max-width: 360px) {
                width: 55px;
                height: 55px;
            }
        }

        img {
            border-radius: 50px;
        }

        .LockedContent {
            opacity: 0.7;
        }

        .VendorListName {
            padding: 0 0 0 20px;

            @media (max-width: 768px) {
                width: 75%;
            }

            @media (max-width: 360px) {
                width: 65%;
            }

            h4 {
                font-size: 15px;
                font-weight: $primary-font-weight;
                color: $linkColor;
                text-decoration: underline;
                margin-bottom: 10px;
            }

            .VendorprofileList {
                img {
                    border: 2px solid #fff;
                    width: 25px;
                    height: 100%;
                }

                .vendorNoImage {
                    img {
                        padding: 4px;
                        background-color: #ededed !important;
                    }
                }

                p {
                    margin: 0;
                    text-align: left;

                    @media (max-width: 768px) {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-height: 2;
                    }
                }

                ul {
                    list-style-type: none;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 8px;

                    li {
                        margin-left: -10px;

                        &:nth-last-child(1) {
                            margin-left: -10px;
                            color: $grayText;
                            font-size: 10px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .lockedVendor {
            cursor: pointer;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(#d93386, #c5307b);
                opacity: 0.7;
                z-index: 0;
                border-radius: 50%;
            }

            &::after {
                content: "";
                position: absolute;
                width: 89%;
                height: 89%;
                top: 0px;
                left: 0;
                right: 0;
                z-index: 0;
                opacity: 0.7;
                border-radius: 50%;
                border: 1px solid #fff;
                margin: 3px 1px 1px 3px;

                @media (max-width: 360px) {
                    margin: 2px 1px 1px 2px;
                }
            }

            .lock {
                position: absolute;
                top: 35%;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

///----------vendorVenueResult-------//
.videoCard {
    position: relative;
    text-align: center;
    border-radius: 25px !important;
    overflow: hidden;

    .videoImage {
        position: relative;

        img {
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    .videoWrap {
        width: 100%;
        height: 100%;
        border-radius: 25px !important;
    }

    .videoPlayButton {
        img {
            position: absolute;
            top: 36%;
            bottom: 0;
            left: 49%;
            right: 35%;

            @media (max-width: 767px) {
                left: 48%;
            }
        }
    }
}

.WeddingTeam {
    p {
        text-align: left;
    }
}

.VenueResultContainer {
    padding-top: 3rem;

    .VendrBanner {
        width: 100%;
        height: 25vh;
        background-image: url(/images/venuBackgroundimage.png);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        text-align: center;
        padding: 0 40px;

        @media (min-width: 800px) {
            height: 15vh;
            background-color: #cf1f77;
            background-image: none;
        }

        @media (max-width: 1280px) {
            height: 25vh;
        }

        @media (max-height: 668px) {
            height: 27vh;
        }

        p {
            color: #fff;
            font-weight: 400;
            font-size: 15px;
        }

        h4 {
            color: #fff;
            font-weight: $primary-font-weight;
            font-size: 18px;
            margin-bottom: 15px;
        }

        ul {
            position: relative;
            list-style: none;
            padding: 0;
            display: flex;
            margin: 25px auto 0px auto;

            @media (max-width: 767px) {
                margin: 15px auto 0px auto;
            }

            li {
                padding: 0;
                position: relative;
                background-color: #fff;
                width: 30px;
                opacity: 0.4;
                height: 5px;
                margin-right: 3px;
                border-radius: 50px;

                @media (max-width: 767px) {
                    padding: 0;
                }

                span {
                    background-color: #fff;
                }
            }

            .active {
                background-color: #fff;
                opacity: 1;
            }
        }
    }

    .VendorAboutbanner {
        width: 100%;
        height: 20vh;
        background-image: url(/images/vendoraboutusimage.jpg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        text-align: center;
        z-index: -1;
    }

    .CircledImage {
        margin-top: -50px;
        border-radius: 50%;
        margin-bottom: -37px;
        width: 100px;
        height: 100px;
        border: 5px solid #fff;
        background-color: #fff;
    }
}

.venueResuktWrap {
    .appContainer {
        padding: 26px 30px 50px 30px;

        .VendorAboutWrap {
            h6 {
                font-size: 13px;
                font-weight: 400;
                font-family: $primaryFont;
                color: $grayText;
            }

            span {
                font-size: 15px;
                font-weight: $primary-font-weight;
                color: #d62c81;
            }

            .AboutTabContent {
                h4 {
                    font-family: $secondaryFont;
                    color: #e23b8f;
                    font-size: 18px;
                }
            }

            .btnHolder {
                list-style: none;
                display: flex;
                min-width: 100%;
                width: 100%;
                margin: 0;
                margin-top: 18px;
                margin-bottom: 20px;
                border-top: 1px solid #f5eee4;
                border-bottom: 1px solid #f5eee4;
                padding: 8px 0;
                background: #f6f1ea;

                @media (min-width: 800px) {
                    min-width: 768px;
                }

                span {
                    color: $bodyTextColor;
                    font-weight: 400;
                }

                .bgBtn {
                    margin: 0 5px;
                }

                li {
                    flex-grow: 1;
                    margin: 0px;
                    padding: 7px 3px 7px 3px;
                    border: solid 1.5px transparent;
                    background: transparent;
                    border-radius: 50px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                    font-size: 15px;
                    cursor: pointer;

                    &.active {
                        border: solid 1.5px $primaryColor;
                        background: $primaryColor;
                        color: #fff;

                        span {
                            color: #fff;
                        }

                        &:first-child {
                            .number {
                                min-width: 20px;
                                height: 20px;
                                background: #fff;
                                border-radius: 25px;
                                color: $primaryColor;
                                padding: 2px;
                                text-align: center;
                            }
                        }
                    }

                    &:first-child {
                        justify-content: center;

                        .number {
                            min-width: 20px;
                            height: 20px;
                            background: #edb5592e;
                            border-radius: 25px;
                            color: #edb559;
                            padding: 2px;
                            text-align: center;
                        }
                    }
                }
            }

            .Faq {
                border-bottom: 1px solid #f7e1bf;

                h4 {
                    font-size: 15px;
                    margin-top: 15px;
                    padding: 5px 15px;
                }

                p {
                    text-align: left;
                    margin: 0 0 20px 0;
                }
            }

            .Question {
                p {
                    font-size: 15px;
                    font-weight: $primary-font-weight;
                    color: #e23b8f;
                    padding: 22px 0;
                    border-bottom: 1px solid #f7e1bf;
                    text-align: left;
                    margin: 0;
                }
            }
        }
    }

    .vendor {
        .fileds {
            display: flex;
            flex-wrap: wrap;
        }

        .PhotographyVideoCard {
            text-align: center;

            h4 {
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 24px;
                text-align: center;
                margin: 15px 0 30px;
            }
        }

        h4 {
            margin-top: 20px;
        }

        .VendorSearch {
            text-align: center;

            input {
                padding: 9px 4px 9px 40px;
                background-image: url(../public/images/searchicon.svg);
                background-repeat: no-repeat;
                background-position: 5% center !important;
                -webkit-appearance: none;

                @media (max-width: 767px) {
                    background-position: 5% center !important;
                }
            }

            h4 {
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 24px;
                text-align: center;
                margin: 15px 0 30px;
            }

            p {
                color: $bodyTextColor;
                text-align: left;
                margin: 15px 0 15px 0;
            }

            &.searchVendorWrap {
                p {
                    text-align: left;
                }
            }
        }

        .contractsCard {
            .contract-image {
                position: relative;

                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;

                    @media (max-width: 767px) {
                        width: 100%;
                        height: 150px;
                    }
                }
            }

            .playbttonVideo {
                position: absolute;
                top: 50%;
                right: 8%;

                @media (max-width: 767px) {
                    top: 44%;
                    right: 10%;
                }
            }
        }
    }
}

/* rating component  */
.starRatingwrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .c-rating {
        display: none;

        &+label {
            display: block;
            width: 12px;
            height: 12px;
            cursor: pointer;
            background-image: url(../public/images/whiteStar.svg);
            background-size: contain;
            text-indent: -9999px;
            margin-right: 2px;
            background-repeat: no-repeat;

            &:hover,
            &:hover~label {
                background-image: url(../public/images/filledstar.svg);
            }
        }

        &:checked {

            &+label,
            &~input+label {
                background-image: url(../public/images/filledstar.svg);
            }
        }
    }

    .c-rating:disabled,
    .c-rating[disabled] {
        &+label {
            cursor: default;
            pointer-events: none;
        }
    }
}

.vendor-name {
    .ratingStar {
        ul {
            display: flex;
            align-items: center;
            margin-top: 10px;

            li {
                flex-grow: 0;
                position: unset;

                &:first-child {
                    background-color: #d62c81;
                    border-radius: 5px;
                    padding: 5px 12px 3px;
                    color: #fff;
                    font-size: 10px;
                    font-weight: $primary-font-weight;
                    font-family: $primaryFont;
                }

                &:last-child {
                    padding-left: 4px;
                    color: #dcc39a;
                    font-size: 10px;
                    font-weight: 400;
                    font-family: $primaryFont;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.VendorProfileCardWrapper {
    h4 {
        margin-bottom: 0px;
        font-size: 20px;
        word-break: break-word;
        max-width: 210px;
    }

    .VendorProfileWrp {
        h4 {
            max-width: initial;
        }
    }

    p {
        text-align: left;
        margin-bottom: 5px;
        color: $bodyTextColor;
    }

    .fileds {
        margin-top: 10px;

        .cmnInput {
            width: 100%;
            padding: 0 2px;

            @media (max-width: 1280px) {
                padding: 0 0px;
            }

            .form-field {
                input {
                    border: none !important;
                    border-bottom: 1px solid #f7e1bf !important;
                    border-radius: 0%;

                    padding: 9px 4px 9px 23px;
                    background-image: url(../public/images/locationIcon.svg);
                    background-repeat: no-repeat;
                    background-position: 0% center !important;

                    @media (max-width: 767px) {
                        background-position: 0% center !important;
                        padding: 9px 4px 9px 25px;
                    }

                    @media (max-width: 1280px) {
                        padding: 9px 4px 9px 18px;
                        background-position: 0% center !important;
                    }
                }
            }
        }
    }
}

.ProfileRatingSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .ratingStar {
        ul {
            display: flex;
            align-items: center;
            margin-top: 10px;

            li {
                flex-grow: 0;
                position: unset;
                list-style: none;

                &:first-child {
                    background-color: #d62c81;
                    border-radius: 5px;
                    padding: 5px 12px 3px;
                    color: #fff;
                    font-size: 10px;
                    font-weight: $primary-font-weight;
                    font-family: $primaryFont;
                }

                &:last-child {
                    padding-left: 4px;
                    color: #dcc39a;
                    font-size: 10px;
                    font-weight: 400;
                    font-family: $primaryFont;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.MediaWrap {
    .genderSelection {
        margin-bottom: 20px;
    }

    .MediaPhotoWrap {
        @include flex;
        flex-wrap: wrap;

        .MediaImage {
            margin: 2px;
            position: relative;
            width: calc(100% / 3 - 5px);

            @media (max-width: 767px) {
                width: calc(100% / 2 - 5px);
            }
            @media (max-width: 565px) {
                width: 100%;
            }

            .videoMedia {
                @include flex;
                height: 100%;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: #000;
                    z-index: 1;
                    opacity: 24%;
                    border-radius: 5px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 7px;
                    right: 0;
                    left: -7px;
                    background-repeat: no-repeat;
                    background-position: top;
                    z-index: 1;
                    background-position-x: right;
                    border-radius: 5px;
                }

                img {
                    width: 100%;
                }
            }

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
                border-radius: 5px;

                @media (max-width: 767px) {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .VedioContainer {
            p {
                color: #1480cd;
                font-size: 15px;
                text-align: left;
            }
        }
    }
}

.BlogContent {
    margin-bottom: 26px;

    img {
        width: 100%;
    }

    h4 {
        font-size: 18px;
        margin-top: 18px !important;
        text-align: left;
    }

    h6 {
        text-align: left;
        color: #edb258;
        font-weight: 400;

        small {
            display: block;
            text-align: left;
            color: $bodyTextColor;
            font-size: 10px;
            opacity: 30%;
        }
    }

    p {
        text-align: left;
    }
}

.BlogDetaialContainer {
    .BlogDeatialBanner {
        width: 100%;
        height: 25vh;
        background-image: url(/images/BlogDetailimg.jpg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        text-align: center;
    }
}

.BlogDetailWraper {
    .appContainer {
        padding: 10px 30px 50px 30px;

        .BlogContent {
            border-bottom: 1px solid #f7e1bf;
            padding-bottom: 22px;
        }

        h5 {
            font-weight: $primary-font-weight;
            font-size: 15px;
        }

        p {
            text-align: left;
        }
    }
}

.ProfileVendorName {
    h3 {
        font-size: 18px;
        color: #edb258;
    }

    p {
        font-size: 15px;
        margin-top: 10px;
    }
}

.ProfileVendorimg {
    img {
        width: 100px;
    }
}

.VendorFLoatingbtn {
    button {
        position: fixed;
        right: 5%;
        bottom: 3%;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d3287e;
        z-index: 99;

        svg {
            margin-right: 4%;
            width: 100%;
        }
    }
}

.FloatMenu {
    position: fixed;
    bottom: 12%;
    right: 5%;

    .VendorFLoatingbtn {
        button {
            @include flex;
            position: fixed;
            right: 5%;
            bottom: 3%;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #d3287e;
            z-index: 111;

            @media (max-width: 1280px) {
                bottom: 3%;
            }

            svg {
                margin-right: 4%;
                width: 100%;
            }
        }
    }

    ul {
        list-style-type: none;

        li {
            color: #fff;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                @include flex;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: #219ddc;
                color: #fff;
                margin-left: 10px;
            }
        }
    }
}

.VendorCustomUploadWrap {
    .appContainer {
        padding: 18px 30px 50px 30px;
        overflow: visible;

        .Vendotupload {
            label {
                font-size: 15px;
            }

            .swiper {
                max-width: 768px;
                width: 100% !important;
                margin: 0 auto !important;

                @media (max-width: 767px) {
                    margin: 0 0 0 !important;
                }
            }

            h4 {
                font-family: $secondaryFont;
                font-weight: 400;
                font-size: 24px;
                text-align: center;
                color: #e23b8f;
                margin-bottom: 22px;
            }

            .day1 {
                display: flex;
                flex-direction: column;

                h5 {
                    text-align: center;
                    color: #e23b8f;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 18px;
                }
            }

            .UploadvendorContract {
                margin: 34px 0;

                h4 {
                    font-family: $primaryFont;
                    font-size: 18px;
                    font-weight: $primary-font-weight;
                    text-align: center;
                }

                .uploadedFile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .Input-Calender {
                -webkit-appearance: none;
                text-align: left;
                text-align: -webkit-left;
            }

            .Input-Calender::-webkit-inner-spin-button,
            .Input-Calender::-webkit-calendar-picker-indicator {
                -webkit-appearance: none;
            }

            .Input-Calender::-webkit-date-and-time-value {
                text-align: left;
            }
        }
    }
}

.time {
    display: flex;
    width: 100%;

    .form-field {
        width: 100%;

        @media (max-width: 767px) {
            width: 95%;
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
            background: none;
            width: 100%;
        }
    }
}

.DeletAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    .deleteicon {
        border: 1px solid #edae56;
        border-radius: 50%;
        background-color: transparent;
        @include flex;
        width: 40px;
        height: 40px;
        margin-right: 8px;

        svg {
            margin-right: 0;
        }
    }

    .addIconBtn {
        border: 1px solid #edae56;
        border-radius: 50%;
        background-color: #edae56;
        color: #fff;
        @include flex;
        width: 40px;
        height: 40px;
        margin-left: 8px;

        svg {
            margin-right: 0;
        }
    }
}

.ChangePassword {
    .appContainer {
        position: relative;
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 40px 30px 50px 30px;
        overflow: hidden;

        h4 {
            text-align: center;
            margin: 0;
        }

        p {
            margin: 22px 0 0px 0;
        }

        .fileds {
            .cmnInput {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }

    .bottomBtns-wrp {
        margin-top: 5px;
    }
}

.SchedulecallWrap {
    margin: 30px 0 15px 0;

    @media (max-width: 767px) {
        margin: 10px 0 20px 0;
    }

    h4 {
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 22px;
        text-align: center;
        color: #e23b8f;
        margin-bottom: 22px;
        margin-top: 44px;
    }
}

.BlueBtn {
    background-color: #2aacdf;
    font-size: 13px;
    font-weight: $primary-font-weight;
    border-radius: 15px;
    padding: 3px 12px 3px 13px;
    color: #fff;
}

.Orangebtn {
    color: #fff;
    background-color: #fa7044;
    font-size: 13px;
    font-weight: $primary-font-weight;
    border-radius: 15px;
    padding: 3px 12px 3px 12px;
}

.MySchedulewrap {
    .eventSet {
        @media (max-width: 767px) {
            margin: 0;
        }
    }

    .MySchedule {
        text-align: left;

        h4 {
            color: #e23b8f;
            font-size: 16px;
            font-weight: $primary-font-weight;

            margin: 22px 0 0px 0;
        }

        p {
            color: #efbc6a;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            margin-bottom: 0;
            margin-top: 10px;
        }

        .cancelbtn {
            background-color: #e23b8f;
            font-size: 13px;
            font-weight: $primary-font-weight;
            border-radius: 15px;
            padding: 3px 12px 3px 13px;
        }

        button {
            font-weight: $primary-font-weight;
            color: #fff;
            margin-top: 28px;
            width: 100%;
            max-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 767px) {
                width: 27%;
            }
        }
    }

    .cmn-wrap {
        width: auto;
        height: 100%;
        padding: 30px 0 50px;
        display: inline;
        display: initial;
    }
}

.timeslot {
    .timebtn {
        background-color: #ffffff;
        border: 1px solid rgba(237, 180, 87, 0.25);
        border-radius: 25px;
        padding: 12px 30px 12px 30px;
        color: $bodyTextColor;
    }
}

.Schedulecallsetup {
    .swiper {
        margin-left: 0 !important;
    }

    h4 {
        text-align: left;
        margin-bottom: 21px;
    }

    .swiper-slide-active {
        .timebtn {
            background-color: #ff65b3;
            color: #fff;
        }
    }
}

.schedulecallwrap {
    .required-confirmBtnSec {
        margin-top: -8vh;
        padding: 0;
        justify-content: center;

        button {
            width: 50%;

            @media (max-width: 767px) {
                width: 75%;
            }
        }
    }
}

////updatePopup/////
.updateBtn {
    display: flex;
    justify-content: space-evenly;

    button {
        width: 45%;
    }
}

.VendorReview {
    position: relative;

    button {
        position: absolute;
        right: 0%;
        top: 22%;
        color: #fff;
        font-size: 10px;
        font-weight: 400;
    }

    .blue {
        background-color: #3399cc;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }

    .green {
        background-color: #7ed917;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }

    .orange {
        background-color: #f9704b;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }

    .lightBlue {
        background-color: #a7aacf;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }

    .pink {
        background-color: #e23b8f;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }

    .red {
        background-color: #e23b3b;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }

    .yellow {
        background-color: #ffc500;
        border: 2px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
    }
}

.VendorContractreviewDetails {
    .wbgBtn {
        &.btntop {
            margin-bottom: 18px;
        }
    }

    .VendorReviewdetails {
        margin-top: 40px;
        margin-bottom: 15px;

        h4 {
            margin-top: 0px;
        }

        button {
            position: initial;
            float: right;
        }
    }

    .eventSet {
        display: initial;
        width: 100%;
    }

    .VendorProfileCardWrapper h4 {
        max-width: 66%;
    }

    .appContainer {
        min-height: auto;
    }
}

.VendorContractDeatil {
    p {
        text-align: left;
        margin: 8px 0 25px 0;
    }

    .detailBox {
        @include border;
        padding: 1px 17px 20px 17px;

        h4 {
            color: #e23b8f;
            font-weight: 500;
            font-size: 14px;
            margin-top: 20px;
        }

        p {
            text-align: left;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 0;
        }

        small {
            opacity: 67%;
        }

        hr {
            border-top: 1px solid #edb457;
        }

        .total-amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            h4 {
                margin-top: 0 !important;
            }

            p {
                margin-top: 0 !important;
            }
        }
    }

    button {
        margin: 20px 0 10px 0;
    }

    h4 {
        margin-top: 20px !important;
        color: #e23b8f;
        font-weight: 500;
        font-size: 14px;
    }
}

.paysec {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .buttonsec {
        text-align: right;

        button {
            margin: 0%;
            padding: 2px 22px;
            height: auto;
            font-size: 13px;
            min-height: auto;
        }

        .wbgBtn {
            opacity: 40%;
            cursor: auto;
            border-color: rgb(229 146 0 / 40%);
        }

        .pbgBtn {
            color: #e23b8f;
            border: 1px solid #f8edef;
            background-color: #f8edef;
            font-size: 13px;
            padding: 1px 20px;
            border-radius: 50px;
        }
    }
}

.VendorScheduleDetailswrp {
    .appContainer {
        .eventSet {
            @media (max-width: 767px) {
                display: contents;
                flex-direction: column;
            }
        }
    }

    .VendorContractDeatil {
        .ScheduledetailBox {
            @include border;
            padding: 1px 17px 20px 17px;
            background: $boxbgColor;
            width: 100%;
            min-width: 768px;

            @media (max-width: 767px) {
                min-width: 100%;
            }

            p {
                text-align: left;
                margin: 10px 0 0 0;
            }

            h3 {
                margin-top: 20px !important;
                color: #e23b8f;
                font-weight: $primary-font-weight;
                font-size: 14px;
            }

            .Evnetitle {
                border-bottom: 1px solid #efbc6a;

                h4 {
                    font-size: 16px;
                    font-weight: $primary-font-weight;
                }

                p {
                    color: #dcc39a;
                    margin-bottom: 20px;
                }
            }

            .BoxContent {
                display: flex;
                align-items: center;

                img {
                    margin-top: 18px;
                    margin-right: 8px;
                }
            }
        }
    }
}

.QuestionnaireContent {
    &.vendorReviewcontent {
        padding-top: 53px;

        .vendorReview {
            margin-top: 67px;

            .starRatingwrapper {
                justify-content: center;

                .c-rating {
                    &+label {
                        display: block;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        background-image: url(../public/images/whiteStar.svg);
                        background-size: contain;
                        text-indent: -9999px;
                        margin-right: 2px;
                        background-repeat: no-repeat;

                        &:hover,
                        &:hover~label {
                            background-image: url(../public/images/whiteStar.svg);
                        }
                    }

                    &:checked {

                        &+label,
                        &~input+label {
                            background-image: url(../public/images/filledstar.svg);
                        }
                    }
                }
            }

            h4 {
                color: #adc212;
            }
        }

        .fileds {
            .cmnInput {
                label {
                    text-align: left;
                    margin-top: 34px;
                }
            }
        }
    }
}

.Ratinghead {
    color: #adc212;
}

.Darkpinkbtn {
    background-color: #e23b8f;
    font-size: 13px;
    font-weight: $primary-font-weight;
    border-radius: 15px;
    padding: 3px 12px 3px 13px;
    color: #fff;
}

.disputebtn {
    background-color: #ffbd24;
    border-radius: 15px;
    padding: 3px 12px 3px 13px;
    font-size: 10px;
}

.riasediputwrp {
    @media (max-width: 767px) {
        .formField {
            margin: 0;
        }
    }

    .required-confirmBtnSec {
        padding: 0px 10px 0px 10px;
        max-width: 600px;
    }
}

.checkboxRaise {
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked~.checkmark {
        background-color: #e23b8f;
    }

    input:checked~.checkmark:after {
        display: block;
    }

    .checkmark {
        position: absolute;
        top: -8px;
        right: 0;
        left: auto;
        height: 16px;
        width: 16px;
        background-color: #ffffff;
        border: 1px solid #f7e1bf;
        border-radius: 3px;
        border-radius: 50%;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 3px;
            height: 7px;
            border: solid #ffffff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            cursor: pointer;
        }
    }
}

.profileName {
    display: flex;
    align-items: baseline;
    justify-content: center;

    h4 {
        margin-top: 18px !important;
        margin-bottom: 54px;
    }

    span {
        img {
            margin-left: 5px;
            margin-bottom: -1px;
        }
    }
}

.AddShedule {
    h4 {
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        color: #e23b8f;
        margin-bottom: 22px;
        margin-top: 0px !important;
    }

    .day1 {
        h5 {
            text-align: center;
            color: #e23b8f;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 18px;
        }
    }
}

.vendorRequestwrp {
    .required-confirmBtnSec {
        .notRequiredBtn {
            z-index: 11;
        }
    }
}

.ReviewVendorwrp {
    .profileName {
        h4 {
            margin-bottom: 35px;
        }
    }

    .Reviewsecwrap {
        background: #f8f4f1;
        padding-bottom: 78px;
    }

    .allReviweBtn {
        /* padding: 23px 0 !important; */
        margin-top: 32px;
    }

    .vendorReview {
        border: 1px solid #edb457;
        border-radius: 25px;
        padding: 14px 0 19px 0;
        background: #f8f4f1;
        margin-bottom: 5px;

        small {
            color: #dcc39a;
            font-size: 10px;
            font-weight: 400;
        }

        .starRatingwrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            margin-bottom: 5px;

            .c-rating {
                &+label {
                    display: block;
                    width: 14px;
                    height: 14px;
                    background-size: contain;
                    text-indent: -9999px;
                    margin-right: 4px;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}

.accortab {
    display: flex;
    align-items: center;

    img {
        width: 34px;
        height: 34px;
        margin-right: 12px;
    }

    h4 {
        margin-top: 0 !important;
    }
}

.accordreview {
    .accordion-content {
        background: $boxbgColor;
        border: 1px solid rgba(237, 180, 87, 0.25);
        border-radius: 25px;
        padding: 25px 14px 15px !important;
        margin: 3px 0 0 0 !important;

        .accordDetaildiv {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }

            .accordcontent {
                p {
                    margin: 0 0 -3px 0;
                    font-size: 13px;
                }

                small {
                    color: #dcc39a;
                    font-size: 10px;
                }
            }
        }
    }
}

.Reviewsec {
    display: flex;
    align-items: center;
    margin-top: 32px;

    .profileimage {
        img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }
    }

    .reviewsContent {
        h4 {
            margin: 0 0 3px 0 !important;
            font-size: 13px;
        }

        p {
            text-align: left;
            margin: 0 !important;
        }
    }
}

.ReviewCardwrp {
    .starRatingwrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
    }
}

.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.reviewratingwrap {
    .swiper-button-next {
        margin-top: 0px;
        position: absolute;
        top: 50%;
        right: -17px;
        width: 45px;
        height: 45px;
        transform: translateY(-50%);
        z-index: 11;
        background-image: url(../public/images/arrowright.svg);
        background-repeat: no-repeat;

        @media (max-width: 767px) {
            right: 55px;
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        left: 0;
        width: 45px;
        height: 45px;
        transform: translateY(-50%);
        margin-top: 0px;
        z-index: 11;
        background-image: url(../public/images/arrowleft.svg);
        background-repeat: no-repeat;
        cursor: pointer;

        @media (max-width: 767px) {
            left: 68px;
            background-image: url(../public/images/reviewcarouselbg.png);
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            padding: 40px 0;
        }
    }
}

.subscription-deatils {
    .swiper-button-next {
        margin-top: 0px;
        position: absolute;
        top: 50%;
        right: -17px;
        width: 45px;
        height: 45px;
        transform: translateY(-50%);
        z-index: 11;
        background-image: url(../public/images/arrowright.svg);
        background-repeat: no-repeat;
        cursor: pointer;

        @media (max-width: 767px) {
            right: 0;
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        left: 0;
        width: 45px;
        height: 45px;
        transform: translateY(-50%);
        margin-top: 0px;
        z-index: 11;
        background-image: url(../public/images/arrowleft.svg);
        background-repeat: no-repeat;
        cursor: pointer;

        @media (max-width: 767px) {
            left: 15px;
        }
    }
}

.reviewprofilimg {
    img {
        border-radius: 50%;
    }
}

.reviewprofilename {
    h3 {
        margin: 0 0 9px 0;
    }

    .starRatingwrapper {
        .c-rating {
            &+label {
                display: block;
                width: 14px;
                height: 14px;
                margin-right: 4px;
            }
        }
    }
}

.evntnot {
    h3 {
        font-size: 14px;
        font-weight: $primary-font-weight;
    }

    p {
        text-align: left;
    }
}

//////////////////////////////////////////////EM1248//////////////////////////////////////
#__next {
    height: 100%;
}

html {
    height: 100%;

    body {
        height: 100%;
        scroll-behavior: smooth;
    }
}

.mainHead {
    h2 {
        font-size: 26px;
        font-weight: 400;
        color: #e23b8f;
        margin-bottom: 15px;
    }
}

.cmn-wrap {
    width: 100%;
    height: 100%;
    padding: 30px 0 50px;
    display: initial;
}

.p-0 {
    padding: 0;
}

.pt-sm-0 {
    @media (max-width: 767px) {
        padding-top: 0;
    }
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-auto {
    margin-top: auto;
}

.cmn-head {
    font-size: 18px;
    font-weight: $primary-font-weight;
    color: #e23b8f;
    margin-bottom: 15px;

    @media (min-width: 1200px) {
        font-size: 20px;
    }
}

.flex-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-col-between {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.blue-btn-sm {
    background-color: #009fde;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 22px;
}

//Buttons
.smallBtn {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    padding: 5px 20px;
    border-radius: 22px;
}

.pinkBg {
    background-color: #ff65b3;
    border: 1px solid rgb(0 145 212 / 6%);
    box-shadow: 0 3px 25px #e07bef4d;
}

.blueBg {
    background-color: #009fde;
    border: 1px solid #0091d40f;
    box-shadow: 0 3px 25px #009fde33;
}

.outline-btn {
    background: #FCF9F7;
    border: solid 1px #f7e1bf;
    font-size: 15px;
    width: 100%;
    border-radius: 12px;
    font-family: "Kantumruy Pro", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 45px;
}

//tab
.custom-tab {
    .tab-head {
        list-style: none;
        display: flex;
        width: 100%;
        margin: 30px 0;
        outline: none !important;

        li {
            flex-grow: 1;
            font-size: 16px;
            font-weight: 400;
            color: $bodyTextColor;
            padding: 12px 15px;
            border: solid 1px $primaryColor;
            background-color: $boxbgColor;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none !important;
            z-index: 000;
            width: 50%;

            @media (max-width: 767px) {
                font-size: 15px;
                padding: 12px 9px;
            }

            @media (max-width: 320px) {
                font-size: 14px;
                padding: 12px 4px;
            }

            &:first-child {
                border-top-left-radius: 22px;
                border-bottom-left-radius: 22px;
                border-right: none;
            }

            &:last-child {
                border-top-right-radius: 22px;
                border-bottom-right-radius: 22px;
                border-left: none;
            }

            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $primaryColor;
                color: #fff;
                font-size: 13px;
                font-weight: 400;
                margin: 0 5px;
                padding: 1px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.active {
                border-color: $primaryColor;
                background: $primaryColor;
                color: #fff;

                span {
                    background-color: $boxbgColor;
                    color: $primaryColor;
                }

                small {
                    color: #fff;

                    @media (max-width: 767px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

//floating button
.floating-btn {
    position: fixed !important;
    right: 30px;
    bottom: 85px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d3287e;
    z-index: 1;
    color: #ffffff;
    @media (max-width: 767px) {
        bottom: 75px;
        width: 45px;
        height: 45px;
    }
    svg {
        margin: 0;
    }

    .disabled {
        background-color: #8f9095;
    }
}

//accordion
.custom-accordion {
    .accordion {
        .accordion-item {
            margin-bottom: 15px;

            .accordion-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 15px;
                width: 100%;
                cursor: pointer;

                h4 {
                    font-size: 16px;
                    font-weight: 400;
                    color: $bodyTextColor;
                }

                span.rotate {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }

            .accordion-content {
                padding: 25px 0 15px;
                margin: 0 15px;

                h5 {
                    font-size: 14px;
                    font-weight: $primary-font-weight;
                    color: #e23b8f;
                    margin-bottom: 20px;
                }
            }
        }
    }

    &.add-guest {
        .accordion-item {
            border: 1px solid rgb(237 180 87 / 25%);
            border-radius: 22px;

            .accordion-content {
                border-top: 1px solid rgb(237 180 87 / 25%);

                .custom-radio-btn {
                    margin: 25px 0 30px;
                }
            }
        }
    }

    &.guest-detail {
        .accordion-title {
            border: 1px solid rgba(237, 180, 87, 0.25);
            border-radius: 22px;
            background-color: rgb(255 255 255 / 25%);
        }

        .custom-checkbox:last-child {
            margin-bottom: 0;
        }
    }
}

//card
.wedding-card {
    margin-bottom: 15px;
    border: 1px solid #f7e1bf;
    border-radius: 13px;
    padding: 20px 15px;
    background-color: #fcfaf8;

    .card-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #efbc6a;
        padding-bottom: 15px;

        .guest-name {
            h4 {
                font-size: 16px;
                font-weight: $primary-font-weight;
                color: #e23b8f;
                margin-bottom: 5px;
            }

            .group-id {
                font-size: 13px;
                font-weight: 400;
                color: #dcc39a;
            }
        }

        .next-arrow {
            padding: 0 10px;
        }
    }

    .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;

        .inivit-status {
            h6 {
                font-size: 14px;
                font-weight: 400;
                color: #e23b8f;
                margin-bottom: 10px;
            }

            p {
                font-size: 15px;
                font-weight: 400;
                color: $bodyTextColor;
                margin: 0;

                .line-breaker {
                    color: rgb(112 112 112 / 50%);
                    padding: 5px;
                }
            }
        }

        .edit-btn {
            width: 40px;
            height: 40px;
            border-radius: 22px;
            background-color: rgb(237 181 89 / 12%);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.read-only {
        .edit-btn {
            display: none;
        }
    }
}

.detail-card {
    background-color: #fcfaf8;
    border-radius: 22px;
    border: 1px solid rgb(237 180 87 / 25%);
    padding: 20px 15px;
    margin-bottom: 15px;

    h4 {
        font-size: 14px;
        font-weight: $primary-font-weight;
        color: #e23b8f;
        margin-bottom: 10px;
    }

    p {
        font-size: 15px;
        font-weight: 400;
        color: $bodyTextColor;
        margin: 0 0 20px;
        text-align: left;
    }
}

.event-card {
    width: 100%;
    border: 1px solid rgb(237 180 87 / 70%);
    border-radius: 13px;
    background-color: #fcfaf8;
    margin-bottom: 15px;

    .card-head {
        padding: 20px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .event-name {
            h4 {
                font-size: 16px;
                font-weight: $primary-font-weight;
                color: #e23b8f;
            }

            .date {
                font-size: 13px;
                font-weight: 400;
                color: #dcc39a;
                margin-top: 5px;
            }
        }
    }

    .card-body {
        padding: 20px 0;
        margin: 0 15px;
        border-top: 0.5px solid #efbc6a;

        .guest-list {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            flex-wrap: wrap;

            li {
                margin: 5px 16px 5px;
                text-align: center;
                min-width: 75px;

                .number {
                    width: 52px;
                    height: 52px;
                    border-radius: 50%;
                    background-color: #e23b8f;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto 15px;

                    span {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        border: 2px solid rgb(255 255 255 / 30%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: $bodyTextColor;
                    margin: 0;
                }
            }
        }

        .guest-num {
            display: flex;
            align-items: center;
            justify-content: center;

            .category {
                width: 108px;
                height: 24px;
                position: relative;
                background-color: rgb(226 59 143 / 4%);
                margin: 0 9px;
                padding: 0 6px;
                border-radius: 22px;
                font-size: 13px;
                font-weight: 400;
                color: $grayText;
                display: flex;
                align-items: center;

                span {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: #e23b8f;
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 13px;
                    font-weight: $primary-font-weight;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.guestEvent-card {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(237 180 87 / 50%);
    padding: 20px 15px;
    background-color: rgb(255 255 255 / 25%);
    border-radius: 13px;

    h6 {
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
    }

    p {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        color: #dcc39a;
        display: flex;
        align-items: center;

        span {
            margin-left: 15px;
        }
    }
}

.contracts-card {
    background: $boxbgColor;
    border-radius: 20px;
    border: 1px solid rgb(248 228 196 / 50%);
    padding: 20px 20px 15px;

    .card-body {
        display: flex;
        align-items: flex-start;

        .image {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .detail {
            padding: 5px 0 0 15px;

            h4 {
                font-size: 16px;
                font-weight: $primary-font-weight;
                color: #e23b8f;
                margin-bottom: 10px;
            }

            .status {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px 15px;
                border-radius: 22px;
                font-size: 10px;
                font-weight: 600;
                color: #ffffff;
                min-width: 65px;
                width: fit-content;
                margin-bottom: 13px;

                @media (max-width: 767px) {
                    padding: 4px 10px;
                }

                &.blue {
                    background-color: #3399cc;
                    border: 2px solid rgb(0 145 212 / 6%);
                }

                &.green {
                    background-color: #7ed917;
                    border: 2px solid rgb(0 145 212 / 6%);
                }

                &.orange {
                    background-color: #f9704b;
                    border: 2px solid rgb(0 145 212 / 6%);
                }

                &.lightBlue {
                    background-color: #a7aacf;
                    border: 2px solid rgb(0 145 212 / 6%);
                }

                &.pink {
                    background-color: #e23b8f;
                    border: 2px solid rgb(0 145 212 / 6%);
                }

                &.red {
                    background-color: #e23b3b;
                    border: 2px solid rgb(0 145 212 / 6%);
                }

                &.yellow {
                    background-color: #ffc500;
                    border: 2px solid rgb(0 145 212 / 6%);
                }
            }

            h6 {
                font-size: 13px;
                font-weight: 400;
                color: $grayText;

                span {
                    font-weight: $primary-font-weight;
                    color: $bodyTextColor;
                    padding-left: 5px;
                }
            }
        }
    }

    .bgBtn {
        margin: 40px auto 0;
    }

    &.no-status {
        .status {
            display: none !important;
        }
    }
}

.template-card {
    width: 100%;
    height: 399px;
    position: relative;
    border-radius: 28px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .card-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: rgb(213 42 128 / 24%);

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 50%;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-image: linear-gradient(to bottom, rgb(206 25 116 / 7%), #c90d6c);
        }
    }

    .card-body {
        padding: 30px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

        h4 {
            font-size: 24px;
            font-weight: $primary-font-weight;
            color: #ffffff;
            margin-bottom: 8px;
            text-align: center;
        }

        p {
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            margin: 0 0 25px;

            @media (min-width: 1200px) {
                font-size: 15px;
            }
        }

        .outline-btn {
            max-width: 203px;
            margin: 0 auto;
        }
    }
}

.category-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .card-body {
        display: flex;
        align-items: center;

        figure {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .text {
            padding: 0 15px;

            h4 {
                font-size: 16px;
                font-weight: $primary-font-weight;
                color: $linkColor;
                text-decoration: underline;

                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }

            p {
                margin: 0;
                padding: 0;
            }
        }
    }
}

//Carousel
.template-carousel {
    margin: 20px 0 0 !important;
    padding: 0 30px !important;
}

//checkbox
.custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    label {
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
    }

    .checkbox-label {
        display: block;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked~.checkmark {
            background-color: #e23b8f;
        }

        input:checked~.checkmark:after {
            display: block;
        }

        .checkmark {
            position: absolute;
            top: -8px;
            right: 0;
            left: auto;
            height: 16px;
            width: 16px;
            background-color: #FAF8F5;
            border: 1px solid #f7e1bf;
            border-radius: 3px;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 5px;
                top: 1px;
                width: 3px;
                height: 7px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }
    &.box-shaped{
        background-color: $boxbgColor;
        align-items: center;
        padding: 15px 15px;
        border: solid 1px #f7e1bf;
        border-radius: 10px;
        font-size: 14px;
        margin-bottom: 15px;
        justify-content: flex-start;
      .checkbox-label {
            width: 16px;
            margin-right: 10px;
        }
    }
}

//radio button
.custom-radio-btn {
    display: flex;
    align-items: center;

    .radio-label {
        display: block;
        position: relative;
        padding-left: 29px;
        margin-right: 25px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        input:checked~.checkmark {
            background-color: #e23b8f;
        }

        input:checked~.checkmark:after {
            display: block;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: $boxbgColor;
            border: 1px solid rgb(245 216 199 / 50%);
            border-radius: 50%;

            &:after {
                content: "";
                position: absolute;
                display: none;
                top: 4px;
                left: 4px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: white;
            }
        }
    }
}

.radio-btn-group {
    margin: 30px 0;

    p {
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
        margin: 0 0 20px;
        text-align: left;
    }
}

//guest status
.guest-status {
    .cmnInput {
        margin-bottom: 25px;
        padding: 0;

        .customSelect__control {
            border-color: #edb457b3 !important;
            box-shadow: none !important;
        }
    }

    .card-group {
        margin-top: 30px;
    }
}

//guest details
.guest-details {
    .flex-between {
        margin-bottom: 20px;

        .group-id {
            font-size: 13px;
            font-weight: 400;
            color: #dcc39a;
            display: block;
        }
    }
}

//add guest
.add-guest {
    button.mb-3 {
        margin-bottom: 30px;
    }

    .btnHolder.mt-4 {
        margin: 40px 0 0;
    }

    .btnHolder.mt-7 {
        margin: 60px 0 0;
    }

    .customSelect__control {
        border-color: #edb457b3 !important;
        box-shadow: none !important;
    }
}

//edit event
.custom-field-group {
    width: 100%;
}

//manage subscription
.manage-subscription {
    @media (max-width: 767px) {
        padding-top: 5px;

        .GetStartBtn {
            padding-top: 0;
        }
    }
    @media (max-width: 567px) {
        .custom-tab .tab-head li{
            flex-direction: column;
        }
    }
}

.subscription-deatils {
    margin-top: 44px;

    .swiper {
        padding-top: 15px !important;
        margin-bottom: 40px !important;
        @media (max-width: 576px) {
            padding: 15px 0px 0 !important;
        }
        .swiper-slide {
            padding-top: 20px;
            height: 100vh;
            max-height: 425px;
            cursor: pointer;

            &.swiper-slide-active {
                padding-top: 0;
                height: 100vh;
                max-height: 440px;

                .plan-card {
                    background-color: #e23b8f14;
                }
            }
        }
    }

    .plan-card {
        background: $boxbgColor;
        border: 1px solid #f8e4c4;
        border-radius: 20px;
        padding: 45px 50px 30px;
        position: relative;
        margin-bottom: 10px;
        height: 100%;

        @media (max-width: 767px) {
            padding: 35px 15px 15px;
        }

        .plan-btn {
            background-color: #e23b8f;
            width: 114px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 15px;
            font-weight: 400;
            border-radius: 22px;
            position: absolute;
            top: -14px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        h2 {
            font-size: 24px;
            font-weight: $primary-font-weight;
            color: #e23b8f;
            text-align: center;

            span {
                font-size: 24px;
            }

            small {
                font-size: 20px;
                font-weight: 400;
            }
        }

        h6 {
            font-size: 14px;
            font-weight: 400;
            color: #dcc39a;
            text-align: center;
        }

        .plan-list {
            max-height: 300px;
            overflow: auto;
            list-style-type: decimal;
            margin: 25px 0 20px;
            padding: 0 10px;

            li {
                margin-bottom: 20px;
                font-size: 15px;
                font-weight: 400;
                color: $bodyTextColor;
                margin-left: 26px;
                @media (max-width: 576px) {
                    margin-bottom: 10px;
                    font-size: 14px;
                    margin-left: 10px;
                }
                span {
                    margin-right: 15px;
                }
            }
        }

        .next-payment {
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 0.5px;
                position: absolute;
                top: 15px;
                left: 0;
                background-color: #efbc6a;
            }

            h5,
            span {
                position: relative;
                margin: 0 auto;
                background: $boxbgColor;
                text-align: center;
                width: 142px;
                z-index: 1;
            }

            span {
                font-size: 13px;
                font-weight: 400;
                color: #dcc39a;
                padding-bottom: 4px;
                display: block;
            }

            h5 {
                font-size: 15px;
                font-weight: $primary-font-weight;
                color: #d62c81;
            }
        }

        p {
            text-align: left;
            margin-left: -14px;
        }
    }
}

//Events
.edit-events {
    min-height: calc(100vh - 159px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .custom-field-group {
        .cmnInput {
            .form-field {
                input[type="date"] {
                    -webkit-appearance: none;
                    text-align: left;
                    text-align: -webkit-left;
                }

                input[type="date"]::-webkit-inner-spin-button,
                input[type="date"]::-webkit-calendar-picker-indicator {
                    -webkit-appearance: none;
                }

                input[type="date"]::-webkit-date-and-time-value {
                    text-align: left;
                }
            }
        }
    }
}

//Profile Edit
.profileEdit-wrap {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 100px 30px 50px 30px;
    overflow: hidden;

    &.backGround-3 {
        @media (max-width: 767px) {
            background-image: url(../public/images/background-3.png);
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    .profileEdit {
        padding-top: 20px;

        @media (max-width: 767px) {
            padding-top: 0px;
        }

        .mainHead {
            text-align: center;
            margin-bottom: 25px;

            h2 {
                font-family: $secondaryFont;
            }
        }

        .cmnInput {
            margin-bottom: 30px;

            input {
                line-height: normal;
            }

            label {
                color: #e23b8f;
                margin-left: 0;
                margin-bottom: 10px;
            }
        }

        .subscription {
            width: 100%;

            .price {
                display: flex;
                align-items: center;
                font-size: 15px;
                font-weight: 400;
                color: $bodyTextColor;

                p {
                    font-size: 18px;
                    font-weight: $primary-font-weight;
                    color: #e23b8f;

                    span {
                        font-size: 15px;
                    }
                }
            }

            .billing-date {
                font-size: 12px;
                font-weight: 400;
                color: #dcc39a;
            }
        }

        button.wbgBtn {
            max-width: 80px;
            width: 100%;
            padding: 5px 0;
            background-color: $boxbgColor;
        }
    }

    .MyProfileDiv {
        @media (max-width: 767px) {
            padding-top: 0;
        }

        .profileImg {
            cursor: context-menu;

            @media (max-width: 767px) {
                margin-top: -15px;
            }
        }
    }
}

.cellphone-feild {
    .form-field {
        display: flex;
        align-items: center;

        input {
            padding-left: 3px !important;
            max-width: 130px;

            @media (max-width: 767px) {
                max-width: initial;
            }
        }

        .link-button-pink {
            margin-top: 0;
            margin-left: 0;
        }

        span:not(.link-button-pink) {
            font-size: 15px;
            line-height: initial;
            flex: none;
        }
    }
}

.invite-cards-wrp {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: wrap;

    .bordered-card {
        flex: 1 1;

        @media (max-width: 767px) {
            flex-basis: 100%;
        }
    }
}

.bordered-card {
    border: 2px solid #f9e9cf;
    border-radius: 20px;
    text-align: center;
    background-color: #fcf9f7;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;

    h4 {
        font-size: 14px;
        font-weight: $primary-font-weight;
        color: #ff65b3;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 20px;
    }

    button.wbgBtn {
        max-width: 300px !important;
        margin-top: auto !important;
    }
}

.profile-btn-grp {
    display: flex;
    gap: 10px;

    @media (max-width: 567px) {
        flex-wrap: wrap;
    }
}

//Emails
.email-wrp {
    .cmn-head {
        text-align: center;
    }

    p {
        text-align: center;
        margin: 0 0 30px;

        @media (min-width: 1200px) {
            font-size: 15px;
        }
    }

    .date {
        width: 121px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        background-color: #e23b8f;
        margin: 0 auto;
        border: 1px solid rgb(0 145 212 / 6%);
        border-radius: 22px;
        margin-bottom: 25px;
    }

    ul {
        list-style-type: none;
        margin-bottom: 25px;

        li {
            padding: 20px 15px;
            font-size: 16px;
            font-weight: 400;
            color: $bodyTextColor;
            border: 1px solid rgb(0 0 0 / 70%);
            border-radius: 13px;
            margin-bottom: 5px;

            &.active {
                color: #e23b8f;
                border: 1px solid #ed98c2;
            }
        }
    }
}

.email-detail {

    h4,
    h5,
    h6,
    h3,
    h2 {
        font-size: 16px !important;
        font-weight: $primary-font-weight !important;
        color: $bodyTextColor !important;
        margin-bottom: 15px !important;
    }

    .date {
        font-size: 10px;
        font-weight: 400;
        color: rgb(32 34 45 / 40%);
        margin-bottom: 12px;
        display: block;

        @media (min-width: 1200px) {
            font-size: 13px;
        }
    }

    p {
        margin: 0 0 0;
        text-align: left;

        @media (min-width: 1200px) {
            font-size: 15px;
        }
    }

    figure {
        width: 100%;
        margin-bottom: 25px;
        text-align: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    img,
    iframe {
        max-width: 100% !important;
        height: auto !important;
    }

    @media (max-width: 767px) {
        iframe {
            width: 100% !important;
        }
    }
}

//Templates
.templates-wrp {
    .cmn-head {
        text-align: center;
    }

    p {
        margin: 0;
        padding: 0 15px;

        @media (min-width: 1200px) {
            font-size: 15px;
        }
    }
}

.templatesDetail-wrp {
    @media (max-width: 767px) {
        padding-top: 65px;
    }

    .image {
        width: 100%;
        height: 600px;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            height: 197px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .detail {
        h4 {
            font-size: 15px;
            font-weight: $primary-font-weight;
            color: $bodyTextColor;
            margin-bottom: 15px;
        }

        p {
            margin: 0 0 10px;
            text-align: left;
        }
    }

    .Template-Pdf {
        ul {
            margin-top: 20px;

            li {
                list-style-type: none;
                margin-bottom: 10px;


                @media (max-width: 767px) {
                    margin-bottom: 8px;
                }

                a {
                    font-size: 14px;
                    font-weight: 600;
                    color: #CC0066;
                    border-bottom: 1px solid;
                    background: none;
                    padding: 0;
                    text-decoration: none !important;
                    @media (max-width: 767px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.padding-top {
    padding-top: 50px;
    height: 100%;

    .appContainer {
        padding-top: 0;
        padding-bottom: 40px;
        min-height: auto;
        height: calc(100% - 150px);
    }

    .cmn-wrap {
        padding-bottom: 0;

        .custom-tab {
            height: 100%;

            .tab-content {
                height: calc(100% - 46px);
            }
        }

        .manage-subscription {
            .subscription-deatils {
                margin-top: 44px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

.subscription-head {
    padding: 40px 30px 30px;
    width: 100%;
    height: 150px;
    background-image: url(/images/Vendorlistbackground.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #cf1f77;
    text-align: center;

    @media (max-width: 767px) {
        background-size: cover;
        background-color: transparent;
    }

    h3 {
        color: #ffffff;
    }

    p {
        color: #ffffff;
        font-size: 16px;

        @media (max-width: 767px) {
            font-size: 15px;
        }
    }
}

.subscription-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .btnHolder {
        margin-bottom: 0;
        margin-top: 50px;
    }

    .plan-card {
        padding: 25px 0;
        margin-bottom: 30px;

        .standard-plan {
            padding: 0 20px 25px;
            border-bottom: 1px solid #f7e1bf;

            h4 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 400;
                color: #eca04f;
                text-transform: uppercase;
            }
        }

        .details {
            padding: 20px 20px 0;

            h6 {
                margin-bottom: 15px;
                text-align: left;

                span {
                    display: block;
                }
            }
        }
    }

    .pink-text {
        font-size: 15px;
        font-weight: $primary-font-weight;
        color: #e23b8f;
    }
    .underline-link{
    font-size: 15px;
    }
}

.payment-popup {
    .bgBtn {
        margin-top: 15px;
    }
}

//EM1248//

///emp1379//
.veryfied {
    p {
        margin: 25px 0 10px 0;
    }

    .bgBtn {
        margin-top: 15px;
    }
}

.TandC-P {
    margin-top: 15px;

    .checkmark {
        top: -2px !important;
    }

    span,
    a {
        font-weight: 600;
    }
}

.M-auto {
    margin: auto;
}

.bottomBtns-wrp.M-auto {
    margin: auto 0 0 0;
}

.MargT {
    margin-top: 32px;
}

.Marg-B {
    margin-bottom: 3rem;
}

.schedulewrp {
    .bottomBtns-wrp {
        @media (max-width: 767px) {
            padding: 0;
        }
    }
}

.letsCon {
    p {
        margin: 15px 0;
    }

    .bgBtn {
        margin-top: 15px;
    }

    .btnHolder {
        margin: 30px 0 0;

        button {
            margin: 0 5px;
        }

        .link-button-pink {
            margin-top: 10px;
        }
    }
}

.form-field.CreateCheck {
    display: flex;
    align-items: flex-start;

    margin-left: 33px;
    position: relative;

    p {
        margin-top: 0 !important;
    }

    label {
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
    }

    .checkbox-label {
        display: block;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked~.checkmark {
            background: linear-gradient(50deg, rgb(237, 181, 89) 0%, rgb(236, 156, 77) 100%);
        }

        input:checked~.checkmark:after {
            display: block;
        }

        .checkmark {
            position: absolute;
            top: -7px;
            right: 0;
            left: auto;
            height: 18px;
            width: 18px;
            background-color: #ffffff;
            border: 1px solid #f7e1bf;
            border-radius: 5px;
            margin-right: 15px;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 3px;
                width: 3px;
                height: 6px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }

    .privacy-check {
        display: flex;
        align-items: flex-start;
        margin-top: 1px;
    }

    .errormsg {
        position: unset;
        margin: 0 0 0 10px;
        margin-top: 0 !important;
        height: auto;
    }
}

.form-field.OnboardCkecker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3px;
    position: relative;

    label {
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
    }

    .checkbox-label {
        display: block;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked~.checkmark {
            background-color: #e23b8f;
        }

        input:checked~.checkmark:after {
            display: block;
        }

        .checkmark {
            position: absolute;
            top: -8px;
            right: 0;
            left: auto;
            height: 18px;
            width: 18px;
            background-color: #ffffff;
            border: 1px solid #f7e1bf;
            border-radius: 25px;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 3px;
                width: 3px;
                height: 6px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }
}

.UpdatePopup {
    .cmnInput {
        &.UpdateGender {
            .genderSelection {
                img {
                    margin: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}

.popup.UpdatePopup {
    img {
        margin-top: 0;
    }

    max-height: 750px;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
        height: 10px;
        padding: 60px;
        margin: 60px;
    }
}

.GrayBg {
    background-color: #e2e2e2 !important;
    border: 1px solid #e2e2e2 !important;
}

.Mt-25 {
    margin-top: 25px;
}

.budget.budGetH1.SetBudget {
    .fileds {
        .cmnInput {
            .form-field {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 0;

                span {
                    font-size: 45px;
                    color: #edb457;
                    text-align: center;
                    margin-right: 3px;
                }

                .form-control {
                    border: none !important;
                    padding: 0;
                    min-width: 30px;
                    max-width: 225px;
                    font-family: $primaryFont;
                    font-size: 48px;
                    color: #edb457;
                    text-align: left;
                    height: auto !important;
                    border-radius: 0;
                    background: none;
                    @media (max-width: 767px) {
                        max-width: 245px;
                    }

                    &::placeholder {
                        font-family: $primaryFont;
                        font-size: 48px;
                        color: #edb457;
                        text-align: center;
                    }
                }
            }

            .errortxt {
                color: red;
                margin: 2px 0 0px 0px;
            }
        }
    }
}

.changehead {
    display: flex;
    align-items: center;

    h1 {
        font-size: 20px;
        color: #e23b8f;
    }
}

.Setting-wrp {
    .content-Wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba(237, 180, 87, 0.7019607843);
        background: $boxbgColor;
        border-radius: 15px;
        padding: 15px 21px;
        margin-bottom: 15px;

        .ContentText {
            h3 {
                font-size: 15px;
                font-weight: $primary-font-weight;
            }
        }

        .disabled {
            color: #cccc;
        }
    }
}

.W-100 {
    width: 100% !important;
}

////Toggle Button ///

.toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 30px;

    &:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        top: 5px;
        left: 5px;
        transition: all 0.5s;
    }
}

.checkbox {
    &:checked {
        + {
            .toggle {
                &::after {
                    left: 30px;
                }

                background-color: #e23b8f;
            }
        }
    }

    display: none;
}

.TodoPendingCard-Wrap {
    border-bottom: 1px solid #f7e1bf;
    margin-bottom: 12px;

    width: 100%;
    min-width: 600px;

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100px;
    }

    .CheckkBox-Section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 10px;

        .custom-checkbox {
            justify-content: flex-start;
            margin-bottom: 0;

            label {
                .checkmark {
                    border: 1px solid #e23b8f;
                    left: 0;
                }
            }

            label:nth-child(2) {
                margin-left: 20px;
                font-family: $secondaryFont;
                font-size: 15px;
                font-weight: 400;

                span {
                    font-family: $primaryFont;

                    margin-left: 5px;
                }
            }

            .custom-label {
                font-family: $secondaryFont;
                font-size: 15px;
                font-weight: 400;
                color: $grayText;

                span {
                    font-family: $primaryFont;

                    margin-left: 5px;
                }
            }
        }

        .Due-By {
            label {
                margin-left: 5px;
                font-family: $secondaryFont;
                font-size: 14px;
                font-weight: 400;
                color: $grayText;

                span {
                    font-family: $primaryFont;
                    color: #6b6b6b;
                    margin-left: 5px;
                }
            }
        }
    }

    .Todo-text {
        display: flex;
        margin-bottom: 10px;

        div {
            font-size: 14px;
            font-weight: $primary-font-weight;
            color: $bodyTextColor;
            margin-left: 6px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            padding-bottom: 1px;

            span {
                background: none !important;
            }

            p {
                display: inline;
            }

            a {
                color: $linkColor;
                background: none !important;
                border-bottom: 1px solid;
            }
        }
    }
}

.displayflx {
    justify-content: space-between;
}

.Todo-Popup-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    button {
        width: 48%;
    }
}

.editicon-btn {
    background-color: transparent;
    padding: 0;
    position: absolute;
    right: 30px;

    svg {
        g {
            stroke: #cf1f77 !important;
        }
    }
}

.vendor-date {
    h5 {
        color: #edb559;
        font-weight: $primary-font-weight;
        font-size: 15px;
        margin-bottom: 5px;
    }

    h4 {
        margin: 0;
    }
}

.errMsg {
    display: flex;
    margin-top: 10px;

    .errortxt {
        color: #e60000;
    }

    .budgetError {
        svg {
            width: 14px;
            margin-right: 8px;
        }
    }
}

.location {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    p {
        margin: 0px 0px 0px 5px;
        text-align: left;
    }
}

.Location {
    &::placeholder {
        color: $grayText !important;
    }
}

.errtxt {
    color: #e60000;
}

.Wedding-Date {
    position: relative;

    .fileds {
        position: absolute;
        top: -9px;
        width: 100%;
        opacity: 0;

        .cmnInput {
            margin: 0 auto;

            input {
                position: relative;
                cursor: pointer;
                margin: 0 auto;
            }

            input::-webkit-calendar-picker-indicator {
                display: block;
                top: 0;
                left: 0;
                background: #0000;
                position: absolute;
                transform: scale(32);
            }
        }
    }
}

.Search-field {
    padding-left: 35px !important;
}

.TemplateSreachWrap {
    display: flex;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    margin-bottom: 25px;

    .fileds {
        width: 100%;

        .cmnInput {
            width: 100%;

            .form-field {
                .form-control {
                    width: 100%;
                    border: 1px solid rgb(238 181 89 / 20%) !important;

                    @media (max-width: 767px) {
                        width: 95%;
                    }
                }

                .searchicon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 15px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgb(238 181 89 / 20%);
        border-radius: 25px;
        height: 44px;
        width: 44px;
        background-color: $boxbgColor;
        padding: 20px;

        .tooltiptext {
            right: 12px;
        }
    }
}

.TemplateFilterWrap {
    h4 {
        font-size: 18px;
    }

    .TemplateSreachWrap {
        .form-control {
            width: 100% !important;
        }
    }

    .FilterChoose {
        border: 1px solid #d4d4d4;
        border-bottom: none;

        .Filter-checkbox {
            border-bottom: 1px solid #d4d4d4;
            padding: 12px 15px;
            position: relative;
            display: flex;

            label {
                font-size: 16px;
                font-weight: 400;
                margin-left: 25px;
            }

            .checkbox-label {
                display: block;
                position: relative;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                margin-left: 0;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                input:checked~.checkmark {
                    background-color: #1480cd;
                    border-color: #1480cd;
                }

                input:checked~.checkmark:after {
                    display: block;
                }

                .checkmark {
                    position: absolute;
                    top: 0px;
                    right: auto;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    background-color: #ffffff;
                    border: 1px solid $grayText;
                    border-radius: 3px;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 5px;
                        top: 1px;
                        width: 3px;
                        height: 7px;
                        border: solid #ffffff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.VendorFilterWrap {
    h4 {
        font-size: 18px;
    }

    h5 {
        margin-bottom: 15px;
    }

    .TemplateSreachWrap {
        .form-control {
            width: 100% !important;
        }
    }

    .FilterChoose {
        border-bottom: none;

        .Filter-checkbox {
            border-bottom: 1px solid #d4d4d4;
            padding: 12px 0px;
            position: relative;

            button {
                font-size: 16px;
                font-weight: 400;
                background: hsl(36deg 33.33% 97.06% / 0%);
            }
        }
    }
}

.Notificationcard {
    border: 1px solid rgba(237, 180, 87, 0.7019607843);
    padding: 15px 12px;
    border-radius: 15px;
    margin-bottom: 8px;

    p {
        text-align: left;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        font-size: 15px;
        margin: 0;
    }

    &.read p {
        color: gray;
    }

    &.pointer {
        cursor: pointer;
    }

    &.read {
        color: gray;
    }

    .Time-Date-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 16px;

        span {
            color: #e23b8f;
            font-weight: 400;
            font-size: 15px;
            margin: 0px 0 0 6px;
        }
    }
}

.PrivacyPolicy-wrap {
    h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    span {
        font-size: 16px;
    }

    p {
        text-align: left;
        line-height: 20px;
        font-size: 15px;
        margin: 5px 0 10px 0 !important;
    }
}

.headCenter {
    text-align: center;

    h1 {
        margin-bottom: 30px;

        @media (max-width: 767px) {
            font-size: 28px;
        }
    }
}

.mergeAccount {
    width: 45% !important;
    margin-right: 10px;
}

.ReminderAccord {
    .accordion {
        .accordion-item {
            border: 1px solid rgba(237, 180, 87, 0.25);

            .accordion-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 15px;
                width: 100%;
                cursor: pointer;

                border-radius: 5px;
                background: $boxbgColor;

                h4 {
                    font-weight: 600;
                    font-size: 15px;
                }
            }

            .accordion-content {
                background: $boxbgColor;
                padding: 0px 15px 15px;
                margin: 0;
            }
        }
    }
}

.MyVendorsHead {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    span {
        color: #d93386;
        font-size: 15px;
        font-weight: 400;
    }
}

.bgBtnImpo {
    background: rgb(237, 181, 89) !important;
    background: linear-gradient(50deg, rgba(237, 181, 89, 1) 0%, rgba(236, 156, 77, 1) 100%) !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: $primary-font-weight !important;
    border: none !important;
    width: 100% !important;
    border-radius: 60px !important;
    height: 40px !important;
    font-family: $primaryFont !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.wbgBtnImpo {
    width: 100% !important;
    color: #edb559 !important;
    background-color: #F6F1EA !important;

    border: 1px solid #e59200 !important;
    font-size: 15px !important;
    font-weight: $primary-font-weight !important;
    border-radius: 50px !important;
    height: 40px !important;
}

.CardDashb {
    align-items: baseline;

    img {
        margin-right: 5px;
    }

    p {
        margin-left: 0px;
        margin-top: 0px !important;

        span {
            color: #efbc6a;
        }
    }
}

.BudgetChart {
    border: 1px solid rgba(237, 180, 87, 0.25);
    padding: 30px 20px 0;
    background: $boxbgColor;
    border-radius: 25px;
    position: relative;

    .EditBdget {
        position: absolute;
        right: 20px;
        top: 20px;
        background: none;

        img {
            z-index: 1;
            width: 30px;

            @media (max-width: 767px) {
                width: 25px;
            }
        }
    }
}

.orangeBorder {
    border: solid 2px #edb559 !important;
    border-top: solid 2px #fff !important;
}

.alignToCenter {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.cursiveLetter {
    font-family: $secondaryFont;
    font-weight: 400;
    font-size: 48px;
    color: #edb457;
    margin: 30px auto;
    text-align: center;
}

.centerAlignedText {
    text-align: center;

}

.cursorPointer {
    cursor: pointer;
}

.cursorNone {
    cursor: context-menu;
}



.disabled {
    filter: grayscale(100%);
}

.TodoDatepopup-Wrap {
    .fileds {
        .cmnInput {
            input {
                position: relative;
            }

            input::-webkit-calendar-picker-indicator {
                display: block;
                top: 0;
                left: 0;
                background: #0000;
                position: absolute;
                transform: scale(50);
            }
        }
    }
}

.setEventWrap {
    .fileds {
        .cmnInput {
            input {
                position: relative;
                padding-right: 50px;
                width: 100%;
            }

            input::-webkit-calendar-picker-indicator {
                display: block;
                top: 0;
                left: 0;
                background: #0000;
                position: absolute;
                transform: scale(50);
            }
        }

        .form-field {
            .errormsg {
                &.rightspan {
                    right: 50px !important;

                    @media (max-width: 767px) {
                        right: 40px !important;
                    }
                }
            }
        }
    }
}

.tooltipMsg {
    cursor: pointer;
    position: relative;
    margin-right: 5px;
}

.tooltipMsg .infotext {
    background: #f9f1e8;
    border: 1px solid rgba(237, 180, 87, 0.7019607843);
    min-width: 180px;
    opacity: 0;
    padding: 4px 12px;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 11;
    top: 22px;
    border-radius: 10px;
    color:$bodyTextColor;
    display: block;
    font-size: 13px;
    position: absolute;
    margin-top: 0 !important;

    @media (hover: none) {
        opacity: 1 !important;
        display: none;

        &.open {
            display: block;
        }

        &.close {
            display: none;
        }
    }
}

.tooltipMsg:hover .infotext {
    opacity: 1;
    pointer-events: all;

    @media (max-width: 767px) {
        // pointer-events: none;
    }
}

.m-10 {
    margin-bottom: 10px;
}

.profileNoImg {
    img {
        width: calc(100% - 135px);
        height: calc(100% - 135px);
        border-radius: 0;
        padding: 8px;
    }
}

.ErrorDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    color: red;
    font-size: 15px;
    margin-top: 10px;

    span {
        color: red;
        margin: 10px 0 10px 0;
    }
}

.Height-auto {
    height: auto;
}

.min-height-auto {
    min-height: auto;
}

.error {
    color: red;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
}

.Mb-6 {
    margin-bottom: 6px;
}

.noSpace {
    right: 41px;
    bottom: 0;

    img {
        width: 20px;
        height: 20px;
    }
}

.eyeIcon {
    img {
        width: 20px;
        height: 20px;
    }
}

button {
    cursor: pointer;
}

.Scroll {
    overflow: auto;
}

.dueDate {
    .errormsg {
        top: 9px;
        right: 35px;
    }
}

.loderVerify {
    height: 30px;
}

.loaderCircle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customSelect__menu div {
    cursor: pointer;
    overflow-x: hidden;
}

.scrollbg {
    background: #e94c9b;
}

.Avatar {
    position: relative;
    margin: 0 auto;
    max-width: 250px;
    width: 100%;
}

.Updateedit-Btn {
    position: absolute;
    top: 18%;
    right: 18%;
    background: #d93386;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border: 1px solid #fff;

    @media (max-width: 767px) {
        position: absolute;
        top: 16%;
        right: 23%;
    }

    svg {
        margin-right: 0;

        g {
            stroke: #fff;
        }
    }
}

.Mt-0 {
    margin-top: 0 !important;
}

.Mt-40 {
    margin-top: 40px;
}

input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
}

input[type="number"]::-webkit-date-and-time-value {
    text-align: left !important;
}

.pb-0 {
    padding-bottom: 0;
}

.Hanburger-Btn {
    svg {
        width: 34px;
        height: 34px;
    }
}

.Notification-btn {
    position: relative;

    svg {
        width: 42px;
        height: 45px;
        margin-right: 0px;
    }

    span {
        background-color: #e23baa;
        border-radius: 50px;
        text-align: center;
        position: absolute;
        width: 19px;
        top: 10px;
        right: 1px;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 1px;
    }
}

.pospositionFixed {
    position: fixed !important;
}

.topRemove {
    top: 0 !important;
    position: relative !important;
}

.disabledField {
    background: #ccc !important;
    border: 1px solid #ccc !important;
    cursor: default !important;
}

.alrdy-member {
    z-index: 10;

    h6 {
        color: #fff;
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 20px;

        a {
            color: #edb457;
            cursor: pointer;
            font-weight: $primary-font-weight;
        }
    }
}

.Mt-30 {
    margin-top: 30px !important;
}

.Text-center {
    text-align: center;
}

.SetBtn {
    text-align: center;

    .bgBtn {
        width: 215px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.btn-Width {
    width: 215px !important;
    max-width: 215px !important;

    @media (max-width: 767px) {
        width: 100% !important;
        max-width: 600px !important;
    }
}

.mandatoryIcon {
    color: $darkRed !important;
}

.Input-Calender {
    -webkit-appearance: none !important;
    text-align: left;
    text-align: -webkit-left;
}

.Input-Calender::-webkit-inner-spin-button,
.Input-Calender::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
}

.Input-Calender::-webkit-date-and-time-value {
    text-align: left;
}

.MsgError {
    display: flex;

    svg {
        stroke: #c9317dd4;
        margin-right: 0;
    }

    .errormsg {
        margin: 0;
        margin-left: auto;
        margin-top: 0px !important;
        position: relative;

        @media (max-width: 767px) {
            left: 30%;
        }

        @media (max-width: 320px) {
            left: 37%;
        }

        span {
            svg {
                width: 12px;
                height: 12px;
            }
        }

        .errortxt {
            top: 10px;
            right: 0;
            left: auto;
            min-width: 200px;
            border: 1px solid #edb157;
            background-color: #fff;
            color: $bodyTextColor;
            z-index: 1;
        }

        .errortxt:after,
        .errortxt:before {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
        }

        .errortxt:after {
            border-color: transparent #fff transparent transparent;
            border-width: 4px;
            top: -8px;
            right: 3px;
            transform: rotate(90deg);
        }

        .errortxt:before {
            border-color: transparent #ffc107 transparent transparent;
            border-width: 5px;
            top: -11px;
            right: 2px;
            transform: rotate(90deg);
        }
    }
}

.transparent {
    background: transparent;
}

.DotNav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    li {
        list-style: none;
        background: #db7fac;
        width: 8px;
        height: 8px;
        float: left;
        margin-right: 5px;
        border-radius: 50px;
    }

    .Dayactive {
        background: #c9317d;
    }
}

.Arrow-Left {
    cursor: pointer;
    position: absolute;
    top: 31%;
    left: 0;
}

.Arrow-Right {
    cursor: pointer;
    position: absolute;
    top: 30%;
    right: 0;
}

.tooltip {
    position: relative;

    .tooltiptext {
        visibility: hidden;
        width: max-content;
        background-color: #de5b9d;
        color: #ffffff !important;
        text-align: center;
        border-radius: 6px;
        padding: 2px 10px;
        position: absolute;
        z-index: 1;
        top: 44px;
        left: auto;
        right: 15px;
        font-weight: 400 !important;
        font-size: 14px !important;

        &::after {
            border-bottom: 7px solid #de5b9d;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            content: "";
            left: auto;
            position: absolute;
            right: 5px;
            top: -6px;
        }
    }
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.ProfileError {
    position: relative;
    margin: 0 0 0 20px !important;

    .errortxt {
        left: unset;
        right: 0;
        float: right;
        min-width: max-content;
    }
}

.profileCheckError {
    position: relative !important;
    top: 0 !important;
    right: 0;
    margin-left: 0 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.close-Btn {
    background-color: #e23b8f;
    color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 22%;
    top: 16%;

    img {
        margin: 0;
        width: 15px;
        height: 15px;
        margin-bottom: 0 !important;
        padding: 2px;
    }
}

.MyProfilrDEtails {
    margin: 0 auto;
    // max-width: 500px;

    label {
        font-size: 15px;
    }
    .colors-wrp span {
        width: 17px;
        height: 17px;
    }
}

.Profile-EditBtn {
    svg {
        width: 34px;
    }
}

.MyProfileWrp {
    .changehead {
        h1 {
            color: #fff;
        }
    }

    .dashboardHeader {
        background: #d3287e;
        padding: 10px 10px;

        &.fixed {
            h1 {
                color: #d3287e;
            }
        }

        @media (max-width: 767px) {
            background: transparent;
        }
    }
}

.C-code {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    margin: auto;
    display: flex;
    align-items: center;
}

.ph-field {
    padding-left: 20px !important;
}

.TimePick {
    display: block;

    &:first-child {
        margin-right: 8px;
    }

    &:last-child {
        margin-left: 8px;
    }

    .form-field {
        width: 100% !important;

        .form-control {
            height: 44px;
            display: flex;
            align-items: center;
            line-height: 33px;
        }
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        opacity: 0;
        position: absolute;
        width: 100%;
        left: 0%;
    }
}

.verifyCodeIcon {
    top: 44% !important;
    right: 28% !important;
    white-space: nowrap;
    color: #e94c9b;
    font-weight: $primary-font-weight;
    font-size: 15px;
}

.Vendor-Unlock {
    p {
        margin-bottom: 15px !important;
    }

    .UnlockWrp {
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
            margin-bottom: 55px;

            li {
                list-style: decimal;
                text-align: left;
            }
        }
    }

    .block-btn {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;

        button {
            width: 100%;
        }
    }

    .modal-header {
        background-image: url(../public/images/modalHeader.svg);
        background-repeat: no-repeat;
        height: 115px;
        margin: -36px -26px 0px;
        background-size: 100%;
    }
}

.unlockBtn-grp {
    display: flex;
    justify-content: space-between;

    button {
        width: 48%;
    }
}

.btn-grp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
        max-width: 49%;
        min-width: 215px;
        @media (max-width: 576px) {
           width: 100%;
           max-width: 100%;
           margin: 10px 0;
        }
    }
}

.ConfirmPaymentWrp {
    display: block;
}

.Text-Left {
    text-align: left !important;
}

.Toastify {
    font-size: 15px;

    .Toastify__toast-icon {
        svg {
            width: 16px;
            height: 16px;
            margin: 0;
        }
    }

    .Toastify__close-button {
        margin-top: 15px;

        svg {
            height: 16px;
            width: 16px;
        }
    }

    .BudgetBtnHeight {
        height: 40px;
    }
}

.VerifyPhnNumber {
    .Updateedit-Btn {
        width: 18px;
        height: 18px;
        padding: 4px;
        top: 43.9% !important;
        right: 28% !important;
    }
}

.BudgetBtnHeight {
    height: 40px;
}

.InputTime {
    -webkit-appearance: none;
    text-align: left;
    text-align: -webkit-left;
}

.PhnSpace {
    padding-left: 32px !important;
}

.GetStartBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 30px 0px 30px;
}

.PlanWrp {
    padding: 0 0 40px;
}

.Tabwrap {
    padding: 0 30px;

    @media (max-width: 767px) {
        padding: 0 5px;
    }
}

.Upgrade {
    p {
        color: $bodyTextColor !important;
        font-size: 15px;
    }
}

.Pt-0 {
    padding-top: 0 !important;
}

.Pt-85 {
    padding-top: 85px;

    @media (max-width: 767px) {
        padding-top: 0;
    }
}

li,
button,
button:active,
button:focus {
    -webkit-tap-highlight-color: transparent;
}

.Mb-50 {
    margin-bottom: 50px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}

.AnnualPlanText {
    color: #d3287e;
    font-size: 14px;
    margin-right: 2px;

    @media (max-width: 767px) {
        font-size: 13px;
    }
}

.tf-v1-widget-close.tf-v1-close-icon {
    display: none !important;
}

@media (max-width: 1280px) {
    .Quiz-Media {
        margin: 0;

        img {
            width: 150px;
        }
    }
}

.SettingsC {
    color: $bodyTextColor !important;
}

.OpeningHours {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        margin-bottom: 15px;
    }

    ul {
        li {
            list-style: none;
            font-size: 14px;
            color: $bodyTextColor;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            span {
                margin-right: 20px;
                font-family: $secondaryFont;
                font-weight: 400 !important;
                font-size: 16px !important;
                width: 50px;
                text-align: left;
            }

            small {
                color: $bodyTextColor;
            }
        }
    }
}

.vendorNoImage {
    img {
        padding: 18px;
        background-color: #ededed !important;
    }
}

.SocialWrp {
    margin-bottom: 65px;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 10px;

        li {
            list-style-type: none;
            background-color: #e23b8f;
            margin: 8px;
            border-radius: 50px;

            .tooltiptext {
                top: 50px;
            }

            a {
                padding: 15px;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover {
                background: rgb(237, 181, 89);
                background: linear-gradient(50deg, rgba(237, 181, 89, 1) 0%, rgba(236, 156, 77, 1) 100%);
                transition-delay: 0.1s;
                transition-duration: 0.1s;
            }

            @media (max-width: 538px) {
                width: 50px;
                height: 50px;
            }

            @media (max-width: 425px) {
                min-width: 50px;
                min-height: 50px;
                width: 15%;
                height: 15%;
            }
        }
    }
}

.ShareBtn {
    position: absolute;
    right: 7%;
    top: 11%;

    @media (max-width: 1280px) {
        top: 15%;
    }
}

.Mt-5 {
    margin-top: 5px;
}

/****Loader********/

.FullloaderContainer-Desiweds {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: url(../public/images/FullLoaderBg.png);
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    flex-direction: column;
    background-size: cover;
    background-position: center;

    .logo {
        img {
            width: 100px;
            height: 100px;
        }
    }

    .loader-line {
        width: 200px;
        height: 5px;
        position: relative;
        overflow: hidden;
        background-color: #ff71b9;
        margin: 30px auto;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

        &::before {
            content: "";
            position: absolute;
            left: -50%;
            height: 5px;
            width: 40%;
            background-color: #fff;
            -webkit-animation: lineAnim 1s linear infinite;
            -moz-animation: lineAnim 1s linear infinite;
            animation: lineAnim 1s linear infinite;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }
    }
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}

.VerifiedProfile {
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.twoButtons {
    display: flex;
    align-items: center;
}

.orgBtn {
    margin-right: 5px;
}

.searchListWrp {
    h5 {
        color: $grayText;
    }

    .FilterChoose {
        border-bottom: none;
        margin-left: 10px;
    }
}

.SpaceBtwn {
    justify-content: space-between;
}

.PaymentConfirm-wrp {
    border: 1px solid rgba(237, 180, 87, 0.7019607843) !important;
    border-radius: 15px;
    padding: 18px 15px 18px 15px;
    margin-bottom: 10px;

    h6 {
        color: #e23b8f;
        font-weight: $primary-font-weight;
        font-size: 14px;
    }

    p {
        font-size: 15px;
        text-align: left;
        margin: 10px 0;
    }
}

.FooterBtnCheck {
    margin: 0 auto;
    max-width: 768px;
    width: 100%;

    .fileds {
        justify-content: center;
        margin-bottom: 25px;

        p {
            margin: 3px 0 0 5px;
        }
    }
}

.Pt-75 {
    padding-top: 75px;
}

.ProfileCarouselwrp {
    padding: 0 15px;
}

.VendorCarousel {
    position: relative;

    .swiper {
        position: initial !important;
    }

    .swiper-button-next {
        margin-top: 0px;
        position: absolute;
        top: 50%;
        right: -40px;
        width: 28px;
        height: 28px;
        transform: translateY(-50%);
        z-index: 11;
        background-image: url(../public/images/arrowright_white.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
        background-color: #edb559;
        border-radius: 50%;
        background-size: 22px;
        &.swiper-button-disabled {
            display: none;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        left: -40px;
        width: 28px;
        height: 28px;
        transform: translateY(-50%);
        margin-top: 0px;
        z-index: 11;
        background-image: url(../public/images/arrowleft_white.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
        background-color: #edb559;
        border-radius: 50%;
        background-size: 22px;
        &.swiper-button-disabled {
            display: none;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }
}

.Emailchekbox {
    margin-left: 15px;

    .custom-checkbox {
        justify-content: flex-start;
        align-items: baseline;

        .checkbox-label {
            margin-right: 10px;
        }

        label {
            font-size: 15px;
        }

        .checkbox-label .checkmark {
            top: -10px;
        }
    }
}

.accordion {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    -webkit-tap-highlight-color: transparent;
}

.panel {
    padding: 0 18px;
    display: none;
    overflow: hidden;
}

.Mr-3 {
    margin-right: 3px !important;
}

.Mt-10 {
    margin-top: 10px !important;
}
.Mt-15 {
    margin-top: 15px !important;
}
.overflowA {
    overflow: auto;
}

.NoSearch {
    appearance: none;
    -webkit-appearance: none;
}

.AddScheduleWrp {
    .Arrow-Left {
        cursor: pointer;
        position: absolute;
        top: 12%;
        left: 0;
    }

    .Arrow-Right {
        cursor: pointer;
        position: absolute;
        top: 12%;
        right: 0;
    }
}

.previewClose {
    top: 51px !important;
    right: 64px !important;
    background: rgb(245, 245, 245) !important;
    border-radius: 5px !important;
    font-size: 17px !important;
    align-items: center;
    display: flex;
    height: 25px !important;
    justify-content: center;
    line-height: normal;
    width: 25px !important;

    @media (max-width: 767px) {
        right: 40px !important;
    }
}

.previewPopup {
    max-width: 600px !important;
    border-radius: 0 !important;
    background-color: #fcf9f700 !important;
    border: none !important;
}

.previewImg {
    margin: 0 auto !important;
    width: 500px;
    height: 300px;

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
    }
}

.editTotal {
    background: none;
    position: absolute;
    right: 35%;
    top: 4px;

    @media (max-width: 767px) {
        position: initial;
    }
}

.editHead {
    position: relative;
}

.guestResponse-wrp {
    text-align: center;
    padding-top: 0;

    .cmnInput {
        text-align: left;
    }

    .accordion {
        padding: 0;
    }

    .custom-accordion .accordion .accordion-item {
        overflow: hidden;
        background: #ffffff40;
    }
}

.fix-bottom-btn {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 1;
    max-width: 768px;
    width: calc(100% - 60px);
    margin: auto;
}

.customRadio-wrp {
    margin-left: 3px;
    position: relative;

    .radio-label {
        font-size: 16px;
        font-weight: 400;
        color: $bodyTextColor;
        position: relative;
        background-color: #ffffff;
        border: 1px solid rgba(242, 202, 137, 0.5019607843);
        padding: 15px;
        border-radius: 100px;
        display: flex;
        cursor: pointer;
        margin-left: 0;
        width: 100%;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 10px;

        p {
            z-index: 1;
            margin: 0;
        }

        .checkbg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked~p {
            color: #fff;
        }

        input:checked~.checkmark {
            background-color: #ffffff;
        }

        input:checked~.checkbg {
            background-color: $primaryColor;
        }

        input:checked~.checkmark:after {
            display: block;
        }

        .checkmark {
            position: relative;
            height: 18px;
            width: 18px;
            background-color: #ffffff;
            border: 1px solid #f7e1bf;
            border-radius: 25px;
            display: block;
            z-index: 1;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 3px;
                width: 3px;
                height: 6px;
                border: solid $primaryColor;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }
}

.response-profile {
    background-image: url(../public/images/response-pbg.svg);
    background-repeat: no-repeat;
    width: 237px;
    height: 232px;
    margin: auto;
    background-size: 100% 100%;
    padding: 16px;
    margin-bottom: 25px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.wedding-invitation {
    text-align: center;
    padding-top: 0;

    h6 {
        color: $bodyTextColor;
        font-size: 16px;
    }

    h3 {
        color: #e23b8f;
        font-size: 36px;
        font-family: $secondaryFont;
        margin: 5px 0 10px;
    }

    p {
        font-size: 15px;
        color: $bodyTextColor;
    }

    .bgBtn {
        margin-bottom: 15px;
    }
}

.invitation-event-details {
    border: 1px solid #edb45780;
    background-color: #ffffff40;
    border-radius: 13px;
    padding: 25px 20px;
    text-align: left;
    margin-bottom: 20px;

    h4 {
        font-size: 16px;
        color: #e23b8f;
        font-weight: $primary-font-weight;
    }

    .event-time {
        font-size: 13px;
        color: #dcc39a;
    }

    h5 {
        font-size: 14px;
        color: #e23b8f;
        font-weight: $primary-font-weight;
        display: flex;
        align-items: center;

        img {
            margin-right: 15px;
        }
    }

    .event-place {
        border-top: 1px solid #edb45780;
        padding-top: 20px;
        margin-top: 20px;
    }

    p {
        text-align: left;
        margin: 10px 0 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.members-list {
    @media (min-width: 567px) {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 48%;
            margin: 5px;
        }
    }

    li {
        list-style: none;
        border: 1px solid #edb45780;
        background-color: #ffffff40;
        border-radius: 13px;
        padding: 18px 20px;
        text-align: left;
        margin-bottom: 5px;
    }
}

.btnGrp {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
        width: 48%;
    }
}

.pdfWrapper {
    height: 100vh;
    overflow: auto;
    padding: 20px 5px;
    width: 620px;
    max-width: 95%;
    margin: auto;

    .rxv-container {
        background: $boxbgColor !important;
        padding: 10px !important;
    }

    .react-pdf__Page__canvas {
        max-width: 100% !important;
        height: auto !important;
        width: 100% !important;
    }

    .react-pdf__Page__annotations {
        max-width: 100% !important;
        height: auto !important;
        width: 100% !important;
    }

    iframe {
        width: 100%;
        min-height: 500px;
        height: calc(100vh - 172px);

        @media (max-width: 567px) {
            height: calc(100vh - 230px);
        }
    }

    p {
        background: $boxbgColor;
        margin: 0px;
        padding-top: 15px;
    }

    .btnHolder {
        background: $boxbgColor;
        margin: 0;
        padding: 24px 10px;
    }

    .closeBtn {
        border: none;
        background: transparent;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;

        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 0;
            width: 18px;
            height: 1.5px;
            background-color: #f2d098;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            @media (max-width: 767px) {
                width: 12px;
            }
        }

        &:after {
            transform: rotate(45deg);
        }

        &:before {
            transform: rotate(-45deg);
        }

        @media (max-width: 767px) {
            background: #edb559;
            z-index: 777;
            padding: 10px;
        }
    }

    .pg-viewer-wrapper .document-container {
        width: 100%;
    }

    .pg-viewer-wrapper {
        overflow-y: visible;
        height: auto !important;
    }
}

.pdf-footer {
    margin: auto;
    width: 100%;
}

.faq-wrp {
    padding: 0;

    .accordion {
        padding: 0;
    }

    .custom-accordion .accordion .accordion-item .accordion-content {
        padding: 0;
    }

    .custom-accordion .accordion .accordion-item .accordion-title {
        padding: 0px 15px 20px 15px;
    }

    p {
        text-align: left;
        margin: 0;
    }
}

.category-budget {
    .custom-accordion {
        .accordion-item {
            border: 1px solid rgb(237 180 87 / 25%);
            border-radius: 10px;
            background: $boxbgColor;

            .accordion-content {
                border-top: 1px solid rgb(237 180 87 / 25%);
                padding: 15px 0 15px;

                .custom-radio-btn {
                    margin: 25px 0 30px;
                }

                figure {
                    display: flex;
                    align-items: center;

                    img {
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        margin-right: 15px;
                    }

                    figcaption {
                        color: $bodyTextColor;
                        font-weight: $primary-font-weight;
                    }
                }
            }

            .accordion-title {
                align-items: flex-start;
                flex-direction: column;

                h4 {
                    font-size: 15px;
                    font-weight: $primary-font-weight;
                    color: $bodyTextColor;
                }
            }
        }
    }

    ul {
        width: 100%;
        list-style: none;
        margin-top: 10px;

        li {
            font-size: 15px;
            color: $grayText;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            span {
                font-weight: $primary-font-weight;
                color: #311e00;
            }
        }
    }

    .D-flex {
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
}

.budget-vendors {
    &+.budget-vendors {
        border-top: 1px solid rgba(237, 180, 87, 0.25);
        padding-top: 15px;
        margin-top: 15px;
    }
}

.displayNone {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.vendorCard {
    @media (max-width: 767px) {
        margin: 5px 0 5px 25px;
    }
}

.recommendation-btn {
    width: 50% !important;
    min-width: 180px;

}

.popup {
    width: 100%;
}

.Mb-15 {
    margin-bottom: 15px !important;
}

.no-notification {
    display: block;
    text-align: center;
}

.insight-head-bg {
    position: relative;
    max-width: 768px;
    text-align: center;
    margin: auto;

    .bg-img {
        max-width: 100%;
        min-height: 210px;
    }

    .insight-logo {
        width: 120px;
        margin: auto;
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
    }
}

.planning-insight-container {
    h4 {
        font-size: 16px;
        font-weight: $primary-font-weight;
        color: $bodyTextColor;
        margin-bottom: 15px;
    }

    h1 {
        color: #e23b8f !important;
        font-family: $secondaryFont !important;
        font-size: 42px !important;
        text-align: left !important;
    }

    ol,
    ul {
        margin-left: 15px;
    }
}

.text-center {
    text-align: center;
}

.insight-footer-bg {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    max-width: 768px;
    text-align: center;
    width: 100%;

    img {
        width: 100%;
    }
}

// QUILL STYLES

.ql-align-center {
    text-align: center !important;
}

.tentative-city-txt {
    position: relative;

    .tooltipMsg {
        margin-top: 0 !important;
        top: -3px;
    }
}

.guestUploadPopup {
    display: flex;
    align-items: center;

    img {
        margin-top: 0 !important;
        width: 40px;
    }
}

.termsAndAgreement {
    text-align: left;

    .borderBtn {
        max-width: 225px;
    }
}

.preferencesList-wrap {
    .preferencesList-card {
        border-radius: 16px;
        border: 1px solid #f7e1bf;
        background: $boxbgColor;
        display: flex;
        padding: 12px 22px;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
            margin: 0;
        }

        .left-block {
            flex: 1 1 100%;

            .title {
                color: $bodyTextColor;
                font-size: 15px;
                font-weight: $primary-font-weight;
                text-align: left;
                margin-bottom: 5px;
            }

            .dec {
                color: #b49e7e;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                margin: 0;
                line-height: 18px;
            }

            .dec-block {
                color: $bodyTextColor;
                font-size: 13px;
                font-weight: 400;
                text-align: left;
                margin: 0;
                line-height: 18px;
            }

            ul {
                margin-top: 7px;
                list-style-type: none;
                margin-bottom: 25px;

                li {
                    border-radius: 10px;
                    border: 1px solid #fff3e0;
                    background: #fffcf9;
                    color: $bodyTextColor;
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 6px;

                    div {
                        padding: 20px 15px;
                        cursor: pointer;
                    }

                    &.isActive {
                        border-radius: 10px;
                        color: #fff;
                        font-weight: $primary-font-weight;
                        background: #e23b8f url("../public/images/checkmark-black.svg") no-repeat center right 16px;
                        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
                    }
                }
            }
        }

        .right-block {
            flex: 1 1 24px;
            cursor: pointer;

            svg {
                path {
                    stroke: #000;
                }
            }
        }
    }
}

.photoOptions {
    img {
        max-width: 100%;
    }
}

.main-title {
    margin-bottom: 15px;

    .title-block {
        color: $bodyTextColor;
        font-size: 18px;
        font-weight: $primary-font-weight;
        margin-bottom: 5px;
    }

    .dec-txt {
        color: #b49e7e;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
}

.vendorAgreementButton {
    padding: 50px;
    justify-content: center;
}

.closeAgreement {
    max-width: 200px;
}

.vendorAgreementIframe {
    display: block;
    margin: 0 auto;
    width: 100%;

    height: 500px;
    max-height: 70vh;
    padding-top: 5%;
    border: none;
}

@media (max-width: 767px) {
    .vendorAgreementIframe {
        padding-top: 25%;
        max-width: 800px;
    }
}

.vendorMacthingContent {
    margin-top: 5%;
    margin-left: 25%;
    max-width: 800px;
    max-height: 70vh;
    overflow-y: scroll;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

.vendorMacthingAgreementhref {
    color: blue;
    cursor: pointer;
}

.agreement-section,
.obligations-section,
.representations-section,
.fees-section,
.mediation-section,
.default-section,
.attorneys-fees-section,
.liability-section {
    margin-bottom: 24px;
}

.agreement-section h2,
.obligations-section h2,
.representations-section h2,
.fees-section h2,
.mediation-section h2,
.default-section h2,
.attorneys-fees-section h2,
.liability-section h2 {
    color: #333;
}

.agreement-section p,
.obligations-section p,
.representations-section p,
.fees-section p,
.mediation-section p,
.default-section p,
.attorneys-fees-section p,
.liability-section p {
    color: #555;
}

.AgreementWrp {
    max-width: 768px;
    margin: auto;
    font-size: 14px;
    color: #555;

    p {
        text-align: left !important;
        margin: 20px 0;
    }

    h2 {
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid #c53d7f;
        font-size: 14px;
        font-weight: 600;
    }

    .agreement-section {
        p {
            font-weight: 600;
            text-align: left;
        }
    }

    ol {
        margin-top: 5px;
        padding-left: 20px;

        li {
            margin: 25px 0;
            color: $bodyTextColor;
            font-size: 14px;
            padding-left: 5px;

            &::marker {
                color: $bodyTextColor;
            }

            ol li {
                margin: 5px 0;
            }
        }
    }
}

.setEventsForm {
    .setEventWrap {
        margin-top: 15px !important;
    }

    .labelOption {
        margin: 0;
    }

    .fileds .cmnInput {
        width: 100% !important;
        padding: 0;
    }

    .tooltipMsg {
        background: transparent !important;
    }
}

.contractsCard.cursorPointer.vendorCard.greyedout {
    background: lightgrey;
}

.contractsCard.vendorCard.greyedout {
    background: lightgrey;
}

.btnVendorAssigned {
    margin-bottom: 10px;
}

.backButtonAgreement {
    path {
        stroke: #e23b8f;
    }

    background: none;
}

.notification-btn span {
    background-color: #e23baa;
    border-radius: 50px;
    text-align: center;
    position: absolute;
    width: 19px;
    top: -4px;
    right: -15px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.footer-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    button:nth-of-type(1) {
        margin-right: 10px;
    }
}

// Chat module

.str-chat__channel .str-chat__container {
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
}

.congrats-popup {
    img {
        width: 175px;
        margin-top: 0px !important;
    }

    .popup {
        background-color: #fff;
    }
}

.cost-exceed-alert {
    .modal-header {
        display: none;
    }
}

.customDatepicker {
    input {
        background-image: url(/images/calendarIcon.svg) !important;
        background-repeat: no-repeat !important;
        background-position: 96% center !important;
        background-size: 18px !important;
        cursor: pointer;
    }

    &.position-up .rdtPicker {
        top: auto;
        bottom: 100%;
    }
}

.scheduleDatepicker {
    &.position-up .rdtPicker {
        top: auto;
        bottom: auto;

        @media (max-width: 1366px) {
            top: auto;
            bottom: 100%;
        }
    }
}

.customTimepicker {
    input {
        flex-direction: column;
        background-image: url(../public/images/timeicon2.svg);
        flex-wrap: nowrap;
        background-repeat: no-repeat !important;
        align-content: space-around;
        background-position: 96% center !important;
        justify-content: center;
        background-size: 21px !important;
        cursor: pointer;
    }

    .rdtPicker {
        @media (max-width: 767px) {
            min-width: 155px;
        }
    }
}

.comingSoonText {
    color: #e23b8f;
    margin-bottom: 3px !important;
    margin-top: 13px !important;
    font-size: 17px !important;
    font-weight: 600;
}

.contractsCarousel {
    position: relative;

    .swiper {
        position: initial !important;
        margin: 0 !important;

        .contracts-card {
            margin-bottom: 20px;
            height: 100%;
            min-height: 215px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 767px) {
                padding: 10px;
            }
        }
    }

    .swiper-button-next {
        margin-top: 0px;
        position: absolute;
        top: 50%;
        right: -40px;
        width: 28px;
        height: 28px;
        transform: translateY(-50%);
        z-index: 11;
        background-image: url(../public/images/arrowright_white.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
        background-color: #edb559;
        border-radius: 50%;

        &.swiper-button-disabled {
            display: none;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        left: -40px;
        width: 28px;
        height: 28px;
        transform: translateY(-50%);
        margin-top: 0px;
        z-index: 11;
        background-image: url(../public/images/arrowleft_white.svg);
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
        background-color: #edb559;
        border-radius: 50%;

        &.swiper-button-disabled {
            display: none;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }
}

iframe {
    border: none;
}

.iframe-container {
    max-width: 100%;
}

.chatUsers-wrp {
    width: 700px;
    margin: 0 auto;
    max-width: 100%;
}

.chatUser {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f7e1bf66;
    padding: 10px 0;

    .chatUser-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 60%;
        flex: none;
    }

    .str-chat__avatar {
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        flex: none;
        background-color: #cacaca;
    }

    .chatUser-name-wrp {
        max-width: calc(100% - 75px);
    }

    h4 {
        font-size: 16px;
        color: #e23b8f;
        font-weight: $primary-font-weight;
        word-break: break-word;
    }

    h5 {
        color: #20222d80;
        font-size: 14px;
        margin-top: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .chatUser-time-wrp {
        margin-left: auto;
    }

    .chatTime {
        color: #dab880;
        font-size: 14px;
        display: block;
    }

    .chatCount {
        color: #edb258;
        font-size: 14px;
        width: 25px;
        height: 25px;
        border-radius: 30px;
        background-color: #f4e9d9;
        display: block;
        margin-left: auto;
        text-align: center;
        line-height: 25px;
    }

    .activeIndicater {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background-color: #56c625;
        margin-left: 10px;
    }
}

.pin-wrp {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .pin-button {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        background: #edb559;

        img {
            vertical-align: middle;
            max-width: 100%;
        }
    }
}

.chat-container {
    width: 1000px;
    margin: 0 auto;
    max-width: 100%;
    padding-top: 70px;
    padding-bottom: 15px;
    background: $boxbgColor;
    svg {
        width: auto;
        margin-right: 0px;
    }

    .str-chat__list .str-chat__message-list-scroll .str-chat__li {
        margin-inline: 0 !important;
        padding-inline: 0 !important;
    }

    .str-chat__message-textarea-container {
        border-color: #edb457 !important;
    }

    .str-chat__message-input {
        button.str-chat__send-button {
            background: #e23b8f;
            margin-left: 5px;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: #ffffff;
                }
            }
        }
    }

    .str-chat__message .str-chat__message-options .str-chat__message-actions-box-button .str-chat__message-action-icon path,
    .str-chat__message .str-chat__message-options .str-chat__message-reply-in-thread-button .str-chat__message-action-icon path,
    .str-chat__message .str-chat__message-options .str-chat__message-reactions-button .str-chat__message-action-icon path {
        fill: #000000;
    }

    .str-chat__message-actions-box {
        border-radius: 5px;

        .str-chat__message-actions-list .str-chat__message-actions-list-item-button {
            font-size: 14px;
            padding: 3px 10px;
        }

        .str-chat__message-actions-list {
            min-width: 120px;
        }
    }

    .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular).str-chat__message-actions-box--mine {
        left: 0;
    }

    .str-chat__modal--open .str-chat__modal__inner {
        width: 350px;
        max-width: 90%;
        margin-top: 50px;

        @media (max-width: 600px) {
            margin-top: 200px;
        }
    }

    .str-chat__avatar-fallback {
        font-weight: $primary-font-weight;
    }

    .str-chat__edit-message-form-options {
        button {
            margin-left: 5px;
            background: #edb559 !important;
            color: #fff !important;
            height: 25px;
            border-radius: 8px !important;
            width: 70px;
            text-transform: uppercase !important;
            font-size: 13px !important;
        }
    }

    .str-chat__message.str-chat__message-simple.pinned-message {
        position: relative;
        padding-top: 20px;
    }

    .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular):not(.str-chat__message-actions-box--mine) {
        inset-inline-end: 0;
    }
    .str-chat__message-input ,
    .str-chat__empty-channel {
        background: $boxbgColor;
    }
}

.chatHeader {
    padding: 10px 25px;
    justify-content: space-between;

    .chatUser {
        padding: 0;
        max-width: calc(100% - 70px);
    }

    .backButton {
        position: initial;

        svg {
            margin-right: 0;
        }
    }
}

.errorText {
    color: red;
}

.textResponse {
    width: 100% !important;
    padding: 0px !important;
}

span.blackText {
    color: $bodyTextColor !important;
    margin: 5px 0 20px;
    display: block;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.powered-text{
    font-weight: 500;
    font-size: 15px;
}
.listing {
    cursor: pointer;
}

.vendorBadge {
    position: absolute;
    top: -19px;
    left: 0;
    transform: translateX(-43%) translateY(0%) rotate(-45deg);
    transform-origin: top right;
    background: #fa7901;
    width: 150px;
    color: #fff;
    z-index: 1;
    font-size: 13px;
    @media (max-width: 767px) {
        font-size: 12px;
    }
}

.budgetProgressbar {
    background: #ffffff;
    border: 1px solid #43485b40;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 20px;

    ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        li {
            color: $bodyTextColor;
            font-size: 22px;
            font-weight: $primary-font-weight;

            &:last-child {
                color: #d1468c;
            }
        }
    }
}

.progressArea {
    position: relative;
    height: 16px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #d1468c29;

    span {
        position: absolute;
        height: 100%;
        background-color: #d1468c;
        left: 0;
        top: 0;
    }
}

.budget-links {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    a {
        line-height: 21px;
        background-color: #3399cc;
        font-size: 12px;
        color: #fff;
        border-radius: 25px;
        padding: 0 10px;
        margin: 0 5px;
        height: 20px;
    }
}

.link-button {
    font-size: 14px;
    display: inline-block;
    border-bottom: 1px solid;
    background: none;
    color: $linkColor;
    padding: 0;
}

.pin-bar {
    button {
        width: 20px;
        padding: 0;
        background: no-repeat;
    }
}

.pinned-messages-bar {
    position: fixed;
    top: 0;
    right: -340px;
    width: 100%;
    height: 100%;
    z-index: 100;
    padding: 0px 20px 20px 20px;
    background: #ffffff;
    max-width: 340px;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3019607843);
    transition: all 0.4s;
    opacity: 0.2;
    overflow: auto;

    @media (max-width: 767px) {
        max-width: 96%;
        right: -100%;
    }

    &.menuOpen {
        right: 0px;
        opacity: 1;
    }

    .close-bar {
        background: #009fde;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        width: 30px;
        height: 30px;

        img {
            max-width: 70%;
        }
    }

    .pinned-message::before {
        display: none;
    }
}

.pinned-messages-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 20px 0 0px 0;
    z-index: 1;

    h2 {
        font-size: 20px;
        color: #e23b8f;
        font-weight: $primary-font-weight;
    }
}

// skeleton
.skeleton-loader {
    position: relative;
    overflow: hidden;
    color: transparent !important;
}

.skeleton-loader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, #f0f0f0, transparent);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background: #f9704b !important;
}

.chat-popup {
    position: fixed;
    width: 500px;
    bottom: 0;
    right: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    max-width: 100%;
    z-index: 1111;
    border: 1px solid #e7e7e7;
    display: none;

    svg {
        width: auto;
        margin-right: 0px;
    }

    @media (max-width: 1366px) {
        width: 400px;
    }

    @media (max-width: 767px) {
        display: none !important;
    }

    &.open {
        display: block;
    }

    &.minimize {
        bottom: -492px;

        @media (max-width: 1366px) {
            bottom: -372px;
        }
    }

    .chat-popup-header {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        background: #2c9fda;
        height: 60px;
        flex: none;
    }

    .str-chat__avatar--circle {
        flex-basis: 32px;
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        width: 32px;
        margin-right: 10px;
    }

    .str-chat__avatar-fallback {
        font-weight: $primary-font-weight;
        background: #005fff;
        border-radius: 50%;
        color: #fff;
    }

    .chat-popup-user-name {
        color: #fff;
        font-weight: $primary-font-weight;

        p {
            font-size: 12px;
        }
    }

    .chat-popup-body {
        height: 550px;

        @media (max-width: 1366px) {
            height: 430px;
        }
    }

    .str-chat__list {
        background: #f4f4f461;
    }

    .str-chat__list-notifications {
        margin: 0;
        padding: 0.375rem;
        background: #f4f4f461;
    }

    .str-chat__date-separator {
        padding: 30px var(--str-chat__spacing-8);
    }

    .str-chat__list .str-chat__message-list-scroll .str-chat__li {
        margin-inline: 0 !important;
        padding-inline: 0 !important;
    }

    .str-chat__message-textarea-container {
        border-color: #edb457 !important;
    }

    .str-chat__message-input:not(.str-chat__modal .str-chat__message-input) {
        background: #f3f3f3 !important;
        padding: 5px var(--str-chat__spacing-1) var(--str-chat__spacing-2);
    }

    .str-chat__message-input {
        button.str-chat__send-button {
            background: #ce3180;
            margin-left: 5px;

            svg {
                width: 18px;
                height: 18px;
                margin: 0;

                path {
                    fill: #ffffff;
                }
            }
        }
    }

    .str-chat__message .str-chat__message-options .str-chat__message-actions-box-button .str-chat__message-action-icon path,
    .str-chat__message .str-chat__message-options .str-chat__message-reply-in-thread-button .str-chat__message-action-icon path,
    .str-chat__message .str-chat__message-options .str-chat__message-reactions-button .str-chat__message-action-icon path {
        fill: #000000;
    }

    .str-chat__message-actions-box {
        border-radius: 5px;
        background: #ffffff;

        .str-chat__message-actions-list .str-chat__message-actions-list-item-button {
            font-size: 13px;
            padding: 1px 10px;
        }

        .str-chat__message-actions-list {
            min-width: 120px;
        }
    }

    .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular).str-chat__message-actions-box--mine {
        left: 0;
    }

    .str-chat__modal--open .str-chat__modal__inner {
        width: 350px;
        max-width: 90%;
        margin-top: 50px;
        background: #fff;

        @media (max-width: 600px) {
            margin-top: 200px;
        }
    }

    .str-chat__edit-message-form-options {
        button {
            margin-left: 5px;
            background: #2c9fda !important;
            color: #fff !important;
            height: 25px;
            border-radius: 8px !important;
            width: 70px;
            text-transform: uppercase !important;
            font-size: 12px !important;
            line-height: 22px !important;
        }
    }

    .str-chat__message.str-chat__message-simple.str-chat__message--regular.str-chat__message--received.pinned-message {
        position: relative;
        padding-top: 20px;

        &::before {
            position: absolute;
            top: 4px;
            right: 8px;
            content: "";
            width: 15px;
            height: 15px;
            background-size: contain;
            background-image: url("/images/pin.png");
        }

        &.str-chat__message--other {
            &::before {
                left: 16px;
                right: auto;
            }
        }
    }

    .str-chat__message-actions-box:not(.str-chat__message-actions-box-angular):not(.str-chat__message-actions-box--mine) {
        inset-block-end: 100%;
        inset-inline-end: 0%;
    }

    button[aria-expanded="true"] {
        background-color: var(--str-chat__message-options-hover-background-color) !important;
    }

    .str-chat__message-input .str-chat__message-input-inner .str-chat__message-textarea-container .str-chat__message-textarea-with-emoji-picker .str-chat__message-textarea {
        font-size: 14px;
    }
}

.chat-action-btns {
    margin-left: auto;
    flex: none;

    button,
    a {
        width: 25px;
        height: 25px;
        margin-left: 6px;
        line-height: 20px;
        display: inline-block;
        background: none;

        &.minimized {
            img {
                transform: rotateX(180deg);
            }
        }

        &.chat-expand {
            img {
                max-width: 14px;
            }
        }

        img {
            max-width: 100%;
        }
    }
}

.word-break-all {
    word-break: break-all !important;
}

.W-50 {
    width: 50% !important;
}

.search-active {
    .customSelect__dropdown-indicator {
        svg {
            display: none;
        }

        background-image: url("../public/images/searchicon2.svg");
        width: 20px;
        height: 20px;
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.noData {
    background: $boxbgColor;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
}

.form-field:has(.validationCheck) .form-control,
.form-field:has(.errormsg) .form-control {
    padding-right: 32px !important;
}

//   new-style

.weddingdetails-slider {
    .swiper {
        height: 100%;
        margin: 0 !important;
        padding-bottom: 30px;
        .swiper-slide {
            overflow: hidden;
        }
        .swiper-slide:first-of-type {
            .wedding-detailsCard-body {
                padding-right: 50px;

                @media (max-width: 576px) {
                    padding-right: 35px;
                }
            }
        }
    }
    &.navigation-arrow{
        .swiper-button-next{
            top: 47%;
            right: -10px;
            @media (max-width: 767px) {
            display: none;
            }
            }
            .swiper-button-prev{
                top: 47%;
               left: -10px;
                @media (max-width: 767px) {
                    display: none;
                    }
            }

    }
}

.wedding-details-wrp {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    @media (max-width: 767px) {
        margin-top: 20px;
        }
}

.wedding-details-card {
    border-radius: 20px;
    border: 0.5px solid rgba(68, 73, 92, 0.25);
    background: $boxbgColor;
    padding: 20px 20px 0 20px;
    width: 42%;

    @media (max-width: 576px) {
        padding: 15px 10px 0 15px;
        width: 150px;
        .underline-link {
            font-size: 10px;
        }
    }
    @media (max-width: 399px) {
        width: 135px;
    }
    h2 {
        color: $bodyTextColor;
        font-size: 16px;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;

        @media (max-width: 576px) {
            font-size: 14px;
            padding-right: 10px;
        }
    }
}

.wedding-detailsCard-body {
    display: flex;
    align-items: flex-start;
    position: relative;

    .quiz-result-single-item{
        li{
        figure{
            height: 100% !important;
            max-height: 220px;
            @media (max-width: 776px) {
                max-height: 147px;
            }
            @media (max-width: 576px) {
                max-height: 100px;
            }
            @media (max-width: 399px) {
                max-height: 90px;
            }
            figCaption{
                height: 30%;
                left: 2px;
            }

        }

    }
    }

    .quiz-result-cat {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        @media (max-width: 576px) {
            gap: 5px;
        }

        li {
            width: calc(50% - 5px);

            @media (max-width: 576px) {
                width: calc(50% - 3px);
            }

            &:last-child {
                width: 100%;

                figure {
                    height: 120px;
                    @media (max-width: 767px) {
                    height: 75px;
                 }
                    @media (max-width: 576px) {
                        height: 55px;
                        width: 100%;
                    }
                    @media (max-width: 399px) {
                        height: 50px;
                    }
                }
            }

            figure {
                position: relative;
                width: 100%;
                height: 90px;
                overflow: hidden;
                border-radius: 5px;

                @media (max-width: 767px) {
                       height: 62px;
                }
                @media (max-width: 576px) {
                    height: 40px;
                    width: 42px;
                }
                @media (max-width: 399px) {
                    height: 35px;
                    width: 35px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow-clip-margin: unset;
                    &.contain{
                        object-fit: contain;
                    }
                }
            }

            figcaption {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
                height: 50%;
                color: #fff;
                font-size: 15px;
                display: flex;
                align-items: self-end;
                padding: 10px;
                overflow: hidden;

                @media (max-width: 576px) {
                    font-size: 10px;
                    padding: 5px;
                    height: 60%;
                }
            }
        }
    }
}

.fileds .cmnInput .dollar-input {
    position: relative;

    .form-control {
        padding-left: 25px;
    }
}

.dollar-icon {
    position: absolute;
    top: 11.6px;
    left: 13px;
    font-size: 17px;
    color: $bodyTextColor;
}

.noDatabox {
    margin-top: 50px;
    border: 1px solid #d3287e4f;
    padding: 30px;
    border-radius: 5px;
    background: $boxbgColor;
    color: $bodyTextColor;
    @media (max-width: 767px) {
        padding: 15px;
    }
}

//Dashbord redesign Rangoli

.text-box-wrap {
    margin-top: 10px !important;
}

.colors-set {
    position: absolute;
    right: 2px;
    bottom: 1px;
    transform: rotate(-90deg) translate(100%, 0);
    transform-origin: right bottom;

    h5 {
        color: $bodyTextColor;
        font-size: 15px;
        font-weight: 400;
        margin-top: 3px;

        @media (max-width: 576px) {
            font-size: 10px;
        }
    }
}

.colors-wrp {
    display: flex;

    span {
        width: 11px;
        height: 11px;
        border-radius: 10px;
        display: block;
        margin-right: 5px;
        border: 1px solid #e23baa;
        @media (max-width: 576px) {
            width: 8px;
            height: 8px;
        }
    }
}

.take-quiz-card {
    width: 58%;
    border-radius: 20px;
    border: 0.5px solid rgba(68, 73, 92, 0.25);
    overflow: hidden;
    background: $boxbgColor;

    @media (max-width: 576px) {
        width: 58%;
        flex: 1;
    }

    .take-quiz-slider {
        height: 100%;

        .swiper-button-next {
            right: 5px;
            @media (max-width: 767px) {
                display: none;
                }
        }

        .swiper-button-prev {
            left: 5px;
            @media (max-width: 767px) {
                display: none;
                }
        }
    }

    .swiper {
        height: 100%;
        margin: 0 !important;

        .swiper-wrapper {
            align-items: center;
        }
    }
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    z-index: 10;
    width: 100%;
    bottom: 12px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
        bottom: 10px;
    }
}

.swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    background-color: #ded5c9;
    display: block;
    border-radius: 50%;
    margin: 0 2px;
    cursor: pointer;

    @media (max-width: 576px) {
        width: 5px;
        height: 5px;
    }

    &.swiper-pagination-bullet-active {
        background-color: #c4187a;
    }
}

.quiz-card {
    display: flex;
    align-items: center;
    padding: 30px;
    height: 100%;

    @media (max-width: 576px) {
        padding: 13px 13px 20px 0px;
    }

    figure {
        width: 160px;
        flex: none;
        @media (max-width: 767px) {
            width: 140px;
        }
        @media (max-width: 576px) {
            width: 95px;
        }
        @media (max-width: 374px) {
                   width: 62%;
        }
        img {
            max-width: 100%;
        }
    }
    &.personality-quiz-card{
        figure {
            @media (max-width: 374px) {
                       width: 58%;
            }

        }
    }
}

.quiz-description {
    @media (max-width: 576px) {
        margin-left: -5px;
    }

    h3 {
        color: $bodyTextColor;
        font-size: 16px;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;

        @media (max-width: 576px) {
            font-size: 14px;
            line-height: 15px;
        }
    }
}

.review-slider-card {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 25%;
    flex-direction: column;
    justify-content: center;
    background-image: url(../public/images/quote-img.png);

    @media (max-width: 576px) {
        padding: 13px 13px 20px;
        background-size: 50%;
    }
    h2{
        font-size: 16px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;

        @media (max-width: 576px) {
            font-size: 15px;
        }
    }

    h4 {
        font-size: 15px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;

        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    h6 {
        font-size: 14px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;

        @media (max-width: 576px) {
            font-size: 12px;
        }
    }
}

.blog-slider-card {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 30%;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 576px) {
        padding: 13px 13px 20px;

        .underline-link {
            font-size: 10px;
            margin-bottom: 3px;
        }
    }

    h4 {
        font-size: 16px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        margin-bottom: 5px;

        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    h5 {
        color: #ff65b3;
        font-size: 12px;
        margin-bottom: 10px;

        @media (max-width: 576px) {
            font-size: 10px;
        }
    }

    p {
        font-size: 14px;
        color: $bodyTextColor;
        margin-bottom: 10px;

        @media (max-width: 767px) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }
        @media (max-width: 576px) {
            font-size: 12px;
            margin-bottom: 5px;
        }
    }
}

.underline-link {
    font-size: 14px;
    font-weight: $primary-font-weight;
    color: $linkColor;
    border-bottom: 1px solid;
    background: none;
    padding: 0;

    @media (max-width: 576px) {
        font-size: 11px;
    }
}

.recommended-card {
    border-radius: 20px;
    border: 0.5px solid rgba(68, 73, 92, 0.25);
    background: $boxbgColor;
    padding: 20px;
    position: relative;
    .buttons-nav {
        flex-wrap: wrap;
    }
    .navigation-arrow {
        position: initial;
        .swiper-button-next {
            right: 5px;
            @media (max-width: 767px) {
                display: none;
                }
        }
        .swiper-button-prev {
            left: 5px;
            @media (max-width: 767px) {
                display: none;
                }
        }
    }
    @media (max-width: 576px) {
        padding: 15px;
    }

    h2 {
        color: $bodyTextColor;
        font-size: 16px;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
}

.buttons-nav {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.dashboard-nav{
        button{
            width: 140px;
            @media (min-width: 577px) {
                font-size: 16px;
                height: 36px;
            }
        }
    }
    button {
        font-size: 14px;
        color: #d1468c;
        border: 1px solid;
        border-radius: 30px;
        background: none;
        margin-right: 10px;
        padding: 0px 12px;
        height: 26px;
        margin-bottom: 10px;

        @media (max-width: 576px) {
            font-size: 12px;
            height: 24px;
            margin-right: 8px;
        }

        &.active {
            background-color: #d1468c;
            color: #fff;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}
.my-contract-slider {
    margin: 0 -12px;

    @media (max-width: 576px) {
        margin: 0 -4px;
    }
    figure {
        position: relative;
        border-radius: 0px !important;
        background: #e9e9e9;
        height: 140px;

        @media (max-width: 767px) {
            height: 108px;
        }

        @media (max-width: 576px) {
            height: 80px;
        }

        @media (max-width: 480px) {
            height: 65px;
        }
    }
    .swiper {
        margin: 0 !important;
    }

    .swiper-slide {
        padding: 0px 10px 30px 10px;
        height: auto;

        @media (max-width: 576px) {
            padding: 0px 4px 30px 4px;
        }
    }

    .swiper-pagination {
        bottom: 20px;
    }
        &:has(.swiper-pagination-bullet:only-child) {
            .swiper-slide {
                padding-bottom: 10px;
            }
        }
}
.recommended-vendor-slider {
    figure {
        position: relative;
        border-radius: 0px !important;
        background: #e9e9e9;
        height: 185px;
        width: 100%;

        @media (max-width: 767px) {
            height: 140px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

    }

    .videoCard .circleLoader {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}
.dashboard-recommended-vendor-slider {
    margin: 0 -12px;

    @media (max-width: 576px) {
        margin: 0 -4px;
    }
    .contractsCard {
        box-shadow: 0 5px 9px rgba(0, 0, 0, 0.0588235294);
        border-radius: 12px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        .vendor-name {
            @media (max-width: 576px) {
            padding: 8px;
            }
            h3 {
                word-break: break-all;
                font-size: 14px;
                color: $bodyTextColor;
                font-weight: $primary-font-weight;
                @media (max-width: 576px) {
                    font-size: 12px;
                    margin-top: 2px;
                }
            }
        }
    }
    .videoCard {
        position: relative;
        border-radius: 0px !important;
        background: #e9e9e9;
        height: 140px;
            @media (max-width: 767px) {
                height: 108px;
            }
            @media (max-width: 576px) {
                height: 80px;
            }
            @media (max-width: 480px) {
                height: 65px;
            }
        .videoWrap {
            border-radius: 0 !important;
            min-height: 100%;
            max-height: 100%;
        }
        .vendorBadge {
            @media (max-width: 767px) {
                font-size: 9px;
                width: 100px;
                top: -12px;
            }
            @media (max-width: 576px) {
                width: 108px;
                top: -15px;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        .circleLoader{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
        }
        figure{
            width: 100%;
            height: 100%;
        }
    }
    .VerifiedProfile {
        margin-top: 0;
        margin-bottom: 0;
        svg {
            margin-right: 0;
            width: 15px;
            height: 15px;
            margin-top: -3px;
            flex: none;
            @media (max-width: 576px) {
                width: 12px;
                height: 12px;
            }
        }
    }
    h5 {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 5px;

        @media (max-width: 576px) {
            font-size: 8px;
        }

        span {
            color: $bodyTextColor;
        }

        small {
            color: $bodyTextColor;
            margin-left: 5px;
            font-size: inherit;
        }

        svg {
            width: 13px;
            height: 13px;
            margin-right: 3px;
            flex: none;
            margin-top: -7px;

            @media (max-width: 576px) {
                width: 10px;
                height: 10px;
                margin-top: -4px;
            }
        }
    }
    .swiper {
        margin: 0 !important;
    }

    .swiper-slide {
        padding: 0px 10px 30px 10px;

        @media (max-width: 576px) {
            padding: 0px 4px 10px 4px;
        }
    }

    .swiper-pagination {
        bottom: 5px;
    }
        &:has(.swiper-pagination-bullet:only-child) {
            .swiper-slide {
                padding-bottom: 10px;
            }
        }
}

.vendor-slider-item {
    box-shadow: 0 5px 20px #0000000f;
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
    cursor: pointer;

    figure {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .videoCard {
        border-radius: revert !important;
    }

    .vendor-item-details {
        padding: 10px;

        @media (max-width: 576px) {
            padding: 6px;
        }

        h4 {
            font-size: 14px;
            color: $bodyTextColor;
            font-weight: $primary-font-weight;
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            @media (max-width: 576px) {
                font-size: 12px;
            }

            svg {
                margin-right: 0;
                width: 15px;
                height: 15px;

                @media (max-width: 576px) {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        h5 {
            display: flex;
            align-items: center;
            font-size: 12px;

            @media (max-width: 576px) {
                font-size: 8px;
            }

            span {
                color: $bodyTextColor;
            }

            small {
                color: $bodyTextColor;
                margin-left: 5px;
                font-size: inherit;
            }

            svg {
                width: 13px;
                height: 13px;
                margin-right: 3px;
                flex: none;
                margin-top: -5px;

                @media (max-width: 576px) {
                    width: 10px;
                    height: 10px;
                    margin-top: -4px;
                }
            }
        }
    }
}
.fav-icon{
    margin-right: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.view-all-wrap{

    @media (max-width: 576px) {
    .fav-icon{
        width: 14px;
        height: 14px;
    }
    }
}
.favourite-active {
    path {
        fill: $darkRed;
    }
}

.call-reminder {
    display: flex;
    align-items: center;
    margin: 15px 0;

    h2 {
        font-weight: 500;
        font-size: 16px;
        color: $bodyTextColor;

    }

    img {
        margin-right: 10px;
    }
    .link-button{
        font-weight: inherit;
    }
}

.dashboard-todo {
    margin-top: 50px;

    h2 {
        font-size: 16px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;

        @media (max-width: 576px) {
            font-size: 14px;
        }

    }
    p {
        cursor: pointer;
        margin-right: 5px;
        button {
            background: none;
            text-align: left;
            padding: 0;
            color: $bodyTextColor;
            font-weight: $primary-font-weight;
        }
    }

    .todo-box {
        border-radius: 5px;
        border: 0.5px solid rgba(68, 73, 92, 0.25);
        background: $boxbgColor;
        padding: 10px;
        font-size: 14px;
        color: $bodyTextColor;
        font-weight: $primary-font-weight;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        line-height: initial;

        small {
            font-weight: 400;
            margin-left: auto;
            font-size: 12px;
            flex: none;
        }

        span {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 2px solid #d9d0c3;
            margin-right: 10px;
            cursor: pointer;
            flex: none;

            &.active {
                background-color: #e23baa;
            }
        }

        @media (max-width: 576px) {
            font-size: 12px;
        }

        a {
            color: $linkColor;
            background: none !important;
            border-bottom: 1px solid;
        }
    }
}

.weekly-progress-wrp {
    position: relative;
    width: 500px;
    text-align: center;
    max-width: 70%;
    .wedding-progress{
        width: 100%;
        margin: 0 auto;

    }
    @media (max-width: 576px) {
        max-width: 90%;
        .wedding-progress{
            width: 80%;

        }
    }
    &.completed {
        h4 {
            color: #ffcc33;

            svg {
                path {
                    fill: #ffcc33;
                }
            }
        }

        h5 {
            color: #ffcc33;
        }

        .progressArea,
        .progressValue {
            background-color: #ffcc33;
        }
    }

    h4 {
        font-size: 18px;
        color: #fff;
        font-weight: $primary-font-weight;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 30px 0;

        svg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            margin-top: -5px;

            @media (max-width: 576px) {
                width: auto;
                height: auto;
            }
        }
    }

    h5 {
        font-size: 15px;
        color: #faf9f8;
        text-align: left;
        margin-top: 8px;

        @media (max-width: 576px) {
            font-size: 10px;
        }
    }

    .progressArea {
        height: 8px;
        background-color: #f6dde7;

        @media (max-width: 576px) {
            height: 5px;
        }
    }

    .progressArea span {
        background-color: #c10463;
    }
}

.navigation-arrow {
    position: relative;

    .swiper {
        position: initial;
    }

    .swiper-button-next {
        margin-top: 0px;
        position: absolute;
        top: 50%;
        right: -10px;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        z-index: 11;
        background-repeat: no-repeat;
        background-color: #e23baa;
        background-size: 15px;
        background-position: center;
        border-radius: 50px;
        cursor: pointer;
        background-image: url(../public/images/arrowright_white.svg);
        &.swiper-button-lock{
            display: none;
        }

        @media (max-width: 576px) {
            width: 15px;
            height: 15px;
            right: -7px;
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        left: -10px;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        margin-top: 0px;
        z-index: 11;
        background-repeat: no-repeat;
        background-color: #e23baa;
        background-size: 15px;
        background-position: center;
        border-radius: 50px;
        cursor: pointer;
        background-image: url(../public/images/arrowleft_white.svg);
        &.swiper-button-lock{
            display: none;
        }
        @media (max-width: 576px) {
            width: 15px;
            height: 15px;
            left: -7px;
        }
    }
}

//   new-style

// Rangoli
.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
    stroke: #444a5e;
    stroke-miterlimit: 6;
}

.cls-1 {
    fill: #fff;

    &.active {
        fill: #a11c21;
    }
}

.cls-2 {
    fill: #fff;

    &.active {
        fill: #f47d20;
    }
}

.cls-3 {
    fill: #fff;

    &.active {
        fill: #4777b8;
    }
}

.cls-4 {
    fill: #fff;

    &.active {
        fill: #f03a21;
    }
}

.cls-5 {
    fill: #fff;

    &.active {
        fill: #71c313;
    }
}

.cls-6 {
    fill: #fff;

    &.active {
        fill: #b1cb4f;
    }
}

.cls-7 {
    fill: #fff;

    &.active {
        fill: #3399cc;
    }
}

.todo-animation-card {
    width: 708px;
    margin: auto;
    text-align: center;
    background: $boxbgColor;
    border: 1px solid #44495c40;
    border-radius: 20px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    padding: 50px 5px 5px 5px;
    margin-bottom: 30px;

    @media (max-width: 576px) {
        padding: 35px 15px 15px 15px;
        margin-bottom: 20px;
    }
}

.to-do-animation-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h1 {
        font-size: 16px;
        color: #c4187a;
        font-weight: $primary-font-weight;
        margin-bottom: 10px;
        background: #ffffff54;

        @media (max-width: 576px) {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }

    h6 {
        font-size: 14px;
        color: $bodyTextColor;
        margin-bottom: 20px;
        background: #ffffff54;

        @media (max-width: 576px) {
            font-size: 13px;
            margin-bottom: 15px;
        }
    }
}

.todo-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    position: relative;

    @media (max-width: 576px) {
        padding: 0px;
    }

    figure {
        flex: none;
        width: 150px;
        margin: 0;
        max-width: 24%;
    }
}

.todo-category {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0;
    flex-wrap: wrap;

    li {
        display: flex;
        align-items: baseline;
        margin: 2px 2px;

        p {
            font-size: 14px;
            font-weight: $primary-font-weight;
            text-align: center;
            margin: 0;
            color: inherit;

            @media (max-width: 576px) {
                font-size: 12px;
            }
        }

        span {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 5px;
            position: relative;
            top: 1px;

            @media (max-width: 576px) {
                width: 7px;
                height: 7px;
            }
        }

        input {
            width: 40px;
            height: 22px;
            font-size: 16px;
        }

        label {
            margin-right: 5px;
        }

        &.logistic-count {
            p {
                color: #e97850;
            }

            span {
                background-color: #e97850;
            }
        }

        &.aesthetics-count {
            p {
                color: #dc4a36;
            }

            span {
                background-color: #dc4a36;
            }
        }

        &.hospitality-count {
            p {
                color: #7fba3d;
            }

            span {
                background-color: #7fba3d;
            }
        }

        &.experience-count {
            p {
                color: #3399cc;
            }

            span {
                background-color: #3399cc;
            }
        }
    }
}

$falling-time: 2500ms;

.stars-grp {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    left: 0;
    pointer-events: none;

    &.onemonth-garland {
        path {
            fill: #ebcb61;
        }
    }

    &.hospitality-garland.active {
        path {
            fill: #7fba3d;
        }
    }

    &.experience-garland.active {
        path {
            fill: #3399cc;
        }
    }

    &.logistics-garland.active {
        path {
            fill: #efa333;
        }
    }

    &.aesthetics-garland.active {
        path {
            fill: #dc4a36;
        }
    }

    .falling-star {
        position: relative;
        width: calc(100% / 23);

        @media (max-width: 576px) {
            padding: 0 0.4%;
        }

        .star-icon {
            width: 100%;
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            transition: all 0.3s;

            @media (max-width: 576px) {
                height: 18px;
            }

            @media (max-width: 400px) {
                height: 15px;
            }

            svg {
                width: 100%;
                margin-right: 0;

                @media (max-width: 576px) {
                    height: 15px;
                }

                @media (max-width: 400px) {
                    height: 13px;
                }
            }
        }
    }

    &.active {
        svg {
            path {
                animation: pathcolor 1ms 8s 1 forwards;
            }
        }

        .star-icon {
            opacity: 0;
        }

        .falling-star {
            @for $i from 1 through 23 {
                &:nth-child(#{$i}) {
                    $delay: random(3000) + 0ms;

                    animation-delay: $delay;

                    @for $k from 1 through 10 {
                        .star-icon {
                            &:nth-child(#{$k}) {
                                transform: translateY(calc(-#{$k * 20px - 20px}));
                                $opacityDelay: $k * 100ms;
                                animation:
                                    fallingStar 3500ms forwards,
                                    fallingOpacity $falling-time $opacityDelay forwards;

                                svg {
                                    animation: starBreath linear 1000ms 1500ms 6 forwards;
                                }

                                @media (max-width: 576px) {
                                    transform: translateY(calc(-#{$k * 15px - 15px}));
                                }

                                @media (max-width: 400px) {
                                    transform: translateY(calc(-#{$k * 5px - 5px}));
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes fallingStar {
    100% {
        transform: translateY(0);
    }
}

@keyframes fallingOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes starBreath {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.2);
    }

    60% {
        transform: scale(0.8);
    }

    70% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes falling {
    0% {
        transform: translateY(0);
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(300px);
    }
}

@keyframes pathcolor {
    to {
        fill: #ebcb61;
    }
}

.rangoli-wrp {
    width: 52%;

    svg {
        width: 100%;
        margin-right: 0;
    }
}

.mob-hide {
    @media (max-width: 576px) {
        display: none;
    }
}

// Rangoli

.close-quiz {
    position: absolute;
    right: 25px;
    top: 15px;
    z-index: 1;
    background: #ffbf00;
    width: 30px;
    left: auto;
    padding: 5px;
    height: 30px;
    border-radius: 2px;
    cursor: pointer;

    img {
        max-width: 100%;
    }
}

.badge-description {
    margin-bottom: 30px;

    h3 {
        font-size: 15px;
        color: #d62c81;
        margin-top: 15px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    p {
        text-align: left;
        margin: 0;
    }
}

.suggested-slider-header {
    h4 {
        margin-bottom: 0;
    }

    p {
        text-align: left;
        margin: 5px 0 10px 0;
    }
}

.gb-icons-wrp {
    display: flex;
    align-items: flex-end;
}

.gb-icons {
    display: flex;
    align-items: center;
    margin-left: auto;

    img {
        width: 28px;
        margin-left: 5px;
    }
}

.create-popup-congrats {
    .popup img {
        margin-top: 0;
    }
}

.social-icons-wrp {
    width: 100%;

    h5 {
        font-size: 14px;
        color: #ffffff;
        position: relative;
        margin: 20px 0;
        text-align: center;

        span {
            background-color: #ce3b85;
            position: relative;
            padding: 0 10px;
        }

        &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #edb457;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
        }
    }
}

.social-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;

    @media (max-width: 576px) {
        flex-direction: column;
        gap: 0px;
    }

    button {
        background: $boxbgColor;
        display: flex;
        align-items: center;
        border-radius: 50px;
        padding: 7px 10px;
        justify-content: center;
        min-width: 200px;
        font-weight: $primary-font-weight;
        line-height: 25px;
        color: $linkColor;

        @media (max-width: 576px) {
            padding: 6px 20px;
            min-width: 100%;
            margin-bottom: 15px;
        }

        img {
            margin-right: 10px;
        }
    }
}

.signup-landing {
    @media (max-width: 576px) {
        min-height: 100vh;
    }

    .social-buttons {
        flex-direction: column;

        button {
            min-width: 85%;
        }
    }

    .formField {
        max-width: 440px;
    }

    .logo {
        display: block;
        text-align: center;
        width: 160px;
        position: unset;
        margin: 30px auto;

        @media (max-width: 576px) {
            width: 130px;
            margin-bottom: 0;
        }
    }
}

.signup-wrp {
    .cmn-wrap {
        @media (max-width: 576px) {
            padding-top: 0;
        }
    }

    .logo {
        display: block;
        text-align: center;
        width: 160px;
        position: unset;
        margin: 30px auto 50px auto;

        @media (max-width: 576px) {
            width: 130px;
            margin-bottom: 30px;
        }
    }

    .form-field:has(.errormsg) .form-control {
        padding-right: 65px !important;
    }

    .form-field:has(.errormsg) .validationCheck {
        right: 41px;
    }
}

.social-buttons button:disabled {
    cursor: not-allowed;
}

.invite-partner {
    .SetBtn {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            justify-content: space-between;
        }

        .bgBtn {
            margin: 0 10px;

            @media (max-width: 767px) {
                width: 48%;
                margin: 0px;
            }
        }
    }
}

.link-button-pink {
    display: inline-block;
    background: none;
    padding: 0;
    color: $linkColor;
    font-weight: 600;
    margin: 15px auto 0;
    font-size: 15px;
    cursor: pointer;
}

.cellphone-input{
    input{
        padding-top: 6px !important;
    }
}
.colorPicker-wrp {
    h5 {
        font-size: 16px;
        color: #303030;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;
        margin-top: 35px;
    }

    .btnHolder {
        li {
            padding: 12px 5px 12px 5px;

            &:first-child {
                justify-content: center;
            }
        }

        @media (max-width: 576px) {
            font-size: 14px;

            li {
                margin: 2px;
            }
        }
    }
}
.image-picker-wrp{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    .col-block{
        width: calc(100% / 2);
        padding: 10px;
    }
    @media (max-width: 576px) {
        .image-picker-wrp {
            margin: 0 -5px;
        }
        .col-block {
            padding: 5px;
        }
    }
}

.img-color-box{
    padding: 5px;
    border: 1px solid #F7E1BF;
    background: #FAF5F2;
    overflow: hidden;
    border-radius: 10px;
cursor: pointer;
    &.active{
        background: #E23B8F;
    }
    figure{
        width: 100%;
        position: relative;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        span,
        img{
            position: relative !important;
            width: 100% !important;
            height: auto !important;
            padding: 0 !important;
        }
    }
    ul{
        width: 100%;
        display: flex;
        gap: 1px;
        justify-content: flex-end;
        padding: 10px 5px 5px 10px;
        @media (max-width: 767px) {
            padding: 5px 5px 0 5px;
        }
        li{
            display: block;
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: 1px solid #fff;
            margin-left: 5px;
            @media (max-width: 767px) {
                width: 19px;
            height: 19px;
            margin-left: 3px;
            }

        }
    }
}


.externalContractButtonContainer{
    display: flex;
    align-items: center;

    margin-bottom: 30px;
    .tooltipMsg
    {
        span{display: flex;}
        background: none;
        margin-right: 0;
        svg{
            margin-right: 0;}

    }

}

.swiper-pagination-bullet:only-child{
display: none;
}
video::-internal-media-controls-overflow-menu-list {
    max-height: 250px;
}

.profile-tasks-slider {
    .swiper-wrapper {
        align-items: center;
    }
      .accordion {
        padding: 15px 30px;
        border: 0.5px solid rgba(68, 73, 92, 0.25);
        border-radius: 20px;
        background: $boxbgColor;
         @media (max-width: 767px) {
            padding: 15px;
        }
        .accordion-item {
            .accordion-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                cursor: pointer;
                border-radius: 5px;

                h4 {
                    font-weight: 600;
                    font-size: 15px;
                }
                .accordion-arrow{
                display: flex;
                }
            }
            .accordion-content {
                margin: 0;
                border-top: 1px solid #F7E1BF;
                padding-top: 20px;
                margin-top: 10px;
                p{
                    margin-bottom: 15px;
                }
            }
        }
        .pinkBtn {
            display: block;
            margin: auto;
        }
    }
 .swiper-button-next {
        right: 5px;
        @media (max-width: 767px) {
            display: none;
        }
    }
 .swiper-button-prev {
        left: 5px;
        @media (max-width: 767px) {
            display: none;
        }
    }
}



  .popup-content-line {
    margin: 0;
    line-height: 1.2;
  }

  .popup-content-truncate {
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .popup-content-for-update
  {
    font-weight: 600;
  }

.manage-guest-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: solid 1px #f7e1bf;
    border-radius: 20px;
    width: 100%;
    background-color: $boxbgColor;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        padding: 15px;
    }

    h4 {
        font-weight: $primary-font-weight;
        font-size: 16px;
        font-family: $primaryFont;
        color: #d93386;
        margin-bottom: 15px;
        text-align: left;

        small {
            display: block;
            color: #dcc39a;
            font-weight: 400;
            font-size: 13px;
            margin-top: 5px;
        }
    }
.manage-guest-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
        background: none;
        margin-left: 10px;
        padding: 0;
        svg{
            stroke: #EFBD6C;
            margin-right: 0;
        }
    }
}
    .guest-age-wise-count {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #faf0f3;
            width: calc(100% / 2 - 5px);
            border-radius: 35px;
            position: relative;
            height: 30px;
            padding-left: 10px;


            span {
                font-size: 14px;
                display: block;
                padding: 5px;
                line-height: normal;
                color: $grayText;

                &.val {
                    background-color: #c5307b;
                    color: #fff;
                    min-width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                }
            }
        }
    }

    .guest-count {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-top: solid 1px #f7e1bf;
        padding-top: 15px;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            .number {
                width: 60px;
                height: 60px;
                background: #e23b8f;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #fff;
                font-size: 18px;
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    top: 5px;
                    bottom: 5px;
                    left: 5px;
                    right: 5px;
                    border: solid 1px rgba(255, 255, 255, 0.473);
                    border-radius: 50%;
                }
            }

            span {
                padding: 10px 0;
            }
        }
    }
}
.manage-guest-links {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px;
    margin-bottom: 10px;

    .link-child {
        background-color: $boxbgColor;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        border: solid 1px #f7e1bf;
        border-radius: 10px;
        margin: 0 5px;
        width: calc(100% / 4 - 10px);
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 25px;
        cursor: pointer;

        @media (max-width: 767px) {
            width: calc(50% - 10px);
            margin-bottom: 10px;
            font-size: 12px;
        }
    }
}

.links-icon-wrapper {
    background-color: #cf1f771a;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.checkbox-group-wrp {
    padding: 20px;
    border: 1px solid rgba(237, 180, 87, 0.7019607843);
    width: 100%;
    border-radius: 25px;
    margin-bottom: 15px;

    h5 {
        color: #E23B8F;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    position: relative;
    .errormsg {
        top: -2px;
        margin-top: 0 !important;
        right: 0;
    }
    }
    .circleLoader{
    margin: auto;
    }
}

.manually-add-guest-wrp {
    .cmn-label {
        font-size: 14px;
        color: #000000;
        margin: 0 0px 8px 5px;
        display: block;
    }

    .radio-btn-group {
        margin: 5px 0 15px 0;
        width: 100%;

        &+.radio-btn-group {
            margin-top: 15px;
        }
    }

    .custom-radio-btn .radio-label {
        font-size: 14px;
        font-weight: 500;
    }

    .custom-checkbox {
        &:last-of-type {
            @media (max-width: 767px) {
            margin-bottom: 0;
            }
        }

        label {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .action-btn-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 35px;

        button {
            max-width: 49%;
        }
    }
    .fileds .cmnInput textarea{
        padding: 12px 15px 5px 15px !important;
        line-height: normal;
        height: auto;
        min-height: 44px;
    }
    .fileds .ask-questions-textarea textarea{
        padding: 25px !important;
    }
}


.invited-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .custom-checkbox {
        width: 40%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.manage-dietary-link {
    background-color: $boxbgColor;
    display: flex;
    align-items: center;
    padding: 15px 15px;
    border: solid 1px #f7e1bf;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
    cursor: pointer;

    .links-icon-wrapper {
        margin-bottom: 0;
        margin-right: 8px;
    }

    .arrow-img {
        margin-left: auto;
    }
}

.add-menu-wrp {
    display: flex;
    flex-direction: column;

    h4 {
        font-size: 15px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .custom-checkbox {
        &:last-of-type {
            margin-bottom: 0;
        }

        label {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .action-btn-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 35px;

        button {
            max-width: 49%;
        }
    }
}

.manage-groups-wrp {
    .TemplateSreachWrap {
        width: 100%;

        img {
            margin-bottom: 0;
        }

        .fileds .cmnInput .form-field .form-control {
            width: 100%;
            border: 1px solid rgba(237, 180, 87, 0.7019607843) !important;
        }
    }
}

.unsubscribe-wrp{
    h5{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 25px;

        a{
           font-size: inherit;
           margin-top: 10px;
        }
    }
    h6{
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;

    }
    .custom-checkbox {
        align-items: center;
        border-radius: 10px;
        font-size: 14px;
        margin-bottom: 15px;
        justify-content: flex-start;
    }
    .custom-checkbox .checkbox-label {
        width: 16px;
        margin-right: 10px;
    }
}

.unsubscribe-popup-contents{
    ol{
        padding-left: 15px;
        li{
            text-align: left;
            line-height: 23px;
            margin-bottom: 15px;
        }
    }
}
.request-venue-recommendations{
    text-align: left !important;
    .underline-link {
        font-size: inherit;
    }
    p{
        margin-top: 0 !important;
        text-align: left !important;
    }
    ul{
        li{
            margin-bottom: 10px;
        }
        ul{
            padding-left: 30px;
            li{
                position: relative;
                margin-bottom: 10px;
                &::before{
                    content: "-";
                    position: absolute;
                    left: -10px;
                    top: -1px;
                }
            }
        }
    }
}