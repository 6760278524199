@import "vars";
@import "template-styles/template";

// builder add photo

.builder-container {
    max-width: 90%;
    margin: 0 auto;
    width: 100%;
    padding: 20px 15px;

    @media (max-width: 1400px) {
        max-width: 1360px;
    }

    svg {
        width: auto;
        margin-right: 0;
    }
}


.card-image {
    border-radius: 8px;
    border: 4px solid #fff;
    box-shadow: -1px 1px 4px 2px rgba(0, 0, 0, 0.07);

    img {
        border-radius: 8px;
        object-fit: cover;
        height: 500px;
        width: 100%;
    }
}

.template-details-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    text-align: center;

    .design-theme-colors button {
        width: 30px;
        height: 30px;
        @media (max-width: 767px) {
            width: 20px;
            height: 20px;
        }
    }
}

.dynamic-template-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .preview-content {
        max-width: 100%;
    }
}

.template-picked-preview {
    height: 100%;

    .builder-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .navigation-arrow {
        flex: 1;
        overflow: auto;

        .swiper-button-prev {
            left: 5px;
            width: 30px;
            height: 30px;
        }

        .swiper-button-next {
            right: 12px;
            width: 30px;
            height: 30px;
        }
    }

    .rosebud-kit-styles {
        pointer-events: none;
    }
}

.templates-design-wraper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.templates-design-card {
    width: calc(100% / 3);
    padding: 0 10px;
    flex: 0 0 auto;
    margin-bottom: 25px;

    @media (max-width: 991px) {
        width: calc(100% / 2);
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    .templates-design-img-block {
        border-radius: 8px;
        box-shadow: -1px 1px 4px 2px rgba(0, 0, 0, 0.07);
        background-color: #fff;
        border: 4px solid #fff;
        width: 100%;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        height: 315px;
        margin-bottom: 15px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &:hover {
            .templates-design-overlay {
                opacity: 1;
            }
        }

        .templates-design-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            transition: all 0.5s ease-in-out;
            background: #e43b8f85;
            inset: 0;
            margin: auto;
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
.templates-design-txt{
    h4{
        font-weight: 600;
    }
}
}

.builder-editer-options {
    background: #fff;
}

.template-editor-wrap {
    display: flex;
    gap: 35px;
    flex: 1;
    overflow: auto;
}

.fit-image {
    span,
    img {
        position: relative !important;
        width: 100% !important;
        height: auto !important;
        padding: 0 !important;
    }
}
.fit-image-mob{
    @media (max-width: 576px) {
        span,
        img {
            width: 100% !important;
            height: auto !important;
            padding: 0 !important;
        }
    }

}
.fit-image-mob-person{
    @media (max-width: 576px) {
        position: relative;
        span,
        img {
            width: 100% !important;
            height: auto !important;
            padding: 0 !important;
            position: initial !important;
            span{
                display: none !important;
            }
        }
    }
}

.builder-editor-wrp {
    height: 100%;

    .builder-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

.template-editor-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
 .template-editor-title{
    display: flex;
    align-items: center;
    gap: 10px;
    .wb-back-btn{
        background: #e5e5e5;
        border: none;
        margin-bottom: 8px;
        padding: 4px 7px;
    }
 }
    h2 {
        color: #000000;
        font-size: 24px;
        font-weight: 400;
        font-family: $secondaryFont;
        margin-bottom: 10px;
    }

    h6 {
        color: $grayText;
        font-size: 14px;
    }
}

.wbBtn {
    color: #ffffff;
    border: none;
    border-radius: 60px;
    font-family: "Kantumruy Pro", sans-serif;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 5px 25px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    height: auto;
    min-height: 34px;

    @media (max-width: 767px) {
        padding: 5px 12px;
        font-size: 12px;
    }
}

.wb-borderBtn {
    border: 1px solid rgb(236, 160, 79);
    background: none;
    color: #000000;
    transition: all .3s;

    &:hover {
        background: #d62c81;
        color: #fff;
        border-color: #d62c81;
        transition: all .3s;
    }
}

.wb-bgBtn {
    background: #d62c81;
    margin-left: 10px;
    transition: all .3s;

    &:hover {
        transition: all .3s;
        background: #b7075f;
    }

    @media (max-width: 767px) {
        margin-left: 6px;
    }
}

.mob-edit-btn {
    display: none;
    margin-left: 0;

    @media (max-width: 767px) {
        display: inline-block;
    }
}

.close-btn {
    width: 34px;
    padding: 0;
    margin-left: 10px;

    @media (max-width: 767px) {
        margin-left: 6px;
    }
}



.template-editor-options {
    background-color: #fff;
    width: 330px;
    flex: none;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 100%;

    .close-btn {
        display: none;
    }

    @media (min-width: 1600px) {
        width: 400px;
    }

    @media (max-width: 1280px) {
        width: 280px;
    }

    @media (max-width: 767px) {
        width: 100%;
        position: fixed;
        z-index: 111;
        left: calc(-100% - 40px);
        top: 0;
        height: 100%;
        background: #ffffff;
        box-shadow: 3px 1px 11px 0px #c1c1c1;
        transition: left .3s;

        &.open {
            transition: left .3s;
            left: 0%;
        }

        .close-btn {
            display: block;
            margin: 10px;
            margin-left: auto;
        }
    }
}

.editor-tabs-head {
    text-align: center;

    button {
        font-size: 18px;
        color: #000000;
        background: none;
        border-bottom: 1px solid #e3a91400;
        font-family: $secondaryFont;
        width: 50%;
        text-align: center;
        padding: 10px;

        &.active {
            border-color: #e3a914;
        }
    }
}

.editor-tabs-contents {
    padding: 25px;
    overflow: auto;
    flex: 1;

    h5 {
        font-size: 12px;
        color: $grayText;
        margin-bottom: 15px;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }
}
.predesigned-templates-list-wrp{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: -15px;
}

.predesigned-templates {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    overflow: auto;
    flex-grow: 1;
    padding-right: 10px;
}

.predesigned-templates-card {
    max-width: calc(50% - 5px);
    flex-grow: 1;
    margin-bottom: 10px;
    .custom-checkbox .checkbox-label {
        display: none;
    }

    &.selected{
        .custom-checkbox {
        .checkbox-label {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            display: block;
            .checkmark {
                top: 10px;
                right: 10px;
                border: none;
                border-radius: 50%;

                &:after {
                    top: 2px;
                }
            }
        }
        }
    }


    h4 {
        font-size: 12px;
        color: #000000;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }

    .custom-checkbox {
        margin-bottom: 0px;
        cursor: pointer;

        figure {
            width: 100%;

            img {
                width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
            }
        }


    }
}

.design-theme-colors {
    display: flex;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
    gap: 5px;

    button {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #ffffff;
      flex: none;

        &.active {
            border-color: #e3a914;
        }
    }
}

.template-preview {
    background-color: #fff;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .preview-header {
        padding: 10px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #f6f1ea;

        h4 {
            font-size: 12px;
            color: $grayText;
            margin-left: 10px;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }
    }
}

.view-switch-btn {
    background-color: #f3f3f3;
    border-radius: 4px;
    display: inline-flex;

    button {
        border-radius: 4px;
        border: 1px solid #ffffff00;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            margin: 0;
            width: auto;
        }

        &.active {
            border-color: #dbdbdb;
            background-color: #ffffff;

            svg {
                path {
                    stroke: #061130;
                }
            }
        }
    }
}

.preview-btn {
    margin-left: auto;
    background: none;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: $primary-font-weight;

    @media (min-width: 1600px) {
        font-size: 14px;
    }
}

.preview-content {
    width: 100%;
    padding: 25px;
    min-height: 300px;
    transition: max-width .8s;
    margin: auto;
    max-width: 1500px;
    height: 100%;

    &.wb-mobile-view {
        max-width: 375px;
        transition: max-width .5s;
    }
    @media (max-width: 767px) {
        padding: 5px;
    }
}

.design-pages-title-btn {
    width: 100%;
    font-size: 13px;
    color: $bodyTextColor;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #b8b8b8;
    background: none;
    padding: 12px 0;

    svg {
        width: 12px;
        margin-right: 0;
    }

    @media (min-width: 1600px) {
        font-size: 14px;
    }
}

.side-panel-editor {
    .side-panel-head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #b8b8b8;
        margin-bottom: 15px;
        padding: 12px 0;

        .side-panel-back-btn {
            font-size: 13px;
            color: $bodyTextColor;
            font-weight: 600;
            background: none;
            padding: 0;

            svg {
                height: 9px;
                transform: rotateY(180deg);
                width: 12px;
                margin-right: 10px;
            }

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

        .wb-delete-with-text-btn {
            margin-top: 0;
            padding: 0;

            svg {
                margin-right: 0;
            }
        }
    }

}

.wb-action-btns {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;

    .wbBtn {
        flex: 1;
    }

    .wb-bgBtn {
        margin-left: 0;
    }
}

.wb-accordion {
    margin-bottom: 10px;

    .wb-accordion-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-size: 12px;
            color: #000000;
            font-weight: $primary-font-weight;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

        .wb-accordion-icon {
            transition: transform 0.3s ease-in-out;

            &.open {
                transform: rotateX(180deg);
            }

            svg {
                vertical-align: middle;
                width: auto;
                margin-right: 0;
            }
        }
    }

    .wb-accordion-content {
        max-height: 0;
        overflow: hidden;

        &.open {
            max-height: 100%;
            padding: 15px 0;
        }
    }

    .side-panel-editor {
        padding-left: 10px;

        .side-panel-head {
            padding-top: 0;
        }

        &+.side-panel-editor {
            .side-panel-head {
                padding-top: 15px;
                border-top: 1px solid #c5c5c5;
                margin-top: 15px;
            }
        }
    }

}

.wb-input {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 6px 8px;
    margin-bottom: 10px;

    label {
        color: $grayText;
        font-size: 12px;
        margin-bottom: 5px;
        display: block;

        @media (min-width: 1600px) {
            font-size: 13px;
        }
    }

    input {
        border: none;
        color: #000000;
        font-weight: $primary-font-weight;
        font-size: 12px;
        width: 100%;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }

    textarea {
        border: none;
        color: #000000;
        font-weight: $primary-font-weight;
        font-size: 12px;
        width: 100%;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }
}

.wb-select {
    .wb-select-wrp {
        text-align: initial;

        .wb-select__control {
            .wb-select__value-container {
                padding: 0 !important;
            }

            border: none;
            width: 100%;
            background: none;
            box-shadow: none;
            cursor: pointer;
            min-height: auto;
            color: #000000;
            font-size: 12px;
            font-weight: $primary-font-weight;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

        .wb-select__menu {
            z-index: 1111;
            margin-top: 0px;
        }

        .wb-select__option {
            font-size: 12px;
            color: #000000;
            padding: 8px;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

        .wb-select__option--is-selected {
            color: #fff;
            background: #d62c81;
        }

        .wb-select__indicator-separator {
            display: none;
        }

        input {
            height: auto;
        }

        .wb-select__indicator {
            padding: 0 !important;
            width: 16px;
        }

        .wb-select__option--is-disabled {
            background-color: #80808045;
        }

        .wb-select__single-value,
        .wb-select__placeholder {
            font-size: 12px;
            color: #000000;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }
    }
}

.wb-row-2 {
    display: flex;
    gap: 10px;

    .wb-input-wrapper {
        flex: 1 1;
        width: 50%;
    }
}

.wb-visibility-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
        font-size: 12px;
        color: $grayText;

        @media (min-width: 1600px) {
            font-size: 13px;
        }
    }

    .view-switch-btn {
        margin-left: 5px;
    }
}

.wb-cover-photo-content {
    .view-switch-btn {
        margin-right: 10px;
    }

    h4 {
        display: inline-flex;
        color: $grayText;
        font-size: 12px;
        vertical-align: middle;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }

    figure {
        width: 100%;
        position: relative;
        margin: 10px 0;

        span,
        img {
            position: relative !important;
            width: 100% !important;
            height: auto !important;
            padding: 0 !important;
        }
    }
}

.wb-underline-btn {
    background: none;
    color: $linkColor;
    font-size: 12px;
    border-bottom: 1px solid;
    padding: 0;
    flex: none;

    @media (min-width: 1600px) {
        font-size: 14px;
    }
}

.wb-underline-link {
    a {
        font-size: 12px;
        border-bottom: 1px solid;
        padding: 0;
        color: $linkColor;

        @media (min-width: 1600px) {
            font-size: 14px;
        }

    }
}

.wb-got-btn {
    font-size: 12px;
    color: #000000;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    font-weight: $primary-font-weight;

    @media (min-width: 1600px) {
        font-size: 14px;
    }

    svg {
        width: auto;
        margin-right: 0;
    }
}



.wb-btn-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    button {
        font-size: 12px;
        color: #000000;
        background: none;
        padding: 5px 6px;
        border-radius: 30px;

        @media (min-width: 1600px) {
            font-size: 14px;
        }

        &.active {
            color: #FFFFFF;
            background-color: #D62C81;

        }
    }
}

.panel-sub-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 0;

    h4 {
        color: #000000;
        font-size: 12px;
        font-weight: $primary-font-weight;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }
}

.wb-events-box {
    border: 1px solid #F3F3F3;
    padding: 20px;

    h4 {
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: $primary-font-weight;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }

    h5 {
        margin-bottom: 8px;
        font-size: 13px;
    }
}

.bordered-btn-wrp {
    button {
        border: 1px dashed #005FA4;
        padding: 30px;
        text-align: center;
        width: 100%;
        margin-top: 16px;

        span {
            border-bottom: 1px solid #000000;
        }
    }
}

.wb-live-stream-links {
    p {
        color: #000000;
        font-size: 14px;
        margin-bottom: 15px;
        font-weight: $primary-font-weight;
    }

    h5 {
        font-size: 13px;
    }

    h4 {
        font-size: 14px;
        color: $grayText;
        word-break: break-all;
    }

    hr {
        border-color: #F3F3;
        border-width: 1px;
    }
}

.wb-text-view-card {
    cursor: pointer;

    p {
        color: #000000;
        font-size: 14px;
        margin-bottom: 15px;
        font-weight: $primary-font-weight;
    }

    h5 {
        font-size: 13px;
    }
}

.wb-title-view-card {
    cursor: pointer;

    h5 {
        font-size: 13px;
    }

    .small-title {
        color: #000000;
        font-size: 13px;

        @media (min-width: 1600px) {
            font-size: 14px;
        }

    }

    .large-title {
        color: #000000;
        font-size: 16px;
        font-weight: $primary-font-weight;

        @media (min-width: 1600px) {
            font-size: 18px;
        }
    }
}

.wb-image-view-card {
    cursor: pointer;

    h5 {
        font-size: 13px;
        margin-bottom: 8px;
    }

    h4 {
        font-size: 14px;
        color: #000000;
        word-break: break-all;
        font-weight: $primary-font-weight;
        margin-bottom: 15px;
    }

    .image-part-container {
        display: flex;
        gap: 15px;
    }

    .wb-underline-btn {
        margin-top: 20px;
    }
}

.editor-tabs-wrap {
    p {
        color: #000000;
        font-size: 12px;
        margin: 16px 0;
        font-weight: 400;
        line-height: 18px;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }

    .button-wraper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        list-style: none;
        padding: 0;
        gap: 10px;

        button {
            width: calc(100% / 3 - 10px);
            padding: 0 10px;
            border-radius: 5px;
            border: 1px solid #F3F3F3;
            background: transparent;
            height: 76px;
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            svg {
                width: 24px;
                height: 24px;
                object-fit: cover;
                margin: 0 auto;
                display: block;
            }

            span {
                color: $grayText;
                font-size: 11px;
                font-weight: 400;
                line-height: 8px;
                line-height: 14px;

                @media (min-width: 1600px) {
                    font-size: 13px;
                }
            }
        }
    }
}

.editor-title-wrap {
    p {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 10px;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }
}

.wb-radio {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    input[type="radio"] {
        padding: 0;
        margin: 0;
        vertical-align: top;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 100px;
        border: 1px solid $grayText;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: none;
    }

    input:checked[type=radio] {
        border: 5px solid #D9177E;
    }

    &.wb-radio-sm {
        label {
            font-size: 12px;
            display: inline-flex;
            align-items: center;
        }
    }

    &.wb-radio-lg {
        label {
            font-size: 16px;
            font-weight: 700;
        }
    }
}

.wb-text-editor-wrap {
    .wb-text-editor-block {
        margin-bottom: 16px;
    }

    h5 {
        margin-bottom: 16px;
    }


}

.event-information-wrap {
    .event-schedule {
        p {
            margin: 16px 0;
            color: #000;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

        a {
            color: $linkColor;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            border-bottom: 1px solid;
            line-height: normal;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

        .radio-wrap {
            h5 {
                margin-top: 5px;
            }
        }
    }
}

.photo-upload {
    position: relative;
    height: 245px;
    margin-bottom: 15px;

    .photo-input {
        z-index: 1;
        position: relative;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .upload-button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 20px;
        background-color: #EEEEEE;
        border: 1px dotted #B4B4B4;
        border-radius: 4px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;

        span {
            font-size: 13px;
            color: $bodyTextColor;
            font-weight: 400;

            @media (min-width:1600px) {
                font-size: 15px;

            }
        }

        svg {
            width: 45px;
        }
    }

}

.wb-upload-photo-grid {
    margin-top: 20px;

    .wb-upload-photo-grid-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        row-gap: 10px;
        margin-bottom: 15px;

        .wb-upload-photo {
            padding: 0 5px;
            width: calc(100% / 3);
            position: relative;
            cursor: pointer;

            img {
                object-fit: cover;
            }

            .Wb-button-wrp {
                display: flex;
                flex-direction: column;
                opacity: 0;
            }

            &:hover .Wb-button-wrp {
                opacity: 1;
                transition-duration: .1s;
            }

            &:hover .wb-edit-without-text-btn {
                opacity: 1;
                transition-duration: .1s;
            }

        }
    }
}

.wb-edit-without-text-btn {
    background-color: #fdfdfd;
    border-radius: 50px;
    padding: 2px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 31px;

    @media (min-width:1600px) {
        right: 33px;
    }

    svg {
        stroke: #D62C81;
        width: 10px;
        margin: 0;
    }
}

.wb-delete-round-btn {
    background-color: #fdfdfd;
    border-radius: 50px;
    padding: 2px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 8px;

    @media (min-width:1600px) {
        right: 10px;
    }

    svg {
        stroke: $darkRed;
        width: 11px;
        margin: 0;
    }
}

.wb-add-more-btn {
    background: transparent;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0;

    p {
        color: $linkColor;
        font-size: 12px;
        font-weight: 400;
        border-bottom: 1px solid;
        line-height: normal;

        @media (min-width:1600px) {
            font-size: 14px;

        }
    }

    svg {
        width: 12px;
        height: 12px;
        border: 1px solid $linkColor;
        border-radius: 50px;
        margin-right: 5px;

        @media (min-width:1600px) {
            width: 14px;
            height: 14px;
            padding: 2px;

        }

        path {
            stroke: $linkColor;
        }

    }
}

.wb-upload-gif-grid {
    .wb-upload-gif {
        padding: 0 5px;
        width: calc(100% / 1);
        position: relative;

        img {
            object-fit: cover;
        }
    }
}

.wb-add-memories-wrp {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .wb-memory-photo {
        position: relative;
        width: 35%;
        padding: 0 6px;
    }

    .wb-memory-content {
        width: 65%;
        padding: 0 6px;
    }
}

.wb-delete-with-text-btn {
    display: flex;
    flex-direction: row;
    background: transparent;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;

    svg {
        stroke: $darkRed;
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }

    p {
        color: $darkRed;
        font-size: 12px;
        font-weight: 400;
        border-bottom: 1px solid;
        line-height: normal;

        @media (min-width:1600px) {
            font-size: 14px;
        }
    }
}

// upload image
.wb-upload-image {
    position: relative;
    height: 110px;
    margin-bottom: 10px;

    .wb-upload-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: -1000px;
    }

    .wb-upload-button {
        padding: 10px 20px;
        background-color: #EEEEEE;
        border: 1px dotted #B4B4B4;
        border-radius: 4px;
        cursor: pointer;
        max-height: 110px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 12px;
            height: 12px;
            border: 1px solid $linkColor;
            border-radius: 50px;
            margin-right: 5px;

            @media (min-width:1600px) {
                width: 14px;
                height: 14px;
                padding: 2px;

            }

            path {
                stroke: $linkColor;
            }
        }

        span {
            color: $linkColor;
            font-size: 12px;
            font-weight: 400;
            border-bottom: 1px solid;
            line-height: normal;

            @media (min-width:1600px) {
                font-size: 14px;
            }
        }
    }

    .wb-add-more-btn {
        margin-top: 0;
    }

    &.upload-link-btn {
        height: auto;
        display: inline-block;

        .wb-upload-button {
            background: no-repeat;
            height: auto;
            width: auto;
            padding: 0;
            border: none;
        }
    }

}

.Wb-textarea-bottom-text {
    color: $grayText;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    @media (min-width:1600px) {
        font-size: 14px;
    }
}

.wb-Edit-photo-sec {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.wb-secondary-btn {
    background: transparent;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p {
        color: $linkColor;
        font-size: 12px;
        font-weight: 400;
        border-bottom: 1px solid;
        line-height: normal;

        @media (min-width:1600px) {
            font-size: 14px;

        }
    }
}

.wb-add-wedding-party-wrp {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .wb-add-wedding-party-card {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:1440px) {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 5px;

        }

        .wb-add-person-photo-wrp {
            display: flex;
            align-items: center;
            gap: 10px;

            h4 {
                font-size: 14px;
                font-weight: 700;
                color: #000;
                line-height: 9px;
                margin-bottom: 15px;

                @media (min-width:1600px) {
                    font-size: 15px;

                }
            }

            h6 {
                font-size: 11px;
                font-weight: 400;
                color: $grayText;
                line-height: 8px;

                @media (min-width:1600px) {
                    font-size: 12px;
                }
            }
        }
    }

    .wb-edit-btn {
        margin-bottom: 0;
    }
}

.wb-add-transport-wrp {
    .wb-transport-delete {
        display: flex;
        justify-content: flex-end;
    }
}

.wb-add-transport-content-wrp {
    .wb-transport-photo-card {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border-bottom: 1px solid #F3F3F3;
        padding: 0 0 15px 0;

        @media (max-width:1440px) {
            flex-direction: column;

        }
    }

    h6 {
        font-size: 11px;
        color: $grayText;
        font-weight: 400;
        line-height: 8px;
        margin-bottom: 10px;

        @media (min-width:1600px) {
            font-size: 12px;
        }
    }

    h4 {
        font-size: 14px;
        color: #000;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 14px;
        color: #000;
        font-weight: 400;
        line-height: 9px;
        margin-bottom: 15px;
    }

    .wb-transport-content {
        .wb-transport-content-card {
            border-bottom: 1px solid #F3F3F3;
            margin-bottom: 10px;
        }
    }
}

.wb-toggle-switch-btn {
    display: inline-flex;

    .toggle-label {
        position: relative;
        display: inline-block;
        width: 24px;
        height: 15px;
        background-color: #ccc;
        border-radius: 30px;
        cursor: pointer;

        &:after {
            content: "";
            position: absolute;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #fff;
            top: 2px;
            left: 3px;
            transition: all 0.5s;
        }
    }

    input {
        &:checked {
            + {
                .toggle-label {
                    &::after {
                        left: 10px;
                    }

                    background-color: #e23b8f;
                }
            }
        }

        display: none;
    }

}
.wb-privacy-url {
    .wb-accordion .wb-accordion-content.open {
        padding: 8px 0;
    }


    .privacy-url-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    h4 {
        font-size: 12px;
        color: #000000;

        button {
            background: none;
            vertical-align: middle;

            svg {
                width: 12px;
                margin-right: 0;
                height: 15px;
                path {
                    stroke: $linkColor;
                }
            }
        }

        @media (min-width:1600px) {
            font-size: 14px;
        }

        small {
            margin-top: 3px;
            color: $grayText;
            display: block;
        }
    }

    svg {
        vertical-align: middle;
    }
}

.privacy-accordion {

    &.wb-accordion .wb-accordion-content {
        &.open {
            padding-bottom: 0;
            margin-bottom: 0px;
        }
    }
    .panel-sub-heading {
        margin-top: 20px;
    }
}
.wb-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 85%);
    flex-wrap: wrap;
    overflow: auto;
    align-items: center;
    justify-content: center;
    z-index: 9999 !important;

    &.show {
        display: flex;
    }

    .wb-popup {
        width: 420px;
        max-width: 95%;
        min-height: 150px;
        padding: 40px;
        background-color: #fcf9f7;
        text-align: center;
        position: relative;
        z-index: 9999;
        margin: 10px 0;
        overflow: hidden;

        h2 {
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            padding-bottom: 10px;
            color: $red;
        }

        @media (max-width: 767px) {
            padding: 35px 15px 15px;
            max-width: 350px;
        }
        .close-popup-btn{
            width: 20px;
            height: 20px;
            padding: 0;
            position: absolute;
            right: 6px;
            top: 6px;
            background: #ff7777;
            color: #fff;
            border-radius: 50%;
        }

    }
}

.crop-popup {
    .wb-popup {
        width: 525px;
    }

    .crop-popup-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h4 {
            font-weight: $primary-font-weight;
            font-size: 14px;
            color: $bodyTextColor;

            @media (min-width: 1600px) {
                font-size: 16px;
            }
        }

        .crop-action-btns {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                background: none;
                display: inline-flex;
                padding: 1px;
                border-radius: 2px;

                &.right-rotate {
                    transform: rotateY(180deg);
                }
            }

            button.active {
                border: 1px solid rgb(213, 213, 255);

                svg {
                    rect {
                        fill: rgb(213, 213, 255);
                    }
                }
            }
        }

        .crop-btn-grp {
            border-right: 1px solid #DCDCDC;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            padding: 0 10px;
        }

        h6 {
            font-size: 13px;
            color: $bodyTextColor;
            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }
    }

    .button-group {
        text-align: right;
        margin-top: 20px;
    }
}

.wb-ranger {
    padding: 15px;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    gap: 10px;


    input {
        background: #D62C81;
        border-radius: 8px;
        height: 7px;
        width: 100%;
        outline: none;
        -webkit-appearance: none;

        &~svg {
            width: 20px;
            height: 20px;
        }
    }

    input::-webkit-slider-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        -webkit-appearance: none;
        cursor: ew-resize;
        background: #D62C81;
    }
}

.reorder-menu {
    li {
        width: 100%;
        font-size: 13px;
        color: $bodyTextColor;
        font-weight: 600;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #b8b8b8;
        background: none;
        padding: 12px 0;

        .wb-delete-with-text-btn {
            margin-top: 0;
        }

        .wb-drag-point {
            margin-right: 10px;
            margin-top: 2px;
        }

        em {
            margin-left: auto;
            margin-right: 8px;
            font-weight: 500;
            font-style: normal;
        }
    }
}

.reorder-btn-wrp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;

    button {
        border: none;

        svg {
            margin-right: 5px;
            vertical-align: middle;
        }

        span {
            border-bottom: 1px solid $linkColor;
        }
    }
}

.custom-upload {
    background-color: #008000;
    border: 1px solid #006400;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    padding: 4px 10px;
}

.custom-upload input {
    left: -9999px;
    position: absolute;
}

.wb-datepicker {
    .rdtPicker {
        min-width: 200px;
        border: 1px solid #e7e7e7;
    }

    .rdtPicker td,
    .rdtPicker th {
        font-size: 13px;
        height: 20px;
    }
}
.wb-timepicker{
    .rdtPicker {
        min-width: 145px;
        border: 1px solid #e7e7e7;
    }
}
.right-align{
    .wb-datepicker .rdtPicker {
        right: 0;
    }
}

.wb-customize-domain {
    background-color: #FFFFFF;
    padding: 25px 30px;
    width: 450px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
    @media (max-width: 767px) {
        padding: 15px;
    }

    h3 {
        font-size: 18px;
        color: #000000;
        font-family: $secondaryFont;
        text-align: center;
        margin-bottom: 8px;

        @media (min-width: 1600px) {
            font-size: 20px;
        }
    }

    h6 {
        font-size: 12px;
        color: $grayText;
        text-align: center;
        margin-bottom: 25px;

        @media (min-width: 1600px) {
            font-size: 14px;
        }
    }

    .side-panel-editor {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .wb-action-btns {
        margin-top: auto;
    }

    .wb-row-2 {
        @media (max-width: 576px) {
            display: block;

            .wb-input-wrapper {
                width: 100%;
            }
        }

    }

    .wb-radio {
        margin-bottom: 0;
        input[type=radio] {
            border: none;
            background-color: #D9D9D9;
            width: 15px;
            height: 15px;

            &:checked[type=radio] {
                border: 4px solid #D9D9D9;
                background-color: #1E1E1E;
            }
        }

        label {
            color: $grayText;
            font-size: 12px;
            word-break: break-word;
            margin-right: 5px;

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }

    }
}
.domain-radio-wrp{
    display: flex;
    margin-bottom: 15px;

    .domain-price{
        color: #D62C81;
        font-weight: $primary-font-weight;
        font-size: 12px;
        margin-left: auto;
            @media (min-width: 1600px) {
                font-size: 14px;
            }
    }
}

.wb-domain-fail-message {
    h6 {
        margin-bottom: 15px;
    }

    hr {
        border-style: dashed;
        border-color: #BBBBBB;
        margin-bottom: 25px;
    }
}

.wb-dropdown {
    position: relative;
    display: inline-block;

    .wb-dropdown-button {
        background-color: #4CAF50;
        color: white;
        padding: 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }

    .arrow {
        margin-left: 10px;
        transition: transform 0.3s ease;

        svg {
            width: 10px;
            height: 10px;
            vertical-align: middle;

            path {
                stroke: rgb(255 255 255);
            }
        }

        &.rotate {
            transform: rotateX(180deg);
        }
    }



    .wb-dropdwon-content {
        display: block;
        position: absolute;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1019607843);
        min-width: 100%;
        width: 150px;
        right: 0;
        border: 1px solid #E7E7E7;
        border-radius: 5px;
        top: calc(100% + 16px);

        &::before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            top: -14px;
            right: 4px;
            background-repeat: no-repeat;
            z-index: -1;
            background-image: url(../public/images/dropdown-arrow.png);
        }

        li {
            list-style: none;
            cursor: pointer;

            &:hover {
                background-color: #f1f1f1;
            }

            &+li {
                border-top: 1px solid #E7E7E7;
            }
        }
    }
}

.share-website-dropdown {
    .wb-dropdwon-content {
        width: 250px;

        @media (min-width: 1600px) {
            width: 350px;
        }

        button {
            display: flex;
            color: #000000;
            font-size: 13px;
            padding: 15px;
            text-align: left;
            background: none;

            svg {
                margin-right: 10px;
            }

            small {
                display: block;
                color: $grayText;
                margin-top: 5px;
            }

            @media (min-width: 1600px) {
                font-size: 14px;
            }
        }
    }
}

.swpap-info-btn {
    color: #0273E6;
    font-size: 11px;
    background-color: #F0F4F8;
    border: 1px solid #E3EBF2;
    border-radius: 5px;
    padding: 10px 8px;
    margin: 5px auto 15px;
    display: block;

    svg {
        vertical-align: middle;
        margin-right: 6px;
        path{
            fill: #0273E6;
        }
    }

    @media (min-width: 1600px) {
        font-size: 13px;
    }
}

.wb-text-editor {
    .ql-toolbar.ql-snow {
        border: none;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid #ccc;
        padding: 0;
        padding-bottom: 5px;
        margin-bottom: 5px;
        font-family: $primaryFont;
    }

    .ql-container.ql-snow {
        border: none;
    }

    .ql-editor {
        padding: 0;
        height: 100px;
    }

    .ql-snow .ql-tooltip {
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 0 !important;

        &.ql-hidden {
            display: none;
        }
    }
}

.wb-error-message {
    color: red;
    font-size: 10px;
    margin-bottom: 15px;
    margin-top: -5px;

    @media (min-width: 1600px) {
        font-size: 12px;
    }
    &+.wb-error-message{
        margin-top: -10px;
    }
}

.order-summary-table {
    margin-top: 30px;

    table {
        width: 100%;
    }

    th,
    td {
        color: $grayText;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 0px;
    }

    th {
        text-align: left;
    }

    td {
        text-align: right;
    }

    tr {
        &:first-child {
            th,
            td {
                border-bottom: 1px dashed #BBBBBB;
            }

        }
        &:last-child {
            th,
            td {
                color: #000000;
            }
        }
    }
}

//order-success popup

.wb-order-successful-popup{
    .wb-popup{
        padding: 30px;
        background-color: #FFFFFF;
        border-radius: 5px;
        text-align: center;
        max-width: 400px;
        margin: 0 auto;
        position: relative;
        z-index: 9999;

    .wb-order-successful-popup-body{

        h3{
            font-size: 24px;
            color: #000000;
            text-align: center;
            margin-bottom: 8px;
            line-height: 32.9px;
            font-weight: 400;
            font-family: $secondaryFont;

        }

        p {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: $grayText;
            font-family: $primaryFont;
            text-align: center;


        }
        svg{
            width: 100%;
        }
    }

 }
}
.gift-registry-wrp{
    h6{
        font-size: 11px;
        color: $grayText;
        margin-bottom: 10px;
        @media (min-width: 1600px) {
            font-size: 13px;
        }
    }
    h4{
        font-size: 14px;
        color: #000000;
        font-weight: $primary-font-weight;
        @media (min-width: 1600px) {
            font-size: 16px;
        }
    }
    .registry-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        border-bottom: 1px solid #F3F3F3;
        padding-bottom: 15px;

    }
}
.registry-type-box {
    background-color: #FAF8F5;
    text-align: center;
    margin-top: 15px;
    padding: 20px;

    @media (max-width: 767px) {
        padding: 10px;
    }

    .wb-action-btns {
        @media (min-width:767px) and (max-width: 1280px) {
            flex-direction: column;
        }
    }

    .wbBtn {
        margin-left: 0;
        margin-top: 15px;

        @media (max-width: 1400px) {
            padding: 5px 8px;
        }


    }
}
.registries-wrp{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .fit-image{
        width: 100%;
        max-width: 150px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 567px) {
         height: auto;
        }
        span,
        img{
            span{
                display: none !important
            }
            height: 100% !important;
        }
    }
    .registry-box{
        padding: 25px 20px;
        background-color: #FFFFFF;
        position: relative;
        width: calc((100% / 5) - 12px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 1400px) {
            width: calc((100% / 3) - 12px);
        }
        @media (max-width: 767px) {
            width: calc((100% / 2) - 12px);
        }
        @media (max-width: 567px) {
            width: 100%;
        }
    }
}
.redirection-details-wrp{
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 13px;
    padding: 60px 30px;
    gap: 20px;
    @media (max-width: 991px) {
        flex-direction: column;
    }
    @media (max-width: 767px) {
        padding: 15px;
    }
    .redirection-video{
        width: 50%;
        video,
        img{
            max-width: 100%;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    h3{
        font-size: 24px;
        color: #000000;
        font-family: $secondaryFont;
        margin-bottom: 15px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
    ul{
        li{
            display: flex;
            font-size: 13px;
            color: #000000;
            margin-bottom: 15px;
            align-items: baseline;
            line-height: 18px;
            @media (min-width: 1680px) {
                font-size: 15px;
                line-height: 20px;
            }
            b{
                font-weight: 600;
            }
            svg{
                width: 10px;
                margin-right: 5px;
                flex: none;
            }
        }
    }
    .link-button{
        font-size: inherit;
        svg{
            margin-right: 0;
        }
    }
}
.overlay {
    .domainreminderPopup {
        p {
            line-height: 25px;
            margin: 30px 0 60px;

            b {
                font-weight: 600;
                display: block;
            }
        }
    }
}